import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FILTERS_KEY, IFiltersState } from '../models/filters.model';
import { FiltersOptions } from 'src/app/shared/enums';

export const selectFeature = createFeatureSelector<IFiltersState>(FILTERS_KEY);

export const selectFiltersState = createSelector(
  selectFeature,
  (state: IFiltersState) => state.filtersState,
);

export const selectNumOfActiveFilters = createSelector(
  selectFeature,
  (state: IFiltersState) => state.numOfActiveFilters,
);

export const selectFiltersMenuState = createSelector(selectFeature, (state: IFiltersState) => {
  return {
    filters: state.filtersMenuState,
    query: state.filtersState.query,
  };
});

export const selectFiltersMenuHaveOptions = (excludeOptions?: FiltersOptions[]) =>
  createSelector(selectFeature, (state: IFiltersState): boolean =>
    Boolean(
      (state.filtersMenuState?.filtersMenuParams &&
        state.filtersMenuState?.filtersMenuParams !== null &&
        Object.keys(state.filtersMenuState?.filtersMenuParams!)
          .filter((option) => !excludeOptions?.includes(option as FiltersOptions))
          .some(
            (option) =>
              state.filtersMenuState?.filtersMenuParams?.[option as FiltersOptions]?.params &&
              state.filtersMenuState?.filtersMenuParams?.[option as FiltersOptions]?.params?.length,
          )) ||
        state.filtersMenuState?.favs,
    ),
  );

export const selectFiltersStateHaveOptions = (excludeOptions?: FiltersOptions[]) =>
  createSelector(selectFeature, (state: IFiltersState): boolean =>
    Boolean(
      (state.filtersState.filtersList &&
        state.filtersState.filtersList !== null &&
        Object.keys(state.filtersState.filtersList)
          .filter((option) => !excludeOptions?.includes(option as FiltersOptions))
          .some(
            (option) =>
              state.filtersState.filtersList?.[option as FiltersOptions]?.params &&
              state.filtersState.filtersList?.[option as FiltersOptions]?.params?.length,
          )) ||
        state.filtersState.favs,
    ),
  );

export const selectCurrentFilterHaveOptions = (option: FiltersOptions) =>
  createSelector(selectFeature, (state: IFiltersState): boolean =>
    Boolean(
      state.filtersMenuState &&
        state.filtersMenuState?.filtersMenuParams &&
        state.filtersMenuState?.filtersMenuParams?.[option] !== null &&
        state.filtersMenuState?.filtersMenuParams?.[option]?.params?.length,
    ),
  );

export const selectEndTrainingDate = createSelector(
  selectFeature,
  (state: IFiltersState) =>
    state.filtersMenuState?.filtersMenuParams?.[FiltersOptions.endTraining]?.params?.[0],
);

export const selectIsAllElementsFetched = createSelector(
  selectFeature,
  (state: IFiltersState) => state.isAllFiltersElementsFetched,
);

export const selectElementsListIsLoading = createSelector(
  selectFeature,
  (state: IFiltersState) => state.isFiltersElementsListLoading,
);

export const selectActiveFiltersElementsList = createSelector(
  selectFeature,
  (state: IFiltersState) => state.filtersElementsList,
);
