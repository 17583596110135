import { first, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { Channel, LocalNotifications } from '@capacitor/local-notifications';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { UserService } from '../user/user.service';
import { CurrentPlatform } from '../../enums';
import { befreeUrlValues } from '../../constants';
import { TPushNavigationPayload, TPushMessage } from './push.type';
import { StorageService } from '../storage/storage.service';
import { StorageType } from '../storage/storage.type';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  private platform: CurrentPlatform | null = null;

  private channelId = 'befree.bsn';

  constructor(
    private userService: UserService,
    private storage: StorageService,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  private createChannel() {
    if (this.platform !== CurrentPlatform.android) {
      return;
    }

    const notificationChannel: Channel = {
      id: this.channelId,
      name: 'Befree BSN',
      importance: 5,
      visibility: 1,
    };

    PushNotifications.createChannel(notificationChannel);
    LocalNotifications.createChannel(notificationChannel);
  }

  private handleNotification(action: ActionPerformed) {
    if (action.actionId !== 'tap') {
      return;
    }

    try {
      const commentData: TPushMessage = action.notification.data;

      if (commentData) {
        let navigateUrl = '';
        let payload: TPushNavigationPayload = { commentId: commentData.commentId };

        if (commentData.commentReplyForCommentId) {
          payload = {
            commentId: commentData.commentReplyForCommentId,
            replyCommentId: commentData.commentId,
          };
        }

        switch (commentData.commentType) {
          case 'Post':
            navigateUrl = `${befreeUrlValues.dashboard}/${befreeUrlValues.posts}/${commentData.commentPostId}`;
            break;

          case 'PhotoReport':
            navigateUrl = `${befreeUrlValues.dashboard}/${befreeUrlValues.photoreports}/${befreeUrlValues.page}/${commentData.commentPhotoReportId}`;
            break;

          case 'PhotoReportImage':
            payload = { ...payload, imageId: commentData.commentImageId };
            navigateUrl = `${befreeUrlValues.dashboard}/${befreeUrlValues.photoreports}/${befreeUrlValues.page}/${commentData.commentPhotoReportId}`;
            break;

          default:
            break;
        }

        navigateUrl += `?t=${new Date().getTime()}`;

        if (navigateUrl) {
          this.ngZone.run(() => this.router.navigate([navigateUrl], { state: payload }));
        }
      }
    } catch (error) {
      console.error('Push notification parsing error', error);
    }
  }

  private async addListeners() {
    await PushNotifications.addListener('registration', (token) => {
      this.userService
        .addDevice({
          type: this.platform === CurrentPlatform.ios ? 'IOS' : 'ANDROID',
          token: token.value,
          bundle: 'com.befree.ui',
        })
        .pipe(first())
        .subscribe(() => {
          this.storage.setItem(StorageType.PushToken, token);
        });
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      // console.info('Push notification received: ', notification);
      if (this.platform === CurrentPlatform.android) {
        try {
          const delivered = await PushNotifications.getDeliveredNotifications();
          const notifications = delivered.notifications.filter(
            (n) => n.title === notification.title && n.body === notification.body,
          );

          await PushNotifications.removeDeliveredNotifications({ notifications });

          LocalNotifications.schedule({
            notifications: [
              {
                id: new Date().getMilliseconds(),
                body: notification.body || '',
                title: notification.title || '',
                extra: notification,
                channelId: this.channelId,
              },
            ],
          });
        } catch (error) {
          console.error('Local push notification sending error', error);
        }
      }
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
      // console.info('Push notification action performed', action);
      this.handleNotification(action);
    });

    await LocalNotifications.addListener('localNotificationActionPerformed', (action) => {
      // console.info('Local push notification action performed: ', action);
      this.handleNotification({
        ...action,
        notification: {
          ...action.notification,
          id: action.notification.id + '',
          data: action.notification.extra?.data,
        },
      });
    });
  }

  private async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  public async unregister() {
    try {
      if (this.platform !== CurrentPlatform.web) {
        await PushNotifications.unregister();

        const pushToken = await firstValueFrom(
          this.storage.getItem<{ value: string }>(StorageType.PushToken),
        );
        if (pushToken?.value) {
          await firstValueFrom(this.userService.deleteDevice(pushToken.value).pipe());
          this.storage.removeItem(StorageType.PushToken);
        }
      }
    } catch {}
  }

  public async init(platform: CurrentPlatform) {
    try {
      this.platform = platform;

      // this.unregister();
      await this.registerNotifications();

      this.createChannel();
      this.addListeners();
    } catch (error) {
      console.error('Push Service initialize error:', error);
    }
  }
}
