import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, first, of, switchMap } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';
import { IAppUpdate } from './general.type';
import { BaseHttpService } from '../base-http/base-http.service';
import {
  selectAppInfo,
  selectCurrentPlatform,
} from 'src/app/data/store/selectors/platform.selectors';
import { CurrentPlatform } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private readonly _update = '/version/checkAppSupport';

  constructor(private _http: BaseHttpService, private _store: Store) {}

  public checkAppSupport() {
    return this._store.select(selectAppInfo).pipe(
      first(),
      concatLatestFrom(() => this._store.select(selectCurrentPlatform)),
      filter(([appInfo]) => Boolean(appInfo)),
      switchMap(([appInfo, platform]) => {
        if (platform === CurrentPlatform.web) {
          return of({ status: true });
        }

        return this._http.get<IAppUpdate>(this._update, {
          os: platform,
          // version: '0.0.0',
          version: appInfo?.version,
        });
      }),
    );
  }
}
