import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { from, take } from 'rxjs';
import { searchValueChange } from 'src/app/data/store/actions/filters.actions';
import { searchLimit } from '../../constants';
import { CurrentPlatform, FiltersOptions, SectionsListModes } from '../../enums';
import { getSectionsList } from '../../../data/store/actions/training.action';
import { Keyboard } from '@capacitor/keyboard';
import { selectCurrentPlatform } from '../../../data/store/selectors/platform.selectors';

@UntilDestroy()
@Component({
  selector: 'app-search-bar-with-filters',
  templateUrl: './search-bar-with-filters.component.html',
  styleUrls: ['./search-bar-with-filters.component.scss'],
})
export class SearchBarWithFiltersComponent implements OnInit, AfterViewInit {
  @ViewChild('searchbar') searchbar: IonSearchbar | undefined;

  @Input() searchBarText: string = 'Поиск';

  @Input() isNeedFiltersButton: boolean = true;

  @Input() isFavoriteOption: boolean = false;

  @Input() isCheckboxType: boolean = false;

  @Input() currentMode: string | undefined;

  @Input() excludeSingleFiltersOptions: FiltersOptions[] = [];

  currentPlatform: CurrentPlatform | null | undefined;

  public dataForFilters: {
    withFavoriteOption?: boolean;
    isCheckboxType?: boolean;
  } | null = null;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this.dataForFilters = {
      withFavoriteOption: this.isFavoriteOption,
      isCheckboxType: this.isCheckboxType,
    };

    this._store
      .select(selectCurrentPlatform)
      .pipe(untilDestroyed(this))
      .subscribe((platform: CurrentPlatform | null) => (this.currentPlatform = platform));
  }

  public ngAfterViewInit() {
    if (this.searchbar) {
      from(this.searchbar.getInputElement())
        .pipe(untilDestroyed(this), take(1))
        .subscribe((input) => {
          input.maxLength = searchLimit;
        });
    }
  }

  public searchValueChange(event: any) {
    this._store.dispatch(searchValueChange({ searchValue: event.detail.value }));
    if (this.currentMode === SectionsListModes.listViewMode) {
      this._store.dispatch(getSectionsList());
    }
  }

  hideKeyboard() {
    if (this.currentPlatform !== CurrentPlatform.web) {
      Keyboard.hide();
    }
  }
}
