import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPlatformDataState, PLATFORM_DATA_KEY } from '../models/platform.model';
import { CurrentPlatform } from 'src/app/shared/enums';

export const selectFeature = createFeatureSelector<IPlatformDataState>(PLATFORM_DATA_KEY);

export const selectCurrentPlatform = createSelector(
  selectFeature,
  (state: IPlatformDataState) => state.platform,
);

export const selectAppInfo = createSelector(
  selectFeature,
  (state: IPlatformDataState) => state.appInfo,
);

export const selectIsWeb = createSelector(
  selectFeature,
  (state: IPlatformDataState) => state.platform === CurrentPlatform.web,
);

export const selectIsIOS = createSelector(
  selectFeature,
  (state: IPlatformDataState) => state.platform === CurrentPlatform.ios,
);

export const selectIsAndroid = createSelector(
  selectFeature,
  (state: IPlatformDataState) => state.platform === CurrentPlatform.android,
);

export const selectAppUpdateData = createSelector(
  selectFeature,
  (state: IPlatformDataState) => state.appUpdateData,
);
