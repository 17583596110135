import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITrainingState, TRAINING_KEY } from '../models/training.model';

export const selectFeature = createFeatureSelector<ITrainingState>(TRAINING_KEY);

export const selectSections = createSelector(
  selectFeature,
  (state: ITrainingState) => state.sectionsList,
);

export const selectTrainingsIsLoading = createSelector(
  selectFeature,
  (state: ITrainingState) => state.isLoading,
);

export const selectTrainingFilesList = createSelector(
  selectFeature,
  (state: ITrainingState) => state.trainingFilesList,
);

export const selectCurrentTrainingMode = createSelector(
  selectFeature,
  (state: ITrainingState) => state.currentMode,
);

export const selectCurrentSection = createSelector(
  selectFeature,
  (state: ITrainingState) => state.section,
);

export const selectCurrentStage = createSelector(
  selectFeature,
  (state: ITrainingState) => state.stage,
);

export const selectIsAllDataFetched = createSelector(
  selectFeature,
  (state: ITrainingState) => state.allDataFetched,
);

export const selectTrainingLevels = createSelector(
  selectFeature,
  (state: ITrainingState) => state.trainingLevels,
);

export const selectPositionsList = createSelector(
  selectFeature,
  (state: ITrainingState) => state.positionsList,
);

export const selectFormData = createSelector(
  selectFeature,
  (state: ITrainingState) => state.formData,
);

export const selectTrainingEmployeesList = createSelector(
  selectFeature,
  (state: ITrainingState) => state.trainingEmployees,
);

export const selectCurrentTrainingEmployeeData = createSelector(
  selectFeature,
  (state: ITrainingState) => state.currentTrainingEmployeeData,
);

export const selectTrainingXlsReportData = createSelector(
  selectFeature,
  (state: ITrainingState) => state.trainingXlsReportData,
);

export const selectTrainingXlsReportLoading = createSelector(
  selectFeature,
  (state: ITrainingState) => state.isReportLoading,
);
