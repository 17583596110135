<ng-container *ngIf="filtersButtonType === buttonType.icon">
  <button class="button__type-icon focus-visible" (click)="openModalFilters()">
    <img
      [src]="
        (filtersMenuHaveOptions$ | async) || filtersMenuHaveOptionsInUrl
          ? filtersIconWithOptions
          : filtersIcon
      "
    />
  </button>
</ng-container>

<ng-container *ngIf="filtersButtonType === buttonType.text">
  <div (click)="isButtonDisabled ? null : openModalFilters()" class="button__type-text">
    <img [src]="'assets/images/icons-svg/add-post-icon.svg'" />
    <app-text
      [textSize]="TextSize.h3Body"
      [textType]="TextType.base"
      [text]="buttonText"
    ></app-text>
  </div>
</ng-container>

<ng-container *ngIf="filtersButtonType === buttonType.button">
  <app-button
    class="button__type-button"
    [class.button__type-button-active]="isFilterHaveOption$ | async"
    [btnType]="Buttons.btnFilter"
    [textSize]="ButtonTextSizes.btnFilter"
    [text]="buttonText"
    (click)="openModalFilters()"
  ></app-button>
</ng-container>
