import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { createPollData } from 'src/app/data/store/actions/posts.actions';
import { selectCreatePoll } from 'src/app/data/store/selectors/posts.selectors';
import {
  Buttons,
  ButtonTextSizes,
  CurrentPlatform,
  PollTypes,
  TextSizes,
  TextType,
} from 'src/app/shared/enums';
import { IPoll } from 'src/app/shared/services/posts/posts.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { PluginListenerHandle } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { UtilsService } from 'src/app/shared/services/utils.service';

@UntilDestroy()
@Component({
  selector: 'app-create-poll',
  templateUrl: './create-poll.component.html',
  styleUrls: ['./create-poll.component.scss'],
})
export class CreatePollComponent implements OnInit, OnDestroy {
  @Input() isMob: boolean | null = false;

  @ViewChild('formOptions') formOptions?: ElementRef<HTMLDivElement>;

  @ViewChild('formContent') formContent?: ElementRef<HTMLDivElement>;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly Buttons = Buttons;

  public readonly ButtonTextSizes = ButtonTextSizes;

  public readonly pollTypes = PollTypes;

  public readonly minNumOfOptions: number = 2;

  public readonly maxNumOfOptions: number = 10;

  public isForceDismiss: boolean = false;

  public poll$: Observable<IPoll | null> = this._store.select(selectCreatePoll);

  public createPollForm: FormGroup = this._fb.group({
    id: [null],
    isMultipleChoices: [false],
    text: [null, [Validators.required]],
    type: [PollTypes.public],
    pollOptions: this._fb.array([
      this._fb.group({
        id: [null],
        text: [null, [Validators.required]],
        sequenceNumber: [0],
      }),
    ]),
  });

  private keyboardDidShowListener: PluginListenerHandle | undefined;

  constructor(private _fb: FormBuilder, private _store: Store, private _utils: UtilsService) {}

  ngOnInit(): void {
    this.poll$.subscribe((poll) => {
      if (poll) {
        this.createPollForm.patchValue({
          id: poll.id,
          isMultipleChoices: poll.isMultipleChoices,
          text: poll.text,
          type: poll.type,
        });

        this.options.removeAt(0);
        poll.pollOptions.forEach((option) => this.options.push(this._fb.group(option)));
      }
    });

    this._store
      .select(selectCurrentPlatform)
      .pipe(untilDestroyed(this))
      .subscribe((platform) => {
        if (platform !== CurrentPlatform.web) {
          this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
            // При открытии клавиатуры скроллим контент к активному инпуту
            if (this.formContent && this.formOptions && document.activeElement) {
              // Ищем элемент списка вариантов ответа, который содержит активный инпут
              const element = Array.from(this.formOptions.nativeElement.children).find((child) =>
                child.contains(document.activeElement),
              ) as HTMLElement;
              if (element) {
                this.formContent.nativeElement.scrollTo({
                  top: element.offsetTop - 67 - 8, // 67 - высота блока с заголовком опроса, 8 - расстояние между вариантами ответов
                });
              }
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.keyboardDidShowListener?.remove();
  }

  get options() {
    return this.createPollForm.controls['pollOptions'] as FormArray;
  }

  public addOption(num: number) {
    const optionForm = this._fb.group({
      id: [null],
      text: [null, [Validators.required]],
      sequenceNumber: [num],
    });

    this.options.push(optionForm);
  }

  public deleteOption(optionIndex: number) {
    this.options.removeAt(optionIndex);
  }

  public setPollType(event: any) {
    if (event.detail.checked) {
      this.createPollForm.controls['type'].setValue(PollTypes.anonymous);
    } else this.createPollForm.controls['type'].setValue(PollTypes.public);
  }

  public submitForm() {
    if (this.createPollForm.valid && this.options.controls.length >= this.minNumOfOptions) {
      this.isForceDismiss = true;
      this._store.dispatch(createPollData({ poll: this.createPollForm.value }));
      this.close();
    }
  }

  public close() {
    this._utils.dismissModal(this.isForceDismiss);
  }
}
