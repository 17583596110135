import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPhotoReportDataState, PHOTO_REPORT_DATA_KEY } from '../models/photo-report.model';
import { IUploadingFilesState, UPLOADING_FILES_KEY } from '../models/uploading-files.model';

export const selectFeature = createFeatureSelector<IPhotoReportDataState>(PHOTO_REPORT_DATA_KEY);

export const selectUploadingFilesFeature =
  createFeatureSelector<IUploadingFilesState>(UPLOADING_FILES_KEY);

export const selectPhotoReportData = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.photoReport,
);

export const selectPhotoReportsList = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.photoReportsList,
);

export const selectPhotoReportsListIsLoading = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.isLoading,
);

export const selectPhotoReportsListIsFetched = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.isAllListFetched,
);

export const selectCategoriesData = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.categories,
);

export const selectNotFilledCategoriesOfUploadedPhotos = createSelector(
  selectFeature,
  selectUploadingFilesFeature,
  (state: IPhotoReportDataState, state2: IUploadingFilesState) => {
    let chosenCategories = [...new Set(state2.uploadingFiles.map((obj) => obj.imageCategory))];

    return state.categories?.filter(
      (category) => !chosenCategories.includes(category.categoryName),
    );
  },
);

export const selectPhotoReportImages = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.photoReportImages,
);

export const selectPhotoReportCategoriesNames = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.photoReportCategoriesNames,
);

export const selectPhotoReportCategories = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.photoReportCategories,
);

export const selectPhotoReportImagesIds = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.photoReportImagesId,
);

export const selectIsEditMode = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.currentMode,
);

export const selectLikedLIst = createSelector(
  selectFeature,
  (state: IPhotoReportDataState) => state.likedList,
);
