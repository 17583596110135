import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { AppInfo, PlatformActions } from '../models/platform.model';
import { IAppUpdate } from 'src/app/shared/services/general/general.type';

export const setCurrentPlatformData = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.SetCurrentPlatformData} ${ReducerStatuses.SUCCESS}`,
  props<{ platform: string }>(),
);

export const setCurrentPlatformDataError = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.SetCurrentPlatformData} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const setCurrentAppInfo = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.SetCurrentAppInfo} `,
);

export const setCurrentAppInfoSuccess = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.SetCurrentAppInfo} ${ReducerStatuses.SUCCESS}`,
  props<{ appInfo: AppInfo }>(),
);

export const setCurrentAppInfoFailed = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.SetCurrentAppInfo} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const getAppUpdateData = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.GetAppUpdateData} ${ReducerStatuses.INIT}`,
);

export const setAppUpdateData = createAction(
  `${ReducerSections.PLATFORM} ${PlatformActions.SetAppUpdateData} ${ReducerStatuses.INIT}`,
  props<{ appUpdateData: IAppUpdate }>(),
);
