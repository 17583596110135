<app-ion-page-content [forceOverscroll]="false">
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <app-block class="app-block__web-desktop">
      <app-employee-development-filters
        [pageType]="pageType"
        [currentUser]="currentUser"
      ></app-employee-development-filters>

      <ion-grid class="table">
        <ion-row class="table__header">
          <ng-container *ngFor="let cell of tableHeaderCellsNames; trackBy: trackByFnTableHeader">
            <ion-col [size]="cell?.colSize">
              <app-text
                [textSize]="TextSizes.fileSubtitle"
                [textType]="TextType.base"
                [text]="cell.name"
              ></app-text>
            </ion-col>
          </ng-container>
        </ion-row>

        <ng-container *ngIf="trainingEmployees$ | async as list">
          <app-pagination-container
            [isLoading$]="isLoading$"
            [paginationData]="list"
            [isAllLoaded]="allListFetched$ | async"
            (emitScrolling)="getTrainingEmployees()"
            [listName]="listName"
            [clearListAction]="clearAction"
          >
            <ng-container *ngFor="let employee of trainingEmployees$ | async; trackBy: trackByFn">
              <ion-row
                class="table__body"
                (click)="goToDevelopmentForm(employee, currentUser)"
                [id]="employee.id"
              >
                <ion-col class="table__body-user" size="5">
                  <app-employee-card
                    [employee]="employee"
                    [isShowDepartment]="true"
                    [isTrainingEmployees]="true"
                  ></app-employee-card>
                </ion-col>
                <ion-col class="table__body-step" size="1">
                  <div class="table__body-step__wrapper" *ngIf="employee.userTraining.length">
                    <app-text
                      [textSize]="TextSizes.numberPhone"
                      [textType]="TextType.base"
                      [text]="employee.userTraining.length"
                    ></app-text>
                  </div>
                </ion-col>
                <ion-col class="table__body-status" size="1.7">
                  <div
                    class="table__body-status__wrapper"
                    [ngClass]="
                      (employee.userTraining[employee.userTraining.length - 1] | trainingStatus)
                        .color
                    "
                  >
                    <app-text
                      [textSize]="TextSizes.numberPhone"
                      [textType]="TextType.base"
                      [text]="
                        (employee.userTraining[employee.userTraining.length - 1] | trainingStatus)
                          .status
                      "
                    ></app-text>
                  </div>
                </ion-col>
                <ion-col class="table__body-trainer">
                  <app-text
                    *ngIf="employee.userTraining.length"
                    [textSize]="TextSizes.h5Post"
                    [textType]="TextType.base"
                    [text]="getCurrentTrainer(employee.userTraining) | getFullName"
                  ></app-text>
                </ion-col>
                <ion-col class="table__body-date" size="2.2">
                  <app-text
                    *ngIf="employee.userTraining.length"
                    [textSize]="TextSizes.h5Post"
                    [textType]="TextType.base"
                    [text]="
                      employee.userTraining[employee.userTraining.length - 1].endTraining
                        | dateFormat : false : true
                    "
                  ></app-text>
                </ion-col>
              </ion-row>
            </ng-container>
          </app-pagination-container>
        </ng-container>
      </ion-grid>
    </app-block>
  </ng-container>
</app-ion-page-content>
