import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  TextSizes,
  TextType,
  TrainingLevels,
  TrainingStatuses,
  UserRoles,
} from '../../../../../../../shared/enums';
import { IUser } from '../../../../../../../shared/services/user/user.type';
import { befreeUrlValues } from '../../../../../../../shared/constants';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  selectIsAllDataFetched,
  selectTrainingEmployeesList,
  selectTrainingsIsLoading,
} from '../../../../../../../data/store/selectors/training.selectors';
import { Store } from '@ngrx/store';
import { tableHeaderCellsNames } from 'src/app/shared/constants';
import {
  clearTrainingData,
  getTrainingEmployees,
} from 'src/app/data/store/actions/training.action';
import { selectCurrentUser } from '../../../../../../../data/store/selectors/users.selectors';
import { TrainingStatusPipe } from 'src/app/shared/pipe/training-status/training-status.pipe';

@Component({
  selector: 'app-training-development-table',
  templateUrl: './training-development-table.component.html',
  styleUrls: ['./training-development-table.component.scss'],
})
export class TrainingDevelopmentTableComponent {
  @Input() pageType: string | undefined;

  @Input() listName: string | undefined;

  public currentUser$: Observable<IUser | null> = this._store.select(selectCurrentUser);

  public trainingEmployees$: Observable<IUser[]> = this._store.select(selectTrainingEmployeesList);

  public isLoading$: Observable<boolean> = this._store.select(selectTrainingsIsLoading);

  public allListFetched$: Observable<boolean | null> = this._store.select(selectIsAllDataFetched);

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly tableHeaderCellsNames = tableHeaderCellsNames;

  public readonly trainingStatuses = TrainingStatuses;

  public clearAction = clearTrainingData();

  private routerState: { notification: boolean } | null = null;

  constructor(
    private _router: Router,
    private _store: Store,
    private _trainingStatus: TrainingStatusPipe,
    private _location: Location,
  ) {
    this.routerState = this._location.getState() as { notification: boolean };
  }

  public goToDevelopmentForm(employee: IUser, currentUser: IUser) {
    if (this._isModeratorRoleCanCheckFirstLevel(employee, currentUser)) {
      return;
    }

    this._router.navigate([
      befreeUrlValues.dashboard,
      befreeUrlValues.training,
      this.listName,
      employee.id,
    ]);
  }

  public trackByFn(index: number, employee: IUser) {
    return employee.id;
  }

  public getTrainingEmployees() {
    this._store.dispatch(
      getTrainingEmployees({
        pageType: this.pageType!,
        onlyUntrained: this.routerState?.notification ? true : undefined,
        isLastWeekOfEducation: this.routerState?.notification ? true : undefined,
      }),
    );
    this.routerState = null;
  }

  public trackByFnTableHeader(index: number) {
    return index;
  }

  private _isModeratorRoleCanCheckFirstLevel(employee: IUser, currentUser: IUser) {
    if (currentUser.userRole === UserRoles.standard) return false;
    return (
      (this._trainingStatus.transform(employee.userTraining[employee.userTraining.length - 1])
        .status === this.trainingStatuses.not_filled ||
        this._trainingStatus.transform(employee.userTraining[employee.userTraining.length - 1])
          .status === this.trainingStatuses.draft) &&
      employee.userTraining[employee.userTraining.length - 1].trainingLevel ===
        TrainingLevels.first &&
      currentUser.isAccessToTrainingResult &&
      currentUser.userTrainingRole !== UserRoles.tsmRole &&
      currentUser.userTrainingRole !== UserRoles.regionalManager
    );
  }

  getCurrentTrainer(userTraining: IUser['userTraining']): Partial<IUser> | null {
    return (
      userTraining[userTraining.length - 1]?.trainer ||
      userTraining[userTraining.length - 2]?.trainer ||
      null
    );
  }
}
