import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/auth.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { befreeUrlValues } from './shared/constants';
import { AuthGuard } from './shared/guards/auth.guard';
import { AppUpdateComponent } from './modules/app-update/app-update.component';

const routes: Routes = [
  {
    path: befreeUrlValues.update,
    component: AppUpdateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/app-update/app-update.module').then((m) => m.AppUpdateModule),
      },
    ],
  },
  {
    path: befreeUrlValues.auth,
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: befreeUrlValues.dashboard,
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: befreeUrlValues.dashboard },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
