import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITrainingUserPositionGroup } from 'src/app/shared/services/user/user.type';
import { Router } from '@angular/router';
import { ITrainingStage } from 'src/app/shared/services/training/training.type';
import { befreeUrlValues } from 'src/app/shared/constants';
import { TextSizes, TextType, TrainingPageType } from 'src/app/shared/enums';
import { LinkNav } from 'src/app/shared/interfaces';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-section-card',
  templateUrl: './section-card.component.html',
  styleUrls: ['./section-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCardComponent implements OnInit {
  @Input() navigation!: LinkNav;

  @Input() section?: ITrainingUserPositionGroup | undefined;

  @Input() stage?: ITrainingStage | undefined;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public currentPage: string = typeof befreeUrlValues;

  constructor(public utils: UtilsService, private _router: Router) {}

  public ngOnInit() {
    if (this._router.url.includes(befreeUrlValues.sections))
      this.currentPage = TrainingPageType.sections;
    else {
      this.currentPage = TrainingPageType.section;
    }
  }
}
