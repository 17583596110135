import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TextSizes, TextType, UserDataImgBorder, UserDataImgSize } from 'src/app/shared/enums';
import { befreeUrlValues } from 'src/app/shared/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent {
  @Input() id?: number;

  @Input() imgSrc: string | undefined;

  @Input() fullName: string | undefined;

  @Input() position: string | undefined;

  @Input() date: string | undefined;

  @Input() imgSize: string = UserDataImgSize.m;

  @Input() phoneNumber: string | undefined;

  @Input() email: string | undefined;

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  readonly UserDataImgSize = UserDataImgSize;

  readonly UserDataImgBorder = UserDataImgBorder;

  readonly befreeUrl = environment.befreeUrl;

  public isActive = false;

  constructor(private _cdr: ChangeDetectorRef, public utils: UtilsService) {}

  get link() {
    return this.id ? ['/', befreeUrlValues.dashboard, befreeUrlValues.employees, this.id] : null;
  }

  public onClick() {
    this.isActive = true;
    setTimeout(() => {
      this.isActive = false;
      this._cdr.detectChanges();
    }, 200);
  }
}
