import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter } from 'rxjs';
import {
  applySpecificFilters,
  clearFiltersInit,
  setFiltersOptions,
} from 'src/app/data/store/actions/filters.actions';
import { FiltersOptionsType, IFiltersOptionsParam } from 'src/app/data/store/models/filters.model';
import {
  selectCurrentFilterHaveOptions,
  selectEndTrainingDate,
  selectFiltersMenuHaveOptions,
  selectFiltersState,
} from 'src/app/data/store/selectors/filters.selectors';
import {
  selectTrainingXlsReportData,
  selectTrainingXlsReportLoading,
} from 'src/app/data/store/selectors/training.selectors';
import { selectCurrentUser } from 'src/app/data/store/selectors/users.selectors';
import {
  ActiveFiltersSelectionsTypes,
  Buttons,
  ButtonTextSizes,
  FiltersButtonType,
  FiltersOptions,
  FiltersResetTypes,
  PageType,
  SpinnerColor,
  TextSizes,
  TextType,
  TrainingSegmentTypes,
  UserRoles,
} from 'src/app/shared/enums';
import { IUser } from 'src/app/shared/services/user/user.type';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { DepartmentsService } from '../../../../../../../shared/services/departments/departments.service';
import { getTrainingXlsReportInit } from 'src/app/data/store/actions/training.action';

@UntilDestroy()
@Component({
  selector: 'app-employee-development-filters',
  templateUrl: './employee-development-filters.component.html',
  styleUrls: ['./employee-development-filters.component.scss'],
})
export class EmployeeDevelopmentFiltersComponent implements OnInit, OnDestroy {
  @Input() pageType: string | undefined;

  @Input() currentUser?: IUser;

  @Output() emitSortingChange: EventEmitter<string> = new EventEmitter<string>();

  public defaultFilterValue?: string;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly ButtonTextSizes = ButtonTextSizes;

  public readonly Buttons = Buttons;

  public readonly filtersButtonType = FiltersButtonType.button;

  public readonly userRoles = UserRoles;

  public readonly pageTypes = PageType;

  public readonly segmentTypes = TrainingSegmentTypes;

  public readonly SpinnerColor = SpinnerColor;

  public dataForFiltersPositions = {
    activeFilterList: FiltersOptions.positions,
    isOnlyFiltersList: true,
  };

  public dataForFiltersStage = {
    activeFilterList: FiltersOptions.stage,
    isOnlyFiltersList: true,
  };

  public dataForFiltersTrainer = {
    activeFilterList: FiltersOptions.trainer,
    isOnlyFiltersList: true,
  };

  public dataForFiltersTrainerRole = {
    activeFilterList: FiltersOptions.trainerRole,
    isOnlyFiltersList: true,
  };

  public isCalenderOpen: boolean = false;

  public filtersOptionsParamsDevelopment: IFiltersOptionsParam = {
    REGIONS: {
      params: null,
    },
    SHOPS: {
      params: null,
      isFilterExcludedInDependentFilters: true,
    },
    CITIES: {
      params: null,
    },
    TRAINER: {
      params: null,
      isFilterExcludedInDependentFilters: true,
    },
    POSITIONS: {
      params: null,
      isFilterSingleButton: true,
    },
    STAGE: {
      params: null,
      isFilterSingleButton: true,
    },
    END_TRAINING: {
      params: null,
      isFilterSingleButton: true,
    },
    SEGMENT: {
      params: null,
      isFilterSingleButton: true,
    },
  };

  public filtersOptionsParamsAnalytics: IFiltersOptionsParam = {
    REGIONS: {
      params: null,
    },
    SHOPS: {
      params: null,
      isFilterExcludedInDependentFilters: true,
    },
    CITIES: {
      params: null,
    },
    TRAINER: {
      params: null,
      isFilterSingleButton: true,
    },
    TRAINER_ROLE: {
      params: null,
      isFilterSingleButton: true,
    },
    STAGE: {
      params: null,
      isFilterSingleButton: true,
    },
  };

  public excludeSingleFiltersOptionsDevelopment = [
    FiltersOptions.positions,
    FiltersOptions.stage,
    FiltersOptions.endTraining,
    FiltersOptions.segment,
  ];

  public excludeSingleFiltersOptionsAnalytics = [
    FiltersOptions.stage,
    FiltersOptions.trainer,
    FiltersOptions.trainerRole,
    FiltersOptions.endTraining,
  ];

  public selectedFilters: IFiltersOptionsParam | null = null;

  public options: FiltersOptionsType[] = [];

  public currentUser$: Observable<IUser | null> = this._store.select(selectCurrentUser);

  public filtersState$ = this._store.select(selectFiltersState);

  public endTrainingDate$ = this._store.select(selectEndTrainingDate);

  public filtersMenuHaveOptions$: Observable<boolean> = this._store.select(
    selectFiltersMenuHaveOptions([FiltersOptions.segment]),
  );

  public positionFiltersState$: Observable<boolean> = this._store.select(
    selectCurrentFilterHaveOptions(FiltersOptions.positions),
  );

  public stageFiltersState$: Observable<boolean> = this._store.select(
    selectCurrentFilterHaveOptions(FiltersOptions.stage),
  );

  public trainerRoleFiltersState$: Observable<boolean> = this._store.select(
    selectCurrentFilterHaveOptions(FiltersOptions.trainerRole),
  );

  public trainerFiltersState$: Observable<boolean> = this._store.select(
    selectCurrentFilterHaveOptions(FiltersOptions.trainer),
  );

  public endTrainingFiltersState$: Observable<boolean> = this._store.select(
    selectCurrentFilterHaveOptions(FiltersOptions.endTraining),
  );

  public trainingXlsReportData$ = this._store.select(selectTrainingXlsReportData);

  public trainingXlsReportLoading$ = this._store.select(selectTrainingXlsReportLoading);

  private _routerSubscription?: Subscription;

  constructor(
    private _store: Store,
    private _utilsService: UtilsService,
    private _router: Router,
    private _departmentsService: DepartmentsService,
    private _cdr: ChangeDetectorRef,
  ) {
    this._routerSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this._initFilters();
      });
  }

  ngOnInit(): void {
    this._initFilters();
  }

  ngOnDestroy(): void {
    this._routerSubscription?.unsubscribe();
  }

  public changeSegment(event: any) {
    this._store.dispatch(
      applySpecificFilters({
        activeFilterOption: FiltersOptions.segment,
        specificFilterData: event.detail.value,
      }),
    );
  }

  public clearAllFilters() {
    this._store.dispatch(
      clearFiltersInit({
        clearType: FiltersResetTypes.resetAll,
        isNeedExternalFiltersMenuCleaning: true,
      }),
    );
  }

  public downloadReport() {
    this._store.dispatch(getTrainingXlsReportInit());
    this.trainingXlsReportData$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data) {
        this._utilsService.downloadFile(data.name, data.path, false);
      }
    });
  }

  public setDate(event: any) {
    this._store.dispatch(
      applySpecificFilters({
        activeFilterOption: FiltersOptions.endTraining,
        specificFilterData: moment(event.detail.value).format('YYYY-MM-DDT00:00:00'),
      }),
    );
  }

  private _initFilters() {
    const filtersFromUrl = this._utilsService.getFiltersFromUrl();

    this.defaultFilterValue =
      history.state?.notification || filtersFromUrl?.onlyUntrained?.value
        ? this.segmentTypes.urgent
        : this.segmentTypes.all;

    this._store.dispatch(
      setFiltersOptions({
        filtersOption:
          this.pageType === PageType.analytics
            ? this.filtersOptionsParamsAnalytics
            : this.filtersOptionsParamsDevelopment,
        selectionType: ActiveFiltersSelectionsTypes.multiple,
      }),
    );

    this.options = Object.keys(
      this.pageType === PageType.analytics
        ? this.filtersOptionsParamsAnalytics
        : this.filtersOptionsParamsDevelopment,
    ) as FiltersOptionsType[];

    this.filtersState$.pipe(untilDestroyed(this)).subscribe((filters) => {
      if (filters.filtersList) {
        this.selectedFilters = filters.filtersList;
        this._cdr.detectChanges();
      }
    });

    if (filtersFromUrl?.endTraining?.value) {
      this._store.dispatch(
        applySpecificFilters({
          activeFilterOption: FiltersOptions.endTraining,
          specificFilterData: moment(filtersFromUrl?.endTraining?.value).format(
            'YYYY-MM-DDT00:00:00',
          ),
        }),
      );
    }

    if (
      !!Object.keys(filtersFromUrl).filter((i) => !['onlyUntrained', 'endTraining'].includes(i))
        .length
    ) {
      this._utilsService.updateStateFilterOnInit(true);
    }
  }
}
