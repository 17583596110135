import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FiltersOptionsType, IFiltersOptionsParam } from 'src/app/data/store/models/filters.model';
import {
  Buttons,
  ButtonTextSizes,
  FiltersModalSteps,
  FiltersOptions,
  FiltersResetTypes,
  TextSizes,
  TextType,
} from 'src/app/shared/enums';
import { ICountryOrCity, IRegion } from 'src/app/shared/services/departments/departments.type';
import { IShop, IUserPosition } from 'src/app/shared/services/user/user.type';
import { Store } from '@ngrx/store';
import {
  activeOptionFilterSearchValueChange,
  applySingleFilterAction,
  clearFiltersElementsList,
  clearFiltersInit,
  getFilterElementsList,
  updateFiltersAction,
} from 'src/app/data/store/actions/filters.actions';
import { IDepartment } from 'src/app/shared/services/posts/posts.type';
import {
  selectActiveFiltersElementsList,
  selectElementsListIsLoading,
  selectFiltersState,
  selectIsAllElementsFetched,
} from '../../../../../data/store/selectors/filters.selectors';
import { FiltersOptionsNames } from 'src/app/shared/constants';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';

@UntilDestroy()
@Component({
  selector: 'app-filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersListComponent implements OnInit, OnDestroy {
  @Input() activeFilterList: FiltersOptionsType | null = null;

  @Input() initialFilters: IFiltersOptionsParam | null = null;

  @Input() isCheckboxType: boolean = false;

  @Input() isOnlyFiltersList: boolean = false;

  @Output() public emitStepChange: EventEmitter<string> = new EventEmitter();

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  readonly Buttons = Buttons;

  readonly ButtonTextSizes = ButtonTextSizes;

  readonly filtersOptions = FiltersOptions;

  readonly filtersOptionsNames = FiltersOptionsNames;

  public elements: any[] = [];

  public onlyFavorite: boolean = false;

  public isNeedSearch: boolean = false;

  public selectedElements: (IShop | ICountryOrCity | IRegion | IDepartment | IUserPosition)[] = [];

  public isLoading$: Observable<boolean> = this._store.select(selectElementsListIsLoading);

  public isAllListDataFetched$: Observable<boolean | null> = this._store.select(
    selectIsAllElementsFetched,
  );

  public activeFilterElementsList$: Observable<any[]> = this._store.select(
    selectActiveFiltersElementsList,
  );

  public filtersState$ = this._store.select(selectFiltersState);

  constructor(
    private _store: Store,
    private _cdr: ChangeDetectorRef,
    private _utils: UtilsService,
  ) {}

  public ngOnInit(): void {
    if (this.initialFilters && this.activeFilterList) {
      this.selectedElements = this.initialFilters[this.activeFilterList]?.params || [];
    }

    this.activeFilterElementsList$.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.elements = elements;

      this._cdr.detectChanges();
    });

    this.filtersState$.pipe(untilDestroyed(this)).subscribe((filters) => {
      this.initialFilters = Object.assign({}, filters.filtersList) || null;
    });

    this.isNeedSearch =
      this.activeFilterList !== FiltersOptions.regions &&
      this.activeFilterList !== FiltersOptions.depts &&
      this.activeFilterList !== FiltersOptions.potential &&
      this.activeFilterList !== FiltersOptions.positions &&
      this.activeFilterList !== FiltersOptions.stage &&
      this.activeFilterList !== FiltersOptions.trainerRole;
  }

  public getElements() {
    if (!this.activeFilterList) {
      return;
    }

    this._store.dispatch(
      getFilterElementsList({
        activeFilterOption: this.activeFilterList,
      }),
    );
  }

  public clearFiltersList() {
    let isNeedClearStore: boolean = false;

    if (this.initialFilters && this.activeFilterList) {
      isNeedClearStore = Boolean(this.initialFilters[this.activeFilterList]?.params?.length);
    }

    if (isNeedClearStore && this.activeFilterList) {
      this._store.dispatch(
        clearFiltersInit({
          clearType: FiltersResetTypes.resetCurrent,
          activeFilterOption: this.activeFilterList,
        }),
      );
    }
    this.clearChosenElem();
  }

  public applyFiltersList() {
    if (this.initialFilters && this.activeFilterList) {
      this.initialFilters = Object.assign(
        { ...this.initialFilters },
        {
          [this.activeFilterList]: {
            ...this.initialFilters[this.activeFilterList],
            params: this.selectedElements,
          },
        },
      );
    }

    if (
      this.initialFilters &&
      this.activeFilterList &&
      this.initialFilters[this.activeFilterList]?.isFilterSingleButton
    ) {
      this._store.dispatch(
        applySingleFilterAction({
          data: this.initialFilters,
          activeFilterOption: this.activeFilterList,
        }),
      );
    } else {
      this._store.dispatch(
        updateFiltersAction({
          filtersOptionsParams: this.initialFilters,
          activeFilterOption: this.activeFilterList,
        }),
      );
    }

    if (!this.isOnlyFiltersList) {
      this.backToMenu();
    } else {
      this._utils.dismissModal();
    }
  }

  public backToMenu() {
    this.emitStepChange.emit(FiltersModalSteps.menu);
  }

  public searchValueChange(event: any) {
    if (!this.activeFilterList) {
      return;
    }

    this._store.dispatch(
      activeOptionFilterSearchValueChange({
        searchValue: event.detail.value ? event.detail.value : null,
        activeFilterOption: this.activeFilterList,
      }),
    );
  }

  public onChange(event: any) {
    let selectedElement: IShop | ICountryOrCity | IRegion | IDepartment | IUserPosition | null =
      null;

    if (this.isCheckboxType && !event.detail.checked) {
      this.selectedElements = this.selectedElements.filter((el) => el.id !== event.detail.value);
      return;
    } else {
      selectedElement = this.elements.find((el) => el.id === event.detail.value);
    }

    if (selectedElement && (!this.isCheckboxType || !this.selectedElements.length)) {
      this.selectedElements = [selectedElement];
    } else if (this.selectedElements.find((el) => el.id === event.detail.value)) {
      this.selectedElements = this.selectedElements.filter((el) => el.id !== event.detail.value);
    } else if (selectedElement) {
      this.selectedElements = [...this.selectedElements, selectedElement];
    }
  }

  public clearChosenElem() {
    this.selectedElements = [];
  }

  public trackByFn(
    index: number,
    obj: IShop | ICountryOrCity | IRegion | IDepartment | IUserPosition,
  ) {
    return obj.id;
  }

  ngOnDestroy(): void {
    this._store.dispatch(clearFiltersElementsList());
  }
}
