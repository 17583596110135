export enum Buttons {
  base = 'button-base',
  small = 'button-small',
  btnFilter = 'btn-filter',
}

export enum TextType {
  base = 'base',
  button = 'button',
}

export enum TextSizes {
  h1Header = 'h1-header',
  h2Menu = 'h2-menu',
  h3Body = 'h3-body',
  h4Name = 'h4-name',
  h5Post = 'h5-post',
  h6BtnSmall = 'h6-btn-small',
  label = 'label',
  numberPhone = 'number-phone',
  like = 'like',
  date = 'date',
  alert = 'alert',
  mobPost = 'mob-post',
  mobInput = 'mob-input',
  mobLabel = 'mob-label',
  qty = 'qty',
  mobBody = 'mob-body',
  fileTitle = 'file-title',
  fileSubtitle = 'file-subtitle',
  notification = 'notification',
}

export enum ButtonTextSizes {
  buttonText1 = 'text-1',
  h6BtnSmall = 'h6-btn-small',
  btnMainSmall = 'btn-main-small',
  btnFilter = 'btn-filter',
}

export enum UserDataImgSize {
  xs = '24px',
  s = '32px',
  m = '48px',
}

export enum UserDataImgBorder {
  s = '4px',
  m = '6px',
}

export enum MenuIconWidth {
  mobile = '17px',
  web = '20px',
}

export enum CurrentPlatform {
  ios = 'ios',
  android = 'android',
  web = 'web',
}

export enum UnreadCountersStates {
  posts = 'userPostUnread',
  comments = 'userCommentUnread',
  replies = 'userRepliesUnread',
}

export enum PollTypes {
  public = 'PUBLIC',
  anonymous = 'ANONYMOUS',
}

export enum UserRoles {
  moderator = 'MODERATOR',
  standard = 'STANDARD',
  tsmRole = 'TSM_ROLE',
  regionalManager = 'REGIONAL_MANAGER_ROLE',
}

export enum FiltersModalSteps {
  menu = 'MENU',
  filterList = 'FILTER_LIST',
}

export enum FiltersOptions {
  regions = 'REGIONS',
  shops = 'SHOPS',
  cities = 'CITIES',
  depts = 'DEPTS',
  groups = 'GROUPS',
  potential = 'POTENTIAL',
  positions = 'POSITIONS',
  trainer = 'TRAINER',
  stage = 'STAGE',
  trainerRole = 'TRAINER_ROLE',
  endTraining = 'END_TRAINING',
  segment = 'SEGMENT',
}

export enum FileType {
  image = 'IMAGE',
  file = 'FILE',
}

export enum DepartmentsType {
  shop = 'SHOP',
  dept = 'DEPT',
  group = 'GROUP',
  region = 'REGION',
}

export enum ShopsListTypes {
  allShops = 'ALL_SHOPS',
  favShops = 'FAV_SHOPS',
  regionShops = 'REGION_SHOPS',
}

export enum ScrollToType {
  top = 'scrollToTop',
  bottom = 'scrollToBottom',
}

export enum ViewType {
  gallery = 'gallery',
  modal = 'modal',
  post = 'post',
  editPhotoReport = 'editPhotoReport',
  createPhotoReport = 'createPhotoReport',
}

export enum DataType {
  photoReport = 'photoReport',
  post = 'post',
  image = 'image',
  poll = 'poll',
}

export enum FiltersModalType {
  shopList = 'SHOP_LIST',
  photoReports = 'PHOTO_REPORTS',
  employeesList = 'EMPLOYEES_LIST',
  training = 'TRAINING',
}

export enum RefresherTypes {
  favoriteShops = 'FAV_SHOPS',
  postFeed = 'POST_FEED',
  pagination = 'PAGINATION',
}

export enum EmployeesPageType {
  allEmployees = 'ALL_EMPLOYEES',
  additionalEmployees = 'ADDITIONAL_EMPLOYEES',
}

export enum TrainingPageType {
  sections = 'sections',
  section = 'section',
}

export enum RequestNesting {
  withData = 'WITH_DATA',
  withoutData = 'WITHOUT_DATA',
}

export enum NotificationsTypes {
  post = 'POST',
  postImg = 'POST_IMAGE',
  photoReport = 'PHOTO_REPORT',
  photoReportImg = 'PHOTO_REPORT_IMAGE',
}
export enum RepliesResultTypes {
  comment = 'COMMENT',
  like = 'LIKE',
}

export enum EntityForNotification {
  post = 'Post',
  photoReport = 'PhotoReport',
  replyToLike = 'REPLY_TO_LIKE',
}

export enum NotificationPageType {
  all = 'ALL',
  likes = 'LIKES',
  comments = 'COMMENTS',
  photoReports = 'PHOTO_REPORTS',
}

export enum SectionsListModes {
  listViewMode = 'listViewMode',
  searchMode = 'searchMode',
}

export enum StageFileContentType {
  video = 'Video',
  file = 'File',
}

export enum TrainingStatuses {
  draft = 'ЧЕРНОВИК',
  end = 'ЗАВЕРШЁН',
  expired = 'ПРОСРОЧЕН',
  not_filled = 'НЕ ЗАПОЛНЕН',
}

export enum TrainingStatusColors {
  draft = 'dark-blue',
  end = 'green',
  expired = 'red',
  not_filled = 'blue',
}

export enum LinksNames {
  EMPLOYEES = 'СОТРУДНИКИ',
  PHOTOREPORTS = 'ФОТООТЧЁТЫ',
  SHOPS = 'МАГАЗИНЫ',
}

export enum TrainingLevels {
  first = 'FIRST_TRAINING_LEVEL',
  second = 'SECOND_TRAINING_LEVEL',
  third = 'THIRD_TRAINING_LEVEL',
  completed = 'COMPLETED_TRAINING',
}

export enum TrainingFormControlsNames {
  comment = 'comment',
  startDate = 'startDate',
  endDate = 'endDate',
  userPositions = 'userPositions',
  grade = 'grade',
}

export enum PageType {
  analytics = 'ANALYTICS',
  employeeDevelopment = 'EMPLOYEE_DEVELOPMENT',
}

export enum FiltersResetTypes {
  resetCurrent = 'RESET_CURRENT_FILTER',
  resetAll = 'RESET_ALL_FILTERS',
  deleteChip = 'DELETE_CHIP',
}

export enum ActiveFiltersSelectionsTypes {
  mono = 'ONLY_ONE_FILTER',
  multiple = 'MULTIPLE_FILTERS',
}

export enum FiltersButtonType {
  icon = 'ICON',
  button = 'BUTTON',
  text = 'TEXT',
}

export enum TrainingSegmentTypes {
  all = 'ALL',
  urgent = 'URGENT',
}

export enum SpinnerColor {
  blue = 'blue',
  light = 'light',
  default = 'default',
}

export enum PostUserViewType {
  mini = 'mini',
  full = 'full',
}

export enum ImagePreviewType {
  small = 'PREVIEW_SMALL',
  medium = 'PREVIEW_MEDIUM',
  normal = 'PREVIEW_NORMAL',
  fullScreen = 'PREVIEW_FULL_SCREEN',
}
