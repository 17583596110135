import { Pipe, PipeTransform } from '@angular/core';
import { NotificationsTypes } from '../../enums';
import { INotification } from '../../services/notifications/notifications.type';

@Pipe({
  name: 'notificationText',
})
export class NotificationTextPipe implements PipeTransform {
  transform(notify: INotification, currentUserId?: number): string {
    let your: string = 'вашу';
    let entity: string = 'запись';

    if (notify.entity.type === NotificationsTypes.photoReport) {
      your = 'ваш';
      entity = 'фотоотчёт';
    } else if (notify.entity.type === NotificationsTypes.postImg) {
      entity = 'фотографию';
    } else if (notify.entity.type === NotificationsTypes.photoReportImg) {
      return `${notify.author.isMale ? 'оценил' : 'оценила'} фото из фотоотчёта:`;
    }

    if (notify.author.id === currentUserId) {
      return `вы оценили ${entity}:`;
    }

    return `${notify.author.isMale ? 'оценил' : 'оценила'} ${your} ${entity}:`;
  }
}
