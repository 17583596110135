import { IPollOptions, IPost } from '../../../services/posts/posts.type';
import { ButtonTextSizes, Buttons, TextSizes, TextType, ViewType } from '../../../enums';
import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { voteInThePollAction } from '../../../../data/store/actions/posts.actions';
import { pollTypesData } from '../../../constants';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.scss'],
})
export class PollsComponent implements OnChanges {
  @Input() postData: IPost | undefined;

  @Input() isStoreList: boolean = false;

  @Input() viewType: string | undefined;

  public showResults: boolean = false;

  public pollsForm: FormGroup;

  public chosenOptions: IPollOptions[] = [];

  public chosenId: Array<number> = [];

  public readonly TextType = TextType;

  public readonly TextSize = TextSizes;

  public readonly ButtonTextSize = ButtonTextSizes;

  public readonly Buttons = Buttons;

  public readonly pollTypesData = pollTypesData;

  public readonly modalMod = 'modalMod';

  public readonly ViewTypesList = ViewType;

  constructor(private _fb: FormBuilder, private _store: Store) {
    this.pollsForm = this._fb.group({});
  }

  ngOnChanges(): void {
    this.showResults = Boolean(this.postData?.poll.pollOptions.find((item) => item.isVoted));
  }

  handleSubmitPollResults(): void {
    if (this.postData?.poll && this.chosenOptions) {
      this._store.dispatch(
        voteInThePollAction({
          pollsOptions: this.chosenOptions,
          pollsId: this.postData.poll.id,
          postId: this.postData.id,
        }),
      );
    }
    this.showResults = true;
  }

  onCheckChange(e: any): void {
    let targetId = Number(
      this.viewType === this.ViewTypesList.modal
        ? e.target.id.slice(this.modalMod.length)
        : e.target.id,
    );

    const currentOption = this.postData?.poll.pollOptions.find(
      (item: IPollOptions) => item.id === targetId,
    );

    if (e.target.checked && currentOption && !this.postData?.poll.isMultipleChoices) {
      const optionIsSelected = !!this.chosenOptions.find(
        (option) => option.id === currentOption.id,
      );
      this.chosenOptions = optionIsSelected ? [] : [currentOption];
      this.chosenId = optionIsSelected ? [] : [currentOption.id];
    } else if (e.target.checked && currentOption && this.postData?.poll.isMultipleChoices) {
      this.chosenOptions.push(currentOption);
      this.chosenId.push(currentOption.id);
    } else {
      this.chosenOptions = this.chosenOptions.filter((item: IPollOptions) => item?.id !== targetId);
      this.chosenId = this.chosenId.filter((item: number) => item !== targetId);
    }
  }
}
