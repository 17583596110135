import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import {
  getCounters,
  getCountersFailed,
  getCountersSuccess,
  getCurrentUser,
  getCurrentUserFailed,
  getCurrentUserSuccess,
  setCounters,
  loginAction,
  loginFailed,
  loginSuccess,
  logoutAction,
  getUserPageData,
  getUserPageDataSuccess,
  getUserPageDataFailed,
  getFavoriteFiles,
  getFavoriteFilesSuccess,
  getFavoriteFilesFailed,
  addUserAvatar,
  addUserAvatarSuccess,
  addUserAvatarFailed,
} from '../actions/users.actions';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ICounters } from 'src/app/shared/services/user/user.type';
import { UserService } from 'src/app/shared/services/user/user.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { befreeUrlValues } from '../../../shared/constants';
import { Router } from '@angular/router';
import { selectCurrentUser } from '../selectors/users.selectors';
import { Store } from '@ngrx/store';
import { setNotifications } from '../actions/notifications.action';
import { PushService } from 'src/app/shared/services/push/push.service';

@Injectable()
export class UsersEffects {
  constructor(
    private _actions$: Actions,
    private _userService: UserService,
    private _utils: UtilsService,
    private _storage: StorageService,
    private _router: Router,
    private _storageService: StorageService,
    private _store: Store,
    private _pushService: PushService,
  ) {}

  public getCurrentUser$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentUser),
      switchMap(() => {
        return this._userService.getCurrent().pipe(
          map((user) => {
            let pageToRedirect;

            const isLoginPage = new RegExp(
              `/${befreeUrlValues.auth}/${befreeUrlValues.login}`,
            ).test(this._router.url);

            if (isLoginPage) {
              pageToRedirect = befreeUrlValues.posts;
            }
            if (!user.avatar) {
              pageToRedirect = befreeUrlValues.avatar;
            }

            if (pageToRedirect) {
              this._router.navigate([befreeUrlValues.dashboard, pageToRedirect]);
            }

            return getCurrentUserSuccess({ user });
          }),
          catchError((error) => {
            return of(getCurrentUserFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCounters$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCounters, setNotifications),
      concatLatestFrom(() => this._store.select(selectCurrentUser)),
      filter(([, user]) => Boolean(user)),
      switchMap(() => {
        return this._userService.getCounters().pipe(
          map((counters) => {
            return getCountersSuccess({
              userPostUnread: counters.userPostUnread,
              userCommentUnread: counters.userCommentUnread,
              userRepliesUnread: counters.userRepliesUnread,
            });
          }),
          catchError((error) => {
            return of(getCountersFailed({ error }));
          }),
        );
      }),
    );
  });

  login$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loginAction),
      switchMap((action) => {
        return this._userService.login({ ...action.data }).pipe(
          map(() => {
            return loginSuccess();
          }),
          catchError((error) => {
            return of(loginFailed({ error }));
          }),
        );
      }),
    );
  });

  public setCountersValue$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCountersSuccess),
      map((action: ICounters) => {
        return setCounters({
          counters: {
            userPostUnread: action.userPostUnread,
            userCommentUnread: action.userCommentUnread,
            userRepliesUnread: action.userRepliesUnread,
          },
        });
      }),
    );
  });

  public loginSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(loginSuccess),
      map(() => {
        return getCurrentUser();
      }),
    );
  });

  public logoutSuccess$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(logoutAction),
        switchMap(async () => {
          await this._pushService.unregister();
          this._storageService.clearStorage();
          this._router.navigateByUrl(befreeUrlValues.auth);
          window.location.reload();
          return of();
        }),
      );
    },
    { dispatch: false },
  );

  public getUserPageData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getUserPageData),
      switchMap((action) => {
        return this._userService.getUserById(action.userId).pipe(
          map((user) => {
            return getUserPageDataSuccess({
              user,
            });
          }),
          catchError((error) => {
            return of(getUserPageDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getFavoriteFilesList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getFavoriteFiles),
      switchMap((action) => {
        return this._userService
          .getFavoritesData({
            type: action.favoriteFilesFilters.type,
          })
          .pipe(
            map((favoriteFilesList) => {
              return getFavoriteFilesSuccess({
                favoriteFilesList: favoriteFilesList,
              });
            }),
            catchError((error) => {
              return of(getFavoriteFilesFailed({ error }));
            }),
          );
      }),
    );
  });

  public addUserAvatar$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(addUserAvatar),
      concatLatestFrom(() => this._store.select(selectCurrentUser)),
      filter(([, user]) => Boolean(user)),
      switchMap(([action, user]) => {
        return this._userService.addAvatar(user!.id, action.avatar).pipe(
          map((avatar) => {
            return addUserAvatarSuccess({ avatar, userId: user!.id });
          }),
          catchError((error) => {
            return of(addUserAvatarFailed({ error }));
          }),
        );
      }),
    );
  });
}
