import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, switchMap, take } from 'rxjs';
import { HttpResponse } from '../base-http/base-http.type';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../../environments/environment';
import { BaseHttpService } from '../base-http/base-http.service';

const DEFAULT_HEADERS = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

@Injectable({
  providedIn: 'root',
})
export class FileUploadHttpService {
  private readonly _baseHref = environment.befreeUrl + '/api/v1';

  constructor(
    private _storageService: StorageService,
    private _http: HttpClient,
    private _baseHTTP: BaseHttpService,
  ) {}

  public postBlob(
    url: string,
    file: { file: File; rotateAngle?: number; imageCategory?: string },
  ): Observable<HttpResponse<any>['data']> {
    let defaultHeaders = { ...DEFAULT_HEADERS };
    const data = new FormData();
    data.append('file', file.file, file.file.name);
    data.append('fileType', 'COMMON');
    if (file.rotateAngle) data.append('rotateAngle', file.rotateAngle + '');
    if (file.imageCategory) data.append('imageCategory', file.imageCategory);
    return this._baseHTTP.createHeaders(defaultHeaders).pipe(
      take(1),
      switchMap(async (headers) => {
        return { headers: headers };
      }),
      switchMap((headers) => {
        return this._http.post(this._baseHref + url, data, headers).pipe(catchError(() => EMPTY));
      }),
    );
  }
}
