<ng-container *ngIf="platform$ | async as platform">
  <app-ion-page-container
    [id]="isModalView ? 'modalView' : 'postView'"
    [isDataLoaded$]="photoReportId ? selectCurrentPhotoReport$ : selectCurrentPost$"
    [headerName]="!photoReportId ? 'Публикация' : 'Фотоотчёт'"
    [isNeedBack]="true"
    [class.mob-content]="platform !== CurrentPlatform.web"
    [class.modal-view]="isModalView"
    [backNavigateUrl]="backNavigateUrl"
    [isModalView]="isModalView"
  >
    <app-ion-page-content [isModalView]="isModalView" [class.modal-view]="isModalView">
      <ion-refresher refresh slot="fixed" (ionRefresh)="refreshList($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ng-container *ngIf="data">
        <div class="post-view">
          <ng-content select="[photoReportTitle]"></ng-content>
          <app-post-template
            [postData]="postData"
            [photoReportData]="photoReportData"
            [viewType]="isModalView ? ViewTypesList.modal : undefined"
            [dataType]="!postId ? DataTypesList.photoReport : DataTypesList.post"
            [onCommentIconClick]="onCommentIconClick"
            [userViewType]="userViewType"
          >
            <ng-container *ngIf="(isCommentMode || isCommentMode === undefined) && comments.length">
              <div
                class="answers ml-20"
                *ngIf="comments.length && comments.length / size < this.page"
                (click)="getPrevious()"
              >
                <app-text
                  [textType]="TextType.base"
                  [textSize]="TextSizes.h6BtnSmall"
                  [text]="'Показать предыдущие'"
                ></app-text>
                <app-image [img]="arrowRightSmall" class="arrow-up"></app-image>
              </div>
              <ion-list>
                <app-post-comment
                  #commentComponent
                  class="comment"
                  *ngFor="let comment of comments; let index; trackBy: trackByFn"
                  [id]="comment.id"
                  [comment]="comment"
                  [newComment]="newComment"
                  [changeCommentEditMode]="changeCommentEditMode"
                  [editCommentId]="editCommentId"
                  [rerenderPhotoReportList]="rerenderPhotoReportList"
                  [preventScrollToReplies]="!!commentToScroll"
                ></app-post-comment>
              </ion-list>
              <ion-infinite-scroll
                *ngIf="!(comments.length && comments.length / size < this.page)"
                (ionInfinite)="onScroll($event)"
              >
                <ion-infinite-scroll-content *ngIf="!isAllCommentsFetched">
                  <div class="spinner" *ngIf="isLoading">
                    <ion-spinner></ion-spinner>
                  </div>
                </ion-infinite-scroll-content>
              </ion-infinite-scroll>
            </ng-container>
            <ng-container *ngIf="platform === CurrentPlatform.web && photoReportData">
              <div>
                <app-add-comment (emitComment)="createComment($event)"></app-add-comment>
              </div>
            </ng-container>
            <ng-content photoReport select="[photoReports]"></ng-content>
          </app-post-template>
        </div>
      </ng-container>
      <!-- Данное разделение необходимо для корректного отображения компонента добавления комментариев(инпут)
        на различных платформах и для корректной работы клавиатуры на ios. Для веба достаточно, чтобы этот компонент
        был частью поста, поэтому он используется привычным образом. Для моб. версий необходимо, чтобы он был прикреплен
        к низу экрана, что в вебе выглядит несуразно. Но для ios и для работы ее клавиатуры нужно, чтобы добавление
        комментариев лежало в ion-footer и в общем компоненте (ion-page-container), дабы сохранить связку
        ion-header - ion-content - ion-footer. Для этого было принято решение воспользоваться select для ng-content
        и для мобильных версий вставлять компонент добавления комментариев в ion-page-container. Для этого варианта
        проверка на платформу и проверка на то, загрузились ли данные с ngIf лежит в общем компоненте -->
      <ng-container *ngIf="platform === CurrentPlatform.web && !photoReportData">
        <div class="add-comment">
          <app-add-comment (emitComment)="createComment($event)"></app-add-comment>
        </div>
      </ng-container>
    </app-ion-page-content>
    <div
      class="keyboard-overlay"
      *ngIf="showKeyboardOverlay && platform !== CurrentPlatform.web"
      (click)="handleBackgroundClick($event)"
    ></div>
    <app-add-comment
      footer-content-mob
      [isNeedFocus]="isNeedFocus"
      (emitComment)="createComment($event)"
    ></app-add-comment>
    <!-- -->
  </app-ion-page-container>
</ng-container>
