<div #container class="sticky padding">
  <div *ngIf="isEditMode" class="edit-comment">
    <svg class="edit-comment__edit" icon="edit-icon"></svg>
    <div class="edit-comment__content">
      <app-text text="Редактирование" class="edit-comment__title"></app-text>
      <app-text text="{{ commentToEditText }}" class="edit-comment__text"></app-text>
    </div>
    <button type="button" (click)="resetEdit()" class="edit-comment__cancel">
      <img src="assets/images/icons-svg/cross-to-delete.svg" />
    </button>
  </div>

  <div *ngIf="replyToComment$ | async as replyToComment" class="edit-comment">
    <img class="edit-comment__edit" src="assets/images/icons-svg/reply-icon.svg" />
    <div class="edit-comment__content">
      <app-text text="Ответ" class="edit-comment__title"></app-text>
      <app-text text="{{ replyToComment.text }}" class="edit-comment__text"></app-text>
    </div>
    <button type="button" (click)="resetReply()" class="edit-comment__cancel">
      <img src="assets/images/icons-svg/cross-to-delete.svg" />
    </button>
  </div>

  <form [formGroup]="createCommentForm" autofocus (keyup.enter)="createComment()">
    <!--    Adding image isn't configured-->
    <!--    <div class="button">-->
    <!--      <app-image [img]="'assets/images/icons-svg/attach-photo.svg'"></app-image>-->
    <!--    </div>-->
    <ion-item class="textarea-item">
      <ion-textarea
        #commentInput
        class="textarea"
        rows="1"
        maxlength="1200"
        placeholder="Добавить комментарий"
        formControlName="comment"
        (ngModelChange)="clearReplyData($event)"
        [ngClass]="{
          'textarea-focus': isFocused,
          'textarea_light-blue': !!commentInput.value?.trim()
        }"
        (ionFocus)="isFocused = true"
        (ionBlur)="isFocused = false"
        (keydown.enter)="onEnterKeydown($event)"
        auto-grow="true"
      ></ion-textarea>
    </ion-item>
    <button
      type="button"
      (click)="createComment()"
      class="send-icon"
      [ngClass]="{
        'send-icon_light-blue': !isDisabled,
        'send-icon_disable': isDisabled
      }"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_2459_12868)">
          <path
            d="M20.5779 11.3447L4.74353 2.47596C4.61015 2.40228 4.45749 2.37097 4.30588 2.3862C4.15426 2.40144 4.01089 2.46249 3.89483 2.56124C3.77878 2.65999 3.69556 2.79174 3.65625 2.93896C3.61694 3.08618 3.6234 3.24188 3.67478 3.38534L6.65603 11.7478C6.72171 11.9102 6.72171 12.0917 6.65603 12.2541L3.67478 20.6166C3.6234 20.76 3.61694 20.9157 3.65625 21.063C3.69556 21.2102 3.77878 21.3419 3.89483 21.4407C4.01089 21.5394 4.15426 21.6005 4.30588 21.6157C4.45749 21.631 4.61015 21.5996 4.74353 21.526L20.5779 12.6572C20.6951 12.5924 20.7928 12.4973 20.8609 12.3819C20.9289 12.2665 20.9648 12.1349 20.9648 12.001C20.9648 11.867 20.9289 11.7355 20.8609 11.6201C20.7928 11.5046 20.6951 11.4096 20.5779 11.3447Z"
            fill="#D9D9DE"
          />
        </g>
        <defs>
          <clipPath id="clip0_2459_12868">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </form>
</div>
