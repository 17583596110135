import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, of, switchMap, take, tap } from 'rxjs';
import {
  getAppUpdateData,
  setAppUpdateData,
  setCurrentAppInfo,
  setCurrentAppInfoFailed,
  setCurrentAppInfoSuccess,
  setCurrentPlatformData,
} from '../actions/platform.actions';
import { App } from '@capacitor/app';
import { Store } from '@ngrx/store';
import { CurrentPlatform } from 'src/app/shared/enums';
import packageJson from 'package.json';
import { GeneralService } from 'src/app/shared/services/general/general.service';
import { befreeUrlValues } from 'src/app/shared/constants';

@Injectable()
export class PlatformEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _router: Router,
    private _ngZone: NgZone,
    private _generalService: GeneralService,
  ) {}

  public setCurrentPlatformDataData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(setCurrentPlatformData),
      take(1),
      tap((action) => {
        if (action.platform !== CurrentPlatform.web) {
          this._store.dispatch(setCurrentAppInfo());
        } else {
          this._store.dispatch(
            setCurrentAppInfoSuccess({
              appInfo: { version: packageJson.version, name: packageJson.name },
            }),
          );
        }
      }),
    );
  });

  public setCurrentAppInfo$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(setCurrentAppInfo),
      switchMap(() => {
        return from(App.getInfo()).pipe(
          map((appInfo) => {
            return setCurrentAppInfoSuccess({ appInfo: appInfo });
          }),
          catchError((error) => {
            return of(setCurrentAppInfoFailed({ error }));
          }),
        );
      }),
    );
  });

  public getAppUpdateData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getAppUpdateData, setCurrentAppInfoSuccess),
      switchMap(() =>
        this._generalService.checkAppSupport().pipe(
          map((appUpdateData) => setAppUpdateData({ appUpdateData })),
          catchError(() => of(setAppUpdateData({ appUpdateData: { status: true } }))),
        ),
      ),
    );
  });

  public setAppUpdateData$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(setAppUpdateData),
        map(({ appUpdateData }) => {
          if (!appUpdateData.status) {
            this._ngZone.run(() => this._router.navigate([befreeUrlValues.update]));
          }
        }),
      );
    },
    { dispatch: false },
  );
}
