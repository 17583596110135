import { createReducer, on } from '@ngrx/store';
import { IPhotoReportDataState } from '../models/photo-report.model';
import {
  addPhotoReportComment,
  changeEditMode,
  clearCurrentPhotoReportData,
  clearLikedList,
  clearPhotoReportsList,
  createPhotoReportInit,
  deletePhotoReport,
  deletePhotoReportFailed,
  deletePhotoReportImageFailed,
  deletePhotoReportSuccess,
  dislikePhotoReport,
  dislikePhotoReportFailed,
  dislikePhotoReportSuccess,
  getCurrentPhotoReportData,
  getCurrentPhotoReportDataFailed,
  getCurrentPhotoReportDataSuccess,
  getPhotoReportCategoriesSuccess,
  getPhotoReportsList,
  getPhotoReportsListFailed,
  getPhotoReportsListSuccess,
  likePhotoReport,
  likePhotoReportFailed,
  likePhotoReportSuccess,
  updateCurrentPhotoReportData,
  updateCurrentPhotoReportDataSuccess,
  updateLikedList,
  updatePhotoReportData,
  updatePhotoReportDataFailed,
} from '../actions/photo-report.action';
import { ViewType } from '../../../shared/enums';
import deepClone from 'src/app/shared/utils/deepClone';

const initialState: IPhotoReportDataState = {
  photoReport: null,
  photoReportsList: [],
  isAllListFetched: false,
  categories: null,
  photoReportImages: null,
  photoReportCategories: null,
  photoReportCategoriesNames: null,
  photoReportImagesId: null,
  uploadingFiles: null,
  currentMode: '',
  likedList: [],
  isLoading: false,
  error: null,
};

export const photoReportDataReducer = createReducer(
  initialState,
  on(
    getCurrentPhotoReportData,
    (state): IPhotoReportDataState => ({
      ...state,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    clearCurrentPhotoReportData,
    (state): IPhotoReportDataState => ({
      ...state,
      isLoading: false,
      photoReport: null,
      categories: null,
      photoReportImages: null,
      photoReportCategories: null,
      photoReportCategoriesNames: null,
      photoReportImagesId: null,
      likedList: [],
      error: null,
    }),
  ),
  on(
    likePhotoReport,
    dislikePhotoReport,
    deletePhotoReport,
    deletePhotoReportSuccess,
    updatePhotoReportData,
    updateCurrentPhotoReportData,
    (state): IPhotoReportDataState => (state.isLoading ? { ...state, isLoading: false } : state),
  ),
  on(
    getPhotoReportsList,
    (state: IPhotoReportDataState): IPhotoReportDataState => ({
      ...state,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    getPhotoReportsListSuccess,
    (state: IPhotoReportDataState, action): IPhotoReportDataState => ({
      ...state,
      photoReportsList:
        !state.photoReportsList || action.resetData
          ? action.photoReportsList
          : [...state.photoReportsList, ...action.photoReportsList],
      isAllListFetched: action.photoReportsList.length < action.size,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    updateCurrentPhotoReportDataSuccess,
    (state, action): IPhotoReportDataState => ({
      ...state,
      isLoading: false,
      photoReport: action.photoReportToBeUpdated,
      photoReportImages: action.photoReportToBeUpdated.imagesData,
      photoReportCategories: Object.keys(action.photoReportToBeUpdated.imagesData),
      error: null,
    }),
  ),
  on(
    createPhotoReportInit,
    (state): IPhotoReportDataState => ({
      ...state,
      isLoading: true,
      photoReport: null,
      photoReportImages: null,
      photoReportCategories: null,
      error: null,
    }),
  ),
  on(
    getCurrentPhotoReportDataSuccess,
    (state: IPhotoReportDataState, action): IPhotoReportDataState => ({
      ...state,
      photoReport: action.photoReport,
      categories: action.categories,
      photoReportImages: action.photoReportImages,
      photoReportCategories: action.photoReportCategories,
      photoReportCategoriesNames: action.photoReportCategoriesNames,
      photoReportImagesId: action.photoReportImagesId,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getPhotoReportCategoriesSuccess,
    (state: IPhotoReportDataState, action): IPhotoReportDataState => ({
      ...state,
      categories: action.categories,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    addPhotoReportComment,
    (state: IPhotoReportDataState, { index, category }): IPhotoReportDataState => {
      if (state.photoReport) {
        const photoReportImages = deepClone(state.photoReport.imagesData);
        photoReportImages[category][index].commentCount += 1;
        return {
          ...state,
          photoReport: {
            ...state.photoReport,
            imagesData: photoReportImages,
          },
        };
      }
      return state;
    },
  ),
  on(
    likePhotoReportSuccess,
    (state: IPhotoReportDataState): IPhotoReportDataState => ({
      ...state,
      photoReport: state.photoReport && {
        ...state.photoReport,
        liked: true,
        likes: state.photoReport.likes + 1,
      },
    }),
  ),
  on(
    dislikePhotoReportSuccess,
    (state: IPhotoReportDataState): IPhotoReportDataState => ({
      ...state,
      photoReport: state.photoReport && {
        ...state.photoReport,
        liked: false,
        likes: state.photoReport.likes - 1,
      },
    }),
  ),
  on(
    likePhotoReportFailed,
    dislikePhotoReportFailed,
    getCurrentPhotoReportDataFailed,
    deletePhotoReportFailed,
    deletePhotoReportImageFailed,
    updatePhotoReportDataFailed,
    getPhotoReportsListFailed,
    (state: IPhotoReportDataState, action): IPhotoReportDataState => ({
      ...state,
      isLoading: false,
      error: action.error,
    }),
  ),
  on(
    changeEditMode,
    (state: IPhotoReportDataState, action): IPhotoReportDataState => ({
      ...state,
      currentMode: action.currentMode,
      uploadingFiles:
        state.currentMode !== ViewType.editPhotoReport ? state.photoReportImages : null,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    updateLikedList,
    (state: IPhotoReportDataState, action): IPhotoReportDataState => ({
      ...state,
      likedList:
        state.likedList.length && state.likedList.includes(action.fileId)
          ? state.likedList.filter((id) => id !== action.fileId)
          : [...state.likedList, action.fileId],
    }),
  ),
  on(
    clearLikedList,
    (state: IPhotoReportDataState): IPhotoReportDataState => ({
      ...state,
      likedList: [],
    }),
  ),
  on(
    clearPhotoReportsList,
    (state: IPhotoReportDataState): IPhotoReportDataState => ({
      ...state,
      photoReportsList: [],
      isLoading: false,
      isAllListFetched: false,
    }),
  ),
);
