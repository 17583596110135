<a class="user-block no-drag" [routerLink]="!utils.isWeb ? link : null">
  <div
    class="user-block__img"
    [class.image-border]="!imgSrc"
    [style.width]="imgSize"
    [style.height]="imgSize"
    [style.borderRadius]="imgSize === UserDataImgSize.s ? UserDataImgBorder.s : UserDataImgBorder.m"
  >
    <app-image [img]="imgSrc | addBaseUrl"></app-image>
  </div>
  <div class="user-block__title">
    <div>
      <a [routerLink]="utils.isWeb ? link : null" class="nav-link">
        <app-text
          *ngIf="fullName"
          [text]="fullName"
          (click)="onClick()"
          class="user-block__title_name"
          [ngClass]="{ 'user-block__title_name-active': isActive }"
        ></app-text>
      </a>
    </div>
    <div>
      <app-text
        *ngIf="position"
        [textType]="TextType.base"
        [textSize]="TextSizes.mobPost"
        [text]="position"
      ></app-text>
    </div>
    <div *ngIf="date">
      <app-text
        *ngIf="date"
        [textType]="TextType.base"
        [textSize]="TextSizes.date"
        [text]="date | dateFormat"
      ></app-text>
    </div>
    <div *ngIf="phoneNumber || email" class="additional-information">
      <div *ngIf="phoneNumber" class="additional-information__item">
        <img src="assets/images/icons-svg/phone-number.svg" />
        <a [href]="'tel:+' + phoneNumber">
          <app-text
            [textType]="TextType.base"
            [textSize]="TextSizes.mobInput"
            [text]="phoneNumber"
          ></app-text>
        </a>
      </div>
      <div *ngIf="email" class="additional-information__item">
        <img src="assets/images/icons-svg/email.svg" />
        <a [href]="'mailto:' + email">
          <app-text
            [textType]="TextType.base"
            [textSize]="TextSizes.mobInput"
            [text]="email"
          ></app-text>
        </a>
      </div>
    </div>
  </div>
</a>
