import { HttpErrorResponse } from '@angular/common/http';
import { IFavouritesFile, IUser } from 'src/app/shared/services/user/user.type';

export const USERS_KEY = 'usersData';

export interface IUsersState {
  currentUser: IUser | null;
  userPageData: IUser | null;
  favoriteFilesList: IFavouritesFile[];
  isLoading: boolean;
  isAvatarLoading: boolean;
  error: HttpErrorResponse | null;
  counters: { [x: string]: number } | null;
}

export enum UsersActions {
  GetCurrent = 'GetCurrent',
  GetCounters = 'GetCounters',
  SetCounters = 'SetCounters',
  Login = 'Login',
  Logout = 'Logout',
  SetCurrentUserFavShops = 'SetCurrentUserFavShops',
  GetUser = 'GetUser',
  GetFavoritesFiles = 'GetFavoritesFiles',
  AddAvatar = 'AddAvatar',
}
