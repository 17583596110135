export interface Storage {
  type: StorageType;
  value?: any;
}

export enum StorageType {
  PushToken = 'PUSH_TOKEN',
  Token = 'TOKEN',
  User = 'USER',
  IsTrainingNotificationActive = 'IS_TRAINING_NOTIFICATION_ACTIVE',
}
