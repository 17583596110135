import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  activeOptionFilterSearchValueChange,
  applySingleFilter,
  applySingleFilterAction,
  clearFilters,
  clearFiltersInit,
  externalFiltersMenuCleaning,
  getFilterElementsList,
  getFilterElementsListFailed,
  getFilterElementsListSuccess,
  updateFiltersAction,
  updateFiltersData,
} from '../actions/filters.actions';
import { selectFiltersState, selectNumOfActiveFilters } from '../selectors/filters.selectors';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import {
  ActiveFiltersSelectionsTypes,
  FiltersOptions,
  FiltersResetTypes,
} from 'src/app/shared/enums';
import { FiltersOptionsType, FiltersRequestTypes } from '../models/filters.model';
import { IFilter } from 'src/app/shared/interfaces';
import { DepartmentsService } from 'src/app/shared/services/departments/departments.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { TrainingService } from 'src/app/shared/services/training/training.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { trainingStageFilters, trainingTrainerRolesFilters } from 'src/app/shared/constants';

@Injectable()
export class FiltersEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _departmentsService: DepartmentsService,
    private _trainingService: TrainingService,
    private _userService: UserService,
    private _utils: UtilsService,
  ) {}

  public clearFiltersState$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(clearFiltersInit),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectNumOfActiveFilters),
      ]),
      switchMap(([action, chosenFilters, numOfActiveFilters]) => {
        let updateFiltersState = {
          filtersParams: { ...chosenFilters.filtersList },
          favs: chosenFilters.favs,
        };

        let options: FiltersOptionsType[] = [];
        let excludeFiltersOptions: FiltersOptions[] = [];
        const ignoreSpecificFiltersOptions = [FiltersOptions.segment];

        if (chosenFilters.filtersList) {
          options = Object.keys(chosenFilters.filtersList).filter(
            (option) => !ignoreSpecificFiltersOptions?.includes(option as FiltersOptions),
          ) as FiltersOptionsType[];

          excludeFiltersOptions = this._utils.getOnlyExcludeFiltersOptions(
            chosenFilters.filtersList,
          );
        }

        if (
          (action.clearType === FiltersResetTypes.deleteChip ||
            action.clearType === FiltersResetTypes.resetCurrent) &&
          action.activeFilterOption
        ) {
          if (numOfActiveFilters === ActiveFiltersSelectionsTypes.mono) {
            updateFiltersState.filtersParams = this._utils.cleanMonoTypeFilters(
              updateFiltersState.filtersParams,
              options,
              action.activeFilterOption,
              action.id,
            );
          }

          if (
            numOfActiveFilters === ActiveFiltersSelectionsTypes.multiple &&
            !ignoreSpecificFiltersOptions.includes(action.activeFilterOption)
          ) {
            updateFiltersState.filtersParams = this._utils.cleanMultiplyTypeFilters(
              updateFiltersState.filtersParams,
              options,
              excludeFiltersOptions,
              action.activeFilterOption,
            );
          }
        }

        if (action.clearType === FiltersResetTypes.resetAll) {
          options.forEach((option) => {
            if (!ignoreSpecificFiltersOptions.includes(option)) {
              updateFiltersState.filtersParams = this._utils.updateFiltersDataByOption(
                updateFiltersState.filtersParams,
                option,
              );
            }
          });
          updateFiltersState.favs = false;
        }

        return of(
          clearFilters({
            update: updateFiltersState.filtersParams,
            updateFavs: updateFiltersState.favs,
            isNeedExternalFiltersMenuCleaning: action.isNeedExternalFiltersMenuCleaning,
          }),
        );
      }),
    );
  });

  public externalClearFiltersMenu$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(clearFilters),
      switchMap((data) => {
        if (data.isNeedExternalFiltersMenuCleaning) {
          return of(externalFiltersMenuCleaning({ ...data }));
        }
        return of();
      }),
    );
  });

  public getFilterElements$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getFilterElementsList, activeOptionFilterSearchValueChange),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectNumOfActiveFilters),
      ]),
      switchMap(([action, filtersState, numOfActiveFilters]) => {
        let filters: IFilter = {
          page: filtersState.page,
          size: filtersState.size,
        };

        filters[
          action.activeFilterOption === FiltersOptions.cities ||
          action.activeFilterOption === FiltersOptions.groups ||
          action.activeFilterOption === FiltersOptions.trainer
            ? 'name'
            : 'query'
        ] = action.type === activeOptionFilterSearchValueChange.type ? action.searchValue : null;

        if (action.activeFilterOption === FiltersOptions.groups) {
          filters.type = 'GROUP';
          filters.rootOnly = false;
        }

        if (
          numOfActiveFilters === ActiveFiltersSelectionsTypes.multiple &&
          (action.activeFilterOption === FiltersOptions.shops ||
            action.activeFilterOption === FiltersOptions.trainer)
        ) {
          filters = {
            ...filters,
            region: this._utils.setRequestFilterParams({
              filtersOptionsParams: filtersState.filtersList,
              filterOption: FiltersOptions.regions,
            }),
            city: this._utils.setRequestFilterParams({
              filtersOptionsParams: filtersState.filtersList,
              filterOption: FiltersOptions.cities,
            }),
            shop:
              action.activeFilterOption === FiltersOptions.trainer
                ? this._utils.setRequestFilterParams({
                    filtersOptionsParams: filtersState.filtersList,
                    filterOption: FiltersOptions.shops,
                  })
                : null,
            trainerRole:
              action.activeFilterOption === FiltersOptions.trainer
                ? this._utils.setRequestFilterParams({
                    filtersOptionsParams: filtersState.filtersList,
                    filterOption: FiltersOptions.trainerRole,
                  })
                : null,
          };
        }

        let requestObservable$: Observable<FiltersRequestTypes> = this._departmentsService
          .getParamsForFilters(action.activeFilterOption, filters)
          .pipe(map((data) => data.elements));

        switch (action.activeFilterOption) {
          case FiltersOptions.potential:
            requestObservable$ = this._trainingService
              .getPotentialList(filters)
              .pipe(map((data) => data.elements));
            break;
          case FiltersOptions.positions:
            requestObservable$ = this._trainingService.getPositionsList();
            break;
          case FiltersOptions.trainer:
            requestObservable$ = this._userService
              .getTrainersList(filters)
              .pipe(map((data) => data.elements));
            break;
          case FiltersOptions.stage:
            requestObservable$ = of(trainingStageFilters);
            break;
          case FiltersOptions.trainerRole:
            requestObservable$ = of(trainingTrainerRolesFilters);
            break;
        }

        return requestObservable$.pipe(
          map((list) => {
            return getFilterElementsListSuccess({
              list,
              resetData: filtersState.page === 0,
              size:
                action.activeFilterOption === FiltersOptions.positions
                  ? list.length + 1
                  : filtersState.size,
            });
          }),
          catchError((error) => {
            return of(getFilterElementsListFailed({ error }));
          }),
        );
      }),
    );
  });

  public updateFiltersState$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updateFiltersAction),
      concatLatestFrom(() => this._store.select(selectNumOfActiveFilters)),
      switchMap(([action, numOfActiveFilters]) => {
        let filters = { ...action.filtersOptionsParams };
        let options: FiltersOptionsType[] = [];

        if (action.filtersOptionsParams) {
          options = Object.keys(action.filtersOptionsParams) as FiltersOptionsType[];
        }

        if (numOfActiveFilters === ActiveFiltersSelectionsTypes.mono && action.activeFilterOption) {
          options.forEach((option) => {
            if (
              option !== action.activeFilterOption &&
              !filters[option]?.isFilterSingleButton &&
              filters[action.activeFilterOption!]?.params?.length
            ) {
              filters = this._utils.updateFiltersDataByOption(filters, option);
            }
          });
        }

        if (
          numOfActiveFilters === ActiveFiltersSelectionsTypes.multiple &&
          action.activeFilterOption
        ) {
          filters = this._utils.updateFiltersWhenSelecting(
            filters,
            options,
            action.activeFilterOption,
          );
        }

        return of(updateFiltersData({ updateData: filters ?? null }));
      }),
    );
  });

  public updateFiltersStateBySingleFilter$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(applySingleFilterAction),
      concatLatestFrom(() => this._store.select(selectFiltersState)),
      switchMap(([action, filtersState]) => {
        let filters = { ...filtersState.filtersList, ...action.data };

        if (
          action.activeFilterOption === FiltersOptions.trainerRole &&
          action.data?.[FiltersOptions.trainer]?.params?.length
        ) {
          filters = {
            ...filters,
            ...action.data,
            [FiltersOptions.trainer]: {
              ...filters[FiltersOptions.trainer],
              params: null,
            },
          };
        }

        return of(applySingleFilter({ update: filters ?? null }));
      }),
    );
  });
}
