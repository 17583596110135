<div class="image" [class.no-image]="isLoading && img">
  <img
    *ngIf="imgSrc"
    draggable="false"
    [style.transform]="rotateAngle ? 'rotate(' + rotateAngle + 'deg)' : undefined"
    [class.plug]="!isLoading && imgSrc === plugImgSrc"
    [src]="imgSrc"
    [alt]="imgSrc"
    [style]="imgStyle"
  />
</div>
