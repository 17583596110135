import { createReducer, on } from '@ngrx/store';
import { IUploadingFilesState } from '../models/uploading-files.model';
import {
  changeUploadedImageRotate,
  changeUploadedImageRotateFailed,
  changeUploadedImageRotateSuccess,
  changeUploadingFilesInEditMode,
  changeUploadingFilesList,
  cleanCreatePhotoReportUploadingImages,
  clearUploadedFilesData,
  deleteUploadedFilesData,
  initUploadFile,
  initUploadFileFailed,
  initUploadFileSuccess,
  updateFileLocalUrl,
  uploadFileFailed,
  uploadFileSuccess,
} from '../actions/uploading-files.actions';
import { closePostModal, createNewPost, editPost } from '../actions/posts.actions';
import { createPhotoReportSuccess } from '../actions/photo-report.action';
import { IFileData, IUploadingFile } from '../../../shared/services/posts/posts.type';
import { FileType } from '../../../shared/enums';
import { environment } from '../../../../environments/environment';
import { imagePlaceholderUrl } from 'src/app/shared/constants';
import isImage from 'src/app/shared/utils/isImage';

const initialState: IUploadingFilesState = {
  uploadingFiles: [],
  uploadingFilesByCategory: null,
  uploadingFilesOfEditedPost: [],
  postEditMode: false,
  localId: 0,
  error: null,
  isLoading: false,
};

function getLocalRotateAngle(file: IUploadingFile) {
  if (file.localRotateAngle === 270) {
    return 0;
  } else if (!file.localRotateAngle) {
    return 90;
  } else {
    return file.localRotateAngle + 90;
  }
}

export const UploadingFilesReducer = createReducer(
  initialState,
  on(
    initUploadFileFailed,
    uploadFileFailed,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      error: action.error,
      isLoading: false,
      uploadingFiles:
        !state.postEditMode && action.type === uploadFileFailed.type && state.uploadingFiles
          ? state.uploadingFiles.filter((file) => file.localId !== action.localId)
          : state.uploadingFiles,
      uploadingFilesOfEditedPost:
        state.postEditMode &&
        action.type === uploadFileFailed.type &&
        state.uploadingFilesOfEditedPost
          ? state.uploadingFilesOfEditedPost.filter((file) => file.localId !== action.localId)
          : state.uploadingFilesOfEditedPost,
    }),
  ),
  on(
    initUploadFile,
    (state: IUploadingFilesState): IUploadingFilesState => ({
      ...state,
      error: null,
      isLoading: true,
    }),
  ),
  on(
    initUploadFileSuccess,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFiles: !state.postEditMode
        ? [
            ...state.uploadingFiles,
            {
              ...action,
              localRotateAngle: 0,
              isLoading: true,
            },
          ]
        : state.uploadingFiles,
      uploadingFilesOfEditedPost: state.postEditMode
        ? [
            ...state.uploadingFilesOfEditedPost,
            {
              ...action,
              localRotateAngle: 0,
              isLoading: true,
            },
          ]
        : state.uploadingFilesOfEditedPost,
      uploadingFilesByCategory: action.uploadingFilesByCategory,
      localId: action.localId + 1,
      error: null,
    }),
  ),
  on(
    uploadFileSuccess,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      isLoading: false,
      uploadingFiles: !state.postEditMode
        ? state.uploadingFiles.map((file) =>
            file.localId === action.localId
              ? {
                  ...file,
                  isLoading: file.localUrl === imagePlaceholderUrl,
                  uploadedFileData: action.uploadedFileData,
                  id: action.id,
                }
              : file,
          )
        : state.uploadingFiles,
      uploadingFilesOfEditedPost: state.postEditMode
        ? state.uploadingFilesOfEditedPost.map((file) =>
            file.localId === action.localId
              ? {
                  ...file,
                  isLoading: file.localUrl === imagePlaceholderUrl,
                  uploadedFileData: action.uploadedFileData,
                  id: action.id,
                }
              : file,
          )
        : state.uploadingFiles,
      uploadingFilesByCategory:
        action.imageCategory && state.uploadingFilesByCategory
          ? {
              ...state.uploadingFilesByCategory,
              [action.imageCategory]: state.uploadingFilesByCategory[action.imageCategory].map(
                (file: IUploadingFile) =>
                  file.localId === action.localId
                    ? {
                        ...file,
                        isLoading: file.localUrl === imagePlaceholderUrl,
                        uploadedFileData: action.uploadedFileData,
                        id: action.id,
                      }
                    : file,
              ),
            }
          : null,
      error: null,
    }),
  ),
  on(
    updateFileLocalUrl,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFiles: !state.postEditMode
        ? state.uploadingFiles.map((file) =>
            file.localId === action.localId
              ? { ...file, isLoading: !file.uploadedFileData, localUrl: action.localUrl }
              : file,
          )
        : state.uploadingFiles,
      uploadingFilesOfEditedPost: state.postEditMode
        ? state.uploadingFilesOfEditedPost.map((file) =>
            file.localId === action.localId
              ? { ...file, isLoading: !file.uploadedFileData, localUrl: action.localUrl }
              : file,
          )
        : state.uploadingFiles,
      uploadingFilesByCategory:
        action.imageCategory && state.uploadingFilesByCategory
          ? {
              ...state.uploadingFilesByCategory,
              [action.imageCategory]: state.uploadingFilesByCategory[action.imageCategory].map(
                (file: IUploadingFile) =>
                  file.localId === action.localId
                    ? { ...file, isLoading: !file.uploadedFileData, localUrl: action.localUrl }
                    : file,
              ),
            }
          : null,
      error: null,
    }),
  ),
  on(
    changeUploadingFilesInEditMode,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFilesByCategory: action.photoReportData,
    }),
  ),
  on(
    changeUploadingFilesList,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFilesOfEditedPost: action.uploadingFiles.files.map(
        (file: IFileData): IUploadingFile => ({
          isLoading: false,
          id: file.id,
          localRotateAngle: 0,
          localId: file.id,
          localUrl: environment.befreeUrl + file.path,
          localName: file.name,
          localSize: file.size,
          fileType: isImage(file) ? FileType.image : FileType.file,
        }),
      ),
      postEditMode: action.isEditMode,
    }),
  ),
  on(
    deleteUploadedFilesData,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFiles: !state.postEditMode
        ? state.uploadingFiles.filter((file) => file.localId !== action.localIdToBeDeleted)
        : state.uploadingFiles,
      uploadingFilesOfEditedPost: state.postEditMode
        ? state.uploadingFilesOfEditedPost.filter(
            (file) => file.localId !== action.localIdToBeDeleted,
          )
        : [],
      uploadingFilesByCategory:
        action.imageCategory && state.uploadingFilesByCategory
          ? {
              ...state.uploadingFilesByCategory,
              [action.imageCategory]: state.uploadingFilesByCategory[action.imageCategory].filter(
                (file: IUploadingFile) =>
                  action.imageId
                    ? file.id !== action.imageId
                    : file.localId !== action.localIdToBeDeleted,
              ),
            }
          : null,
    }),
  ),
  on(
    changeUploadedImageRotate,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFiles: state.uploadingFiles.map((file) =>
        file.localId === action.localId
          ? {
              ...file,
              isLoading: true,
            }
          : file,
      ),
      uploadingFilesByCategory:
        action.imageCategory && state.uploadingFilesByCategory
          ? {
              ...state.uploadingFilesByCategory,
              [action.imageCategory]: state.uploadingFilesByCategory[action.imageCategory].map(
                (file: IUploadingFile) =>
                  (action.id ? action.id === file.id : file.localId === action.localId)
                    ? {
                        ...file,
                        isLoading: true,
                      }
                    : file,
              ),
            }
          : null,
    }),
  ),
  on(
    changeUploadedImageRotateSuccess,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFiles: state.uploadingFiles.map((file) =>
        file.localId === action.localId
          ? {
              ...file,
              isLoading: false,
              localRotateAngle: getLocalRotateAngle(file),
            }
          : file,
      ),
      uploadingFilesByCategory:
        action.imageCategory && state.uploadingFilesByCategory
          ? {
              ...state.uploadingFilesByCategory,
              [action.imageCategory]: state.uploadingFilesByCategory[action.imageCategory].map(
                (file: IUploadingFile) => {
                  return (action.id ? action.id === file.id : file.localId === action.localId)
                    ? {
                        ...file,
                        isLoading: false,
                        localRotateAngle: action.rotateAngle,
                      }
                    : file;
                },
              ),
            }
          : null,
    }),
  ),
  on(
    changeUploadedImageRotateFailed,
    (state: IUploadingFilesState, action): IUploadingFilesState => ({
      ...state,
      uploadingFiles: state.uploadingFiles.map((file) =>
        file.localId === action.localId
          ? {
              ...file,
              isLoading: false,
            }
          : file,
      ),
      uploadingFilesByCategory:
        action.imageCategory && state.uploadingFilesByCategory
          ? {
              ...state.uploadingFilesByCategory,
              [action.imageCategory]: state.uploadingFilesByCategory[action.imageCategory].map(
                (file: IUploadingFile) =>
                  file.localId === action.localId
                    ? {
                        ...file,
                        isLoading: false,
                      }
                    : file,
              ),
            }
          : null,
      error: action.error,
    }),
  ),
  on(
    closePostModal,
    createNewPost,
    editPost,
    createPhotoReportSuccess,
    cleanCreatePhotoReportUploadingImages,
    clearUploadedFilesData,
    (state: IUploadingFilesState): IUploadingFilesState => ({
      ...state,
      uploadingFiles: initialState.uploadingFiles,
      uploadingFilesOfEditedPost: [],
      uploadingFilesByCategory: null,
      postEditMode: false,
    }),
  ),
);
