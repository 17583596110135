<!-- ion-content вынесен в отдельный компонент с возможностью вставить ion-refresher по селекту в ng-content из-за того,
     что для корректного отображения refresher’a необходима четкая вложенность ion-refresher’а в ion-content и никак иначе,
     то есть не должно быть никакой вложенности DOM-элементов.
     Благодаря данному решению, можно вставлять компоненты в ion-page-container(через router-outlet и не только),
     которые в свою очередь можно обернуть в ion-page-content, непосредственно в самой компоненте,
     и использовать на каждую компоненту свой ion-refresher -->

<ion-content
  appIonScrollBar
  [scrollEvents]="true"
  (ionScroll)="onScroll($any($event))"
  [forceOverscroll]="forceOverscroll"
>
  <ng-content select="[refresh]"></ng-content>
  <ion-grid>
    <ion-row>
      <ion-col *ngIf="!isModalView" class="page-content__col-menu" size="2.5">
        <div class="menu-wrapper">
          <ng-container *ngFor="let option of menuOptions">
            <a
              *ngIf="isUrlExternal(option.url)"
              class="menu-item"
              target="_blank"
              [href]="option.url"
            >
              <ng-container *ngTemplateOutlet="menuItem"></ng-container>
            </a>

            <a
              *ngIf="!isUrlExternal(option.url)"
              class="menu-item"
              (click)="navigate(option.url)"
              [routerLink]="['/', dashboardUrl, option.url]"
            >
              <ng-container *ngTemplateOutlet="menuItem"></ng-container>
            </a>

            <ng-template #menuItem>
              <div class="menu-item__info">
                <img [src]="option.img" />
                <app-text
                  [textSize]="TextSizes.h2Menu"
                  [textType]="TextType.base"
                  [text]="option.name"
                ></app-text>
                <app-svg icon="open-in-new" *ngIf="option.name === 'Библиотека'"></app-svg>
              </div>
              <ng-container *ngIf="option.counterName && { data: counters$ | async } as counters">
                <div
                  class="menu-item__counter"
                  *ngIf="counters.data?.[option.counterName] as menuCounterValue"
                >
                  <app-text
                    [textSize]="TextSizes.qty"
                    [textType]="TextType.base"
                    [text]="menuCounterValue.toString()"
                  ></app-text>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
        </div>
      </ion-col>
      <ion-col class="page-content__col-content">
        <ng-content></ng-content>
      </ion-col>
      <ion-col
        *ngIf="!isModalView && isBirthdaysColShow"
        class="page-content__col-birthdays"
        size="3"
      ></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
