<div class="create-poll" [class.create-poll-mob]="isMob">
  <div class="create-poll__header">
    <app-text
      [textSize]="TextSizes.h1Header"
      [textType]="TextType.base"
      [text]="'опрос'"
    ></app-text>

    <ion-buttons slot="end">
      <ion-icon (click)="close()" slot="icon-only" name="close-outline"></ion-icon>
    </ion-buttons>
  </div>

  <form class="create-poll__form" [formGroup]="createPollForm">
    <div #formContent class="create-poll__form-content">
      <div class="create-poll__form-content-question">
        <div class="content-section-name">
          <app-text
            [textSize]="TextSizes.h3Body"
            [textType]="TextType.base"
            [text]="'Задайте вопрос'"
          ></app-text>
        </div>
        <ion-input
          class="custom"
          formControlName="text"
          autocapitalize="true"
          maxlength="255"
        ></ion-input>
        <ion-note
          *ngIf="
            createPollForm.get('text')?.hasError('required') && createPollForm.get('text')?.touched
          "
          slot="error"
          class="error-text"
          ><app-text
            [textSize]="TextSizes.alert"
            [textType]="TextType.base"
            [text]="'обязательное поле'"
          ></app-text
        ></ion-note>
      </div>

      <div #formOptions class="create-poll__form-content-answers">
        <div class="content-section-name">
          <app-text
            [textSize]="TextSizes.h3Body"
            [textType]="TextType.base"
            [text]="'Варианты ответа'"
          ></app-text>
        </div>
        <ng-container formArrayName="pollOptions">
          <ng-container *ngFor="let optionsForm of options.controls; let i = index">
            <div class="create-poll__form-content-answers-options">
              <div
                class="create-poll__form-content-answers-options-delete"
                (click)="deleteOption(i)"
              >
                <app-image [img]="'assets/images/icons-svg/cross-to-delete.svg'"></app-image>
              </div>
              <div class="create-poll__form-content-answers-options-current" [formGroupName]="i">
                <ion-input
                  class="custom"
                  formControlName="text"
                  autocapitalize="true"
                  maxlength="255"
                ></ion-input>
              </div>
            </div>
            <ion-note
              *ngIf="
                optionsForm.get('text')?.hasError('required') && optionsForm.get('text')?.touched
              "
              slot="error"
              class="error-text"
              ><app-text
                [textSize]="TextSizes.alert"
                [textType]="TextType.base"
                [text]="'обязательное поле'"
              ></app-text
            ></ion-note>
          </ng-container>
        </ng-container>

        <div
          *ngIf="options.controls.length < maxNumOfOptions"
          class="create-poll__form-content-answers-add"
          (click)="addOption(options.controls.length)"
        >
          <app-image [img]="'assets/images/icons-svg/posts/add-answer.svg'"></app-image>
          <app-text
            [textSize]="TextSizes.h3Body"
            [textType]="TextType.base"
            [text]="'добавить вариант'"
          ></app-text>
        </div>
      </div>

      <div class="create-poll__form-content-settings">
        <div class="content-section-name">
          <app-text
            [textSize]="TextSizes.h3Body"
            [textType]="TextType.base"
            [text]="'Настройки опроса'"
          ></app-text>
        </div>
        <div class="checkbox-wrapper">
          <ion-item>
            <ion-checkbox
              slot="start"
              [checked]="
                this.createPollForm.controls['type'].value === pollTypes.anonymous ? true : false
              "
              (ionChange)="setPollType($event)"
            ></ion-checkbox>
            <ion-label
              ><app-text
                [textSize]="TextSizes.h3Body"
                [textType]="TextType.base"
                [text]="'Анонимный опрос'"
              ></app-text
            ></ion-label>
          </ion-item>
        </div>
        <div class="checkbox-wrapper">
          <ion-item>
            <ion-checkbox slot="start" formControlName="isMultipleChoices"></ion-checkbox>
            <ion-label
              ><app-text
                [textSize]="TextSizes.h3Body"
                [textType]="TextType.base"
                [text]="'Выбор нескольких вариантов'"
              ></app-text
            ></ion-label>
          </ion-item>
        </div>
      </div>
    </div>

    <div class="create-poll__form-footer">
      <app-button
        [btnType]="Buttons.small"
        [textSize]="ButtonTextSizes.btnMainSmall"
        [isDisabled]="this.createPollForm.invalid || options.controls.length < minNumOfOptions"
        [text]="'Готово'"
        (click)="submitForm()"
      ></app-button>
    </div>
  </form>
</div>
