import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { DepartmentsService } from 'src/app/shared/services/departments/departments.service';
import {
  currentDepartmentDataFailed,
  currentDepartmentDataSuccess,
  currentGroupDataFailed,
  currentGroupDataSuccess,
  currentRegionDataFailed,
  currentRegionDataSuccess,
  getAdditionalDepartmentsList,
  getAdditionalDepartmentsListFailed,
  getAdditionalDepartmentsListSuccess,
  getDepartmentsList,
  getDepartmentsListFailed,
  getDepartmentsListSuccess,
  getEmployeesList,
  getEmployeesListFailed,
  getEmployeesListSuccess,
  initCurrentDepartmentData,
  initCurrentGroupData,
  initCurrentRegionData,
} from '../actions/department.action';
import { UserService } from '../../../shared/services/user/user.service';
import { selectFiltersMenuState, selectFiltersState } from '../selectors/filters.selectors';
import { Store } from '@ngrx/store';
import { IFilter } from '../../../shared/interfaces';
import { DepartmentsType, EmployeesPageType, FiltersOptions } from '../../../shared/enums';
import { UtilsService } from '../../../shared/services/utils.service';
import { getShopsListSuccess } from '../actions/shop.action';
import { FiltersOptionsType } from '../models/filters.model';

@Injectable()
export class DepartmentEffects {
  constructor(
    private _actions$: Actions,
    private _departmentsService: DepartmentsService,
    private _userService: UserService,
    private _store: Store,
    private _utils: UtilsService,
  ) {}

  public getCurrentDepartment$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(initCurrentDepartmentData),
      switchMap((action) => {
        return this._departmentsService.getAnyDepartment(action.departmentId).pipe(
          map((department) => {
            return currentDepartmentDataSuccess({ department });
          }),
          catchError((error) => {
            return of(currentDepartmentDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentGroup$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(initCurrentGroupData),
      switchMap((action) => {
        return this._departmentsService.getAnyDepartment(action.groupId).pipe(
          map((group) => {
            return currentGroupDataSuccess({ group });
          }),
          catchError((error) => {
            return of(currentGroupDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentRegion$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(initCurrentRegionData),
      switchMap((action) => {
        return this._departmentsService.getAnyDepartment(action.regionId).pipe(
          map((region) => {
            return currentRegionDataSuccess({ region });
          }),
          catchError((error) => {
            return of(currentRegionDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getEmployeesList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getEmployeesList),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectFiltersMenuState),
      ]),
      switchMap(([action, filtersState, filtersMenu]) => {
        const queryFilters = this._utils.getFiltersFromUrl();

        const department =
          action.currentEmployeesPageType === EmployeesPageType.allEmployees
            ? queryFilters?.department?.value || null
            : action.departmentId;

        let filters = {
          page: filtersState.page,
          size: filtersState.size,
          name: this._utils.setRequestEmployeeSearch(filtersState.query).name || null,
          surname: this._utils.setRequestEmployeeSearch(filtersState.query).surname || null,
          department,
        };

        if (filtersMenu.filters?.filtersMenuParams) {
          filters = {
            ...filters,
            department:
              this._utils.setRequestFilterParams({
                filtersOptionsParams: filtersMenu.filters?.filtersMenuParams,
              }) || null,
          };
        }

        const currentType = filtersMenu.filters?.filtersMenuParams
          ? Object.keys(filtersMenu.filters?.filtersMenuParams).find(
              (option) =>
                filtersMenu.filters?.filtersMenuParams?.[option as FiltersOptionsType]?.params
                  ?.length,
            )
          : queryFilters?.department?.type;

        const getUrlEntity = (itemName: keyof IFilter, type: FiltersOptions) =>
          this._utils.getUrlEntity(filters, itemName, type, filtersMenu.filters?.filtersMenuParams);

        const filterToUrl = {
          department:
            action.currentEmployeesPageType === EmployeesPageType.allEmployees
              ? getUrlEntity('department', currentType)
              : undefined,
        };

        this._utils.syncFiltersRouteQuery(filterToUrl);

        return this._userService.getEmployees(filters).pipe(
          map((employeesList) => {
            return getEmployeesListSuccess({
              employeesList: employeesList,
              resetData: filters.page === 0,
              size: filtersState.size,
            });
          }),
          catchError((error) => {
            return of(getEmployeesListFailed({ error }));
          }),
        );
      }),
    );
  });

  public getDepartmentsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getDepartmentsList),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([, filtersState]) => {
        const filters = { page: filtersState.page, size: filtersState.size, sort: 'name' };
        return this._departmentsService.getDepartments(filters).pipe(
          map((departmentsList) => {
            return getDepartmentsListSuccess({
              departmentsList: departmentsList.elements,
              resetData: filters.page === 0,
              size: filtersState.size,
            });
          }),
          catchError((error) => {
            return of(getDepartmentsListFailed({ error }));
          }),
        );
      }),
    );
  });

  public getAdditionalDepartmentsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getAdditionalDepartmentsList),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([action, filtersState]) => {
        let filters: IFilter = {
          page: filtersState.page,
          size: filtersState.size,
          type: action.departmentType,
          name: filtersState.query || null,
          sort: 'name',
        };

        return this._departmentsService
          .getAdditionalDepartmentLists(action.departmentId, filters)
          .pipe(
            map((departmentsList) => {
              if (action.departmentType === DepartmentsType.shop) {
                return getShopsListSuccess({
                  shopList: departmentsList,
                  resetData: filters.page === 0,
                  size: filtersState.size,
                });
              }
              return getAdditionalDepartmentsListSuccess({
                departmentsList: departmentsList.elements,
                resetData: filters.page === 0,
                size: filtersState.size,
              });
            }),
            catchError((error) => {
              return of(getAdditionalDepartmentsListFailed({ error }));
            }),
          );
      }),
    );
  });
}
