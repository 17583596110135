import { Pipe, PipeTransform } from '@angular/core';
import { IImage } from '../../services/posts/posts.type';

@Pipe({
  name: 'isImageLiked',
})
export class IsImageLikedPipe implements PipeTransform {
  transform(likedList: number[] | undefined, currentImage: IImage): boolean {
    if (!likedList) {
      return currentImage.liked;
    }
    return (
      (currentImage.liked && !likedList.includes(currentImage.id)) ||
      (!currentImage.liked && likedList.includes(currentImage.id))
    );
  }
}
