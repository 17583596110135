import { createReducer, on } from '@ngrx/store';
import {
  activeOptionFilterSearchValueChange,
  applyMenuFiltersAction,
  applySingleFilter,
  applySpecificFilters,
  changeFavoriteState,
  clearFilters,
  clearFiltersElementsList,
  clearFiltersStore,
  externalFiltersMenuCleaning,
  getFilterElementsList,
  getFilterElementsListSuccess,
  incrementFilterPage,
  refreshPaginationData,
  searchValueChange,
  setFiltersOptions,
  updateFiltersData,
} from '../actions/filters.actions';
import { createPhotoReportSuccess } from '../actions/photo-report.action';
import { IFiltersState } from '../models/filters.model';
import { ActiveFiltersSelectionsTypes } from 'src/app/shared/enums';

const initialState: IFiltersState = {
  numOfActiveFilters: ActiveFiltersSelectionsTypes.mono,
  filtersState: {
    page: 0,
    size: 20,
    query: null,
    favs: null,
    filtersList: null,
  },
  filtersMenuState: null,
  filtersElementsList: [],
  filtersElementsListQuery: null,
  isAllFiltersElementsFetched: false,
  isFiltersElementsListLoading: false,
  error: null,
};

export const filtersReducer = createReducer(
  initialState,
  on(
    getFilterElementsList,
    (state): IFiltersState => ({
      ...state,
      isFiltersElementsListLoading: true,
      error: null,
    }),
  ),
  on(
    getFilterElementsListSuccess,
    (state: IFiltersState, action): IFiltersState => ({
      ...state,
      filtersElementsList:
        !state.filtersElementsList.length || action.resetData
          ? action.list
          : [...state.filtersElementsList, ...action.list],
      isAllFiltersElementsFetched:
        action.list.length < (action.size ? action.size : state.filtersState.size),
      isFiltersElementsListLoading: false,
      error: null,
    }),
  ),
  on(
    setFiltersOptions,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        filtersList: { ...action.filtersOption },
      },
      numOfActiveFilters: action.selectionType ?? initialState.numOfActiveFilters,
    }),
  ),
  on(
    incrementFilterPage,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        page: action.initialPage ?? state.filtersState.page + 1,
      },
    }),
  ),
  on(
    searchValueChange,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        page: initialState.filtersState.page,
        query: action.searchValue,
      },
    }),
  ),
  on(
    activeOptionFilterSearchValueChange,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        page: initialState.filtersState.page,
      },
      filtersElementsListQuery: action.searchValue,
    }),
  ),
  on(
    updateFiltersData,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        filtersList: action.updateData,
        favs: action.favs || null,
      },
    }),
  ),
  on(
    applySingleFilter,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        filtersList: { ...action.update },
      },
      filtersMenuState: {
        filtersMenuParams: {
          ...action.update,
        },
      },
    }),
  ),
  on(
    applySpecificFilters,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        filtersList: {
          ...state.filtersState?.filtersList,
          [action.activeFilterOption]: {
            ...state.filtersState?.filtersList?.[action.activeFilterOption],
            params: [action.specificFilterData],
          },
        },
        page: initialState.filtersState.page,
      },
      filtersMenuState: {
        ...state.filtersMenuState,
        filtersMenuParams: {
          ...state.filtersMenuState?.filtersMenuParams,
          [action.activeFilterOption]: {
            ...state.filtersMenuState?.filtersMenuParams?.[action.activeFilterOption],
            params: [action.specificFilterData],
          },
        },
      },
    }),
  ),
  on(
    changeFavoriteState,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        favs: action.favs,
      },
    }),
  ),
  on(
    applyMenuFiltersAction,
    (state): IFiltersState => ({
      ...state,
      filtersMenuState: {
        filtersMenuParams: {
          ...state.filtersState.filtersList,
        },
        favs: state.filtersState.favs,
      },
    }),
  ),
  on(
    clearFilters,
    (state, action): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        page: initialState.filtersState.page,
        filtersList: { ...action.update },
        favs: action.updateFavs,
      },
    }),
  ),
  on(
    externalFiltersMenuCleaning,
    (state, action): IFiltersState => ({
      ...state,
      filtersMenuState: {
        filtersMenuParams: {
          ...action.update,
        },
        favs: action.updateFavs,
      },
    }),
  ),
  on(
    createPhotoReportSuccess,
    refreshPaginationData,
    (state): IFiltersState => ({
      ...state,
      filtersState: {
        ...initialState.filtersState,
        filtersList: state.filtersState.filtersList ?? initialState.filtersState.filtersList,
        query: state.filtersState.query ?? initialState.filtersState.query,
        page: 0,
      },
      filtersMenuState: { ...state.filtersMenuState },
    }),
  ),
  on(
    clearFiltersElementsList,
    (state): IFiltersState => ({
      ...state,
      filtersState: {
        ...state.filtersState,
        page: initialState.filtersState.page,
      },
      isAllFiltersElementsFetched: false,
      filtersElementsList: [],
    }),
  ),
  on(
    clearFiltersStore,
    (state): IFiltersState => ({
      ...state,
      filtersState: {
        ...initialState.filtersState,
      },
      filtersMenuState: initialState.filtersMenuState,
      numOfActiveFilters: initialState.numOfActiveFilters,
      isAllFiltersElementsFetched: initialState.isAllFiltersElementsFetched,
    }),
  ),
);
