import { createReducer, on } from '@ngrx/store';
import { INotificationsState } from '../models/notifications.model';
import {
  clearNotificationsLists,
  clearUnreadNotificationsList,
  deleteNotification,
  getNotificationsList,
  getNotificationsListSuccess,
  getUnreadNotificationsList,
  getUnreadNotificationsListSuccess,
  setNotifications,
} from '../actions/notifications.action';
import { NotificationTypes } from 'src/app/shared/constants';

const initialState: INotificationsState = {
  notifications: [],
  notificationsList: [],
  unreadNotificationsList: [],
  isAllFetched: false,
  isAllUnreadFetched: false,
  isLoading: false,
  isUnreadLoading: false,
  error: null,
};

export const notificationsReducer = createReducer(
  initialState,
  on(
    setNotifications,
    (state: INotificationsState, action): INotificationsState => ({
      ...state,
      notifications: action.reset
        ? action.notifications
        : [...state.notifications, ...action.notifications],
      error: null,
    }),
  ),
  on(
    deleteNotification,
    (state: INotificationsState): INotificationsState => ({
      ...state,
      notifications: state.notifications.filter(
        (n) => n.notificationType === NotificationTypes.trainingNotification,
      ),
      error: null,
    }),
  ),
  on(
    getNotificationsList,
    (state: INotificationsState): INotificationsState => ({
      ...state,
      isAllFetched: false,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    getUnreadNotificationsList,
    (state: INotificationsState): INotificationsState => ({
      ...state,
      isAllUnreadFetched: false,
      isUnreadLoading: true,
      error: null,
    }),
  ),
  on(getNotificationsListSuccess, (state: INotificationsState, action): INotificationsState => {
    const list =
      !state.notificationsList.length || action.resetData
        ? action.list
        : [...state.notificationsList, ...action.list];
    return {
      ...state,
      notificationsList: list,
      isAllFetched: list.length === action.totalElements,
      isLoading: false,
      error: null,
    };
  }),
  on(
    getUnreadNotificationsListSuccess,
    (state: INotificationsState, action): INotificationsState => {
      const list =
        !state.unreadNotificationsList.length || action.resetData
          ? action.list
          : [...state.unreadNotificationsList, ...action.list];
      return {
        ...state,
        unreadNotificationsList: list,
        isAllUnreadFetched: true,
        isUnreadLoading: false,
        error: null,
      };
    },
  ),
  on(
    clearNotificationsLists,
    (state: INotificationsState): INotificationsState => ({
      ...state,
      notificationsList: [],
      isLoading: false,
      isAllFetched: false,
    }),
  ),
  on(
    clearUnreadNotificationsList,
    (state: INotificationsState): INotificationsState => ({
      ...state,
      unreadNotificationsList: [],
      isUnreadLoading: false,
      isAllUnreadFetched: false,
    }),
  ),
);
