import { Injectable } from '@angular/core';
import heic2any from 'heic2any';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { imagePlaceholderUrl } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class ImgService {
  constructor(private _utils: UtilsService) {}

  public imgOnLoad(src: string): Observable<boolean> {
    return new Observable((observer) => {
      let element = document.createElement('img');
      element.src = src;
      element.onload = () => {
        element.remove();
        observer.next(true);
        observer.complete();
      };
    });
  }

  imageFileReader(file: File): Observable<string | ArrayBuffer | undefined | null> {
    return new Observable((observer) => {
      const fileFormat = file.name?.split('.').pop()?.toLowerCase() || '';
      const mimeType = file.type.toLowerCase() || fileFormat;
      const reader = new FileReader();

      if (mimeType.includes('heic') || mimeType.includes('heif')) {
        observer.next(imagePlaceholderUrl);

        heic2any({ blob: file, toType: 'image/jpeg' })
          .then((converted) => {
            reader.readAsDataURL(
              new File([converted as Blob], 'converted.jpeg', {
                type: 'image/jpeg',
              }),
            );
          })
          .catch(async (error) => {
            // Ошибка может возникнуть тогда, когда мы руками конвертируем картинку в heic
            if (error.message.includes('Image is already browser readable')) {
              const hardMimeType = await this._utils.getMimeTypeViaFileReader(file);
              if (hardMimeType !== mimeType) {
                reader.readAsDataURL(
                  new File([file as Blob], file.name, {
                    type: hardMimeType,
                  }),
                );
              }
            }
          });
      } else {
        reader.readAsDataURL(file);
      }

      reader.onloadend = () => {
        observer.next(reader.result);
        observer.complete();
      };
    });
  }
}
