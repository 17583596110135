import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(
    value: string | null,
    isNotificationEntityCreation?: boolean,
    isDateTraining?: boolean,
    withLineBreak?: boolean,
  ): string {
    if (!value) return '';

    const date = moment.utc(value);
    let localeDate = moment(date).local().locale('ru');

    if (isNotificationEntityCreation) {
      return `${localeDate.format('DD MMMM')} ${localeDate.format('HH:mm')}`;
    }

    if (isDateTraining) {
      localeDate = moment(value).local().locale('ru');
      return `${localeDate.format('DD MMMM')} ${localeDate.format('YYYY')}`;
    }

    if (withLineBreak) {
      return `${localeDate.format('DD MMM')}\n${localeDate.format('HH:mm')}`.replace('.', '');
    }

    return `${localeDate.format('DD MMMM')} в ${localeDate.format('HH:mm')}`;
  }
}
