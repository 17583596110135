<div class="file-block" [class.file-block_loading]="isLoading">
  <div [id]="itemId" class="file-block__content">
    <div
      *ngIf="currentPlatform !== 'web'"
      class="file-block__content__information"
      [ngClass]="{ 'file-block__content_removed': isRemoved }"
      (click)="openLinkMob()"
    >
      <img *ngIf="!isLoading" class="file-block__preview-mobile" [src]="getFilePreview(true)" />
      <ng-container *ngTemplateOutlet="fileLoader"></ng-container>
      <div class="file-block__text">
        <app-text
          [text]="fileTitle"
          [textType]="TextType.base"
          [textSize]="TextSizes.fileTitle"
        ></app-text>
        <app-text
          *ngIf="fileSubtitle"
          [text]="fileSubtitle.toString()"
          [textType]="TextType.base"
          [textSize]="TextSizes.fileSubtitle"
        ></app-text>
      </div>
    </div>
    <a
      *ngIf="currentPlatform === 'web'"
      #downloadLink
      class="file-block__content__information"
      [class.file-block__content_removed]="isRemoved || type === 'poll'"
      [href]="filePath"
      target="_blank"
    >
      <img *ngIf="!isLoading" class="file-block__preview-web" [src]="getFilePreview(false)" />
      <ng-container *ngTemplateOutlet="fileLoader"></ng-container>
      <div class="file-block__text">
        <app-text
          [text]="fileTitle"
          [textType]="TextType.base"
          [textSize]="TextSizes.fileTitle"
        ></app-text>
        <app-text
          *ngIf="fileSubtitle"
          [text]="fileSubtitle.toString()"
          [textType]="TextType.base"
          [textSize]="TextSizes.fileSubtitle"
        ></app-text>
      </div>
    </a>
    <img
      *ngIf="!postFileView && !isDisabled"
      class="delete-button"
      src="assets/images/icons-svg/post-creation/delete-icon.svg"
      (click)="handleDeleteItem(itemId)"
    />
    <img
      *ngIf="postFileView"
      class="delete-button"
      [class.btn-disabled]="isDisabled"
      [src]="
        'assets/images/icons-svg/' + (inFavouritesList ? 'favourite.svg' : 'not-favourite.svg')
      "
      (click)="handleChangeFavouritesStatus(itemId)"
    />
  </div>
</div>

<ng-template #fileLoader>
  <div *ngIf="isLoading" class="file-block__loader">
    <app-svg icon="files/default"></app-svg>
    <ion-spinner name="circular"></ion-spinner>
  </div>
</ng-template>
