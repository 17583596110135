import { IAppUpdate } from 'src/app/shared/services/general/general.type';
import { CurrentPlatform } from '../../../shared/enums';
import { HttpErrorResponse } from '@angular/common/http';

export const PLATFORM_DATA_KEY = 'platformData';

export interface IPlatformDataState {
  platform: CurrentPlatform | null;
  error: HttpErrorResponse | null;
  appInfo: AppInfo | null;
  appUpdateData: IAppUpdate | null;
}
export interface AppInfo {
  name: string;
  id?: string;
  build?: string;
  version: string;
}

export enum PlatformActions {
  SetCurrentPlatformData = 'SetCurrentPlatformData',
  SetCurrentAppInfo = 'SetCurrentAppInfo',
  SetAppUpdateData = 'setAppUpdateData',
  GetAppUpdateData = 'getAppUpdateData',
}
