<div class="block-{{ platform }} block">
  <div *ngIf="headerText" class="header">
    <app-text
      [textSize]="TextSizes.h1Header"
      [textType]="TextType.base"
      [text]="headerText"
    ></app-text>
    <div class="header__info-icon">
      <img *ngIf="iconImg" [src]="iconImg" alt="icon" />
      <div class="info-description">
        <ng-container *ngFor="let description of descriptions">
          <app-text
            [text]="description"
            [textSize]="TextSizes.h3Body"
            [textType]="TextType.base"
          ></app-text>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
