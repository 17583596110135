import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUnreadRepliesCounter } from 'src/app/data/store/selectors/users.selectors';
import { TextSizes, TextType } from '../../enums';

@Component({
  selector: 'app-notifications-counter',
  templateUrl: './notifications-counter.component.html',
  styleUrls: ['./notifications-counter.component.scss'],
})
export class NotificationsCounterComponent {
  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public unreadReplies$: Observable<number | undefined> = this._store.select(
    selectUnreadRepliesCounter,
  );

  constructor(private _store: Store) {}
}
