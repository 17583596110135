import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { IDepartment } from 'src/app/shared/services/posts/posts.type';
import { DepartmentActions } from '../models/department.model';
import { IUsersArray } from '../../../shared/services/user/user.type';
import { DepartmentsType } from 'src/app/shared/enums';

export const initCurrentDepartmentData = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrent} ${ReducerStatuses.INIT}`,
  props<{ departmentId: number }>(),
);

export const currentDepartmentDataSuccess = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrent} ${ReducerStatuses.SUCCESS}`,
  props<{ department: IDepartment }>(),
);

export const currentDepartmentDataFailed = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrent} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const initCurrentGroupData = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrentGroup} ${ReducerStatuses.INIT}`,
  props<{ groupId: number }>(),
);

export const currentGroupDataSuccess = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrentGroup} ${ReducerStatuses.SUCCESS}`,
  props<{ group: IDepartment }>(),
);

export const currentGroupDataFailed = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrentGroup} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const initCurrentRegionData = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrentRegion} ${ReducerStatuses.INIT}`,
  props<{ regionId: number }>(),
);

export const currentRegionDataSuccess = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrentRegion} ${ReducerStatuses.SUCCESS}`,
  props<{ region: IDepartment }>(),
);

export const currentRegionDataFailed = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.getCurrentRegion} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getEmployeesList = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getEmployeesList}`,
  props<{
    departmentId: number | null;
    currentEmployeesPageType: string;
  }>(),
);

export const getEmployeesListSuccess = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getEmployeesList} ${ReducerStatuses.SUCCESS}`,
  props<{ employeesList: IUsersArray; resetData: boolean; size: number }>(),
);

export const getEmployeesListFailed = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getEmployeesList} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getDepartmentsList = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getDepartmentsList}`,
);

export const getDepartmentsListSuccess = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getDepartmentsList} ${ReducerStatuses.SUCCESS}`,
  props<{ departmentsList: IDepartment[]; resetData: boolean; size: number }>(),
);

export const getDepartmentsListFailed = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getDepartmentsList} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getAdditionalDepartmentsList = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getAdditionalDepartmentsList}`,
  props<{
    departmentType: DepartmentsType;
    departmentId: number;
  }>(),
);

export const getAdditionalDepartmentsListSuccess = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getAdditionalDepartmentsList} ${ReducerStatuses.SUCCESS}`,
  props<{ departmentsList: IDepartment[]; resetData: boolean; size: number }>(),
);

export const getAdditionalDepartmentsListFailed = createAction(
  `${ReducerSections.EMPLOYEES} ${DepartmentActions.getAdditionalDepartmentsList} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const clearDepartmentsList = createAction(
  `${ReducerSections.DEPARTMENT} ${DepartmentActions.clearDepartmentsList}`,
);
