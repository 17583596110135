import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import './app/shared/utils/userSelect';
import { sentryConfig } from './settings/sentry';

if (environment.enableSentry) {
  Sentry.init(sentryConfig);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
defineCustomElements(window);
