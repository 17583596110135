import { HttpErrorResponse } from '@angular/common/http';
import { IDepartment } from 'src/app/shared/services/posts/posts.type';
export const DEPARTMENT_KEY = 'departmentData';

export interface IDepartmentState {
  department: IDepartment | null;
  currentList: any[];
  isAllListFetched: boolean;
  group: IDepartment | null;
  region: IDepartment | null;
  isLoading: boolean;
  error: HttpErrorResponse | null;
}

export enum DepartmentActions {
  getCurrent = 'getCurrent',
  getCurrentGroup = 'getCurrentGroup',
  getCurrentRegion = 'getCurrentRegion',
  getEmployeesList = 'getEmployeesList',
  getDepartmentsList = 'getDepartmentsList',
  getAdditionalDepartmentsList = 'getAdditionalDepartmentsList',
  clearDepartmentsList = 'clearDepartmentsList',
  clearEmployeesList = 'clearEmployeesList',
}
