import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-container',
  templateUrl: './dropdown-container.component.html',
  styleUrls: ['./dropdown-container.component.scss'],
})
export class DropdownContainerComponent {
  @Output() emitDropdownClose = new EventEmitter<void>();

  close() {
    this.emitDropdownClose.emit();
  }
}
