import * as Sentry from '@sentry/angular-ivy';
import ignoreErrors from './ignoreErrors';

export const sentryConfig = {
  dsn: 'https://990e8480aceb458597bfec341470dff6@glitchtip.befree.ru/13',
  tunnel:
    'https://social.befree.ru/glitchtip-monitoring/api/13/envelope/?sentry_key=990e8480aceb458597bfec341470dff6&sentry_version=7&sentry_client=sentry.javascript.angular-ivy=7.83.0',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  allowUrls: [/^https:\/\/(?:\w+\.)?befree\.ru(?:\/\w*)?/],
  ignoreErrors: ignoreErrors,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
