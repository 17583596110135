import { Component, Input } from '@angular/core';
import { CurrentPlatform, TextSizes, TextType } from '../../enums';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
})
export class BlockComponent {
  @Input() platform: CurrentPlatform | null | undefined;

  @Input() headerText: string | undefined;

  @Input() iconImg: string | undefined;

  @Input() descriptions: string[] = [];

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;
}
