import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { take } from 'rxjs';
import { plugImgSrc } from '../../constants';
import { ImgService } from '../../services/img/img.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  public imgSrc: string | null = null;

  public isLoading: boolean = false;

  readonly plugImgSrc = plugImgSrc;

  @Input() img: string | null | undefined = null;

  @Input() imgStyle: object | undefined;

  @Input() rotateAngle: number = 0;

  constructor(
    public imgService: ImgService,
    private readonly _changeDetection: ChangeDetectorRef,
  ) {}

  public ngOnChanges(): void {
    if (!this.isLoading && this.img) {
      this.loadImg();
    }
    if (!this.img) {
      this.imgSrc = plugImgSrc;
    }
  }

  public loadImg() {
    this.isLoading = true;
    this.imgService
      .imgOnLoad(this.img!)
      .pipe(take(1))
      .subscribe(() => {
        this.isLoading = false;
        this.imgSrc = this.img!;
        this._changeDetection.detectChanges();
      });
  }
}
