import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { selectCurrentPlatform } from '../../../data/store/selectors/platform.selectors';
import { CurrentPlatform } from '../../enums';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input() pdfUrl: string = '';

  @Input() pdfName: string = '';

  @Input() goBack: (() => void) | undefined;

  public currentPlatform$: Observable<CurrentPlatform | null> | undefined =
    this._store.select(selectCurrentPlatform);

  public page = 0;

  public isLoad: boolean = true;

  public zoom = 1;

  constructor(private _store: Store) {}

  public setIsLoad(value: boolean) {
    this.isLoad = value;
  }

  public zoomIn() {
    this.zoom = this.zoom + 0.1;
  }

  public zoomOut() {
    if (this.zoom > 1) {
      this.zoom = this.zoom - 0.1;
    }
  }
}
