import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { clearUnreadNotificationsList } from 'src/app/data/store/actions/notifications.action';
import { befreeUrlValues } from 'src/app/shared/constants';
import { TextSizes, TextType } from 'src/app/shared/enums';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent implements OnDestroy {
  @Output() emitOpenNotificationPage = new EventEmitter<void>();

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  constructor(private _router: Router, private _store: Store) {}

  public goToNotificationPage() {
    this._router.navigate([
      befreeUrlValues.dashboard,
      befreeUrlValues.notifications,
      befreeUrlValues.all,
    ]);
    this.emitOpenNotificationPage.emit();
  }

  ngOnDestroy(): void {
    this._store.dispatch(clearUnreadNotificationsList());
  }
}
