import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, EMPTY, map, of, switchMap, tap } from 'rxjs';
import {
  changeEditMode,
  createPhotoReportFailed,
  createPhotoReportInit,
  createPhotoReportSuccess,
  deletePhotoReport,
  deletePhotoReportFailed,
  deletePhotoReportSuccess,
  dislikeImageAction,
  dislikeImageActionFailed,
  dislikePhotoReport,
  dislikePhotoReportFailed,
  dislikePhotoReportSuccess,
  getCurrentPhotoReportData,
  getCurrentPhotoReportDataFailed,
  getCurrentPhotoReportDataSuccess,
  getPhotoReportCategoriesFailed,
  getPhotoReportCategoriesInit,
  getPhotoReportCategoriesSuccess,
  getPhotoReportsList,
  getPhotoReportsListFailed,
  getPhotoReportsListSuccess,
  likeImageAction,
  likeImageActionFailed,
  likePhotoReport,
  likePhotoReportFailed,
  likePhotoReportSuccess,
  updateCurrentPhotoReportData,
  updateCurrentPhotoReportDataFailed,
  updateCurrentPhotoReportDataSuccess,
  updateLikedList,
  updatePhotoReportData,
  updatePhotoReportDataFailed,
} from '../actions/photo-report.action';
import { PhotoReportsService } from '../../../shared/services/photo-reports/photo-reports.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { befreeUrlValues, successNotificationMessages } from '../../../shared/constants';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectPhotoReportImages } from '../selectors/photo-report.selectors';
import { changeUploadingFilesInEditMode } from '../actions/uploading-files.actions';
import { FiltersOptions, ViewType } from '../../../shared/enums';
import { selectFiltersMenuState, selectFiltersState } from '../selectors/filters.selectors';
import { IFilter } from 'src/app/shared/interfaces';
import moment from 'moment';

@Injectable()
export class PhotoReportEffects {
  constructor(
    private _actions$: Actions,
    private _photoReportsService: PhotoReportsService,
    private _utils: UtilsService,
    private _router: Router,
    private _store: Store,
    private _location: Location,
  ) {}

  public photoReportData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentPhotoReportData),
      switchMap((action) => {
        if (!action.photoReportId) {
          return EMPTY;
        }
        return combineLatest([
          this._photoReportsService.getPhotoReportById(action.photoReportId),
          this._photoReportsService.getPhotoReportCategories(),
        ]).pipe(
          map(([photoReport, categories]) => {
            const photoReportCategories = this._utils.getCategoriesList(photoReport, categories);
            const photoReportCategoriesNames = this._utils.getPhotoReportCategoriesNames(
              photoReportCategories,
              categories,
            );
            const photoReportImagesId = this._utils.getPhotoReportImagesId(photoReport);
            return getCurrentPhotoReportDataSuccess({
              photoReport: photoReport,
              categories: categories.photoReportCategory,
              photoReportImages: photoReport.imagesData,
              photoReportCategories: photoReportCategories,
              photoReportCategoriesNames: photoReportCategoriesNames,
              photoReportImagesId: photoReportImagesId,
            });
          }),
          catchError((error) => {
            return of(getCurrentPhotoReportDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getPhotoReportsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getPhotoReportsList),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectFiltersMenuState),
      ]),
      switchMap(([, filtersState, filtersMenu]) => {
        const { regionId, cityId, shopId, favs } = this._utils.getFiltersFromUrl();
        let filters: IFilter = {
          page: filtersState.page,
          size: filtersState.size,
          search: filtersState.query,
          regionId: regionId?.value || null,
          cityId: cityId?.value || null,
          shopId: shopId?.value || null,
        };

        if (filtersMenu.filters?.filtersMenuParams && (!filtersMenu.filters.favs ?? !favs)) {
          filters = {
            ...filters,
            regionId: this._utils.setRequestFilterParams({
              filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
              filterOption: FiltersOptions.regions,
            }),
            cityId: this._utils.setRequestFilterParams({
              filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
              filterOption: FiltersOptions.cities,
            }),
            shopId: this._utils.setRequestFilterParams({
              filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
              filterOption: FiltersOptions.shops,
            }),
          };
        }

        const getUrlEntity = (itemName: keyof IFilter, type: FiltersOptions) =>
          this._utils.getUrlEntity(filters, itemName, type, filtersMenu.filters?.filtersMenuParams);

        const filterToUrl = {
          regionId: getUrlEntity('regionId', FiltersOptions.regions),
          cityId: getUrlEntity('cityId', FiltersOptions.cities),
          shopId: getUrlEntity('shopId', FiltersOptions.shops),
        };

        this._utils.syncFiltersRouteQuery(filterToUrl, filtersMenu.filters?.favs ?? favs);

        return this._photoReportsService
          .getPhotoReports(filters, filtersMenu.filters?.favs ?? favs)
          .pipe(
            map((photoReportsList) => {
              return getPhotoReportsListSuccess({
                photoReportsList: photoReportsList.elements,
                resetData: filters.page === 0,
                size: filtersState.size,
              });
            }),
            catchError((error) => of(getPhotoReportsListFailed({ error }))),
          );
      }),
    );
  });

  public updatePhotoReportData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updatePhotoReportData),
      switchMap((action) =>
        this._photoReportsService.updatePhotoReport(action.photoReportData).pipe(
          map(() => {
            return getCurrentPhotoReportData({ photoReportId: action.photoReportData?.id });
          }),
          catchError((error) => of(updatePhotoReportDataFailed({ error }))),
        ),
      ),
    );
  });

  public likePhotoReport$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(likePhotoReport),
      switchMap((action) =>
        this._photoReportsService.likePhotoReport(action.photoReportId).pipe(
          map(() => {
            return likePhotoReportSuccess();
          }),
          catchError((error) => of(likePhotoReportFailed({ error }))),
        ),
      ),
    );
  });

  public updateCurrentPhotoReportData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updateCurrentPhotoReportData),
      switchMap((action) => {
        return this._photoReportsService.getPhotoReportById(action.photoReportId).pipe(
          map((photoReport) => {
            return updateCurrentPhotoReportDataSuccess({ photoReportToBeUpdated: photoReport });
          }),
          catchError((error) => of(updateCurrentPhotoReportDataFailed({ error }))),
        );
      }),
    );
  });

  public dislikePhotoReport$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(dislikePhotoReport),
      switchMap((action) =>
        this._photoReportsService.dislikePhotoReport(action.photoReportId).pipe(
          map(() => {
            return dislikePhotoReportSuccess();
          }),
          catchError((error) => of(dislikePhotoReportFailed({ error }))),
        ),
      ),
    );
  });

  public deletePhotoReport$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(deletePhotoReport),
      switchMap((action) =>
        this._photoReportsService.deletePhotoReport(action.photoReportId).pipe(
          map(() => {
            this._utils.openNotification(successNotificationMessages.deletePhotoReport);
            return deletePhotoReportSuccess();
          }),
          catchError((error) => of(deletePhotoReportFailed({ error }))),
        ),
      ),
    );
  });

  public deletePhotoReportSuccess$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(deletePhotoReportSuccess),
        tap(() => this._location.back()),
      );
    },
    { dispatch: false },
  );

  public getPhotoReportCategories$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getPhotoReportCategoriesInit),
      switchMap(() =>
        this._photoReportsService.getPhotoReportCategories().pipe(
          map((categories) => {
            return getPhotoReportCategoriesSuccess({ categories: categories.photoReportCategory });
          }),
          catchError((error) => of(getPhotoReportCategoriesFailed({ error }))),
        ),
      ),
    );
  });

  public createPhotoReport$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(createPhotoReportInit),
      switchMap((action) =>
        this._photoReportsService
          .createPhotoReport({
            ...action.createPhotoReportData,
            date: moment().format('YYYY-MM-DD'),
          })
          .pipe(
            map((photoReport) => {
              return createPhotoReportSuccess({
                photoReportId: photoReport.id,
                currentMode: action.currentMode,
              });
            }),
            catchError((error) => of(createPhotoReportFailed({ error }))),
          ),
      ),
    );
  });

  public createPhotoReportSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(createPhotoReportSuccess),
      switchMap((action) => {
        this._utils.dismissModal();
        if (action.currentMode === ViewType.editPhotoReport) {
          this._utils.openNotification(successNotificationMessages.editPhotoReport);
          return of(getCurrentPhotoReportData({ photoReportId: action.photoReportId }));
        } else {
          this._utils.openNotification(successNotificationMessages.createPhotoReport);
          this._router.navigateByUrl(
            `${befreeUrlValues.dashboard}/${befreeUrlValues.photoreports}/${befreeUrlValues.page}/${action.photoReportId}`,
          );
          return of();
        }
      }),
    );
  });

  public changeEditMode$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(changeEditMode),
      concatLatestFrom(() => this._store.select(selectPhotoReportImages)),
      switchMap(([action, images]) => {
        if (!images || action.currentMode !== ViewType.editPhotoReport) {
          return EMPTY;
        }
        return of(changeUploadingFilesInEditMode({ photoReportData: images }));
      }),
    );
  });

  public likeImage$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(likeImageAction),
      switchMap((action) => {
        return this._photoReportsService.likeImage(action.fileId).pipe(
          map(() => {
            return updateLikedList({ fileId: action.fileId });
          }),
          catchError((error) => of(likeImageActionFailed({ error }))),
        );
      }),
    );
  });

  public dislikeImage$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(dislikeImageAction),
      switchMap((action) => {
        return this._photoReportsService.dislikeImage(action.fileId).pipe(
          map(() => {
            return updateLikedList({ fileId: action.fileId });
          }),
          catchError((error) => of(dislikeImageActionFailed({ error }))),
        );
      }),
    );
  });
}
