import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { befreeUrlValues } from 'src/app/shared/constants';
import { TextSizes, TextType } from 'src/app/shared/enums';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-post-header',
  templateUrl: './post-header.component.html',
  styleUrls: ['./post-header.component.scss'],
})
export class PostHeaderComponent {
  @Input() id?: number;

  @Input() avatar?: string;

  @Input() fullName?: string;

  @Input() position?: string;

  @Input() department?: string;

  @Input() city?: string;

  @Input() date?: string;

  readonly TextType = TextType;

  readonly TextSizes = TextSizes;

  public isActive = false;

  constructor(public utils: UtilsService, private _cdr: ChangeDetectorRef) {}

  get link() {
    return ['/', befreeUrlValues.dashboard, befreeUrlValues.employees, this.id];
  }

  public onNameClick() {
    this.isActive = true;
    setTimeout(() => {
      this.isActive = false;
      this._cdr.detectChanges();
    }, 200);
  }
}
