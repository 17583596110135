<ion-header
  [ngClass]="{
    'ion-no-border': 'true',
    'ion-no-padding': isHeaderTranslucent
  }"
>
  <div
    *ngIf="isHeaderTranslucent"
    class="back-block_translucent"
    [ngClass]="{
      'back-block_translucent_android': (currentPlatform$ | async) === CurrentPlatform.android
    }"
  >
    <img [src]="arrowRightLight" class="back" (click)="back()" *ngIf="isNeedBack" />
  </div>
  <div
    [ngClass]="{
      'page-container__header': 'true'
    }"
    *ngIf="!isHeaderTranslucent"
  >
    <div class="page-container__header-title">
      <img [src]="arrowRight" class="back" (click)="back()" *ngIf="isNeedBack" />
      <app-text
        [textSize]="TextSizes.h1Header"
        [textType]="TextType.base"
        [text]="headerName"
      ></app-text>
    </div>
    <div class="page-container__header-version" *ngIf="isShowVersion">
      <app-text
        *ngIf="currentAppInfo$ | async as appInfo"
        [textSize]="TextSizes.mobBody"
        [textType]="TextType.base"
        [text]="'ver ' + appInfo.version"
        class="version"
      ></app-text>
    </div>
    <span class="header-content-mob">
      <ng-content select="[header-content-mob]"></ng-content>
    </span>
  </div>
</ion-header>

<div class="tabs-container" *ngIf="tabsLinks.length && !isModalView">
  <ion-grid>
    <ion-row>
      <ion-col class="tabs-container__col-menu" size="2.5">
        <div class="menu-wrapper"></div
      ></ion-col>
      <ion-col class="tabs-container__col-content" *ngIf="currentPlatform$ | async as platform">
        <div class="tabs">
          <a *ngFor="let link of tabsLinks" [routerLink]="link.url" [routerLinkActive]="'active'">
            <app-text
              [textSize]="TextSizes.h1Header"
              [textType]="TextType.base"
              [text]="
                platform !== CurrentPlatform.web && link.nameMobile ? link.nameMobile : link.name
              "
            ></app-text
          ></a>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ng-content></ng-content>

<ng-container *ngIf="currentPlatform$ | async as platform">
  <ng-container *ngIf="isDataLoaded$ | async">
    <ng-container *ngIf="platform !== CurrentPlatform.web">
      <ion-footer *ngIf="needFooter" class="ion-no-border">
        <ion-toolbar>
          <ng-content select="[footer-content-mob]"></ng-content>
        </ion-toolbar>
      </ion-footer>
    </ng-container>
  </ng-container>
</ng-container>
