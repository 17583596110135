import { Component, Input } from '@angular/core';
import {
  CurrentPlatform,
  NotificationPageType,
  NotificationsTypes,
  PollTypes,
  RepliesResultTypes,
  TextSizes,
  TextType,
} from '../../enums';
import { Router } from '@angular/router';
import { befreeUrlValues } from '../../constants';
import { UtilsService } from '../../services/utils.service';
import { INotification } from '../../services/notifications/notifications.type';
import { selectCurrentUser } from 'src/app/data/store/selectors/users.selectors';
import { Store } from '@ngrx/store';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent {
  @Input() notify: INotification | null = null;

  @Input() notificationCurrentPageType: NotificationPageType = NotificationPageType.all;

  @Input() notificationType: NotificationPageType = NotificationPageType.all;

  @Input() isModalMode: boolean = false;

  public readonly Platform = CurrentPlatform;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly notificationTypes = NotificationsTypes;

  public readonly notificationPageTypeEnum = NotificationPageType;

  public readonly repliesResultTypes = RepliesResultTypes;

  public readonly splitReplyFor = /,(.*)/s;

  public currentUser$ = this._store.select(selectCurrentUser);

  public platform$ = this._store.select(selectCurrentPlatform);

  constructor(private _router: Router, private _utils: UtilsService, private _store: Store) {}

  public goToUser(event: MouseEvent) {
    event.stopPropagation();
    this._router.navigate([
      befreeUrlValues.dashboard,
      befreeUrlValues.employees,
      this.notify!.author.id,
    ]);
  }

  public goToEntity() {
    if (!this.notify || this.isModalMode) return;

    if (
      this.notify.entity.type === NotificationsTypes.post ||
      this.notify.entity.type === NotificationsTypes.postImg
    ) {
      this._router.navigate([
        `${befreeUrlValues.dashboard}/${befreeUrlValues.posts}/${this.notify.entity.id}`,
      ]);
      return;
    }

    if (this.notify.entity.type === NotificationsTypes.photoReport) {
      this._router.navigate([
        `${befreeUrlValues.dashboard}/${befreeUrlValues.photoreports}/${befreeUrlValues.page}/${this.notify.entity.id}`,
      ]);
      return;
    }

    if (this.notify.entity.type === NotificationsTypes.photoReportImg) {
      this._router.navigate(
        [
          befreeUrlValues.dashboard,
          befreeUrlValues.photoreports,
          befreeUrlValues.page,
          this.notify.entity.id,
        ],
        {
          state: {
            path: this.notify.entity.imagePreview,
            category: this.notify.entity.imageCategory,
          },
        },
      );
    }
  }

  get showCounter() {
    if (!this.notify) {
      return false;
    }

    return (
      this.notify.entity.imageCount > 1 &&
      (this.notify.entity.type === this.notificationTypes.post ||
        this.notify.entity.type === this.notificationTypes.photoReport)
    );
  }

  get showPollIcon() {
    return (
      this.notify &&
      !!this.notify.entity.postPollName &&
      !this.notify.entity.imageCount &&
      this.notify.type === this.repliesResultTypes.like
    );
  }

  get showFileIcon() {
    return (
      this.notify &&
      !this.notify.entity.msg &&
      !this.notify.entity.postPollName &&
      !!this.notify.entity.postFileName &&
      !this.notify.entity.postFileMimeType?.includes('image') &&
      this.notify.type === this.repliesResultTypes.like
    );
  }

  get showImageIcon() {
    if (!this.notify) {
      return false;
    }

    const hasImage = this.notify.entity.imageCount > 0;

    if (this.notify.type === this.repliesResultTypes.like) {
      return hasImage;
    } else {
      return hasImage && !this.notify.entity.msg && !this.notify.entity.postPollName;
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  get notificationText() {
    if (!this.notify || this.notify.entity.replyForCommentText) {
      return '';
    }

    // Лайки постов
    if (
      this.notify.entity.type === this.notificationTypes.post &&
      this.notify.type === this.repliesResultTypes.like
    ) {
      if (this.showFileIcon && !this.notify.entity.msg) {
        return this.notify.entity.postFileName;
      }

      if (this.notify.entity.postPollName) {
        const pollType =
          this.notify.entity.postPollType === PollTypes.public ? 'Публичный' : 'Анонимный';

        return `${pollType} опрос: «${this.notify.entity.postPollName}»`;
      }

      return this.notify.entity.msg;
    }

    // Лайки фотографий фотоотчетов
    if (
      this.notify.entity.type === this.notificationTypes.photoReportImg &&
      this.notify.type === this.repliesResultTypes.like
    ) {
      return `Фотоотчет «${this.notify.entity.shopName}, ${this.notify.entity.shopCityName}»`;
    }

    // Лайки фотоотчетов
    if (
      this.notify.entity.type === this.notificationTypes.photoReport &&
      this.notify.type === this.repliesResultTypes.like
    ) {
      return `Фотоотчет «${this.notify.entity.shopName}, ${this.notify.entity.shopCityName}»`;
    }

    // Комменты
    if (this.notify.type === this.repliesResultTypes.comment) {
      return this.notify.entity.commentText;
    }

    return '';
  }

  get notificationReplyText() {
    if (
      !this.notify ||
      !this.notify.entity.replyForCommentText ||
      !this.notify.entity.commentText
    ) {
      return [];
    }

    if (this.notify.type === this.repliesResultTypes.comment) {
      const [name, ...text] = this.notify.entity.commentText.split(', ');

      return [name, `, ${text.join(', ')}`];
    }

    return [];
  }
}
