import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFilter, ILoginAccount } from '../../interfaces';
import { BaseHttpService } from '../base-http/base-http.service';
import { IImage } from '../posts/posts.type';
import {
  ICounters,
  ILoginData,
  IUser,
  IUsersArray,
  IFavouritesFile,
  IAvatar,
  ICropParams,
  IDevice,
} from './user.type';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly _adminHref = '/admin';

  private readonly _usersUrl = `/users`;

  private readonly _loginUrl = '/login';

  private readonly _logoutUrl = '/logout';

  private readonly _currentUserUrl = `${this._usersUrl}/current`;

  private readonly _userPagedUrl = `${this._usersUrl}/paged`;

  private readonly _counters = `${this._usersUrl}/state`;

  private readonly _favshop = `${this._usersUrl}/favshop`;

  private readonly _unfavshop = `${this._usersUrl}/unfavshop`;

  private readonly _favcontent = '/favcontent';

  private readonly _trainers = '/trainers';

  private readonly _avatar = '/avatar';

  private readonly _block = '/block';

  constructor(private _http: BaseHttpService) {}

  public login(accountData: ILoginAccount) {
    return this._http.post<ILoginData>(`${this._usersUrl}${this._loginUrl}`, accountData);
  }

  public getCurrent(): Observable<IUser> {
    return this._http.get<IUser>(`${this._currentUserUrl}`);
  }

  public getUserById(userId: number): Observable<IUser> {
    return this._http.get<IUser>(`${this._usersUrl}/${userId}`);
  }

  public getUserByIdAsAdmin(userId: number): Observable<IUser> {
    return this._http.get<IUser>(`${this._adminHref + this._usersUrl}/${userId}`);
  }

  public getUsersPaged(filters?: IFilter): Observable<IUsersArray> {
    return this._http.get<IUsersArray>(`${this._userPagedUrl}`, filters);
  }

  public getUsersPagedAsAdmin(filters?: IFilter): Observable<IUsersArray> {
    return this._http.get<IUsersArray>(`${this._adminHref + this._userPagedUrl}`, filters);
  }

  public getCounters(): Observable<ICounters> {
    return this._http.get<ICounters>(`${this._counters}`);
  }

  public logout(): Observable<any> {
    return this._http.get<any>(`${this._usersUrl}${this._logoutUrl}`);
  }

  public getEmployees(filters?: IFilter): Observable<IUsersArray> {
    return this._http.get<IUsersArray>(`${this._userPagedUrl}`, filters);
  }

  public addShopToFavorite(shopId: number) {
    return this._http.post<null>(`${this._favshop}/${shopId}`);
  }

  public removeShopFromFavorite(shopId: number) {
    return this._http.post<null>(`${this._unfavshop}/${shopId}`);
  }

  public getFavoritesData(filters: IFilter): Observable<IFavouritesFile[]> {
    return this._http.get<IFavouritesFile[]>(`${this._usersUrl}${this._favcontent}`, filters);
  }

  public addToFavorites(id: number) {
    return this._http.post(`${this._usersUrl}${this._favcontent}/${id}`, { view: 'POST' });
  }

  public removeFromFavorites(id: number) {
    return this._http.delete(`${this._usersUrl}${this._favcontent}/${id}`);
  }

  public getTrainersList(filters: IFilter): Observable<IUsersArray> {
    return this._http.get<IUsersArray>(`${this._usersUrl}${this._trainers}`, filters);
  }

  public addAvatar(
    id: number,
    payload: { cropParams: ICropParams; originalImage: Pick<IImage, 'id'> },
  ): Observable<IAvatar> {
    return this._http.put(`${this._usersUrl}/${id}${this._avatar}`, payload);
  }

  public addDevice(payload: IDevice): Observable<Omit<IDevice, 'type'>> {
    return this._http.post(`${this._currentUserUrl}/devices`, payload);
  }

  public deleteDevice(token: string) {
    return this._http.delete(`${this._currentUserUrl}/devices/${token}`);
  }

  public blockUser(id: number) {
    return this._http.post(`${this._usersUrl}/${id}/${this._block}`);
  }
}
