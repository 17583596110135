<ng-container *ngIf="currentPlatform$ | async as currentPlatform">
  <div
    class="pdf-view-container"
    [ngClass]="{ 'pdf-view-container__ios': currentPlatform === 'ios' }"
  >
    <div class="pdf-view-container__header">
      <img
        (click)="goBack?.()"
        class="pdf-view-container__header__button"
        [src]="'assets/images/icons-svg/back-button.svg'"
        alt="back"
      />
      <div class="pdf-view-container__header__pdf-name" [textContent]="pdfName"></div>
      <a [href]="pdfUrl">
        <img (click)="goBack?.()" [src]="'assets/images/icons-svg/download.svg'" alt="back" />
      </a>
    </div>
    <div *ngIf="isLoad" class="pdf-view-container__loader-block">
      <ion-spinner></ion-spinner>
    </div>
    <pdf-viewer
      (load)="setIsLoad(true)"
      (after-load-complete)="setIsLoad(false)"
      [src]="pdfUrl"
      [(page)]="page"
      [fit-to-page]="true"
      [original-size]="false"
      [stick-to-page]="true"
      [show-all]="true"
      [zoom]="zoom"
    ></pdf-viewer>
    <div class="pdf-view-container__zoom_block">
      <img
        [src]="'assets/images/icons-svg/zoom-plus.svg'"
        class="pdf-view-container__zoom"
        (click)="zoomIn()"
        alt="plus"
      />
      <img
        [src]="'assets/images/icons-svg/zoom-minus.svg'"
        class="pdf-view-container__zoom"
        (click)="zoomOut()"
        alt="minus"
      />
    </div>
  </div>
</ng-container>
