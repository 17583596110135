import { Pipe, PipeTransform } from '@angular/core';
import { IUserTrainings } from '../../services/user/user.type';
import { TrainingLevels, TrainingStatusColors, TrainingStatuses } from '../../enums';
import * as moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const dateNow = moment().format(dateFormat);

@Pipe({
  name: 'trainingStatus',
})
export class TrainingStatusPipe implements PipeTransform {
  transform(userTraining?: IUserTrainings): { status: string; color: string } {
    const endTrainingDate = moment(userTraining?.endTraining).format(dateFormat);
    const isExpired = moment(dateNow).isAfter(endTrainingDate);
    if (
      !userTraining ||
      (userTraining?.id === null &&
        userTraining?.isDraft &&
        (!isExpired || userTraining.trainingLevel === TrainingLevels.first))
    ) {
      return { status: TrainingStatuses.not_filled, color: TrainingStatusColors.not_filled };
    }
    if (
      userTraining?.id &&
      userTraining?.isDraft &&
      (!isExpired || userTraining.trainingLevel === TrainingLevels.first)
    ) {
      return { status: TrainingStatuses.draft, color: TrainingStatusColors.draft };
    }
    if (isExpired && userTraining?.isDraft && userTraining.trainingLevel !== TrainingLevels.first) {
      return { status: TrainingStatuses.expired, color: TrainingStatusColors.expired };
    }
    return { status: TrainingStatuses.end, color: TrainingStatusColors.end };
  }
}
