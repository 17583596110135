import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { GalleryActions, IGalleryState } from '../models/gallery.model';
import { IImage } from 'src/app/shared/services/posts/posts.type';

export const getGalleryData = createAction(
  `${ReducerSections.GALLERY} ${GalleryActions.getGallery} ${ReducerStatuses.INIT}`,
);

export const setGalleryData = createAction(
  `${ReducerSections.GALLERY} ${GalleryActions.setGallery} ${ReducerStatuses.INIT}`,
  props<{ gallery: IGalleryState }>(),
);

export const setGalleryImages = createAction(
  `${ReducerSections.GALLERY} ${GalleryActions.setGalleryImages} ${ReducerStatuses.INIT}`,
  props<{ images: IImage[] }>(),
);

export const resetGalleryData = createAction(
  `${ReducerSections.GALLERY} ${GalleryActions.resetGallery} ${ReducerStatuses.INIT}`,
);

export const changeGalleryDataImageIndex = createAction(
  `${ReducerSections.GALLERY} ${GalleryActions.changeGalleryDataImageIndex} ${ReducerStatuses.INIT}`,
  props<{ index: number }>(),
);
