import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getInnerHtml',
})
export class GetInnerHtmlPipe implements PipeTransform {
  transform(text?: string): string | undefined {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.replace(urlRegex, '<a href="$1" target="_blank" class="link">$1</a>');
  }
}
