import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import {
  IComment,
  IPoll,
  IPollOptions,
  IPost,
  IPostData,
} from '../../../shared/services/posts/posts.type';
import { PostActions } from '../models/posts.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../../../shared/services/user/user.type';

export const initPostsData = createAction(`${ReducerSections.POSTS} ${ReducerStatuses.INIT}`);

export const getPostData = createAction(
  `${ReducerSections.POSTS} ${PostActions.PostData}`,
  props<{ startsFrom?: number }>(),
);

export const getPostDataSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.PostData} ${ReducerStatuses.SUCCESS}`,
  props<{ posts: IPost[]; resetData: boolean; size: number }>(),
);

export const getPostDataFailed = createAction(
  `${ReducerSections.POSTS}  ${PostActions.PostData} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const createNewPost = createAction(
  `${ReducerSections.POSTS} ${PostActions.CreateNewPost}`,
  props<{ post: Partial<IPostData>; isPinned: boolean }>(),
);

export const createNewPostSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.CreateNewPost} ${ReducerStatuses.SUCCESS}`,
  props<{ post: IPost; isPinned: boolean }>(),
);

export const createNewPostFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.CreateNewPost} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const editPost = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditPost}`,
  props<{ post: Partial<IPostData>; isPinned?: boolean }>(),
);

export const editPostSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditPost} ${ReducerStatuses.SUCCESS}`,
  props<{ post: IPost; isPinned?: boolean }>(),
);

export const editPostFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditPost} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const openPostModal = createAction(
  `${ReducerSections.POSTS} ${PostActions.OpenPostModal}`,
  props<{ isEditMode?: boolean }>(),
);

export const closePostModal = createAction(
  `${ReducerSections.POSTS} ${PostActions.ClosePostModal}`,
);

export const voteInThePollAction = createAction(
  `${ReducerSections.VOTE} ${PostActions.VoteInThePoll}`,
  props<{
    pollsOptions: IPollOptions[];
    pollsId: number;
    postId: number;
  }>(),
);

export const voteInThePollSuccess = createAction(
  `${ReducerSections.VOTE} ${PostActions.VoteInThePoll} ${ReducerStatuses.SUCCESS}`,
  props<{ postTobeUpdated: IPost }>(),
);

export const voteInThePollFailed = createAction(
  `${ReducerSections.VOTE} ${PostActions.VoteInThePoll} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const updatePostData = createAction(
  `${ReducerSections.VOTE} ${PostActions.UpdateDataAfterTheVoting}`,
  props<{ postId: number; dataTypeToBeUpdated?: string }>(),
);

export const updatePostDataSuccess = createAction(
  `${ReducerSections.VOTE} ${PostActions.UpdateDataAfterTheVoting} ${ReducerStatuses.SUCCESS}`,
  props<{ postToBeUpdated: IPost; dataTypeToBeUpdated?: string }>(),
);

export const updatePostDataFailed = createAction(
  `${ReducerSections.VOTE} ${PostActions.UpdateDataAfterTheVoting} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const voteParticipantsAction = createAction(
  `${ReducerSections.VOTE} ${PostActions.VoteParticipants}`,
  props<{ pollsId: number; pollsOptionId: number }>(),
);

export const voteParticipantsSuccess = createAction(
  `${ReducerSections.VOTE} ${ReducerStatuses.SUCCESS}`,
  props<{ voteParticipants: IUser[] | null }>(),
);

export const voteParticipantsFailed = createAction(
  `${ReducerSections.VOTE} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const createPollData = createAction(
  `${ReducerSections.POSTS} ${PostActions.CreatePoll}`,
  props<{ poll: IPoll | null }>(),
);

export const getCurrentPostData = createAction(
  `${ReducerSections.CURRENT_POST} ${PostActions.GetCurrentPost}`,
  props<{ postId: number; isEditMode?: boolean }>(),
);

export const getCurrentPostDataSuccess = createAction(
  `${ReducerSections.CURRENT_POST} ${ReducerStatuses.SUCCESS}`,
  props<{ post: IPost; isEditMode?: boolean }>(),
);

export const getCurrentPostDataFailed = createAction(
  `${ReducerSections.CURRENT_POST} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const createReplyToCommentData = createAction(
  `${ReducerSections.PINNED_POSTS} ${PostActions.CreateReply}`,
  props<{ comment: IComment | null }>(),
);

export const likePost = createAction(
  `${ReducerSections.LIKE} ${PostActions.LikePost}`,
  props<{ postId: number }>(),
);

export const likePostSuccess = createAction(
  `${ReducerSections.LIKE} ${PostActions.LikePost} ${ReducerStatuses.SUCCESS}`,
  props<{ postId: number }>(),
);

export const likePostFailed = createAction(
  `${ReducerSections.LIKE} ${PostActions.LikePost} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const dislikePost = createAction(
  `${ReducerSections.LIKE} ${PostActions.DislikePost}`,
  props<{ postId: number }>(),
);

export const dislikePostSuccess = createAction(
  `${ReducerSections.LIKE} ${PostActions.DislikePost} ${ReducerStatuses.SUCCESS}`,
  props<{ postId: number }>(),
);

export const dislikePostFailed = createAction(
  `${ReducerSections.LIKE} ${PostActions.DislikePost} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const pinPostByPostId = createAction(
  `${ReducerSections.POSTS} ${PostActions.PinPostByPostId}`,
  props<{
    postId: number;
    isPostView: boolean;
    postIsPinned: boolean;
    removeNotification?: boolean;
    postCreationMode: boolean;
    postEditMode?: boolean;
    noScrolling?: boolean;
  }>(),
);

export const pinPostByPostIdFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.PinPostByPostId} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const deletePostByPostId = createAction(
  `${ReducerSections.POSTS} ${PostActions.DeletePostByPostId}`,
  props<{ postId: number; isPostView: boolean }>(),
);

export const deletePostByPostIdSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.DeletePostByPostId} ${ReducerStatuses.SUCCESS}`,
  props<{ deletePostId: number | null }>(),
);

export const deletePostByPostIdFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.DeletePostByPostId} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const addFileToFavoritesList = createAction(
  `${ReducerSections.POSTS} ${PostActions.AddToFavouritesList}`,
  props<{ fileId: number; postId?: number }>(),
);

export const addFileToFavoritesListSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.AddToFavouritesList} ${ReducerStatuses.SUCCESS}`,
  props<{ postId: number }>(),
);

export const addToFavoritesListFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.AddToFavouritesList} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const removeFileFromFavoritesList = createAction(
  `${ReducerSections.POSTS} ${PostActions.RemoveFromFavouritesList}`,
  props<{ fileId: number; postId?: number }>(),
);

export const removeFileFromFavoritesListSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.RemoveFromFavouritesList} ${ReducerStatuses.SUCCESS}`,
  props<{ postId: number }>(),
);

export const removeFromFavoritesListFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.RemoveFromFavouritesList} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const favoriteListToBeUpdated = createAction(
  `${ReducerSections.POSTS} ${PostActions.UpdateFavouritesList}`,
  props<{ fileId: number }>(),
);

export const clearFavoriteListToBeUpdated = createAction(
  `${ReducerSections.POSTS} ${PostActions.ClearFavoriteListToBeUpdated}`,
);

export const changeEditCommentMode = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditCommentMode}`,
  props<{ isEditMode: boolean; currentCommentData: Partial<IComment> }>(),
);

export const editComment = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditComment}`,
  props<{ commentId: number; commentData: Partial<IComment> }>(),
);

export const editCommentSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditComment} ${ReducerStatuses.SUCCESS}`,
  props<{ commentId: number; commentData: IComment }>(),
);

export const editCommentFailed = createAction(
  `${ReducerSections.POSTS} ${PostActions.EditComment} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const clearPostData = createAction(`${ReducerSections.POSTS} ${PostActions.ClearPostsData}`);

export const blockPost = createAction(
  `${ReducerSections.POSTS} ${PostActions.BlockPost}`,
  props<{ postId: number; reason: string }>(),
);

export const blockPostSuccess = createAction(
  `${ReducerSections.POSTS} ${PostActions.BlockPost} ${ReducerStatuses.SUCCESS}`,
);
