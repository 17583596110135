import { HttpErrorResponse } from '@angular/common/http';
import {
  INotification,
  INotifications,
} from 'src/app/shared/services/notifications/notifications.type';

export const NOTIFICATIONS_KEY = 'notifications';

export interface INotificationsState {
  notifications: INotifications[];
  notificationsList: INotification[];
  unreadNotificationsList: INotification[];
  isAllFetched: boolean;
  isAllUnreadFetched: boolean;
  isLoading: boolean;
  isUnreadLoading: boolean;
  error: HttpErrorResponse | null;
}

export enum NotificationActions {
  pollingNotification = 'pollingNotification',
  setNotifications = 'setNotifications',
  deleteNotification = 'deleteNotification',
  noopNotification = 'noopNotification',
  notificationsList = 'notificationsList',
  clearNotificationsLists = 'clearNotificationsLists',
  unreadNotificationsList = 'unreadNotificationsList',
  clearUnreadNotificationsList = 'clearUnreadNotificationsList',
}
