<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <app-text
      [textSize]="TextSizes.h1Header"
      [textType]="TextType.base"
      [text]="'ФОТООТЧЁТ'"
    ></app-text>

    <ion-buttons slot="end">
      <ion-icon
        (click)="closeAlert()"
        slot="icon-only"
        name="close-outline"
        class="close-photo-report-icon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false" [forceOverscroll]="false">
  <div class="create-photo-report">
    <ng-container *ngIf="categories.length">
      <form class="create-photo-report__form" [formGroup]="createPhotoReportForm">
        <div class="create-photo-report__form-comment">
          <ion-grid>
            <ion-row>
              <ion-col size="col-comment"
                ><div class="create-photo-report__form-comment-icon">
                  <app-image [img]="'assets/images/icons-svg/comment.svg'"></app-image></div
              ></ion-col>
              <ion-col>
                <ion-item class="create-photo-report__form-comment-textarea">
                  <ion-textarea
                    formControlName="description"
                    rows="1"
                    placeholder="Комментарий"
                    [autoGrow]="true"
                    maxlength="255"
                    [disabled]="isLoading"
                  ></ion-textarea> </ion-item
              ></ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <div class="create-photo-report__categories">
          <ng-container *ngFor="let category of categories; trackBy: trackByFn">
            <div class="wrapper">
              <ng-container *ngIf="currentPlatform$ | async as currentPlatform">
                <div class="create-photo-report__categories-item">
                  <app-text
                    [textSize]="TextSizes.h1Header"
                    [textType]="TextType.base"
                    [text]="
                      category.categoryValue
                        ? category.categoryValue.toUpperCase()
                        : 'БЕЗ КАТЕГОРИИ'
                    "
                  ></app-text>
                  <input
                    #imageInput
                    type="file"
                    [accept]="availableImageFormats"
                    (change)="handleUploadImages($event, category.categoryName)"
                    multiple
                    [style.display]="'none'"
                  />
                  <div
                    class="create-photo-report__categories-item-add"
                    [class.disabled]="isLoading"
                    (click)="
                      currentPlatform === CurrentPlatform.web
                        ? imageInput.click()
                        : chooseImageForCategory(category.categoryName)
                    "
                  >
                    <app-image [img]="'assets/images/icons-svg/add-post-icon.svg'"></app-image>
                  </div>
                </div>
                <div
                  *ngIf="uploadingImages$ | async as uploadingImages"
                  class="create-photo-report__categories-images-wrapper"
                >
                  <div [id]="category.categoryName" class="create-photo-report__categories-images">
                    <ng-container
                      *ngFor="
                        let image of uploadingImages[category.categoryName];
                        trackBy: trackByFn
                      "
                    >
                      <div
                        *ngIf="!image.error"
                        class="create-photo-report__categories-images-current"
                      >
                        <img
                          *ngIf="!image.isLoading"
                          (click)="changeImageRotate(image, category.categoryName)"
                          class="create-photo-report__categories-images-current-turn"
                          [class.disabled]="isLoading"
                          src="assets/images/icons-svg/turn-photo-arrow.svg"
                        />
                        <img
                          [src]="image.localUrl ? image.localUrl : (image.path | addBaseUrl)"
                          [class.loading]="image.isLoading"
                          [style.transform]="
                            image.localRotateAngle === 0 || image.localRotateAngle
                              ? 'rotate(' + image.localRotateAngle + 'deg)'
                              : 'rotate(' + image.rotateAngle + 'deg)'
                          "
                          draggable="false"
                          class="create-photo-report__categories-images-current-image"
                        />
                        <img
                          class="create-photo-report__categories-images-current-delete"
                          [class.disabled]="isLoading"
                          src="assets/images/icons-svg/delete-icon-black.svg"
                          (click)="
                            handleDeleteChosenItem(image.localId, image.id, category.categoryName)
                          "
                        />
                        <div class="spinner" *ngIf="image.isLoading">
                          <ion-spinner name="circles"></ion-spinner>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container
                    *ngIf="
                      uploadingImages[category.categoryName] &&
                      uploadingImages[category.categoryName].length > 3
                    "
                  >
                    <div
                      *ngIf="category.showLeftArrow"
                      class="arrow arrow-left"
                      (click)="
                        currentPlatform === CurrentPlatform.web
                          ? scrollLeft(category.categoryName)
                          : null
                      "
                    >
                      <img src="assets/images/icons-svg/arrow-right-big.svg" />
                    </div>
                    <div
                      *ngIf="category.showRightArrow"
                      class="arrow arrow-right"
                      (click)="
                        currentPlatform === CurrentPlatform.web
                          ? scrollRight(category.categoryName)
                          : null
                      "
                    >
                      <img src="assets/images/icons-svg/arrow-right-big.svg" />
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
    </ng-container>
  </div>
</ion-content>

<ion-footer>
  <app-button
    [btnType]="Buttons.small"
    text="готово"
    textSize="text-1"
    [isDisabled]="!imageIds.length || isLoading || isImageLoading"
    (click)="createAlert()"
  ></app-button>
</ion-footer>
