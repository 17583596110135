import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { TrainingActions } from '../models/training.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ICurrentSection,
  ITrainingFormData,
  ITrainingLevels,
  ITrainingLevelsSection,
  ITrainingStage,
} from '../../../shared/services/training/training.type';
import { IFilter } from '../../../shared/interfaces';
import { IPaginationFilter } from '../models/filters.model';
import { SectionsListModes } from '../../../shared/enums';
import { ITrainingSearchResultElement } from '../../../shared/services/training/training.type';
import {
  ITrainingUserPositionGroup,
  IUser,
  IUserPosition,
} from 'src/app/shared/services/user/user.type';

export const getSectionsList = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getSectionsList}`,
);

export const getSectionsListSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getSectionsList} ${ReducerStatuses.SUCCESS}`,
  props<{ sectionsList: ITrainingUserPositionGroup[] }>(),
);

export const getSectionsListFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getSectionsList} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getSearchResultsFiles = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.searchResultsFiles}`,
  props<{ filters: IPaginationFilter }>(),
);

export const getSearchResultsFilesSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.searchResultsFiles} ${ReducerStatuses.SUCCESS}`,
  props<{
    trainingFilesList: ITrainingSearchResultElement[];
    currentMode: SectionsListModes;
    resetData: boolean;
  }>(),
);

export const getSearchResultsFilesFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.searchResultsFiles} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getCurrentSectionInfo = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentSectionInfo}`,
  props<{ sectionId: number }>(),
);

export const getCurrentSectionInfoSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentSectionInfo} ${ReducerStatuses.SUCCESS}`,
  props<{ section: ICurrentSection }>(),
);

export const getCurrentSectionInfoFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentSectionInfo} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getCurrentStage = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentStage}`,
  props<{ stageId: number }>(),
);

export const getCurrentStageSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentStage} ${ReducerStatuses.SUCCESS}`,
  props<{ stage: ITrainingStage }>(),
);

export const getCurrentStageFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentStage} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

//#region training (tables & form)

export const getAllNecessaryTrainingFormDataInit = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingLevels}`,
  props<{ userId: number; filters: IFilter }>(),
);

export const getTrainingLevelsSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingLevels} ${ReducerStatuses.SUCCESS}`,
  props<{ trainingLevels: ITrainingLevels[]; formData: Partial<ITrainingFormData> }>(),
);

export const getTrainingLevelsFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingLevels} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const changeCurrentTrainingStage = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.setCurrentTrainingStage}`,
  props<{ chosenStage: number }>(),
);

export const setCurrentTrainingStageData = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.setCurrentTrainingStage}`,
  props<{ formData: Partial<ITrainingFormData> }>(),
);

export const sendForm = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.sendForm}`,
  props<{
    startDate: string;
    endDate: string;
    currentStage: number;
    isDraft: boolean;
    userPositions?: IUserPosition[];
    developmentTalk?: string;
  }>(),
);

export const sendFormSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.sendForm} ${ReducerStatuses.SUCCESS}`,
  props<{ userId: number; isDraft: boolean; currentStage: number }>(),
);

export const sendFormFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.sendForm} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const changeFormData = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.changeFormData}`,
  props<{ data: Partial<ITrainingLevelsSection> }>(),
);

export const changeFormDataSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.changeFormData} ${ReducerStatuses.SUCCESS}`,
  props<{ form: ITrainingFormData }>(),
);

export const changeFormDataParams = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.changeFormDataParams}`,
  props<{
    data: any;
  }>(),
);

export const getTrainingEmployees = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingEmployees}`,
  props<{ pageType: string; onlyUntrained?: boolean; isLastWeekOfEducation?: boolean }>(),
);

export const getTrainingEmployeesSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingEmployees} ${ReducerStatuses.SUCCESS}`,
  props<{ list: IUser[]; resetData: boolean; size: number }>(),
);

export const getTrainingEmployeesFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingEmployees} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getCurrentTrainingEmployeeDataForFormSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentTrainingEmployeeDataForForm} ${ReducerStatuses.SUCCESS}`,
  props<{ currentEmployeeData: IUser; userId: number; currentStage: number }>(),
);

export const getCurrentTrainingEmployeeDataForFormFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getCurrentTrainingEmployeeDataForForm} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

//#endregion

export const clearTrainingData = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.clearSectionsData}`,
);

export const clearStageData = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.clearStageData}`,
);

export const clearSectionData = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.clearSectionData}`,
);

export const getTrainingXlsReportInit = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingXlsReport}`,
);

export const getTrainingXlsReportSuccess = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingXlsReport} ${ReducerStatuses.SUCCESS}`,
  props<{ path: string; name: string }>(),
);

export const getTrainingXlsReportFailed = createAction(
  `${ReducerSections.TRAINING} ${TrainingActions.getTrainingXlsReport} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);
