<app-filters-menu
  *ngIf="step === filtersModalSteps.menu && !isOnlyFiltersList"
  [menuOptions]="menuOptions"
  [initialFilters]="initialFilters"
  [withFavoriteOption]="withFavoriteOption"
  [onlyFavorite]="onlyFavorite"
  [isFiltersChosen]="filtersStateHaveOptions$ | async"
  (emitSelectedMenuOption)="goToFiltersList($event)"
>
</app-filters-menu>
<app-filters-list
  *ngIf="step === filtersModalSteps.filterList || isOnlyFiltersList"
  [isOnlyFiltersList]="isOnlyFiltersList"
  [activeFilterList]="activeFilterList"
  [initialFilters]="initialFilters"
  [isCheckboxType]="isCheckboxType"
  (emitStepChange)="step = filtersModalSteps.menu"
></app-filters-list>
