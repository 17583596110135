import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IImage } from '../../services/posts/posts.type';
import { ImagePreviewType } from '../../enums';

@Pipe({
  name: 'getImagePreview',
})
export class GetImagePreviewPipe implements PipeTransform {
  transform(image: IImage | undefined, previewType: ImagePreviewType): string {
    const src =
      image?.previewImages.find((img) => img.previewType === previewType)?.preview || image?.path;

    return src ? environment.befreeUrl + src : '';
  }
}
