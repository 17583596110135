import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageType } from '../../../../../../shared/enums';
import { befreeUrlValues } from '../../../../../../shared/constants';

@Component({
  selector: 'app-employee-development',
  templateUrl: './employee-development.component.html',
  styleUrls: ['./employee-development.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDevelopmentComponent {
  public readonly PageType = PageType;

  public listName = befreeUrlValues.employeeDevelopment;
}
