import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
  @Input() phone: string | undefined;

  @Input() email: string | undefined;

  public onLinkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
