import { Directive, ElementRef, OnInit } from '@angular/core';
import { customScrollBarStyles } from '../../constants';
import { Capacitor } from '@capacitor/core';
import { CurrentPlatform } from '../../enums';

@Directive({
  selector: '[appIonScrollBar]',
})
export class IonScrollBarDirective implements OnInit {
  hostElement: HTMLElement | undefined;

  currentPlatform = Capacitor.getPlatform();

  constructor(public elementRef: ElementRef) {}

  ngOnInit() {
    this.hostElement = this.elementRef.nativeElement;
    if (
      this.currentPlatform === CurrentPlatform.web &&
      this.hostElement &&
      this.hostElement.tagName &&
      this.hostElement.tagName == 'ION-CONTENT'
    ) {
      let el = document.createElement('style');
      el.innerText = customScrollBarStyles;
      this.hostElement?.shadowRoot?.appendChild(el);
    }
  }
}
