import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  @Input() text: string | number | undefined;

  @Input() textType: string | undefined;

  @Input() textSize: string | undefined;

  @Input() uppercase: boolean = false;

  @Input() initialCase: boolean = false;

  @Input() canCopy: boolean = false;
}
