import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INotificationsState, NOTIFICATIONS_KEY } from '../models/notifications.model';
import { NotificationTypes } from 'src/app/shared/constants';

export const selectFeature = createFeatureSelector<INotificationsState>(NOTIFICATIONS_KEY);

export const selectNotifications = createSelector(
  selectFeature,
  (state: INotificationsState) => state.notifications,
);

export const selectNotificationsWithoutLikes = createSelector(
  selectFeature,
  (state: INotificationsState) =>
    state.notifications.filter(
      (notification) =>
        notification.notificationType !== NotificationTypes.trainingNotification &&
        notification.notificationType !== 'REPLY_TO_LIKE',
    ),
);

export const selectNotificationsList = createSelector(
  selectFeature,
  (state: INotificationsState) => state.notificationsList,
);

export const selectUnreadNotificationsList = createSelector(
  selectFeature,
  (state: INotificationsState) => state.unreadNotificationsList,
);

export const selectNotificationsListsIsLoading = createSelector(
  selectFeature,
  (state: INotificationsState) => state.isLoading,
);

export const selectUnreadNotificationsListsIsLoading = createSelector(
  selectFeature,
  (state: INotificationsState) => state.isUnreadLoading,
);

export const selectIsAllListFetched = createSelector(
  selectFeature,
  (state: INotificationsState) => state.isAllFetched,
);

export const selectIsAllUnreadListFetched = createSelector(
  selectFeature,
  (state: INotificationsState) => state.isAllUnreadFetched,
);
