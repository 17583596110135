import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable, switchMap, take, tap } from 'rxjs';
import { getCurrentUser } from 'src/app/data/store/actions/users.actions';
import { IUsersState } from 'src/app/data/store/models/users.model';
import { selectUsersState } from 'src/app/data/store/selectors/users.selectors';
import { befreeUrlValues } from '../constants';
import { IToken } from '../interfaces';
import { StorageService } from '../services/storage/storage.service';
import { StorageType } from '../services/storage/storage.type';
import { selectAppUpdateData } from 'src/app/data/store/selectors/platform.selectors';
import { IAppUpdate } from '../services/general/general.type';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private _auth: UrlTree;

  private _update: UrlTree;

  private _dashboard: UrlTree;

  public selectUsersState$: Observable<IUsersState> = this._store.select(selectUsersState);

  constructor(
    private _router: Router,
    private _store: Store,
    private _storageService: StorageService,
  ) {
    this._auth = this._router.parseUrl(befreeUrlValues.auth);
    this._update = this._router.parseUrl(befreeUrlValues.update);
    this._dashboard = this._router.parseUrl(befreeUrlValues.dashboard);
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this._storageService.getItem<IToken>(StorageType.Token).pipe(
      take(1),
      switchMap((token) => {
        return this._store.select(selectAppUpdateData).pipe(
          switchMap((appUpdateData) => {
            return this.selectUsersState$.pipe(
              tap((state) => {
                if (!state?.currentUser && !state?.isLoading && token?.access_token) {
                  this._store.dispatch(getCurrentUser());
                }
              }),
              filter((state) => {
                return (
                  ((!state.isLoading && (Boolean(state.error) || Boolean(state.currentUser))) ||
                    !token?.access_token) &&
                  !!appUpdateData
                );
              }),
              map((state) => this._defineActiveRoute(route, state, appUpdateData!)),
              first(),
            );
          }),
        );
      }),
    );
  }

  private _defineActiveRoute(
    route: ActivatedRouteSnapshot,
    userState: IUsersState,
    appUpdateData: IAppUpdate,
  ): boolean | UrlTree {
    if (!appUpdateData.status) {
      return route.routeConfig?.path === befreeUrlValues.update ? true : this._update;
    }
    if (!userState.currentUser) {
      if (route.routeConfig?.path === befreeUrlValues.auth) {
        return true;
      }
      return this._auth;
    } else {
      if (
        route.routeConfig?.path === befreeUrlValues.auth ||
        route.routeConfig?.path === befreeUrlValues.update
      ) {
        return this._dashboard;
      }
      return true;
    }
  }
}
