import { createReducer, on } from '@ngrx/store';
import {
  setGalleryData,
  setGalleryImages,
  resetGalleryData,
  changeGalleryDataImageIndex,
} from '../actions/gallery.action';
import { IGalleryState } from '../models/gallery.model';

const initialState: IGalleryState = {
  images: [],
  userData: undefined,
  hideInputs: false,
  hideComments: false,
  chosenImgIndex: 0,
  isLoopDisabled: false,
  photoReportData: undefined,
};

export const galleryReducer = createReducer(
  initialState,
  on(setGalleryData, (_, { gallery }): IGalleryState => gallery),
  on(setGalleryImages, (state, { images }): IGalleryState => ({ ...state, images })),
  on(resetGalleryData, (): IGalleryState => initialState),
  on(
    changeGalleryDataImageIndex,
    (state, { index }): IGalleryState => ({ ...state, chosenImgIndex: index }),
  ),
);
