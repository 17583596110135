import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from, map, mergeMap, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  dislikePost,
  likePost,
  updatePostData,
} from '../../../../data/store/actions/posts.actions';
import {
  dislikeImageAction,
  dislikePhotoReport,
  likeImageAction,
  likePhotoReport,
} from '../../../../data/store/actions/photo-report.action';
import { PostViewComponent } from 'src/app/modules/dashboard/components/post-view/post-view.component';
import { CurrentPlatform, DataType, ViewType } from 'src/app/shared/enums';

@UntilDestroy()
@Component({
  selector: 'app-post-information',
  templateUrl: './post-information.component.html',
  styleUrls: ['./post-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostInformationComponent implements OnChanges {
  @Input() isStoreList: boolean = false;

  @Input() postId?: number;

  @Input() postLikesCount?: number;

  @Input() postCommentsCount?: number;

  @Input() postIsLiked?: boolean;

  @Input() viewType?: string;

  @Input() dataType?: string;

  @Input() onCommentIconClick: (() => void) | undefined | null;

  @Input() hideComments: boolean = false;

  private _isLoading = false;

  constructor(private _store: Store, private _modalCtrl: ModalController) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['postIsLiked']?.previousValue !== changes['postIsLiked']?.currentValue &&
      this._isLoading
    ) {
      this._isLoading = false;
    }
  }

  public onClickComments() {
    if (this.onCommentIconClick === null) {
      return;
    }
    if (this.onCommentIconClick) {
      this.onCommentIconClick?.();
      return;
    }

    if (this.viewType !== ViewType.gallery && this.viewType !== ViewType.modal) {
      this._goToPost();
    }
  }

  public onLikePost() {
    if (!this.postId || this._isLoading) {
      return;
    }

    this._isLoading = true;

    if (this.viewType === ViewType.gallery && !this.postIsLiked) {
      this._store.dispatch(likeImageAction({ fileId: this.postId }));
    } else if (this.viewType === ViewType.gallery) {
      this._store.dispatch(dislikeImageAction({ fileId: this.postId }));
    }

    if (this.dataType === DataType.photoReport && !this.postIsLiked) {
      this._store.dispatch(likePhotoReport({ photoReportId: this.postId }));
      return;
    } else if (this.dataType === DataType.photoReport && this.postIsLiked) {
      this._store.dispatch(dislikePhotoReport({ photoReportId: this.postId }));
      return;
    }
    if (
      !this.postIsLiked &&
      this.viewType !== ViewType.gallery &&
      this.dataType !== DataType.photoReport
    ) {
      this._store.dispatch(likePost({ postId: this.postId }));
    } else if (this.viewType !== ViewType.gallery && this.dataType !== DataType.photoReport) {
      this._store.dispatch(dislikePost({ postId: this.postId }));
    }
  }

  private _goToPost() {
    const modal$ = from(
      this._modalCtrl.create({
        component: PostViewComponent,
        componentProps: {
          postId: this.postId,
          isModalView: true,
        },
        mode: CurrentPlatform.ios,
        breakpoints: [0, 0.2, 0.9, 1],
        cssClass: 'modal-post-view',
        animated: true,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();
    modal$
      .pipe(
        untilDestroyed(this),
        mergeMap((modal) => from(modal.onWillDismiss())),
      )
      .subscribe(() => {
        this._store.dispatch(updatePostData({ postId: this.postId! }));
      });
  }
}
