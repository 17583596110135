<ion-header class="ion-no-border" no-border-bottom>
  <ion-toolbar>
    <div class="title">
      <app-image
        class="back"
        *ngIf="!isOnlyFiltersList"
        [img]="'assets/images/icons-svg/back-button.svg'"
        (click)="backToMenu()"
      ></app-image>
      <app-text
        [textSize]="TextSizes.h1Header"
        [textType]="TextType.base"
        [text]="filtersOptionsNames[activeFilterList!]"
      ></app-text>
    </div>
    <ion-buttons slot="end">
      <app-button
        (click)="clearFiltersList()"
        class="custom-btn"
        [btnType]="Buttons.small"
        [textSize]="ButtonTextSizes.btnMainSmall"
        [color]="'white'"
        [text]="'сбросить'"
        [class.disable-reset-btn]="!selectedElements.length"
        [isDisabled]="!selectedElements.length"
      ></app-button> </ion-buttons
  ></ion-toolbar>
</ion-header>

<ion-content appIonScrollBar [forceOverscroll]="false">
  <ion-searchbar
    *ngIf="isNeedSearch"
    placeholder="Поиск"
    [debounce]="600"
    (ionChange)="searchValueChange($event)"
  ></ion-searchbar>

  <app-pagination-container
    [isLoading$]="isLoading$"
    [isAllLoaded]="isAllListDataFetched$ | async"
    [paginationData]="elements"
    [isFiltersPagination]="true"
    (emitScrolling)="getElements()"
  >
    <ng-container *ngIf="!isCheckboxType">
      <ion-radio-group
        [value]="selectedElements.length && selectedElements[0].id"
        (ionChange)="onChange($event)"
      >
        <ion-item *ngFor="let param of elements; trackBy: trackByFn">
          <ion-radio slot="start" [value]="param.id" mode="md"></ion-radio>
          <ion-label class="label-flex">
            <div class="trainer-avatar" *ngIf="activeFilterList === filtersOptions.trainer">
              <app-image [img]="param.avatar?.previewSmall | addBaseUrl"></app-image>
            </div>
            <div class="flex-column">
              <app-text
                [textSize]="
                  activeFilterList === filtersOptions.trainer ? TextSizes.h4Name : TextSizes.mobBody
                "
                [textType]="TextType.base"
                [text]="param.name ?? (param | getFullName)"
              ></app-text>
              <app-text
                *ngIf="activeFilterList === filtersOptions.trainer"
                [textSize]="TextSizes.h5Post"
                [textType]="TextType.base"
                [text]="param.userPosition?.name"
              ></app-text>
            </div>
          </ion-label>
        </ion-item>
      </ion-radio-group>
    </ng-container>
    <ng-container *ngIf="isCheckboxType">
      <ion-item *ngFor="let param of elements; trackBy: trackByFn">
        <ion-checkbox
          slot="start"
          [value]="param.id"
          [checked]="param.id | checkIsSelected : selectedElements"
          [disabled]="param.id | disableFiltersCheckbox : selectedElements : activeFilterList"
          mode="md"
          (ionChange)="onChange($event)"
        ></ion-checkbox>
        <ion-label
          ><app-text
            [textSize]="TextSizes.mobBody"
            [textType]="TextType.base"
            [text]="param.name"
          ></app-text
        ></ion-label>
        <div *ngIf="activeFilterList === filtersOptions.shops">
          <app-text
            class="text-grey"
            [textSize]="TextSizes.alert"
            [textType]="TextType.base"
            [text]="param.city.name"
          ></app-text>
        </div>
      </ion-item>
    </ng-container>
  </app-pagination-container>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <app-button
        class="button-footer button-list"
        [btnType]="Buttons.small"
        [textSize]="ButtonTextSizes.buttonText1"
        [text]="'готово'"
        (click)="applyFiltersList()"
      ></app-button
    ></ion-buttons>
  </ion-toolbar>
</ion-footer>
