import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setNotifications } from 'src/app/data/store/actions/notifications.action';
import { logoutAction } from 'src/app/data/store/actions/users.actions';
import {
  selectCurrentUser,
  selectUnreadCommentsCounter,
} from 'src/app/data/store/selectors/users.selectors';
import { TextSizes, TextType, UserDataImgSize } from 'src/app/shared/enums';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly UserDataImgSize = UserDataImgSize;

  public isDropdownOpen = false;

  public isNotificationsOpen = false;

  public currentUser$ = this._store.select(selectCurrentUser);

  public unreadComments$: Observable<number | undefined> = this._store.select(
    selectUnreadCommentsCounter,
  );

  constructor(private _store: Store, public utils: UtilsService) {}

  public logout() {
    this._store.dispatch(logoutAction());
    this._store.dispatch(setNotifications({ notifications: [] }));
  }
}
