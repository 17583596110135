import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearFiltersInit } from 'src/app/data/store/actions/filters.actions';
import { FiltersOptionsType } from 'src/app/data/store/models/filters.model';
import { removeChip } from 'src/app/shared/constants';
import { FiltersOptions, FiltersResetTypes, TextSizes, TextType } from 'src/app/shared/enums';

@Component({
  selector: 'app-selected-filters',
  templateUrl: './selected-filters.component.html',
  styleUrls: ['./selected-filters.component.scss'],
})
export class SelectedFiltersComponent {
  @Input() selectedOptionData?: any[] | null;

  @Input() selectedOptionName?: FiltersOptionsType;

  @Input() isChipDisable: boolean = false;

  @Input() isNeedExternalFiltersMenuCleaning: boolean = false;

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  readonly removeChip = removeChip;

  readonly optionTypes = FiltersOptions;

  constructor(private _store: Store) {}

  public removeElem(option?: FiltersOptionsType, id?: number) {
    this._store.dispatch(
      clearFiltersInit({
        activeFilterOption: option,
        id,
        clearType: FiltersResetTypes.deleteChip,
        isNeedExternalFiltersMenuCleaning: this.isNeedExternalFiltersMenuCleaning,
      }),
    );
  }
}
