<ng-container *ngIf="currentPlatform$ | async as platform">
  <ng-container *ngIf="selectLikedList$ | async as likedList">
    <ion-header>
      <div class="header">
        <img
          src="/assets/images/icons-svg/arrow-right.svg"
          class="header__back"
          (click)="goBack()"
        />

        <app-text
          [text]="galleryData.chosenImgIndex | photoViewCounter : imagesList.length"
          [textType]="TextType.base"
          [textSize]="TextSizes.h1Header"
        ></app-text>

        <ng-container *ngIf="favouritesListToBeUpdated$ | async as favouritesListToBeUpdated">
          <svg
            icon="fav-photo-icon"
            class="fav-icon fav-icon_small"
            (click)="onFavoriteButtonClick(favouritesListToBeUpdated)"
            [ngClass]="{
              'fav-icon_active': !isFileNotFav(favouritesListToBeUpdated),
              'fav-icon_disable': (isLoadingFavorite$ | async) === true
            }"
          ></svg>
        </ng-container>
      </div>
    </ion-header>

    <div
      #columnsView
      class="gallery__columns"
      (scroll)="platform !== CurrentPlatform.web && onScroll($event)"
    >
      <div class="gallery__slider">
        <ng-container *ngIf="platform !== CurrentPlatform.web">
          <ng-container *ngTemplateOutlet="userDataBlock"></ng-container>
        </ng-container>

        <div class="image-gallery__container">
          <div class="image-gallery__swiper">
            <div
              *ngIf="platform === CurrentPlatform.web && imagesList.length > 1"
              class="image-gallery__arrow image-gallery__arrow_left"
              [class.image-gallery__arrow_disabled]="
                galleryData.isLoopDisabled && swiper?.swiperRef?.realIndex === 0
              "
            >
              <app-image
                [img]="'assets/images/icons-svg/arrow-right-big.svg'"
                [rotateAngle]="180"
                (click)="swiper?.swiperRef?.slidePrev()"
              ></app-image>
            </div>

            <swiper
              #swiper
              *ngIf="!!config"
              class="swiper"
              [class.swiper_fullscreen]="galleryData.hideInputs"
              [config]="config"
              [keyboard]="platform === CurrentPlatform.web"
              [autoHeight]="platform !== CurrentPlatform.web"
              [slidesPerView]="1"
              [slidesPerView]="1"
              [longSwipesRatio]="0.05"
              (slideChange)="slideChanged()"
            >
              <ng-template swiperSlide *ngFor="let img of imagesList">
                <div class="swiper__item">
                  <img
                    [src]="img | getImagePreview : ImagePreviewType.fullScreen"
                    (click)="openFullscreenModal()"
                  />

                  <ng-container
                    *ngIf="favouritesListToBeUpdated$ | async as favouritesListToBeUpdated"
                  >
                    <svg
                      *ngIf="platform === CurrentPlatform.web"
                      icon="fav-photo-icon"
                      class="fav-icon"
                      (click)="onFavoriteButtonClick(favouritesListToBeUpdated)"
                      [ngClass]="{
                        'fav-icon_active': !isFileNotFav(favouritesListToBeUpdated),
                        'fav-icon_disable': (isLoadingFavorite$ | async) === true
                      }"
                    ></svg>
                  </ng-container>
                </div>
              </ng-template>
            </swiper>

            <div
              *ngIf="platform === CurrentPlatform.web && imagesList.length > 1"
              class="image-gallery__arrow image-gallery__arrow_right"
              [class.image-gallery__arrow_disabled]="
                galleryData.isLoopDisabled && swiper?.swiperRef?.realIndex === imagesList.length - 1
              "
            >
              <app-image
                [img]="'assets/images/icons-svg/arrow-right-big.svg'"
                (click)="swiper?.swiperRef?.slideNext()"
              ></app-image>
            </div>
          </div>

          <div class="image-gallery__counter">
            <ng-container *ngIf="!!galleryData.photoReportData">
              <b>{{ galleryData.photoReportData.name }} ({{ galleryData.photoReportData.city }})</b>
              <b>{{ galleryData.photoReportData.category }}</b>
            </ng-container>
            <span>{{ galleryData.chosenImgIndex + 1 }}/{{ imagesList.length }}</span>
          </div>
        </div>
      </div>

      <div class="gallery__comments">
        <div
          #commentsView
          class="comments"
          (scroll)="platform === CurrentPlatform.web && onScroll($event)"
        >
          <div class="comments__user">
            <ng-container *ngIf="platform === CurrentPlatform.web">
              <ng-container *ngTemplateOutlet="userDataBlock"></ng-container>
            </ng-container>

            <app-post-information
              *ngIf="!galleryData.hideInputs"
              [postId]="imagesList[galleryData.chosenImgIndex]?.id"
              [postCommentsCount]="commentsCount"
              [postIsLiked]="likedList | isImageLiked : imagesList[galleryData.chosenImgIndex]"
              [postLikesCount]="
                likedList | getImagesLikesCount : imagesList[galleryData.chosenImgIndex]
              "
              [viewType]="ViewTypesList.gallery"
              [hideComments]="true"
            ></app-post-information>
          </div>

          <ion-popover
            *ngIf="platform !== CurrentPlatform.web"
            [isOpen]="openImageId"
            [trigger]="imagesList[galleryData.chosenImgIndex]?.id"
            (didDismiss)="openImageId = undefined"
            [dismissOnSelect]="true"
            side="bottom"
            alignment="start"
            [showBackdrop]="false"
          >
            <ng-template>
              <ion-content [forceOverscroll]="false">
                <app-dropdown-menu
                  (closeModalEvent)="openImageId = undefined"
                  [fileId]="openImageId"
                  [isFavourite]="imagesList[galleryData.chosenImgIndex].favorite"
                  [dataType]="DataTypesList.image"
                  class="dropdown-menu"
                ></app-dropdown-menu>
              </ion-content>
            </ng-template>
          </ion-popover>

          <div *ngIf="comments && !galleryData.hideComments" class="comments__list" appIonScrollBar>
            <app-post-comment
              *ngFor="let comment of comments; let index; trackBy: trackByFn"
              [id]="comment.id"
              [comment]="comment"
              [newComment]="newComment"
              [editCommentId]="editCommentId"
              [changeCommentEditMode]="changeCommentEditMode"
            ></app-post-comment>

            <div class="spinner" *ngIf="isLoading && isFirstLoading">
              <ion-spinner></ion-spinner>
            </div>
          </div>
        </div>

        <div class="gallery__comment" *ngIf="platform === CurrentPlatform.web">
          <app-add-comment
            *ngIf="!galleryData.hideInputs"
            (emitComment)="createComment($event)"
          ></app-add-comment>
        </div>
      </div>
    </div>

    <div
      *ngIf="keyboardOpen"
      class="keyboard-overlay"
      (click)="handleBackgroundClick($event)"
    ></div>

    <ion-footer *ngIf="!galleryData.hideInputs && platform !== CurrentPlatform.web">
      <div #commentContainer class="gallery__comment gallery__comment_padding">
        <app-add-comment (emitComment)="createComment($event)"></app-add-comment>
      </div>
    </ion-footer>
  </ng-container>
</ng-container>

<ng-template #userDataBlock>
  <app-user-data
    *ngIf="imagesList[galleryData.chosenImgIndex]?.owner && !galleryData.userData"
    [id]="imagesList[galleryData.chosenImgIndex].owner.id"
    [imgSrc]="imagesList[galleryData.chosenImgIndex].owner.avatar?.previewSmall"
    [fullName]="imagesList[galleryData.chosenImgIndex].owner | getFullName"
    [position]="imagesList[galleryData.chosenImgIndex].owner.userPosition.name"
  ></app-user-data>

  <app-user-data
    *ngIf="galleryData.userData"
    [id]="galleryData.userData.id"
    [imgSrc]="galleryData.userData.avatar?.previewSmall"
    [fullName]="galleryData.userData | getFullName"
    [position]="galleryData.userData.userPosition.name"
  ></app-user-data>
</ng-template>

<div class="gallery__close" (click)="closeModal()">
  <app-svg icon="close"></app-svg>
</div>
