<ng-container *ngIf="trainingNotifications.length">
  <ng-container *ngFor="let notification of trainingNotifications">
    <div *ngIf="trainingNotifications.length" class="notification-card">
      <div class="notification-card__block">
        <ion-button (click)="close(notification.message)" size="small">
          <ion-icon name="close-outline" slot="icon-only"></ion-icon>
        </ion-button>
        <app-text
          text="Заполните формы развития"
          [textType]="TextType.base"
          [textSize]="TextSize.h1Header"
        ></app-text>
        <app-text
          [text]="notification.message"
          [textType]="TextType.base"
          [textSize]="TextSize.h3Body"
        ></app-text>
        <app-button
          (click)="goToEmployeeDevelopmentPage()"
          btnType="button-base"
          text="перейти"
          [textSize]="ButtonTextSizes.buttonText1"
        ></app-button>
      </div>
    </div>
  </ng-container>
</ng-container>
