import { createReducer, on } from '@ngrx/store';
import {
  setAppUpdateData,
  setCurrentAppInfoFailed,
  setCurrentAppInfoSuccess,
  setCurrentPlatformData,
  setCurrentPlatformDataError,
} from '../actions/platform.actions';
import { IPlatformDataState } from '../models/platform.model';

const initialState: IPlatformDataState = {
  error: null,
  platform: null,
  appInfo: null,
  appUpdateData: null,
};

export const platformDataReducer = createReducer(
  initialState,
  on(
    setCurrentPlatformData,
    (state: any, action): IPlatformDataState => ({
      ...state,
      platform: action.platform,
      error: null,
    }),
  ),
  on(
    setCurrentAppInfoSuccess,
    (state: any, action): IPlatformDataState => ({
      ...state,
      appInfo: action.appInfo,
    }),
  ),
  on(
    setCurrentPlatformDataError,
    setCurrentAppInfoFailed,
    (state: any, action): IPlatformDataState => ({
      ...state,
      error: action.error,
    }),
  ),
  on(
    setAppUpdateData,
    (state, action): IPlatformDataState => ({
      ...state,
      appUpdateData: action.appUpdateData,
    }),
  ),
);
