import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { IShop, IShopArray } from 'src/app/shared/services/user/user.type';
import { ShopActions } from '../models/shop.model';
import { IPhotoReport } from '../../../shared/services/photo-reports/photo-reports.type';

export const initCurrentShopData = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getCurrent} ${ReducerStatuses.INIT}`,
  props<{ shopId: number }>(),
);

export const initShopData = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getShopsList} ${ReducerStatuses.INIT}`,
);

export const currentShopDataSuccess = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getCurrent} ${ReducerStatuses.SUCCESS}`,
  props<{ shop: IShop }>(),
);

export const currentShopDataFailed = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getCurrent} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getShopsList = createAction(`${ReducerSections.SHOP} ${ShopActions.getShopsList}`);

export const getShopsListSuccess = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getShopsList} ${ReducerStatuses.SUCCESS}`,
  props<{ shopList: IShopArray; size: number; resetData?: boolean }>(),
);

export const getShopsListFailed = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getShopsList} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const addToFavorite = createAction(
  `${ReducerSections.SHOP} ${ShopActions.addToFavorite}`,
  props<{ shopId: number }>(),
);

export const addToFavoriteSuccess = createAction(
  `${ReducerSections.SHOP} ${ShopActions.addToFavorite} ${ReducerStatuses.SUCCESS}`,
  props<{ shopId: number }>(),
);

export const addToFavoriteFailed = createAction(
  `${ReducerSections.SHOP} ${ShopActions.addToFavorite} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const removeFromFavorite = createAction(
  `${ReducerSections.SHOP} ${ShopActions.removeFromFavorite}`,
  props<{ shopId: number }>(),
);

export const removeFromFavoriteSuccess = createAction(
  `${ReducerSections.SHOP} ${ShopActions.removeFromFavorite} ${ReducerStatuses.SUCCESS}`,
  props<{ shopId: number }>(),
);

export const removeFromFavoriteFailed = createAction(
  `${ReducerSections.SHOP} ${ShopActions.removeFromFavorite} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getCurrentShopsPhotoReports = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getCurrentShopsPhotoReports}`,
  props<{ shopId: number }>(),
);

export const getCurrentShopsPhotoReportsSuccess = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getCurrentShopsPhotoReports} ${ReducerStatuses.SUCCESS}`,
  props<{ currentShopsPhotoReports: IPhotoReport[]; size: number; resetData?: boolean }>(),
);

export const getCurrentShopsPhotoReportsFailed = createAction(
  `${ReducerSections.SHOP} ${ShopActions.getCurrentShopsPhotoReports} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const clearShopsList = createAction(`${ReducerSections.SHOP} ${ShopActions.clearShopsList}`);

export const clearShopsPhotoReportsList = createAction(
  `${ReducerSections.SHOP} ${ShopActions.clearShopsPhotoReportsList}`,
);
