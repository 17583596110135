import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockComponent } from './components/block/block.component';
import { ButtonComponent } from './components/button/button.component';
import { FilterBlockComponent } from './components/filter-block/filter-block.component';
import { ImageComponent } from './components/image/image.component';
import { TextComponent } from './components/text/text.component';
import { UserDataComponent } from './components/user-data/user-data.component';
import { DropdownMenuComponent } from './components/post-template/dropdown-menu/dropdown-menu.component';
import { SearchComponent } from './components/search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddBaseUrlPipe } from './pipe/add-baseUrl/add-base-url.pipe';
import { IonicModule } from '@ionic/angular';
import { DateFormatPipe } from './pipe/date-format/date-format.pipe';
import { MobileMenuUserComponent } from '../modules/dashboard/components/mobile-menu/mobile-menu-user/mobile-menu-user.component';
import { PostTextComponent } from './components/post-template/post-text/post-text.component';
import { PostInformationComponent } from './components/post-template/post-information/post-information.component';
import { PostHeaderComponent } from './components/post-template/post-header/post-header.component';
import { PostImagesGridComponent } from './components/post-template/post-images-grid/post-images-grid.component';
import { PostTemplateComponent } from './components/post-template/post-template.component';
import { HideHeaderDirective } from './directives/hide-header.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { DropdownContainerComponent } from '../modules/dashboard/components/dropdown-container/dropdown-container.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ShopCardComponent } from './components/shop-card/shop-card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PollsComponent } from './components/post-template/polls/polls.component';
import { InformationLabelComponent } from './components/post-template/information-label/information-label.component';
import { PostCommentComponent } from './components/post-comment/post-comment.component';
import { RouterModule } from '@angular/router';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SlidePhotoGalleryComponent } from './components/slide-photo-gallery/slide-photo-gallery.component';
import { PhotoViewCounterPipe } from './pipe/photo-view-counter/photo-view-counter.pipe';
import { HeaderComponent } from '../modules/dashboard/components/header/header.component';
import { IonPageContainerComponent } from './components/ion-page-container/ion-page-container.component';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { NgxGridModule } from '@egjs/ngx-grid';
import { PaginationContainerComponent } from './components/pagination-container/pagination-container.component';
import { FiltersModalComponent } from './components/filters-modal/filters-modal.component';
import { FiltersMenuComponent } from './components/filters-modal/components/filters-menu/filters-menu.component';
import { FiltersListComponent } from './components/filters-modal/components/filters-list/filters-list.component';
import { PhotoReportCardComponent } from './components/photo-report-card/photo-report-card.component';
import { SearchBarWithFiltersComponent } from './components/search-bar-with-filters/search-bar-with-filters.component';
import { WordDeclensionPipe } from './pipe/word-declension/word-declension.pipe';
import { PhoneNumberPipe } from './pipe/phone-number/phone-number.pipe';
import { EmployeeCardComponent } from './components/employee-card/employee-card.component';
import { BillboardPageComponent } from './components/billboard-page/billboard-page.component';
import { InformationCardComponent } from './components/billboard-page/information-card/information-card.component';
import { SetBillboardInfoPipe } from './pipe/set-billboard-info/set-billboard-info.pipe';
import { DepartmentCardComponent } from './components/department-card/department-card.component';
import { IonScrollBarDirective } from './directives/ion-scroll-bar/ion-scroll-bar.directive';
import { CommonEmployeesListComponent } from './components/common-employees-list/common-employees-list.component';
import { ShopListComponent } from '../modules/dashboard/components/shops/pages/shop-list/shop-list.component';
import { CommonShopsListComponent } from './components/common-shops-list/common-shops-list.component';
import { SlidePhotoGalleryWithCommentsComponent } from './components/slide-photo-gallery-with-comments/slide-photo-gallery-with-comments.component';
import { SlidePhotoGalleryFullscreenComponent } from './components/slide-photo-gallery-fullscreen/slide-photo-gallery-fullscreen.component';
import { SwiperModule } from 'swiper/angular';
import { CheckIsSelectedPipe } from './pipe/check-filters-selection-status/check-is-selected.pipe';
import { GetFullNamePipe } from './pipe/get-full-name/get-full-name.pipe';
import { GetFilePicturePipe } from './pipe/get-file-picture/get-file-picture.pipe';
import { GetImagesLikesCountPipe } from './pipe/images-like/get-images-likes-count.pipe';
import { IsImageLikedPipe } from './pipe/images-like/is-image-liked.pipe';
import { IonPageContentComponent } from './components/ion-page-container/ion-page-content/ion-page-content.component';
import { SectionCardComponent } from './components/section-card/section-card.component';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { NotificationsCounterComponent } from './components/notifications-counter/notifications-counter.component';
import { StageCardComponent } from './components/stage-card/stage-card.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { NotificationTextPipe } from './pipe/notification-text/notification-text.pipe';
import { CommonNotificationsListComponent } from './components/common-notifications-list/common-notifications-list.component';
import { CurrentEmployeeCardComponent } from './components/current-employee-card/current-employee-card.component';
import { TrainingStatusPipe } from './pipe/training-status/training-status.pipe';
import { NotificationReplyPipe } from './pipe/notificationReply/notification-reply.pipe';
import { NumToArrayPipe } from './pipe/num-to-array/num-to-array.pipe';
import { StageQuestionCounterPipe } from './pipe/stage-question-counter/stage-question-counter.pipe';
import { GetTrainingTabsPipe } from './pipe/get-training-tabs/get-training-tabs.pipe';
import { SelectedFiltersComponent } from './components/filters-modal/components/selected-filters/selected-filters.component';
import { FiltersButtonComponent } from './components/filters-modal/components/filters-button/filters-button.component';
import { NotificationsModalComponent } from './components/notifications-modal/notifications-modal.component';
import { DisableFiltersCheckboxPipe } from './pipe/disable-filters-checkbox/disable-filters-checkbox.pipe';
import { AvatarPickerComponent } from './components/avatar-picker/avatar-picker.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BaseCardComponent } from './components/base-card/base-card.component';
import { IconModule } from './components/icon/icon.module';
import { GetInnerHtmlPipe } from './pipe/get-inner-html/get-inner-html.pipe';
import { ContactsComponent } from './components/contacts/contacts.component';
import { SvgComponent } from './components/svg/svg.component';
import { PhotoInfoComponent } from './components/photo-info/photo-info.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GetImagePreviewPipe } from './pipe/get-image-preview/get-image-preview.pipe';

@NgModule({
  declarations: [
    BlockComponent,
    ButtonComponent,
    FilterBlockComponent,
    ImageComponent,
    TextComponent,
    UserDataComponent,
    DropdownMenuComponent,
    SearchComponent,
    DateFormatPipe,
    AddBaseUrlPipe,
    GetImagePreviewPipe,
    MobileMenuUserComponent,
    PostTemplateComponent,
    PostInformationComponent,
    PostHeaderComponent,
    PostImagesGridComponent,
    PostTextComponent,
    HideHeaderDirective,
    ClickOutsideDirective,
    DropdownContainerComponent,
    ComingSoonComponent,
    PollsComponent,
    InformationLabelComponent,
    ShopCardComponent,
    PostCommentComponent,
    FileUploadComponent,
    SlidePhotoGalleryComponent,
    PhotoViewCounterPipe,
    HeaderComponent,
    IonPageContainerComponent,
    AddCommentComponent,
    PaginationContainerComponent,
    FiltersModalComponent,
    FiltersMenuComponent,
    FiltersListComponent,
    PhotoReportCardComponent,
    SearchBarWithFiltersComponent,
    WordDeclensionPipe,
    PhoneNumberPipe,
    EmployeeCardComponent,
    BillboardPageComponent,
    InformationCardComponent,
    SetBillboardInfoPipe,
    DepartmentCardComponent,
    CommonEmployeesListComponent,
    IonScrollBarDirective,
    SlidePhotoGalleryWithCommentsComponent,
    SlidePhotoGalleryFullscreenComponent,
    ShopListComponent,
    CommonShopsListComponent,
    GetImagesLikesCountPipe,
    IsImageLikedPipe,
    CheckIsSelectedPipe,
    GetFullNamePipe,
    GetFilePicturePipe,
    IonPageContentComponent,
    SectionCardComponent,
    NotificationContainerComponent,
    NotificationsCounterComponent,
    StageCardComponent,
    NotificationCardComponent,
    NotificationTextPipe,
    NotificationReplyPipe,
    CommonNotificationsListComponent,
    CurrentEmployeeCardComponent,
    TrainingStatusPipe,
    NumToArrayPipe,
    StageQuestionCounterPipe,
    GetTrainingTabsPipe,
    SelectedFiltersComponent,
    FiltersButtonComponent,
    NotificationsModalComponent,
    DisableFiltersCheckboxPipe,
    AvatarPickerComponent,
    ImageCropperComponent,
    SpinnerComponent,
    PdfViewerComponent,
    BaseCardComponent,
    GetInnerHtmlPipe,
    ContactsComponent,
    SvgComponent,
    PhotoInfoComponent,
  ],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    ImageCropperModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxGridModule,
    SwiperModule,
    PdfViewerModule,
    IconModule,
    AngularSvgIconModule.forRoot(),
  ],
  exports: [
    BlockComponent,
    ButtonComponent,
    FilterBlockComponent,
    ImageComponent,
    TextComponent,
    UserDataComponent,
    DropdownMenuComponent,
    SearchComponent,
    FormsModule,
    ReactiveFormsModule,
    AddBaseUrlPipe,
    GetImagePreviewPipe,
    DateFormatPipe,
    IonicModule,
    MobileMenuUserComponent,
    PostTemplateComponent,
    PostInformationComponent,
    PostHeaderComponent,
    PostImagesGridComponent,
    PostTextComponent,
    HideHeaderDirective,
    ClickOutsideDirective,
    DropdownContainerComponent,
    ShopCardComponent,
    InfiniteScrollModule,
    PollsComponent,
    PostCommentComponent,
    FileUploadComponent,
    SlidePhotoGalleryComponent,
    PhotoViewCounterPipe,
    HeaderComponent,
    IonPageContainerComponent,
    AddCommentComponent,
    NgxGridModule,
    PaginationContainerComponent,
    PhotoReportCardComponent,
    SearchBarWithFiltersComponent,
    WordDeclensionPipe,
    PhoneNumberPipe,
    EmployeeCardComponent,
    BillboardPageComponent,
    DepartmentCardComponent,
    CommonEmployeesListComponent,
    ShopListComponent,
    CommonShopsListComponent,
    GetFullNamePipe,
    GetFilePicturePipe,
    IonPageContentComponent,
    SectionCardComponent,
    NotificationContainerComponent,
    NotificationsCounterComponent,
    StageCardComponent,
    NotificationCardComponent,
    NotificationTextPipe,
    NotificationReplyPipe,
    CommonNotificationsListComponent,
    CurrentEmployeeCardComponent,
    ComingSoonComponent,
    TrainingStatusPipe,
    NumToArrayPipe,
    StageQuestionCounterPipe,
    GetTrainingTabsPipe,
    SelectedFiltersComponent,
    FiltersButtonComponent,
    NotificationsModalComponent,
    DisableFiltersCheckboxPipe,
    GetImagesLikesCountPipe,
    IsImageLikedPipe,
    AvatarPickerComponent,
    SpinnerComponent,
    SvgComponent,
    PhotoInfoComponent,
  ],
})
export class SharedModule {}
