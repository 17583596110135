import { APP_INITIALIZER, LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Store, StoreModule } from '@ngrx/store';
import '@angular/common/locales/global/ru';
import { SharedModule } from './shared/shared.module';
import { UiKitComponent } from './modules/ui-kit/ui-kit.component';
import { effects, metaReducers, reducers } from './data/store/reducers';
import { setCurrentPlatformData } from './data/store/actions/platform.actions';
import { Capacitor } from '@capacitor/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { CurrentPlatform } from './shared/enums';
import { AuthComponent } from './modules/auth/auth.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { TextZoom } from '@capacitor/text-zoom';
import { zoomValue } from './shared/constants';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { UtilsService } from './shared/services/utils.service';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddBaseUrlPipe } from './shared/pipe/add-baseUrl/add-base-url.pipe';
import { TrainingStatusPipe } from './shared/pipe/training-status/training-status.pipe';
import { TrainingModule } from './modules/dashboard/components/training/training.module';
import { ICONS_PATH } from './shared/components/icon/icons-path';
import { IconModule } from './shared/components/icon/icon.module';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { GetFilePicturePipe } from './shared/pipe/get-file-picture/get-file-picture.pipe';
import { GetImagePreviewPipe } from './shared/pipe/get-image-preview/get-image-preview.pipe';

@NgModule({
  declarations: [AppComponent, UiKitComponent, AuthComponent, DashboardComponent],
  imports: [
    BrowserModule,
    IonicModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    ReactiveFormsModule,
    StoreRouterConnectingModule.forRoot(),
    BrowserAnimationsModule,
    TrainingModule,
    IconModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },

    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store, utilsService: UtilsService) => {
        return () => {
          const currentPlatform = Capacitor.getPlatform();
          SplashScreen.show({
            showDuration: 3000,
            autoHide: true,
          });
          store.dispatch(setCurrentPlatformData({ platform: currentPlatform }));
          if (currentPlatform === CurrentPlatform.ios) {
            StatusBar.setStyle({ style: Style.Light });
          }

          if (currentPlatform !== CurrentPlatform.web) {
            TextZoom.getPreferred().then(() => {
              TextZoom.set({ value: zoomValue });
            });
            utilsService.backBtnEventSubscription();
          }
        };
      },
      multi: true,
      deps: [Store, UtilsService, Sentry.TraceService],
    },
    HttpClientModule,
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AddBaseUrlPipe,
    GetImagePreviewPipe,
    TrainingStatusPipe,
    GetFilePicturePipe,
    {
      provide: ICONS_PATH,
      useValue: 'assets/images/icons-svg',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
