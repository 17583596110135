import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  clearUnreadNotificationsList,
  deleteNotification,
  getNotificationsList,
  getNotificationsListFailed,
  getNotificationsListSuccess,
  getUnreadNotificationsList,
  getUnreadNotificationsListSuccess,
  setNotifications,
  startPollingNotifications,
} from '../actions/notifications.action';
import { catchError, filter, map, of, repeat, retry, share, switchMap, timer } from 'rxjs';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  selectNotifications,
  selectUnreadNotificationsList,
} from '../selectors/notifications.selectors';
import { selectFiltersState } from '../selectors/filters.selectors';
import { IFilter } from 'src/app/shared/interfaces';
import { selectCurrentUser, selectUnreadRepliesCounter } from '../selectors/users.selectors';
import { getCounters } from '../actions/users.actions';

@UntilDestroy()
@Injectable()
export class NotificationsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _notifies: NotificationsService,
  ) {}

  public polling$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(startPollingNotifications),
      switchMap(() => {
        return this._notifies.pollingNotifications().pipe(
          untilDestroyed(this),
          concatLatestFrom(() => this._store.select(selectCurrentUser)),
          repeat(),
          filter(([, user]) => Boolean(user)),
          share(),
          retry({ count: 3, delay: 10000 }),
          map(([notifications]) => setNotifications({ notifications })),
          catchError(() => of()),
        );
      }),
    );
  });

  readonly deleteFirstNotification$ = createEffect(() => {
    return this._actions$.pipe(
      concatLatestFrom(() => this._store.select(selectNotifications)),
      filter(([, notifications]) => {
        return Boolean(notifications.length);
      }),
      switchMap(() => {
        return timer(3000).pipe(
          untilDestroyed(this),
          map(() => deleteNotification()),
        );
      }),
    );
  });

  public getNotificationsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getNotificationsList),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([action, filtersState]) => {
        const filters: IFilter = {
          page: filtersState.page,
          size: filtersState.size,
          type: action.currentPageType,
        };

        return this._notifies.getNotifications(filters).pipe(
          map((list) => {
            return getNotificationsListSuccess({
              list: list.elements,
              resetData: filters.page === 0,
              totalElements: list.totalElements,
            });
          }),
          catchError((error) => {
            return of(getNotificationsListFailed({ error }));
          }),
        );
      }),
    );
  });

  public getUnreadNotificationsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getUnreadNotificationsList),
      concatLatestFrom(() => [this._store.select(selectUnreadRepliesCounter)]),
      switchMap(([action, unreadNotificationCounter]) => {
        const filters: IFilter = {
          page: 0,
          size: unreadNotificationCounter,
          type: action.currentPageType,
        };

        return this._notifies.getUnreadNotifications(filters).pipe(
          map((list) => {
            return getUnreadNotificationsListSuccess({
              list: list.elements,
              resetData: filters.page === 0,
              totalElements: list.totalElements,
            });
          }),
          catchError((error) => {
            return of(getNotificationsListFailed({ error }));
          }),
        );
      }),
    );
  });

  public clearUnreadNotificationsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(clearUnreadNotificationsList),
      concatLatestFrom(() => this._store.select(selectUnreadNotificationsList)),
      filter(([, notifications]) => !!notifications.length),
      switchMap(() => {
        return of(getCounters());
      }),
    );
  });
}
