import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneNumbs: string | null): string {
    if (phoneNumbs) {
      return phoneNumbs
        .toString()
        .replace(/^7([\d]{3})([\d]{3})([\d]{2})([\d]{2})$/, '+7 $1 $2-$3-$4');
    }
    return '';
  }
}
