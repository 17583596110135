import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-post-text',
  templateUrl: './post-text.component.html',
  styleUrls: ['./post-text.component.scss'],
})
export class PostTextComponent {
  @Input() postText: string | undefined;

  showMore: boolean = false;

  isOverflown(element: HTMLElement) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }
}
