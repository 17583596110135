import { createReducer, on } from '@ngrx/store';
import { ITrainingState } from '../models/training.model';
import {
  changeFormData,
  changeFormDataParams,
  clearSectionData,
  clearStageData,
  clearTrainingData,
  getAllNecessaryTrainingFormDataInit,
  getCurrentSectionInfo,
  getCurrentSectionInfoFailed,
  getCurrentSectionInfoSuccess,
  getCurrentStage,
  getCurrentStageFailed,
  getCurrentStageSuccess,
  getCurrentTrainingEmployeeDataForFormFailed,
  getCurrentTrainingEmployeeDataForFormSuccess,
  getSearchResultsFiles,
  getSearchResultsFilesFailed,
  getSearchResultsFilesSuccess,
  getSectionsList,
  getSectionsListFailed,
  getSectionsListSuccess,
  getTrainingEmployees,
  getTrainingEmployeesFailed,
  getTrainingEmployeesSuccess,
  getTrainingLevelsFailed,
  getTrainingLevelsSuccess,
  getTrainingXlsReportFailed,
  getTrainingXlsReportInit,
  getTrainingXlsReportSuccess,
  sendForm,
  sendFormFailed,
  setCurrentTrainingStageData,
} from '../actions/training.action';
import { SectionsListModes } from '../../../shared/enums';

const initialState: ITrainingState = {
  sectionsList: [],
  section: null,
  stage: null,
  trainingFilesList: [],
  trainingLevels: [],
  currentMode: SectionsListModes.listViewMode,
  positionsList: [],
  formData: null,
  trainingEmployees: [],
  trainingXlsReportData: null,
  currentTrainingEmployeeData: null,
  allDataFetched: false,
  isLoading: false,
  isReportLoading: false,
  error: null,
};

export const trainingReducer = createReducer(
  initialState,
  on(
    getSectionsList,
    getCurrentSectionInfo,
    getSearchResultsFiles,
    getCurrentStage,
    getAllNecessaryTrainingFormDataInit,
    sendForm,
    getTrainingEmployees,
    (state: ITrainingState): ITrainingState => ({
      ...state,
      allDataFetched: false,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    getTrainingXlsReportInit,
    (state: ITrainingState): ITrainingState => ({
      ...state,
      isReportLoading: true,
      trainingXlsReportData: null,
      error: null,
    }),
  ),
  on(
    getAllNecessaryTrainingFormDataInit,
    (state: ITrainingState): ITrainingState => ({
      ...state,
      currentTrainingEmployeeData: null,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    getSectionsListSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      sectionsList: action.sectionsList,
      currentMode: SectionsListModes.listViewMode,
      allDataFetched: true,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getSearchResultsFilesSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      trainingFilesList:
        action.resetData || !state.trainingFilesList
          ? action.trainingFilesList
          : [...state.trainingFilesList, ...action.trainingFilesList],
      currentMode: SectionsListModes.searchMode,
      allDataFetched: action.trainingFilesList.length < 20,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getCurrentSectionInfoSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      section: action.section,
      currentMode: SectionsListModes.listViewMode,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getCurrentStageSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      stage: action.stage,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getTrainingLevelsSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      trainingLevels: action.trainingLevels,
      formData: action.formData,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    setCurrentTrainingStageData,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      formData: { user: { ...state.formData?.user! }, ...action.formData },
      isLoading: false,
      error: null,
    }),
  ),
  on(
    changeFormData,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      formData: state.formData
        ? {
            ...state.formData,
            [action.data.type!.toLowerCase()]: state.formData[action.data.type!.toLowerCase()].map(
              (parameter: { id: number | undefined }) => {
                return parameter.id === action.data.id ? action.data : parameter;
              },
            ),
          }
        : null,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    changeFormDataParams,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      formData: {
        ...state.formData,
        userTraining: {
          ...state.formData?.userTraining,
          ...action.data,
        },
      },
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getTrainingEmployeesSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      trainingEmployees:
        !state.trainingEmployees.length || action.resetData
          ? action.list
          : [...state.trainingEmployees, ...action.list],
      allDataFetched: action.list.length < action.size,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getTrainingXlsReportSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      trainingXlsReportData: action,
      isReportLoading: false,
      error: null,
    }),
  ),
  on(
    getCurrentTrainingEmployeeDataForFormSuccess,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      currentTrainingEmployeeData: action.currentEmployeeData,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getTrainingXlsReportFailed,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      isReportLoading: false,
      error: action.error,
    }),
  ),
  on(
    getSectionsListFailed,
    getCurrentSectionInfoFailed,
    getCurrentStageFailed,
    getSearchResultsFilesFailed,
    getTrainingLevelsFailed,
    sendFormFailed,
    getTrainingEmployeesFailed,
    getCurrentTrainingEmployeeDataForFormFailed,
    (state: ITrainingState, action): ITrainingState => ({
      ...state,
      isLoading: false,
      error: action.error,
    }),
  ),
  on(
    clearTrainingData,
    (): ITrainingState => ({
      ...initialState,
    }),
  ),
  on(
    clearStageData,
    (state): ITrainingState => ({
      ...state,
      stage: null,
      isLoading: false,
      currentMode: SectionsListModes.listViewMode,
    }),
  ),
  on(
    clearSectionData,
    (state): ITrainingState => ({
      ...state,
      section: null,
      isLoading: false,
      currentMode: SectionsListModes.listViewMode,
    }),
  ),
);
