<div class="post-header">
  <div class="post-header__info">
    <div class="post-header__department">
      <img src="assets/images/icons-svg/logo-black.svg" alt="logo" />

      <div class="post-header__department-text">
        <app-text
          *ngIf="department"
          [text]="department"
          [textType]="TextType.base"
          [textSize]="TextSizes.h5Post"
        ></app-text>

        <app-text
          *ngIf="city"
          [textType]="TextType.base"
          [textSize]="TextSizes.h5Post"
          class="post-header__city"
        >
          <span>&nbsp;·&nbsp;</span>{{ city }}
        </app-text>
      </div>
    </div>

    <app-text
      *ngIf="date"
      [text]="date | dateFormat : false : false : !utils.isWeb"
      [textType]="TextType.base"
      [textSize]="TextSizes.date"
      class="post-header__date"
    ></app-text>
  </div>

  <div class="post-header__user">
    <a
      class="post-user no-drag"
      (click)="!utils.isWeb ? onNameClick() : undefined"
      [routerLink]="!utils.isWeb ? link : null"
    >
      <div class="post-user__img" [class.post-user__img_border]="!avatar">
        <app-image [img]="avatar | addBaseUrl"></app-image>
      </div>

      <div>
        <a [routerLink]="utils.isWeb ? link : null" class="nav-link">
          <app-text
            *ngIf="fullName"
            [text]="fullName"
            class="post-user__name"
            [ngClass]="{ 'post-user__name_active': isActive }"
            [textType]="TextType.base"
            [textSize]="TextSizes.h4Name"
          ></app-text>
        </a>

        <app-text
          *ngIf="position"
          [text]="position"
          class="post-user__position"
          [textType]="TextType.base"
          [textSize]="TextSizes.h5Post"
        ></app-text>
      </div>
    </a>

    <div class="post-header__dropdown">
      <ng-content></ng-content>
    </div>
  </div>
</div>
