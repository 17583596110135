import { Injectable } from '@angular/core';
import { IFilter } from '../../interfaces';
import { BaseHttpService } from '../base-http/base-http.service';
import {
  ICreatePhotoReportData,
  IPhotoReport,
  IPhotoReportArray,
  IPhotoReportCategoryArray,
  IPhotoReportToUpdate,
} from './photo-reports.type';
import { Observable } from 'rxjs';
import { IComment, ICommentsArray } from '../posts/posts.type';

@Injectable({
  providedIn: 'root',
})
export class PhotoReportsService {
  private readonly _pagesUrl = `/pages`;

  private readonly _favsUrl = `/favs`;

  private readonly _likeUrl = `/like`;

  private readonly _dislikeUrl = `/unlike`;

  private readonly _commentsUrl = '/comments';

  private readonly _photoreportUrl = '/photoreport';

  private readonly _publishedUrl = '/published';

  private readonly _categoriesUrl = '/categories';

  private readonly _rootCommentsUrl = `/root-comments`;

  private readonly _imagesUrl = `/images`;

  constructor(private _http: BaseHttpService) {}

  public getPhotoReportById(id: number) {
    return this._http.get<IPhotoReport>(`${this._photoreportUrl}${this._publishedUrl}/${id}`);
  }

  public getPhotoReports(filters?: IFilter, onlyFavorites?: boolean) {
    return this._http.get<IPhotoReportArray>(
      `${this._photoreportUrl}${onlyFavorites ? this._favsUrl : this._pagesUrl}`,
      filters,
    );
  }

  public getPhotoReportCategories(): Observable<IPhotoReportCategoryArray> {
    return this._http.get<IPhotoReportCategoryArray>(
      `${this._photoreportUrl}${this._categoriesUrl}`,
    );
  }

  public likePhotoReport(id: number) {
    return this._http.post(`${this._photoreportUrl}${this._likeUrl}/${id}`);
  }

  public dislikePhotoReport(id: number) {
    return this._http.post(`${this._photoreportUrl}${this._dislikeUrl}/${id}`);
  }

  public getPhotoReportComments(id: number, filters?: IFilter): Observable<ICommentsArray> {
    return this._http.get<ICommentsArray>(
      `${this._photoreportUrl}/${id}${this._commentsUrl}`,
      filters,
    );
  }

  public addCommentToPhotoReport(id: number, photoReport: Partial<IComment>): Observable<IComment> {
    return this._http.post<IComment>(
      `${this._photoreportUrl}/${id}${this._commentsUrl}`,
      photoReport,
    );
  }

  public deletePhotoReport(id: number) {
    return this._http.delete(`${this._photoreportUrl}/${id}`);
  }

  public getCurrentShopsPhotoReport(filters: IFilter) {
    return this._http.get<IPhotoReportArray>(`${this._photoreportUrl}${this._pagesUrl}`, filters);
  }

  public updatePhotoReport(photoReport: IPhotoReportToUpdate): Observable<IPhotoReport> {
    return this._http.post(`${this._photoreportUrl}/${photoReport.id}`, photoReport);
  }

  public createPhotoReport(photoReport: ICreatePhotoReportData): Observable<IPhotoReport> {
    if (photoReport.id) {
      return this._http.post(`${this._photoreportUrl}/${photoReport.id}`, photoReport);
    }
    return this._http.post(`${this._photoreportUrl}`, photoReport);
  }

  public getPhotoReportRootComments(id: number, filters?: IFilter): Observable<ICommentsArray> {
    return this._http.get<ICommentsArray>(
      `${this._photoreportUrl}/${id}${this._rootCommentsUrl}`,
      filters,
    );
  }

  public getImagesComments(id: number, filters?: IFilter): Observable<ICommentsArray> {
    return this._http.get<ICommentsArray>(
      `${this._imagesUrl}/${id}${this._rootCommentsUrl}`,
      filters,
    );
  }

  public addCommentsToImage(id: number, data: Partial<IComment>): Observable<IComment> {
    return this._http.post<IComment>(`${this._imagesUrl}/${id}${this._commentsUrl}`, data);
  }

  public likeImage(id: number) {
    return this._http.post(`${this._imagesUrl}${this._likeUrl}/${id}`);
  }

  public dislikeImage(id: number) {
    return this._http.post(`${this._imagesUrl}${this._dislikeUrl}/${id}`);
  }
}
