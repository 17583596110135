import { HttpErrorResponse } from '@angular/common/http';
import { IShop } from 'src/app/shared/services/user/user.type';
import { IPhotoReport } from '../../../shared/services/photo-reports/photo-reports.type';

export const SHOP_KEY = 'shopData';

export interface IShopState {
  shop: IShop | null;
  shopList: IShop[];
  shopPhotoReportList: IPhotoReport[];
  allShopDataFetched: boolean;
  isLoading: boolean;
  isShopDataListLoading: boolean;
  error: HttpErrorResponse | null;
}

export enum ShopActions {
  getCurrent = 'getCurrent',
  addToFavorite = 'addToFavorite',
  removeFromFavorite = 'removeFromFavorite',
  getShopsList = 'getShopsList',
  clearShopsList = 'clearShopsList',
  getCurrentShopsPhotoReports = 'getCurrentShopsPhotoReports',
  clearShopsPhotoReportsList = 'clearShopsPhotoReportsList',
}
