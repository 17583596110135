import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { FileType } from 'src/app/shared/enums';
import { UploadingFilesActions } from '../models/uploading-files.model';
import { IPoll } from '../../../shared/services/posts/posts.type';

export const initUploadFile = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.InitUploadFiles}`,
  props<{
    localId: number;
    fileType: FileType;
    file: File;
    rotateAngle?: number;
    imageCategory?: string;
  }>(),
);

export const initUploadFileSuccess = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.InitUploadFiles} ${ReducerStatuses.SUCCESS}`,
  props<{
    localId: number;
    localUrl: string;
    localName: string;
    localSize: number;
    file: File;
    fileType: FileType;
    rotateAngle?: number;
    imageCategory?: string;
    uploadingFilesByCategory: any;
  }>(),
);

export const initUploadFileFailed = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.InitUploadFiles} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const uploadFileSuccess = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.UploadFile} ${ReducerStatuses.SUCCESS}`,
  props<{ localId: number; id: number; imageCategory?: string; uploadedFileData?: any }>(),
);

export const uploadFileFailed = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.UploadFile} ${ReducerStatuses.FAILED}`,
  props<{ error: string; localId: number }>(),
);

export const deleteUploadedFilesData = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.DeleteUploadedFilesData}`,
  props<{ localIdToBeDeleted: number; imageCategory?: string; imageId?: number | undefined }>(),
);

export const cleanCreatePhotoReportUploadingImages = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.CleanCreatePhotoReportUploadingImages}`,
);

export const changeUploadedImageRotate = createAction(
  `${ReducerSections.FILE} ${UploadingFilesActions.UploadFile} ${UploadingFilesActions.RotateImage}`,
  props<{ id: number; rotateAngle: number; localId: number; imageCategory?: string }>(),
);

export const changeUploadedImageRotateSuccess = createAction(
  `${ReducerSections.FILE} ${UploadingFilesActions.UploadFile} ${UploadingFilesActions.RotateImage} ${ReducerStatuses.SUCCESS}`,
  props<{ localId: number; id?: number; imageCategory?: string; rotateAngle: number }>(),
);

export const changeUploadedImageRotateFailed = createAction(
  `${ReducerSections.FILE} ${UploadingFilesActions.UploadFile} ${UploadingFilesActions.RotateImage} ${ReducerStatuses.FAILED}`,
  props<{ localId: number; imageCategory?: string; error: string }>(),
);

export const changeUploadingFilesInEditMode = createAction(
  `${ReducerSections.FILE} ${UploadingFilesActions.InitUploadFiles} ${UploadingFilesActions.ChangeUploadingFilesInEditMode}`,
  props<{ photoReportData: any }>(),
);

export const changeUploadingFilesList = createAction(
  `${ReducerSections.FILE} ${UploadingFilesActions.ChangeUploadingFilesList}`,
  props<{ uploadingFiles: any; poll: IPoll | null; isEditMode?: boolean }>(),
);

export const clearUploadedFilesData = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.ClearUploadedFilesData}`,
);

export const updateFileLocalUrl = createAction(
  `${ReducerSections.UPLOADING_FILES} ${UploadingFilesActions.UpdateFileLocalUrl}`,
  props<{ localId: number; localUrl: string; imageCategory?: string }>(),
);
