<a
  class="employee-card no-drag"
  [routerLink]="isTrainingEmployees || utils.isWeb ? null : employeeUrl"
>
  <div
    class="employee-card__avatar"
    [class.image-border]="employee?.avatar?.previewNormal === null"
  >
    <app-image [img]="employee?.avatar?.previewNormal | addBaseUrl"></app-image>
  </div>
  <div class="employee-card__info">
    <div class="employee-card__info-name">
      <a [routerLink]="isTrainingEmployees || !utils.isWeb ? null : employeeUrl" class="nav-link">
        <app-text [textSize]="TextSizes.h4Name" [textType]="TextType.base">
          {{ employee | getFullName }}
          <img src="/assets/images/icons-svg/director.svg" *ngIf="employee?.isDirector" />
        </app-text>
      </a>
    </div>
    <div class="employee-card__info-position">
      <app-text
        [textSize]="TextSizes.mobPost"
        [textType]="TextType.base"
        [text]="employee?.userPosition?.name"
      ></app-text>
    </div>
    <div *ngIf="isShowDepartment" class="employee-card__info-department">
      <app-text
        [textSize]="TextSizes.h5Post"
        [textType]="TextType.base"
        class="employee-card__info-department__text"
        [text]="
          employee?.userDepartment?.type === departmentsType.shop
            ? [employee?.userDepartment?.name, employee?.userDepartment?.city?.name].join(' · ')
            : employee?.userDepartment?.name
        "
      ></app-text>
    </div>
    <div class="employee-card__info-userData user-select-text" *ngIf="!isTrainingEmployees">
      <div class="employee-card__info-userData-item" *ngIf="employee?.phoneNumber">
        <app-svg icon="phone-number"></app-svg>
        <app-text
          class="phone-text"
          [textSize]="TextSizes.mobInput"
          [textType]="TextType.base"
          [text]="employee?.phoneNumber ?? '' | phoneNumber"
          [canCopy]="true"
        ></app-text>
      </div>
      <a
        href="mailto:{{ employee?.email }}"
        class="employee-card__info-userData-item"
        *ngIf="employee?.email"
        (click)="onEmailClick($event)"
      >
        <app-svg icon="email"></app-svg>
        <app-text
          [textSize]="TextSizes.mobInput"
          [textType]="TextType.base"
          [text]="employee?.email"
          [canCopy]="true"
        ></app-text>
      </a>
      <app-contacts [email]="employee?.email" [phone]="employee?.phoneNumber"></app-contacts>
    </div>
  </div>
</a>
