import { first } from 'rxjs';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';
import { ITrainingStageData } from '../../services/training/training.type';
import { CurrentPlatform, TextSizes, TextType } from '../../enums';
import { UtilsService } from '../../services/utils.service';
import { selectCurrentPlatform } from '../../../data/store/selectors/platform.selectors';

@Component({
  selector: 'app-stage-card',
  templateUrl: './stage-card.component.html',
  styleUrls: ['./stage-card.component.scss'],
})
export class StageCardComponent implements OnInit {
  @Input() item?: Partial<ITrainingStageData>;

  @Input() isImageCentered?: boolean;

  public readonly Platform = CurrentPlatform;

  public readonly TextType = TextType;

  public readonly TextSizes = TextSizes;

  public currentPlatform: CurrentPlatform | null = null;

  public isVideoModalOpen: boolean = false;

  constructor(
    private _domSanitizer: DomSanitizer,
    private _utilsService: UtilsService,
    private _store: Store,
  ) {}

  ngOnInit(): void {
    this._store
      .select(selectCurrentPlatform)
      .pipe(first())
      .subscribe((currentPlatform) => (this.currentPlatform = currentPlatform));
  }

  get subtext() {
    if (!this.item?.trainingSection || !this.item?.trainingStage) {
      return '';
    }

    return [this.item.trainingSection.name, this.item.trainingStage.name].join(' · ');
  }

  get videoUrl() {
    if (!this.item?.url) {
      return '';
    }

    if (this.item.url.includes('youtube')) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${this._getCodeFromYouTubeUrl(
          this.item.url,
        )}?rel=0&autoplay=1&loop=0&showinfo=0`,
      );
    } else if (this.item.url.includes('rutube')) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        `https://rutube.ru/play/embed/${this._getCodeFromRutubeUrl(this.item.url)}`,
      );
    } else {
      return '';
    }
  }

  public async openLink() {
    if (this.item?.uploadedFile?.path) {
      this._openInBrowser(this.item.uploadedFile.path);
    } else {
      if (this.videoUrl) {
        this.isVideoModalOpen = true;
      } else if (this.item?.url) {
        this._openInBrowser(this.item.url, false);
      }
    }
  }

  private _openInBrowser = async (urlString: string, transform = true) => {
    if (this.currentPlatform === CurrentPlatform.web) {
      window.open(urlString, '_blank');
    } else {
      if (urlString.includes('pdf') && this.currentPlatform === CurrentPlatform.android) {
        this._utilsService.openPdfModal(urlString, this.item?.name);
      } else {
        await this._utilsService.openUrlInBrowser(urlString, transform);
      }
    }
  };

  private _getCodeFromRutubeUrl = (urlString: string) => {
    const url = new URL(urlString);
    const urlWithoutQuery = urlString.split('?')[0];

    const securityParam = url.searchParams.get('p');

    let code = urlWithoutQuery
      .split('/')
      .filter((p) => !!p)
      .pop();

    if (securityParam) {
      code += `/?p=${securityParam}`;
    }

    return code;
  };

  private _getCodeFromYouTubeUrl = (urlString: string) => {
    return new URL(urlString).searchParams.get('v');
  };
}
