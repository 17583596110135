export enum ReducerSections {
  PLATFORM = '[PLATFORM DATA]',
  POSTS = '[POSTS DATA]',
  PHOTO_REPORT = '[PHOTO REPORT]',
  PINNED_POSTS = '[PINNED POST DATA]',
  CURRENT_POST = '[CURRENT POST DATA]',
  VOTE = '[VOTE DATA]',
  VOTE_PARTICIPANTS = '[VOTE PARTICIPANTS DATA]',
  USERS = '[USERS DATA]',
  FILTERS = '[FILTERS DATA]',
  FILE = '[FILE DATA]',
  LIKE = '[LIKE]',
  SHOP = '[SHOP]',
  UPLOADING_FILES = '[UPLOADING_FILES]',
  DEPARTMENT = '[DEPARTMENT]',
  EMPLOYEES = '[EMPLOYEES]',
  TRAINING = '[TRAINING]',
  NOTIFICATIONS = '[NOTIFICATIONS]',
  ADD_COMMENT = '[ADD_COMMENT]',
  GALLERY = '[GALLERY]',
}

export enum ReducerStatuses {
  INIT = 'init',
  SUCCESS = 'success',
  FAILED = 'failed',
}
