import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FiltersOptionsType, IFiltersOptionsParam } from 'src/app/data/store/models/filters.model';
import {
  selectFiltersState,
  selectFiltersStateHaveOptions,
} from 'src/app/data/store/selectors/filters.selectors';
import { FiltersModalSteps, FiltersOptions } from '../../enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '../../services/utils.service';
import { DepartmentsService } from '../../services/departments/departments.service';

@UntilDestroy()
@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss'],
})
export class FiltersModalComponent implements OnInit {
  @Input() isOnlyFiltersList: boolean = false;

  public step: FiltersModalSteps = FiltersModalSteps.menu;

  readonly filtersModalSteps = FiltersModalSteps;

  public activeFilterList: FiltersOptionsType | null = null;

  public menuOptions: FiltersOptionsType[] = [];

  public initialFilters: IFiltersOptionsParam | null = null;

  public withFavoriteOption: boolean = false;

  public onlyFavorite?: boolean;

  public isCheckboxType: boolean = false;

  public excludeSingleFiltersOptions?: FiltersOptions[];

  public filtersState$ = this._store.select(selectFiltersState);

  public filtersStateHaveOptions$: Observable<boolean> = this._store.select(
    selectFiltersStateHaveOptions(),
  );

  constructor(
    private _store: Store,
    private _utils: UtilsService,
    private _departmentsService: DepartmentsService,
    private _cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.filtersStateHaveOptions$ = this._store.select(
      selectFiltersStateHaveOptions(this.excludeSingleFiltersOptions),
    );

    this.filtersState$.pipe(untilDestroyed(this)).subscribe((filters) => {
      this.initialFilters = Object.assign({}, filters.filtersList) || null;

      if (filters.filtersList) {
        this.menuOptions = this.prepareMenuOptions(filters?.filtersList);
      }
      if (filters.favs !== null) {
        this.onlyFavorite = filters.favs;
      }
    });

    const dataFromUrl = this._utils.getFiltersFromUrl();

    let filters: any = this.initialFilters;
    const loadCondition =
      !Object.values(filters).filter((i: any) => !!i?.params && i.params[0] !== 'ALL').length &&
      !!Object.keys(dataFromUrl).length;

    if (loadCondition) {
      this._utils.updateStateFilterOnInit(false, this.initialFilters);
    }
  }

  public goToFiltersList(option: FiltersOptionsType) {
    this.activeFilterList = option;
    this.step = FiltersModalSteps.filterList;
  }

  public prepareMenuOptions(filtersList?: IFiltersOptionsParam) {
    return Object.entries(filtersList || {})
      .sort((op1, op2) => (op1[1].order && op2[1].order ? op1[1].order - op2[1].order : 0))
      .reduce<string[]>(
        (options, [option]) =>
          !['STAGE', 'POSITIONS'].includes(option) ? [...options, option] : options,
        [],
      ) as FiltersOptionsType[];
  }
}
