import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { FiltersActions, FiltersOptionsType, IFiltersOptionsParam } from '../models/filters.model';
import { ActiveFiltersSelectionsTypes, FiltersOptions } from 'src/app/shared/enums';
import { HttpErrorResponse } from '@angular/common/http';

export const setFiltersOptions = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.SetFiltersOptions}`,
  props<{
    filtersOption: IFiltersOptionsParam | null;
    singleFiltersOption?: IFiltersOptionsParam | null;
    selectionType?: ActiveFiltersSelectionsTypes;
  }>(),
);

export const incrementFilterPage = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.IncrementPage}`,
  props<{
    initialPage?: number;
  }>(),
);

export const searchValueChange = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.SearchValue}`,
  props<{ searchValue: string }>(),
);

export const activeOptionFilterSearchValueChange = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.GetFilterElementsList} ${FiltersActions.SearchValue}`,
  props<{ searchValue: string; activeFilterOption: FiltersOptions }>(),
);

export const changeFavoriteState = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ChangeFavoriteState}`,
  props<{ favs: boolean }>(),
);

export const updateFiltersAction = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.UpdateFilters} ${FiltersActions.InitFilters}`,
  props<{
    activeFilterOption: FiltersOptions | null;
    filtersOptionsParams: IFiltersOptionsParam | null;
  }>(),
);

export const updateFiltersData = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.UpdateFilters}`,
  props<{
    updateData: IFiltersOptionsParam | null;
    favs?: boolean;
  }>(),
);

export const clearFiltersInit = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ClearFilters} ${FiltersActions.InitFilters}`,
  props<{
    activeFilterOption?: FiltersOptionsType;
    id?: number;
    clearType?: string;
    isNeedExternalFiltersMenuCleaning?: boolean;
    excludeFilterOption?: string;
  }>(),
);

export const clearFilters = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ClearFilters}`,
  props<{
    update?: IFiltersOptionsParam | null;
    updateFavs?: boolean | null;
    isNeedExternalFiltersMenuCleaning?: boolean;
  }>(),
);

export const externalFiltersMenuCleaning = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ExternalFiltersMenuCleaning}`,
  props<{
    update?: IFiltersOptionsParam | null;
    updateFavs?: boolean | null;
    isFilterSingle?: boolean;
  }>(),
);

export const applyMenuFiltersAction = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ApplyFilters}`,
);

export const applySingleFilterAction = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ApplySingleFilterAction}`,
  props<{
    data?: IFiltersOptionsParam | null;
    activeFilterOption?: FiltersOptionsType;
  }>(),
);

export const applySingleFilter = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ApplySingleFilterAction} ${ReducerStatuses.SUCCESS}`,
  props<{
    update?: IFiltersOptionsParam | null;
  }>(),
);

export const applySpecificFilters = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ApplySpecificFilters}`,
  props<{
    activeFilterOption: FiltersOptions;
    specificFilterData: string;
  }>(),
);

export const getFilterElementsList = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.GetFilterElementsList} ${ReducerStatuses.INIT}`,
  props<{
    activeFilterOption: FiltersOptions;
  }>(),
);

export const getFilterElementsListSuccess = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.GetFilterElementsList} ${ReducerStatuses.SUCCESS}`,
  props<{
    list: any;
    size?: number;
    resetData?: boolean;
  }>(),
);

export const getFilterElementsListFailed = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.GetFilterElementsList} ${ReducerStatuses.FAILED}`,
  props<{
    error: HttpErrorResponse;
  }>(),
);

export const clearFiltersElementsList = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ClearFilterElementsList}`,
);

export const refreshPaginationData = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.RefreshData}`,
);

export const clearFiltersStore = createAction(
  `${ReducerSections.FILTERS} ${FiltersActions.ClearFiltersStore}`,
);
