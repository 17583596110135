import { ITrainingUserPositionGroup, IUser } from '../../../shared/services/user/user.type';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ICurrentSection,
  ITrainingStage,
  ITrainingSearchResultElement,
  ITrainingLevels,
  IPositionsListItem,
  ITrainingFormData,
} from '../../../shared/services/training/training.type';
import { SectionsListModes } from 'src/app/shared/enums';

export const TRAINING_KEY = 'trainingData';

export interface ITrainingState {
  sectionsList: ITrainingUserPositionGroup[];
  trainingFilesList: ITrainingSearchResultElement[];
  currentMode: SectionsListModes;
  trainingLevels: ITrainingLevels[];
  section: ICurrentSection | null;
  stage: ITrainingStage | null;
  positionsList: IPositionsListItem[];
  formData: Partial<ITrainingFormData> | null;
  trainingEmployees: IUser[];
  trainingXlsReportData: { path: string; name: string } | null;
  currentTrainingEmployeeData: IUser | null;
  allDataFetched: boolean;
  isLoading: boolean;
  isReportLoading: boolean;
  error: HttpErrorResponse | null;
}

export enum TrainingActions {
  getSectionsList = 'getSectionsList',
  clearStageData = 'clearStageData',
  clearSectionData = 'clearSectionData',
  clearSectionsData = 'clearSectionsData',
  searchResultsFiles = 'searchResultsFiles',
  getCurrentSectionInfo = 'getCurrentSectionInfo',
  getCurrentStage = 'getCurrentStage',
  getTrainingLevels = 'getTrainingLevels',
  getPositionsList = 'getPositionsList',
  sendForm = 'sendForm',
  saveDraftForm = 'saveDraftForm',
  changeFormData = 'changeFormData',
  changeFormDataParams = 'changeFormDataParams',
  setCurrentTrainingStage = 'setCurrentTrainingStage',
  getTrainingEmployees = 'getTrainingEmployees',
  getCurrentTrainingEmployeeDataForForm = 'getCurrentTrainingEmployeeDataForForm',
  getTrainingXlsReport = 'getTrainingXlsReport',
}
