<div
  [class]="btnType"
  [class.disabled]="isDisabled"
  [class.button-hover]="!isDisabled && btnType === 'button-base'"
  [style.background-color]="color"
  [class.cursor-pointer]="isCursorPointer && !isDisabled"
>
  <app-spinner [isLoading]="isLoading" class="loader"></app-spinner>
  <ng-container *ngIf="!isLoading">
    <img *ngIf="src" alt="{{ src }}" src="{{ src }}" />
    <p class="button-{{ textSize }}">{{ text }}</p>
  </ng-container>
</div>
