import { IPlatformDataState, PLATFORM_DATA_KEY } from '../models/platform.model';
import { platformDataReducer } from './platform.reducer';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { IPostsDataState, POSTS_DATA_KEY } from '../models/posts.model';
import { postsDataReducer } from './posts.reducer';
import { IUsersState, USERS_KEY } from '../models/users.model';
import { usersReducer } from './users.reducer';
import { DEFAULT_ROUTER_FEATURENAME, routerReducer, RouterReducerState } from '@ngrx/router-store';
import { FILTERS_KEY, IFiltersState } from '../models/filters.model';
import { filtersReducer } from './filters.reducer';
import { IShopState, SHOP_KEY } from '../models/shop.model';
import { shopReducer } from './shop.reducer';
import { IPhotoReportDataState, PHOTO_REPORT_DATA_KEY } from '../models/photo-report.model';
import { photoReportDataReducer } from './photo-report.reducer';
import { IUploadingFilesState, UPLOADING_FILES_KEY } from '../models/uploading-files.model';
import { UploadingFilesReducer } from './uploading-files.reducer';
import { DEPARTMENT_KEY, IDepartmentState } from '../models/department.model';
import { GALLERY_KEY, IGalleryState } from '../models/gallery.model';
import { departmentReducer } from './department.reducer';
import { ITrainingState, TRAINING_KEY } from '../models/training.model';
import { trainingReducer } from './training.reducer';
import { INotificationsState, NOTIFICATIONS_KEY } from '../models/notifications.model';
import { notificationsReducer } from './notifications.reducer';
import { PostsEffects } from '../effects/posts.effects';
import { UsersEffects } from '../effects/users.effects';
import { PlatformEffects } from '../effects/platform.effects';
import { RouterEffects } from '../effects/router.effects';
import { PhotoReportEffects } from '../effects/photo-report.effects';
import { ShopEffects } from '../effects/shop.effects';
import { UploadingFilesEffects } from '../effects/uploading-files.effects';
import { DepartmentEffects } from '../effects/department.effects';
import { TrainingEffects } from '../effects/training.effects';
import { NotificationsEffects } from '../effects/notifiactions.effects';
import { FiltersEffects } from '../effects/filters.effects';
import { galleryReducer } from './gallery.reducer';

export interface State {
  [PLATFORM_DATA_KEY]: IPlatformDataState;
  [POSTS_DATA_KEY]: IPostsDataState;
  [USERS_KEY]: IUsersState;
  [FILTERS_KEY]: IFiltersState;
  [SHOP_KEY]: IShopState;
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
  [PHOTO_REPORT_DATA_KEY]: IPhotoReportDataState;
  [UPLOADING_FILES_KEY]: IUploadingFilesState;
  [DEPARTMENT_KEY]: IDepartmentState;
  [GALLERY_KEY]: IGalleryState;
  [TRAINING_KEY]: ITrainingState;
  [NOTIFICATIONS_KEY]: INotificationsState;
}

export const reducers: ActionReducerMap<State> = {
  [PLATFORM_DATA_KEY]: platformDataReducer,
  [POSTS_DATA_KEY]: postsDataReducer,
  [USERS_KEY]: usersReducer,
  [FILTERS_KEY]: filtersReducer,
  [SHOP_KEY]: shopReducer,
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
  [PHOTO_REPORT_DATA_KEY]: photoReportDataReducer,
  [UPLOADING_FILES_KEY]: UploadingFilesReducer,
  [DEPARTMENT_KEY]: departmentReducer,
  [GALLERY_KEY]: galleryReducer,
  [TRAINING_KEY]: trainingReducer,
  [NOTIFICATIONS_KEY]: notificationsReducer,
};

// Логгер действий со стором, раскомментить при необходимости
// function logger(reducer: ActionReducer<any>): ActionReducer<any> {
//   return (state, action) => {
//     console.log('state', state);
//     console.log('action', action);

//     return reducer(state, action);
//   };
// }

export const metaReducers: MetaReducer<State>[] = [
  /* logger */
];

export const effects = [
  PostsEffects,
  UsersEffects,
  PlatformEffects,
  RouterEffects,
  PhotoReportEffects,
  ShopEffects,
  UploadingFilesEffects,
  DepartmentEffects,
  TrainingEffects,
  NotificationsEffects,
  FiltersEffects,
];
