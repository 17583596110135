<ng-container *ngIf="sectionsList$ | async as sectionsList">
  <ng-container *ngIf="viewMode$ | async as viewMode">
    <app-ion-page-content>
      <ion-refresher
        slot="fixed"
        refresh
        [disabled]="(currentPlatform$ | async) === Platform.web"
        (ionRefresh)="onRefresh($event)"
      >
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="training-sections">
        <app-block>
          <app-search-bar-with-filters
            [currentMode]="viewMode"
            [searchBarText]="'Поиск'"
            [isNeedFiltersButton]="false"
          ></app-search-bar-with-filters>

          <app-pagination-container
            [listName]="listName"
            [clearListAction]="clearAction"
            [isLoading$]="isLoading$"
            [isAllLoaded]="isAllDataFetched$ | async"
            [paginationData]="
              viewMode === SectionsListModes.searchMode
                ? (searchResultsList$ | async)
                : sectionsList
            "
            (emitScrolling)="viewMode === SectionsListModes.searchMode ? getSections() : ''"
          >
            <div *ngIf="viewMode === SectionsListModes.listViewMode">
              <ng-container *ngFor="let section of sectionsList; trackBy: trackByFn">
                <app-section-card
                  [section]="section"
                  [navigation]="getCurrentSectionNavigation(section)"
                ></app-section-card>
              </ng-container>
            </div>

            <div *ngIf="viewMode === SectionsListModes.searchMode">
              <app-stage-card
                *ngFor="let file of searchResultsList$ | async"
                [id]="file.id"
                [item]="file"
              ></app-stage-card>
            </div>
          </app-pagination-container>
        </app-block>
      </div>
    </app-ion-page-content>
  </ng-container>
</ng-container>
