<div
  *ngIf="comment && (platform$ | async) as platform"
  class="comment"
  [attr.data-comment-id]="comment.id"
>
  <div class="comment__image">
    <app-image [img]="comment.author.avatar?.previewSmall | addBaseUrl"></app-image>
  </div>

  <div class="comment__content">
    <a
      class="comment__name nav-link"
      [class.comment__name_active]="isActive"
      (click)="platform !== Platform.web ? onAuthorClick() : undefined"
      [routerLink]="authorUrl"
    >
      <app-text
        [text]="comment.author | getFullName"
        [textSize]="TextSizes.h4Name"
        [textType]="TextType.base"
      ></app-text>
    </a>

    <div class="comment__text">
      <app-text [canCopy]="true" [textType]="TextType.base" [textSize]="TextSizes.h5Post">
        <div class="user-select-text" [innerHTML]="commentText | getInnerHtml"></div>
      </app-text>
    </div>

    <div class="comment__controls" *ngIf="currentUser$ | async as currentUser">
      <app-text
        [text]="comment.created | dateFormat"
        [textSize]="TextSizes.date"
        [textType]="TextType.base"
      ></app-text>

      <button
        *ngIf="
          currentUser.id !== comment.author.id &&
          (platform === Platform.web ? true : replyCommentId ? comment.id !== replyCommentId : true)
        "
        type="button"
        (click)="replyToComment()"
      >
        <app-text
          [text]="comment.id === replyCommentId ? 'Отменить' : 'Ответить'"
          [textType]="TextType.base"
          [textSize]="TextSizes.numberPhone"
        ></app-text>
      </button>

      <button
        *ngIf="
          currentUser.id === comment.author.id &&
          (platform === Platform.web ? true : editCommentId ? comment.id !== editCommentId : true)
        "
        type="button"
        (click)="editComment()"
      >
        <app-text
          [text]="comment.id === editCommentId ? 'Отменить' : 'Редактировать'"
          [textType]="TextType.base"
          [textSize]="TextSizes.numberPhone"
        ></app-text>
      </button>
    </div>
  </div>
</div>

<div class="comment__replies" [class.comment__replies_nested]="replyLevel === 0">
  <app-post-comment
    *ngFor="let comment of replyComments; trackBy: trackRepliesBy"
    [id]="comment.id"
    [comment]="comment"
    [replyLevel]="replyLevel + 1"
    [newComment]="newComment"
    [editCommentId]="editCommentId"
    [changeCommentEditMode]="changeCommentEditMode"
  ></app-post-comment>
</div>
