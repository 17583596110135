import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { TrainingService } from '../../../shared/services/training/training.service';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  changeCurrentTrainingStage,
  clearSectionData,
  clearTrainingData,
  getAllNecessaryTrainingFormDataInit,
  getCurrentSectionInfo,
  getCurrentSectionInfoFailed,
  getCurrentSectionInfoSuccess,
  getCurrentStage,
  getCurrentStageFailed,
  getCurrentStageSuccess,
  getCurrentTrainingEmployeeDataForFormFailed,
  getCurrentTrainingEmployeeDataForFormSuccess,
  getSearchResultsFiles,
  getSearchResultsFilesFailed,
  getSearchResultsFilesSuccess,
  getSectionsList,
  getSectionsListFailed,
  getSectionsListSuccess,
  getTrainingEmployees,
  getTrainingEmployeesFailed,
  getTrainingEmployeesSuccess,
  getTrainingLevelsFailed,
  getTrainingLevelsSuccess,
  getTrainingXlsReportFailed,
  getTrainingXlsReportInit,
  getTrainingXlsReportSuccess,
  sendForm,
  sendFormFailed,
  sendFormSuccess,
  setCurrentTrainingStageData,
} from '../actions/training.action';
import { selectFiltersMenuState, selectFiltersState } from '../selectors/filters.selectors';
import { Store } from '@ngrx/store';
import { UtilsService } from '../../../shared/services/utils.service';
import {
  FiltersOptions,
  PageType,
  SectionsListModes,
  TrainingSegmentTypes,
} from '../../../shared/enums';
import { ITrainingLevels } from '../../../shared/services/training/training.type';
import { selectFormData, selectTrainingLevels } from '../selectors/training.selectors';
import { IFilter } from 'src/app/shared/interfaces';
import * as moment from 'moment';
import { selectCurrentUser } from '../selectors/users.selectors';
import { IAppliedFiltersMenu, IPaginationFilter } from '../models/filters.model';
import { clearFiltersStore } from '../actions/filters.actions';

@Injectable()
export class TrainingEffects {
  constructor(
    private _actions$: Actions,
    private _trainingService: TrainingService,
    private _store: Store,
    private _utils: UtilsService,
  ) {}

  public getSections$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getSectionsList),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([, filtersState]) => {
        if (filtersState.query && filtersState.query?.length) {
          const filters = {
            page: filtersState.page,
            size: filtersState.size,
            query: filtersState.query,
          };
          return of(getSearchResultsFiles({ filters }));
        }
        return this._trainingService.getSections().pipe(
          map((sectionsList) => {
            return getSectionsListSuccess({
              sectionsList: sectionsList,
            });
          }),
          catchError((error) => {
            return of(getSectionsListFailed({ error }));
          }),
        );
      }),
    );
  });

  public getSearchResultsFiles$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getSearchResultsFiles),
      switchMap((action) => {
        return this._trainingService.getTrainingFilesList(action.filters).pipe(
          map((filesList) => {
            return getSearchResultsFilesSuccess({
              trainingFilesList: filesList.elements,
              currentMode: SectionsListModes.searchMode,
              resetData: action.filters.page === 0,
            });
          }),
          catchError((error) => {
            return of(getSearchResultsFilesFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentSectionInfo$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentSectionInfo),
      switchMap((action) => {
        return this._trainingService.getCurrentSectionInfo(action.sectionId).pipe(
          map((section) => {
            return getCurrentSectionInfoSuccess({
              section: section,
            });
          }),
          catchError((error) => {
            return of(getCurrentSectionInfoFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentStage$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentStage),
      switchMap((action) => {
        return this._trainingService.getCurrentStage(action.stageId).pipe(
          map((stage) => {
            return getCurrentStageSuccess({
              stage: stage,
            });
          }),
          catchError((error) => {
            return of(getCurrentStageFailed({ error }));
          }),
        );
      }),
    );
  });

  public getTrainingLevels$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentTrainingEmployeeDataForFormSuccess, sendFormSuccess),
      switchMap((action) => {
        return this._trainingService.getTrainerLevels(action.userId).pipe(
          map((trainingLevels: ITrainingLevels[]) => {
            let currentTrainingLevelIndex: number = action.currentStage - 1;

            const currentLevelData: ITrainingLevels = trainingLevels[currentTrainingLevelIndex];

            const form = this._utils.createTrainingFormData(currentLevelData);

            return getTrainingLevelsSuccess({
              trainingLevels,
              formData: {
                ...form,
                id: currentLevelData.id,
                user: { id: action.userId },
                userPositionGroup: currentLevelData.userPositionGroup,
                userTraining: currentLevelData.userTraining,
              },
            });
          }),
          catchError((error) => {
            return of(getTrainingLevelsFailed({ error }));
          }),
        );
      }),
    );
  });

  public setChosenTrainingStage$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(changeCurrentTrainingStage),
      concatLatestFrom(() => this._store.select(selectTrainingLevels)),
      switchMap(([action, trainingLevels]) => {
        if (action.chosenStage) {
          const currentTrainingLevel: number = action.chosenStage - 1;

          const currentLevelData: ITrainingLevels = trainingLevels[currentTrainingLevel];

          const form = this._utils.createTrainingFormData(
            currentLevelData,
            Boolean(action.chosenStage),
          );

          return of(
            setCurrentTrainingStageData({
              formData: {
                ...form,
                id: currentLevelData.id,
                userPositionGroup: currentLevelData.userPositionGroup,
                userTraining: currentLevelData.userTraining,
              },
            }),
          );
        }
        return of();
      }),
    );
  });

  public getTrainingEmployeesList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getTrainingEmployees),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectFiltersMenuState),
      ]),
      switchMap(([action, filtersState, filtersMenu]) => {
        const filters = this.prepareTrainingFilters(filtersState, filtersMenu, action.pageType);

        filters.onlyUntrained = action.onlyUntrained || filters.onlyUntrained;

        filters.isLastWeekOfEducation =
          action.isLastWeekOfEducation || filters.isLastWeekOfEducation;

        const getUrlEntity = (itemName: keyof IFilter, type: FiltersOptions) =>
          this._utils.getUrlEntity(filters, itemName, type, filtersMenu.filters?.filtersMenuParams);

        const filterToUrl = {
          onlyUntrained:
            filters.onlyUntrained && action.pageType !== PageType.analytics
              ? { value: filters.onlyUntrained, type: FiltersOptions.segment }
              : undefined,
          regionId: getUrlEntity('regionId', FiltersOptions.regions),
          cityId: getUrlEntity('cityId', FiltersOptions.cities),
          shopId: getUrlEntity('shopId', FiltersOptions.shops),
          trainerId: getUrlEntity('trainerId', FiltersOptions.trainer),
          trainingRoleType: getUrlEntity('trainingRoleType', FiltersOptions.trainerRole),
          stage: getUrlEntity('stage', FiltersOptions.stage),
          positionGroupId: getUrlEntity('positionGroupId', FiltersOptions.positions),
          endTraining: filters.endTraining
            ? { value: filters.endTraining, type: FiltersOptions.endTraining }
            : undefined,
          isLastWeekOfEducation: filters.isLastWeekOfEducation
            ? { value: filters.isLastWeekOfEducation, type: null }
            : undefined,
        };

        this._utils.syncFiltersRouteQuery(filterToUrl);

        return this._trainingService.getTrainingEmployeesList(filters).pipe(
          map((list) => {
            return getTrainingEmployeesSuccess({
              list,
              resetData: filters.page === 0,
              size: filtersState.size,
            });
          }),
          catchError((error) => {
            return of(getTrainingEmployeesFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentTrainingEmployeeDetailsForForm$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getAllNecessaryTrainingFormDataInit, sendFormSuccess),
      switchMap((action) => {
        const localFilters = {
          userIds: [action.userId],
        };
        return this._trainingService.getTrainingEmployeesList(localFilters).pipe(
          map((user) => {
            return getCurrentTrainingEmployeeDataForFormSuccess({
              currentEmployeeData: user[0],
              userId: user[0].id,
              currentStage:
                (action as { currentStage: number }).currentStage || user[0].userTraining.length,
            });
          }),
          catchError((error) => {
            return of(getCurrentTrainingEmployeeDataForFormFailed({ error }));
          }),
        );
      }),
    );
  });

  public sendForm$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(sendForm),
      concatLatestFrom(() => [
        this._store.select(selectFormData),
        this._store.select(selectCurrentUser),
      ]),
      switchMap(([action, formData, currentUser]) => {
        const data = {
          ...formData,
          userTraining: {
            ...formData?.userTraining,
            startTraining: moment.utc(action.startDate) as unknown as string,
            endTraining: moment.utc(action.endDate) as unknown as string,
            userPositions: action.userPositions,
            developmentTalk: action.developmentTalk,
          },
        };

        let sendingRequest$ = this._trainingService.saveDraftForm(data);
        if (!action.isDraft) {
          sendingRequest$ = this._trainingService.sendForm(
            {
              ...data,
              userTraining: {
                ...data.userTraining,
                trainer: (formData?.userTraining && formData.userTraining.trainer) ?? {
                  id: currentUser?.id,
                },
              },
            },
            formData?.user?.id,
          );
        }

        return sendingRequest$.pipe(
          map(() => {
            return sendFormSuccess({
              userId: formData?.user?.id!,
              isDraft: action.isDraft,
              currentStage: action.currentStage,
            });
          }),
          catchError((error) => {
            return of(sendFormFailed({ error }));
          }),
        );
      }),
    );
  });

  public getTrainingXlsReport$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getTrainingXlsReportInit),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectFiltersMenuState),
      ]),
      switchMap(([, filtersState, filtersMenu]) => {
        const { page, size, ...filters } = this.prepareTrainingFilters(
          filtersState,
          filtersMenu,
          PageType.analytics,
        );

        const formData = new FormData();
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            formData.append(key, value.toString());
          }
        });

        return this._trainingService.getTrainingXlsReport(formData).pipe(
          map((report) => {
            return getTrainingXlsReportSuccess({
              path: report.path,
              name: report.name,
            });
          }),
          catchError((error) => {
            return of(getTrainingXlsReportFailed({ error }));
          }),
        );
      }),
    );
  });

  public clearSectionData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(clearSectionData, clearTrainingData),
      switchMap(() => of(clearFiltersStore())),
    );
  });

  private prepareTrainingFilters = (
    filtersState: IPaginationFilter,
    filtersMenu: {
      filters: IAppliedFiltersMenu | null;
      query: string | null;
    },
    pageType: string,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): IFilter => {
    const filtersFromUrl = this._utils.getFiltersFromUrl();

    let filters: IFilter = {
      page: filtersState.page,
      size: filtersState.size,
      query: filtersState.query,
      regionId: filtersFromUrl?.regionId?.value || null,
      cityId: filtersFromUrl?.cityId?.value || null,
      shopId: filtersFromUrl?.shopId?.value || null,
      trainerId: filtersFromUrl?.trainerId?.value || null,
      trainingRoleType: filtersFromUrl?.trainingRoleType?.value || null,
      stage: filtersFromUrl?.stage?.value || null,
      positionGroupId: filtersFromUrl?.positionGroupId?.value || null,
      endTraining: filtersFromUrl?.endTraining?.value || null,
      isLastWeekOfEducation: filtersFromUrl?.isLastWeekOfEducation?.value || null,
      onlyUntrained: filtersFromUrl?.onlyUntrained?.value || null,
      customSort: filtersFromUrl?.onlyUntrained?.value ? 'date' : null,
    };

    if (pageType === PageType.analytics) {
      filters = {
        ...filters,
        onlyUntrained: true,
        customSort: 'date',
      };
    } else if (
      filtersMenu.filters?.filtersMenuParams?.[FiltersOptions.segment]?.params?.includes(
        TrainingSegmentTypes.all,
      )
    ) {
      filters = {
        ...filters,
        onlyUntrained: false,
        customSort: null,
      };
    } else if (
      filtersMenu.filters?.filtersMenuParams?.[FiltersOptions.segment]?.params?.includes(
        TrainingSegmentTypes.urgent,
      )
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      filters = {
        ...filters,
        onlyUntrained: true,
        customSort: 'date',
      };
    }

    if (filtersMenu.filters?.filtersMenuParams) {
      filters = {
        ...filters,
        regionId:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.regions,
          }) || null,
        cityId:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.cities,
          }) || null,
        shopId:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.shops,
          }) || null,
        trainerId:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.trainer,
          }) || null,
        trainingRoleType:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.trainerRole,
          }) || null,
        stage:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.stage,
          }) || null,
        positionGroupId:
          this._utils.setRequestFilterParams({
            filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
            filterOption: FiltersOptions.positions,
          }) || null,
        endTraining:
          filtersMenu.filters.filtersMenuParams[FiltersOptions.endTraining]?.params?.[0] || null,
        isLastWeekOfEducation:
          pageType === PageType.employeeDevelopment
            ? !filtersMenu.filters?.filtersMenuParams?.[FiltersOptions.segment]?.params?.includes(
                TrainingSegmentTypes.all,
              )
            : null,
      };
    }

    return filters;
  };
}
