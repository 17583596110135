import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IUploadingFile } from 'src/app/shared/services/posts/posts.type';

export const UPLOADING_FILES_KEY = 'uploadingFiles';

export interface ICroppedImageData {
  file: File;
  position: ImageCroppedEvent['cropperPosition'];
  rotateAngle?: number;
}

export interface IUploadingFilesState {
  uploadingFiles: IUploadingFile[] | [];
  uploadingFilesOfEditedPost: IUploadingFile[] | [];
  uploadingFilesByCategory: { [key: string]: IUploadingFile[] } | null;
  postEditMode: boolean | undefined;
  localId: number;
  error: string | null;
  isLoading: boolean;
}

export enum UploadingFilesActions {
  InitUploadFiles = 'InitUploadFiles',
  UploadFile = 'UploadFile',
  CleanUploadedFilesData = 'CleanUploadedFilesData',
  DeleteUploadedFilesData = 'DeleteUploadedFilesData',
  CleanCreatePhotoReportUploadingImages = 'CleanCreatePhotoReportUploadingImages',
  RotateImage = 'RotateImage',
  ChangeUploadingFilesInEditMode = 'ChangeUploadingFilesInEditMode',
  ChangeUploadingFilesList = 'ChangeUploadingFilesList',
  ClearUploadedFilesData = 'ClearUploadedFilesData',
  UpdateFileLocalUrl = 'UpdateFileLocalUrl',
}
