<div
  class="menu-block__content dropdown-web"
  [class.pinned-post-menu]="isPinned"
  *ngIf="currentUser$ | async as currentUser"
>
  <div
    *ngIf="
      (dataType === DataTypesList.photoReport && currentUser.userDepartment.id === shopId) ||
      currentUser.id === postUserId
    "
    class="menu-block__content__item"
    (click)="dataType === DataTypesList.photoReport ? changeEditMode(dataType) : editPostData()"
  >
    <app-text
      [textSize]="TextSizes.h2Menu"
      [textType]="TextType.base"
      [text]="'Редактировать'"
    ></app-text>
  </div>
  <div
    *ngIf="
      dataType !== DataTypesList.photoReport &&
      currentUser.userRole === userRoles.moderator &&
      currentUser.id === postUserId
    "
    class="menu-block__content__item"
    (click)="pinPost()"
  >
    <app-text
      [textSize]="TextSizes.h2Menu"
      [textType]="TextType.base"
      [text]="isPinned ? 'Открепить из ленты' : 'Закрепить в ленте'"
    ></app-text>
  </div>
  <ng-container *ngIf="favouritesListToBeUpdated$ | async as favouritesListToBeUpdated">
    <div
      *ngIf="dataType === DataTypesList.image"
      class="menu-block__content__item"
      [class.btn-disabled]="(isLoadingFavorite$ | async) === true"
      (click)="changeFavoritesStatus(favouritesListToBeUpdated)"
    >
      <app-text
        [textSize]="TextSizes.h2Menu"
        [textType]="TextType.base"
        [text]="
          fileId &&
          ((favouritesListToBeUpdated.includes(fileId) && isFavourite === true) ||
            (!favouritesListToBeUpdated.includes(fileId) && isFavourite === false))
            ? 'Добавить в избранное'
            : 'Удалить из избранного'
        "
      ></app-text>
    </div>
  </ng-container>
  <div
    *ngIf="canDeletePost"
    class="menu-block__content__item"
    (click)="getAlertDeleteModal?.(deletePost, dataType === DataTypesList.post)"
  >
    <app-text [textSize]="TextSizes.h2Menu" [textType]="TextType.base" text="Удалить"></app-text>
  </div>
  <div *ngIf="canReportPost" class="menu-block__content__item" (click)="reportPost()">
    <app-text
      [textSize]="TextSizes.h2Menu"
      [textType]="TextType.base"
      text="Пожаловаться"
    ></app-text>
  </div>
</div>
