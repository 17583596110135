<div class="header-wrapper">
  <div class="header">
    <a routerLink="">
      <img [src]="'assets/images/icons-svg/befree-social-logo-words.svg'" alt="logo" />
    </a>

    <div class="header__content">
      <app-dropdown-container (emitDropdownClose)="isNotificationsOpen = false">
        <div
          class="header__content-notifications"
          (click)="isNotificationsOpen = !isNotificationsOpen"
        >
          <img
            [src]="'assets/images/icons-svg/footer-menu/notifications.svg'"
            alt="notifications"
          />
          <app-notifications-counter></app-notifications-counter>
        </div>
        <div *ngIf="isNotificationsOpen">
          <app-notifications-modal
            (emitOpenNotificationPage)="isNotificationsOpen = false"
          ></app-notifications-modal>
        </div>
      </app-dropdown-container>

      <ng-container *ngIf="currentUser$ | async as currentUser">
        <app-dropdown-container (emitDropdownClose)="isDropdownOpen = false">
          <div class="header__content-user" (click)="isDropdownOpen = !isDropdownOpen">
            <div class="header__content-user-avatar">
              <app-image [img]="currentUser?.avatar?.previewSmall ?? ''"></app-image>
            </div>
            <div class="header__content-user-arrow">
              <app-image
                [img]="'assets/images/icons-svg/arrow-right.svg'"
                [rotateAngle]="90"
              ></app-image>
            </div>
          </div>
          <div class="header__dropdown" *ngIf="isDropdownOpen">
            <div
              class="header__dropdown-data"
              (click)="utils.goToEmployeePage(currentUser.id); isDropdownOpen = false"
            >
              <app-user-data
                [imgSize]="UserDataImgSize.s"
                [imgSrc]="currentUser?.avatar?.previewSmall ?? ''"
                [fullName]="currentUser | getFullName"
                [position]="currentUser.userPosition.name"
              ></app-user-data>
              <app-image [img]="'assets/images/icons-svg/arrow-right.svg'"></app-image>
            </div>
            <div class="header__dropdown-menu" (click)="logout()">
              <app-image [img]="'assets/images/icons-svg/logout.svg'"></app-image>
              <app-text
                [textSize]="TextSizes.h2Menu"
                [textType]="TextType.base"
                [text]="'Выйти'"
              ></app-text>
            </div>
          </div>
        </app-dropdown-container>
      </ng-container>
    </div>
  </div>
</div>
