import { Component, Input } from '@angular/core';
import { befreeUrlValues } from 'src/app/shared/constants';
import { DepartmentsType, TextSizes, TextType } from 'src/app/shared/enums';
import { IUser } from 'src/app/shared/services/user/user.type';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss'],
})
export class EmployeeCardComponent {
  @Input() employee: IUser | null = null;

  @Input() isShowDepartment: boolean = false;

  @Input() isTrainingEmployees: boolean = false;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly departmentsType = DepartmentsType;

  constructor(public utils: UtilsService) {}

  get employeeUrl() {
    return ['/', befreeUrlValues.dashboard, befreeUrlValues.employees, this.employee!.id];
  }

  public onEmailClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
