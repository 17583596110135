import { Component } from '@angular/core';
import { App } from '@capacitor/app';
import { Store } from '@ngrx/store';
import { getAppUpdateData } from './data/store/actions/platform.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Befree social';

  constructor(private _store: Store) {
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        this._store.dispatch(getAppUpdateData());
      }
    });
  }
}
