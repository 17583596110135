import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { from, map, Observable, take } from 'rxjs';
import { selectFiltersMenuHaveOptions } from 'src/app/data/store/selectors/filters.selectors';
import {
  filtersIcon,
  filtersIconWithOptions,
  ignoredKeysInModalFilters,
} from 'src/app/shared/constants';
import {
  Buttons,
  ButtonTextSizes,
  CurrentPlatform,
  FiltersButtonType,
  FiltersOptions,
  TextSizes,
  TextType,
} from 'src/app/shared/enums';
import { FiltersModalComponent } from '../../filters-modal.component';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-filters-button',
  templateUrl: './filters-button.component.html',
  styleUrls: ['./filters-button.component.scss'],
})
export class FiltersButtonComponent implements OnInit {
  @Input() filtersButtonType = FiltersButtonType.icon;

  @Input() buttonText: string = '';

  @Input() isButtonDisabled: boolean = false;

  @Input() dataForFilters: {
    withFavoriteOption?: boolean;
    isCheckboxType?: boolean;
    isOnlyFiltersList?: boolean;
    activeFilterList?: FiltersOptions;
  } | null = null;

  @Input() isFilterHaveOption$?: Observable<boolean>;

  @Input() excludeSingleFiltersOptions?: FiltersOptions[];

  public readonly TextSize = TextSizes;

  public readonly TextType = TextType;

  public readonly ButtonTextSizes = ButtonTextSizes;

  public readonly Buttons = Buttons;

  public readonly buttonType = FiltersButtonType;

  public readonly filtersIcon = filtersIcon;

  public readonly filtersIconWithOptions = filtersIconWithOptions;

  public filtersMenuHaveOptions$?: Observable<boolean>;

  public filtersMenuHaveOptionsInUrl = false;

  private _currentPlatform: CurrentPlatform | null = null;

  constructor(
    private _store: Store,
    private _modalController: ModalController,
    private _route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.filtersMenuHaveOptions$ = this._store.select(
      selectFiltersMenuHaveOptions(this.excludeSingleFiltersOptions),
    );

    this._route.queryParams.subscribe((params) => {
      this.filtersMenuHaveOptionsInUrl = params['filter']
        ? !!Object.keys(JSON.parse(params['filter'])).filter(
            (i) => !ignoredKeysInModalFilters.includes(i),
          )?.length
        : false;
    });

    this._store
      .select(selectCurrentPlatform)
      .pipe(take(1))
      .subscribe((platform) => {
        this._currentPlatform = platform;
      });
  }

  public openModalFilters() {
    const modal$ = from(
      this._modalController.create({
        component: FiltersModalComponent,
        componentProps: {
          withFavoriteOption: this.dataForFilters?.withFavoriteOption ?? false,
          isCheckboxType: this.dataForFilters?.isCheckboxType ?? false,
          isOnlyFiltersList: this.dataForFilters?.isOnlyFiltersList ?? false,
          activeFilterList: this.dataForFilters?.activeFilterList ?? null,
          excludeSingleFiltersOptions: this.excludeSingleFiltersOptions,
        },
        breakpoints: [0, 1],
        initialBreakpoint: this._currentPlatform === CurrentPlatform.web ? undefined : 1,
        mode: CurrentPlatform.ios,
        animated: true,
        canDismiss: this._currentPlatform === CurrentPlatform.web,
        backdropDismiss: false,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();
    modal$
      .pipe(
        untilDestroyed(this),
        map((modal) => modal.onWillDismiss()),
      )
      .subscribe();
  }
}
