import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageType } from '../../../../../../shared/enums';
import { befreeUrlValues } from '../../../../../../shared/constants';

@Component({
  selector: 'app-training-analytics',
  templateUrl: './training-analytics.component.html',
  styleUrls: ['../employee-development/employee-development.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingAnalyticsComponent {
  public readonly PageType = PageType;

  public listName = befreeUrlValues.analytics;
}
