import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { from, map, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import {
  closePostModal,
  createNewPost,
  createPollData,
  editPost,
} from 'src/app/data/store/actions/posts.actions';
import {
  selectCreatePoll,
  selectCurrentPost,
  selectEditedPost,
  selectIsPostModalOpened,
} from 'src/app/data/store/selectors/posts.selectors';
import {
  selectLocalId,
  selectUploadingFiles,
} from 'src/app/data/store/selectors/uploading-files.selectors';
import { selectCurrentUser } from 'src/app/data/store/selectors/users.selectors';
import {
  availableImageFormats,
  errorNotificationMessages,
  postModalMenuOptions,
} from 'src/app/shared/constants';
import { IPoll, IPost, IUploadingFile } from 'src/app/shared/services/posts/posts.type';
import { CreatePollComponent } from './create-poll/create-poll.component';
import { ActionSheetController, ModalController } from '@ionic/angular';
import {
  Buttons,
  ButtonTextSizes,
  CurrentPlatform,
  FileType,
  ScrollToType,
  TextSizes,
  TextType,
  UserRoles,
} from 'src/app/shared/enums';
import { IPostData } from '../../../../shared/services/posts/posts.type';
import { environment } from '../../../../../environments/environment';
import { selectCurrentPlatform } from '../../../../data/store/selectors/platform.selectors';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { clearUploadedFilesData } from '../../../../data/store/actions/uploading-files.actions';
import { ScrollToService } from '../../../../shared/services/scroll-to/scroll-to.service';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { PluginListenerHandle } from '@capacitor/core';
import isImage from 'src/app/shared/utils/isImage';

@UntilDestroy()
@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
})
export class CreatePostComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isMobModalOpen: boolean | null = false;

  @ViewChild('container') container?: ElementRef<HTMLDivElement>;

  @ViewChild('ionTextArea') ionTextArea?: { el: HTMLInputElement; setFocus: () => void };

  @ViewChild('imagesContainer') imagesContainer?: ElementRef;

  public readonly baseHref: string = environment.befreeUrl;

  public readonly CurrentPlatform = CurrentPlatform;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly Buttons = Buttons;

  public readonly ButtonTextSizes = ButtonTextSizes;

  public readonly userRoles = UserRoles;

  public readonly postModalMenuOptions = postModalMenuOptions;

  public readonly FileType = FileType;

  public readonly fileLimit = 12;

  public readonly availableImageFormats = availableImageFormats;

  public isCreatePostOpen: boolean = false;

  public isCreatePollOpen: boolean = false;

  public showLeftArrow: boolean = false;

  public showRightArrow: boolean = true;

  public isPinned: boolean = false;

  private initIsPinned?: boolean;

  private keyboardHeight = 0;

  private platform: CurrentPlatform | null = null;

  public chosenFiles: IUploadingFile[] = [];

  public currentPlatform$: Observable<CurrentPlatform | null> | undefined =
    this._store.select(selectCurrentPlatform);

  public currentUser$ = this._store.select(selectCurrentUser);

  public isPostModalOpened$: Observable<boolean> = this._store.select(selectIsPostModalOpened);

  public poll$: Observable<IPoll | null> = this._store.select(selectCreatePoll);

  public postData$: Observable<IPost | null> = this._store.select(selectCurrentPost);

  public newPostFormGroup: FormGroup = this._fb.group({
    msg: [''],
    poll: [null],
  });

  public isEditMode: boolean = false;

  public postId: number | undefined;

  private _isFormHasValue: boolean = false;

  private _localId: number = 0;

  keyboardWillShowListener: PluginListenerHandle | undefined;

  keyboardWillHideListener: PluginListenerHandle | undefined;

  constructor(
    public utils: UtilsService,
    private _store: Store,
    private _fb: FormBuilder,
    private _modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private _cdr: ChangeDetectorRef,
    private _scrollToService: ScrollToService,
  ) {}

  ngOnInit(): void {
    if (this.isEditMode) {
      this._store
        .select(selectEditedPost)
        .pipe(untilDestroyed(this))
        .subscribe((currentPost: IPost | null) => {
          this.newPostFormGroup.controls['poll'].patchValue(currentPost?.poll);
          this.newPostFormGroup.controls['msg'].patchValue(currentPost?.msg);
          this.isPinned = currentPost?.pinned ?? false;
          this.initIsPinned = currentPost?.pinned;
        });
    }
    this._store
      .select(selectLocalId)
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => {
        this._localId = id;
      });

    this.poll$.pipe(untilDestroyed(this)).subscribe((poll) => {
      if (poll) {
        this.newPostFormGroup.controls['poll'].patchValue(poll);

        this._isFormHasValue = this._checkFormValues();
      }
    });
    this.currentPlatform$?.subscribe((platform) => {
      this.platform = platform;
      if (platform !== this.CurrentPlatform.web) {
        this.keyboardWillShowListener = Keyboard.addListener(
          'keyboardWillShow',
          this.onKeyboardWillShow.bind(this),
        );
        this.keyboardWillHideListener = Keyboard.addListener(
          'keyboardWillHide',
          this.onKeyboardWillHide.bind(this),
        );
      }
    });

    this._store
      .select(selectUploadingFiles)
      .pipe(untilDestroyed(this))
      .subscribe(([uploadingFiles, editUploadingFiles]) => {
        this.chosenFiles = this.isEditMode ? editUploadingFiles : uploadingFiles;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.ionTextArea?.el.removeEventListener('keypress', this.onKeyPress);
    this._store.dispatch(closePostModal());
    this.deletePoll();
    this.keyboardWillShowListener?.remove();
    this.keyboardWillHideListener?.remove();
  }

  ngAfterViewInit(): void {
    this.ionTextArea?.el.addEventListener('keypress', this.onKeyPress);
    if (this.platform !== CurrentPlatform.web) {
      setTimeout(() => {
        this.ionTextArea?.setFocus();
      }, 200);
    }
  }

  get form() {
    return this.newPostFormGroup.controls;
  }

  public closeForm(chosenImages?: IUploadingFile[]) {
    this._isFormHasValue = this._checkFormValues();

    if (!this._isFormHasValue && !chosenImages?.length) {
      this.isCreatePostOpen = false;
    }
  }

  public confirmPostCreation(departmentId: number, chosenFiles?: IUploadingFile[]) {
    if (!this.checkCanToSavePost(chosenFiles)) return;

    let post: IPostData = {
      department: { id: departmentId },
      msg: this.newPostFormGroup.value.msg,
      poll: this.newPostFormGroup.value.poll,
    };

    if (this.isEditMode) {
      post = { ...post, id: this.postId };
    }

    if (chosenFiles) {
      const files = chosenFiles
        .filter((file) => file.fileType === FileType.file)
        .map((file) => {
          return { id: file.id };
        });
      const images = chosenFiles
        .filter((file) => file.fileType === FileType.image)
        .map((file) => {
          return { id: file.id };
        });
      post = {
        ...post,
        images: images,
        file: files[0],
      };
    }

    if (this.isEditMode && this.postId) {
      const isPinned = this.initIsPinned !== this.isPinned ? this.isPinned : undefined;
      this._store.dispatch(editPost({ post: post, isPinned }));
      this._store.dispatch(closePostModal());
      this.utils.dismissModal();
      if (isPinned) this._scrollToService.scrollToEvent({ scrollTo: ScrollToType.top });
    } else {
      this._store.dispatch(createNewPost({ post: post, isPinned: this.isPinned }));
    }
    this.newPostFormGroup.patchValue({ msg: '', poll: null });
    this.closeForm();
    this.isPinned = false;
  }

  public openPollBottomSheet() {
    if (this.isEditMode) return;

    const modal$ = from(
      this._modalCtrl.create({
        component: CreatePollComponent,
        breakpoints: [0, 0.9],
        initialBreakpoint: 0.9,
        backdropDismiss: false,
        componentProps: {
          isMob: this.isMobModalOpen,
        },
        canDismiss: (isForceDismiss) => this._canDismiss(isForceDismiss),
        mode: CurrentPlatform.ios,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();

    modal$
      .pipe(
        untilDestroyed(this),
        map((modal) => modal.onWillDismiss()),
      )
      .subscribe();
  }

  public openPollWebModal() {
    if (this.isEditMode) return;

    this.isCreatePollOpen = true;

    const modal$ = from(
      this._modalCtrl.create({
        component: CreatePollComponent,
        componentProps: {
          isMob: this.isMobModalOpen,
        },
        backdropDismiss: false,
        mode: CurrentPlatform.ios,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();

    modal$
      .pipe(
        untilDestroyed(this),
        mergeMap((modal) => {
          return from(modal.onWillDismiss()).pipe(
            switchMap((v) => {
              this.isCreatePollOpen = false;
              return of(v);
            }),
          );
        }),
      )
      .subscribe();
  }

  public deletePoll() {
    this._store.dispatch(createPollData({ poll: null }));
    this.newPostFormGroup.patchValue({ poll: null });
  }

  public cancelPostCreation() {
    this._store.dispatch(closePostModal());
    if (this.isEditMode) {
      this.utils.dismissModal();
      this._store.dispatch(clearUploadedFilesData());
    }
  }

  public error: { message: string; formats: string } | null = null;

  public chooseImage(chosenFiles: IUploadingFile[]) {
    this.error = null;
    if (chosenFiles.length >= this.fileLimit) {
      return;
    }

    this.utils.openImagePickerModal(this._localId, undefined, {
      chosenFilesLength: chosenFiles.length,
      limitLength: this.fileLimit,
    });
  }

  public allowedFormatsForFile = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/plain',
    'video/mp4',
    'video/x-matroska',
    '.ts',
    '.heic',
    '.heif',
    '.mkv',
    'video/vnd.dlna.mpeg-tts',
    'video/avi',
    'video/mov',
    'video/mkv',
    'video/quicktime',
    'video/mp2ts',
    '',
  ];

  public handleUploadFile(event: any, chosenFiles: IUploadingFile[], allowedFiles?: string[]) {
    this.error = null;
    const { files: existingFiles, images: existingImages } = this.categorizeFiles(chosenFiles);

    if (this.isInvalidNewFile(event, existingFiles, existingImages)) return;

    let loadingFilesLength = event.target.files.length;
    let currentFileLimit = this.fileLimit - chosenFiles.length;

    if (loadingFilesLength > currentFileLimit) {
      this.utils.openNotification(errorNotificationMessages.postPhotoLimit(currentFileLimit), true);
    }

    const totalFilesToUpload =
      loadingFilesLength <= currentFileLimit ? loadingFilesLength : currentFileLimit;

    this.uploadValidFiles(event.target.files, totalFilesToUpload, allowedFiles);
  }

  private categorizeFiles(chosenFiles: IUploadingFile[]) {
    return chosenFiles.reduce(
      (acc: { files: IUploadingFile[]; images: IUploadingFile[] }, cur: IUploadingFile) => {
        if (cur.fileType === FileType.image) {
          acc.images.push(cur);
        } else {
          acc.files.push(cur);
        }
        return acc;
      },
      { files: [], images: [] },
    );
  }

  private isInvalidNewFile(
    event: any,
    existingFiles: IUploadingFile[],
    existingImages: IUploadingFile[],
  ): boolean {
    return (
      (!!existingFiles.length && isImage(event.target.files[0])) ||
      (!!existingImages.length && !isImage(event.target.files[0]))
    );
  }

  private async uploadValidFiles(
    files: File[],
    totalFilesToUpload: number,
    allowedFiles?: string[],
  ) {
    for (let i = 0; i < totalFilesToUpload; i++) {
      const currentFile = files[i];
      const fileSize = currentFile.size;

      if (
        allowedFiles &&
        (!allowedFiles.includes(currentFile.type) || fileSize > 10 * 1024 * 1024)
      ) {
        this.setError(allowedFiles);
        continue;
      }

      this.utils.uploadFile(
        isImage(currentFile) ? FileType.image : FileType.file,
        currentFile,
        this._localId,
      );
      this._localId++;
    }
  }

  private setError(allowedFiles: string[]) {
    this.error = {
      message: `Выберите другой формат или размер файла`,
      formats:
        allowedFiles.length === this.allowedFormatsForFile.length
          ? 'doc, docx, pdf, txt, xls, xlsx, jpeg, png, gif, HEIF, HEIС, mp4, mov'
          : 'jpeg, png, gif, HEIF, HEIC',
    };
  }

  private scrollTo(step: number) {
    if (this.imagesContainer) {
      const scrollLeft = this.imagesContainer.nativeElement.scrollLeft + step;
      const widthDiff =
        this.imagesContainer.nativeElement.scrollWidth -
        this.imagesContainer.nativeElement.clientWidth;

      this.imagesContainer.nativeElement.scrollLeft = scrollLeft;

      this.showRightArrow = widthDiff > scrollLeft;
      this.showLeftArrow = scrollLeft > 0 && widthDiff > 0;
    }
  }

  public scrollLeft() {
    this.scrollTo(-157);
  }

  public scrollRight() {
    this.scrollTo(157);
  }

  private _checkFormValues(): boolean {
    return Object.keys(this.newPostFormGroup.value).some((key) =>
      Boolean(this.newPostFormGroup.value[key]),
    );
  }

  private async _canDismiss(isForceDismiss: boolean) {
    if (isForceDismiss) return true;

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.form['poll'].value
        ? 'Вы уверены, что хотите отменить редактирование опроса?'
        : 'Вы уверены, что хотите отменить создание опроса?',
      mode: CurrentPlatform.ios,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Отменить',
          role: 'confirm',
        },
        {
          text: 'Вернуться',
          role: 'cancel',
        },
      ],
    });

    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    return role === 'confirm';
  }

  changeIsPinnedValue() {
    this.isPinned = !this.isPinned;
  }

  checkCanToSavePost(chosenFiles?: IUploadingFile[]) {
    return (
      (this.newPostFormGroup.value.msg?.length || chosenFiles?.length || this.form['poll'].value) &&
      chosenFiles?.every((file) => !file.isLoading)
    );
  }

  public onKeyboardWillShow(info: KeyboardInfo) {
    this.keyboardHeight = this.platform === CurrentPlatform.ios ? info.keyboardHeight : 0;
    this._cdr.detectChanges();
    this.container?.nativeElement.classList.remove('create-post_padding');
  }

  public onKeyboardWillHide() {
    this.keyboardHeight = 0;
    this._cdr.detectChanges();
    this.container?.nativeElement.classList.add('create-post_padding');
  }

  get textAreaMaxHeight() {
    let pixels = 26 + this.keyboardHeight;

    if (this.chosenFiles?.some((file) => file.fileType === FileType.image)) {
      pixels += 142 + 16;
    }
    if (this.chosenFiles?.some((file) => file.fileType === FileType.file)) {
      pixels += 56;
    }
    if (this.form['poll'].value) {
      pixels += 48;
    }

    const toDeduct = `${pixels}px` + (this.keyboardHeight ? '' : ' - var(--ion-safe-area-bottom)');

    return `calc(var(--content-height) - ${toDeduct})`;
  }

  private onKeyPress(event: KeyboardEvent) {
    const ionTextArea = (event.target as HTMLInputElement)?.parentElement?.parentElement;
    if (ionTextArea && event.key === 'Enter' && ionTextArea.clientHeight > 190) {
      setTimeout(() => {
        ionTextArea.scrollTo(0, ionTextArea.scrollHeight);
      }, 50);
    }
  }

  public closeModal() {
    this.utils.dismissModal();
  }
}
