import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../../services/user/user.type';

@Pipe({
  name: 'getFullName',
})
export class GetFullNamePipe implements PipeTransform {
  transform(userData?: Partial<IUser> | null): string {
    if (!userData) {
      return '';
    }
    return `${userData.firstName} ${userData.lastName}`;
  }
}
