import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  clearTrainingData,
  getSectionsList,
} from '../../../../../../data/store/actions/training.action';
import {
  selectCurrentTrainingMode,
  selectIsAllDataFetched,
  selectSections,
  selectTrainingFilesList,
  selectTrainingsIsLoading,
} from '../../../../../../data/store/selectors/training.selectors';
import { befreeUrlValues } from '../../../../../../shared/constants';
import { CurrentPlatform, SectionsListModes } from '../../../../../../shared/enums';
import { ITrainingUserPositionGroup } from 'src/app/shared/services/user/user.type';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-training-sections',
  templateUrl: './training-sections.component.html',
  styleUrls: ['./training-sections.component.scss'],
})
export class TrainingSectionsComponent implements OnInit, OnDestroy {
  public viewMode$ = this._store.select(selectCurrentTrainingMode);

  public isLoading$ = this._store.select(selectTrainingsIsLoading);

  public sectionsList$ = this._store.select(selectSections);

  public currentPlatform$ = this._store.select(selectCurrentPlatform);

  public isAllDataFetched$ = this._store.select(selectIsAllDataFetched);

  public searchResultsList$ = this._store.select(selectTrainingFilesList);

  public readonly Platform = CurrentPlatform;

  public readonly SectionsListModes = SectionsListModes;

  public listName = befreeUrlValues.sections;

  public clearAction = clearTrainingData();

  private _routerSubscription?: Subscription;

  constructor(private _store: Store, private _router: Router, private _utils: UtilsService) {
    this.getSections = this.getSections.bind(this);
    this._routerSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this._store) {
          this._store.dispatch(clearTrainingData());
          this.getSections();
        }

        const searchbar = document.querySelector('input[type="search"]');
        if (searchbar) {
          (searchbar as HTMLInputElement).value = '';
        }
      });
  }

  ngOnInit() {
    this.getSections();
  }

  ngOnDestroy(): void {
    if (this._store) {
      this._store.dispatch(clearTrainingData());
    }
    this._routerSubscription?.unsubscribe();
  }

  public onRefresh(event: Event) {
    this._utils.handleRefresh(event, undefined, this.getSections);
  }

  public trackByFn(_index: number, section: ITrainingUserPositionGroup) {
    return section.id;
  }

  public getSections() {
    this._store.dispatch(getSectionsList());
  }

  public getCurrentSectionNavigation(section: ITrainingUserPositionGroup) {
    return {
      state: { title: section.name },
      link: [
        '/',
        befreeUrlValues.dashboard,
        befreeUrlValues.training,
        befreeUrlValues.sections,
        section.id,
      ],
    };
  }
}
