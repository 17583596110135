import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  applyMenuFiltersAction,
  changeFavoriteState,
  clearFiltersInit,
} from 'src/app/data/store/actions/filters.actions';
import { FiltersOptionsType, IFiltersOptionsParam } from 'src/app/data/store/models/filters.model';
import { arrowRight, FiltersOptionsNames } from 'src/app/shared/constants';
import {
  Buttons,
  ButtonTextSizes,
  FiltersResetTypes,
  TextSizes,
  TextType,
} from 'src/app/shared/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UtilsService } from '../../../../services/utils.service';

@UntilDestroy()
@Component({
  selector: 'app-filters-menu',
  templateUrl: './filters-menu.component.html',
  styleUrls: ['./filters-menu.component.scss'],
})
export class FiltersMenuComponent {
  @Input() menuOptions: FiltersOptionsType[] = [];

  @Input() initialFilters: IFiltersOptionsParam | null = null;

  @Input() onlyFavorite?: boolean;

  @Input() withFavoriteOption: boolean = false;

  @Input() isFiltersChosen: boolean | null = null;

  @Output() public emitSelectedMenuOption = new EventEmitter();

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  readonly Buttons = Buttons;

  readonly ButtonTextSizes = ButtonTextSizes;

  readonly FiltersOptionsNames = FiltersOptionsNames;

  readonly arrowRight = arrowRight;

  constructor(
    private _store: Store,
    private _utils: UtilsService,
    private _modalCtrl: ModalController,
  ) {}

  public selectFilter(option: FiltersOptionsType) {
    if (this.onlyFavorite) {
      return;
    }
    this.emitSelectedMenuOption.emit(option);
  }

  public favsChange(event: any) {
    this.onlyFavorite = event.detail.checked;

    this._store.dispatch(
      changeFavoriteState({
        favs: event.detail.checked,
      }),
    );
  }

  public clearAllFilters() {
    if (!this.isFiltersChosen) return;

    this._store.dispatch(clearFiltersInit({ clearType: FiltersResetTypes.resetAll }));
  }

  public applyFilters() {
    this._store.dispatch(applyMenuFiltersAction());
    this._modalCtrl
      .getTop()
      .then((modal) => {
        if (modal) {
          modal.canDismiss = true;
          this._modalCtrl.dismiss();
        }
      })
      .catch(() => {});
  }
}
