<ng-container *ngIf="platform$ | async as platform">
  <app-header *ngIf="platform === CurrentPlatform.web && isHeaderShown"></app-header>
</ng-container>
<router-outlet></router-outlet>

<div class="footer" *ngIf="isFooterShown" #footer>
  <div
    class="tab-icons"
    *ngFor="let btn of footerMenuOptions; trackBy: trackByFn"
    [class.tab-selected]="btn.url === currentUrl"
    (click)="navigate(btn.url)"
  >
    <img [src]="btn.img" />
    <app-notifications-counter
      *ngIf="btn.url.includes(befreeUrlValues.notifications)"
    ></app-notifications-counter>
  </div>
</div>

<app-notification-container></app-notification-container>

<app-training-notification></app-training-notification>
