import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCounters } from 'src/app/data/store/selectors/users.selectors';
import { arrowRight, arrowRightLight } from '../../constants';
import { CurrentPlatform, TextSizes, TextType } from '../../enums';
import {
  selectAppInfo,
  selectCurrentPlatform,
} from '../../../data/store/selectors/platform.selectors';
import { IonContent } from '@ionic/angular';
import { Location } from '@angular/common';
import { ITabsLinks } from '../../interfaces';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-ion-page-container',
  templateUrl: './ion-page-container.component.html',
  styleUrls: ['./ion-page-container.component.scss'],
})
export class IonPageContainerComponent {
  @ViewChild(IonContent) content: IonContent | undefined;

  @Input() headerName: string = '';

  @Input() isHeaderTranslucent: boolean = false;

  @Input() isNeedBack: boolean = false;

  @Input() backNavigateUrl?: string;

  @Input() isDataLoaded$: Observable<any> | undefined;

  @Input() needFooter: boolean = true;

  @Input() isModalView: boolean = false;

  @Input() tabsLinks: ITabsLinks[] = [];

  @Input() isShowVersion: boolean = false;

  public currentPlatform$: Observable<CurrentPlatform | null> | undefined =
    this._store.select(selectCurrentPlatform);

  public counters$: Observable<{ [x: string]: number } | null> = this._store.select(selectCounters);

  public currentAppInfo$ = this._store.select(selectAppInfo);

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly arrowRight = arrowRight;

  public readonly arrowRightLight = arrowRightLight;

  public readonly CurrentPlatform = CurrentPlatform;

  constructor(
    private _router: Router,
    private _store: Store,
    private location: Location,
    private _utils: UtilsService,
  ) {}

  public back() {
    if (this.isModalView) {
      this._utils.dismissModal();
      return;
    }
    if (this.backNavigateUrl) {
      this._router.navigate([this.backNavigateUrl]);
    } else this.location.back();
  }
}
