<ng-container *ngIf="currentUser$ | async as currentUser">
  <div class="filters-wrapper">
    <app-search-bar-with-filters
      *ngIf="!currentUser.isCanTrain"
      [excludeSingleFiltersOptions]="
        pageType === pageTypes.employeeDevelopment
          ? excludeSingleFiltersOptionsDevelopment
          : excludeSingleFiltersOptionsAnalytics
      "
    ></app-search-bar-with-filters>

    <div class="filters">
      <div class="filters__single">
        <ion-segment
          *ngIf="pageType === pageTypes.employeeDevelopment"
          [value]="defaultFilterValue"
          mode="ios"
          (ionChange)="changeSegment($event)"
        >
          <ion-segment-button [value]="segmentTypes.all">
            <ion-label>все формы</ion-label>
          </ion-segment-button>
          <ion-segment-button
            [value]="segmentTypes.urgent"
            [disabled]="
              currentUser.isAccessToTrainingResult &&
              currentUser.userTrainingRole !== userRoles.tsmRole &&
              currentUser.userTrainingRole !== userRoles.regionalManager
            "
          >
            <ion-label>срочные</ion-label>
          </ion-segment-button>
        </ion-segment>

        <app-filters-button
          *ngIf="!currentUser.isCanTrain && pageType === pageTypes.employeeDevelopment"
          class="filters__single-position"
          [dataForFilters]="dataForFiltersPositions"
          [filtersButtonType]="filtersButtonType"
          [isFilterHaveOption$]="positionFiltersState$"
          [buttonText]="'должность'"
        ></app-filters-button>

        <ng-container *ngIf="!currentUser.isCanTrain && pageType === pageTypes.employeeDevelopment">
          <app-button
            id="filter-date"
            class="filters__single-date"
            [class.active-date]="endTrainingFiltersState$ | async"
            [btnType]="Buttons.btnFilter"
            [textSize]="ButtonTextSizes.btnFilter"
            [text]="'срок заполнения'"
            (click)="isCalenderOpen = !isCalenderOpen"
          ></app-button>
          <ion-popover
            [trigger]="'filter-date'"
            [isOpen]="isCalenderOpen"
            size="cover"
            [arrow]="false"
            (didDismiss)="isCalenderOpen = false"
          >
            <ng-template>
              <ion-datetime
                #datetime
                [value]="endTrainingDate$ | async"
                (ionChange)="setDate($event)"
                (ionCancel)="isCalenderOpen = false"
                [firstDayOfWeek]="1"
                presentation="date"
                mode="ios"
                locale="ru-RU"
              >
                <ion-buttons slot="buttons" class="datetime-buttons">
                  <ion-button (click)="datetime.cancel()">
                    <app-button
                      class="cancel"
                      [btnType]="Buttons.small"
                      [textSize]="ButtonTextSizes.buttonText1"
                      [text]="'ОТМЕНА'"
                    ></app-button>
                  </ion-button>
                  <ion-button (click)="datetime.confirm(isCalenderOpen)">
                    <app-button
                      class="confirm"
                      [btnType]="Buttons.small"
                      [textSize]="ButtonTextSizes.buttonText1"
                      [text]="'ГОТОВО'"
                    ></app-button>
                  </ion-button>
                </ion-buttons>
              </ion-datetime>
            </ng-template>
          </ion-popover>
        </ng-container>

        <ng-container *ngIf="!currentUser.isCanTrain && pageType === pageTypes.analytics">
          <app-filters-button
            class="filters__single-trainerRole"
            [dataForFilters]="dataForFiltersTrainerRole"
            [filtersButtonType]="filtersButtonType"
            [isFilterHaveOption$]="trainerRoleFiltersState$"
            [buttonText]="'роль тренера'"
          ></app-filters-button>

          <app-filters-button
            class="filters__single-trainer"
            [dataForFilters]="dataForFiltersTrainer"
            [filtersButtonType]="filtersButtonType"
            [isFilterHaveOption$]="trainerFiltersState$"
            [buttonText]="'тренер'"
          ></app-filters-button>
        </ng-container>

        <app-filters-button
          *ngIf="!currentUser.isCanTrain"
          class="filters__single-stage"
          [dataForFilters]="dataForFiltersStage"
          [filtersButtonType]="filtersButtonType"
          [isFilterHaveOption$]="stageFiltersState$"
          [buttonText]="'этап'"
        ></app-filters-button>
      </div>

      <a
        class="filters__download"
        [class.filters__download_disabled]="trainingXlsReportLoading$ | async"
        *ngIf="pageType === pageTypes.analytics"
        (click)="downloadReport()"
      >
        <img
          class="filters__download-icon"
          src="assets/images/icons-svg/xls-icon.svg"
          alt="xls-icon"
          *ngIf="(trainingXlsReportLoading$ | async) === false"
        />
        <app-spinner
          [isLoading]="(trainingXlsReportLoading$ | async) || false"
          [color]="SpinnerColor.light"
        ></app-spinner>
      </a>
    </div>

    <ng-container *ngIf="!currentUser.isCanTrain">
      <div class="selected-filters" *ngIf="filtersMenuHaveOptions$ | async">
        <div class="selected-filters__reset" (click)="clearAllFilters()">
          <app-text
            [textSize]="TextSizes.h3Body"
            [textType]="TextType.base"
            [text]="'Сбросить фильтры'"
          ></app-text>
        </div>
        <ng-container *ngFor="let option of options">
          <ng-container *ngIf="selectedFilters?.[option]?.params?.length && option !== 'SEGMENT'">
            <app-selected-filters
              [selectedOptionData]="selectedFilters?.[option]?.params"
              [selectedOptionName]="option"
              [isNeedExternalFiltersMenuCleaning]="true"
            ></app-selected-filters>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
