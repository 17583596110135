import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScrollToType } from '../../enums';
import { IScrollToEvent } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ScrollToService {
  private _scrollToEvent = new BehaviorSubject<IScrollToEvent>({ scrollTo: ScrollToType.top });

  public scrollToEvent(event: IScrollToEvent) {
    this._scrollToEvent.next(event);
  }

  public scrollToEventListener() {
    return this._scrollToEvent.asObservable();
  }
}
