import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionContainerComponent } from './section-container/section-container.component';
import { SharedModule } from '../../../../../../shared/shared.module';

@NgModule({
  declarations: [SectionContainerComponent],
  imports: [CommonModule, SharedModule],
  exports: [SectionContainerComponent],
})
export class CurrentSectionPageModule {}
