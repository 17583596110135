<app-block *ngIf="platform$ | async as platform" [platform]="platform">
  <ng-container *ngIf="postData || photoReportData">
    <div>
      <app-information-label
        class="pinned-post"
        *ngIf="postData?.pinned && dataType !== DataTypesList.photoReport"
        text="Запись закреплена"
      ></app-information-label>

      <app-post-header
        *ngIf="userViewType === PostUserViewType.full"
        [date]="currentPostData?.created"
        [avatar]="currentPostData?.author?.avatar?.previewNormal"
        [fullName]="currentPostData?.author | getFullName"
        [position]="currentPostData?.author?.userPosition?.name"
        [department]="currentPostData?.author?.userDepartment?.name"
        [city]="currentPostData?.author?.userDepartment?.city?.name"
        [id]="currentPostData?.author?.id"
      >
        <div class="post-dropdown">
          <ng-container *ngTemplateOutlet="postDropdown"></ng-container>
        </div>
      </app-post-header>

      <div *ngIf="userViewType === PostUserViewType.mini" class="post-dropdown post-user-block">
        <app-user-data
          [imgSize]="
            platform === CurrentPlatform.web
              ? UserDataImgSize.m
              : 'calc(' + UserDataImgSize.m + '*' + zoomValue
          "
          [date]="currentPostData?.created"
          [imgSrc]="currentPostData?.author?.avatar?.previewNormal"
          [fullName]="currentPostData?.author | getFullName"
          [position]="currentPostData?.author?.userPosition?.name"
          [id]="currentPostData?.author?.id"
        >
        </app-user-data>

        <ng-container *ngTemplateOutlet="postDropdown"></ng-container>
      </div>

      <div
        *ngIf="
          dataType === DataTypesList.photoReport
            ? photoReportData?.description
            : postData?.msg as message
        "
        class="post-text"
        [class.block-mobile]="platform !== CurrentPlatform.web"
        [class.block-web]="platform === CurrentPlatform.web"
      >
        <app-post-text [postText]="message"></app-post-text>
      </div>
    </div>

    <div
      *ngIf="
        dataType !== DataTypesList.photoReport &&
        postData &&
        postData.images &&
        postData.images.length
      "
      class="post-images"
    >
      <div *ngIf="postData.images.length === 1" class="post-images__single">
        <img [src]="postData.images[0].path | addBaseUrl" (click)="openGalleryModal(0)" />
      </div>
      <app-post-images-grid
        [images]="postData.images"
        (clicked)="openGalleryModal($event)"
      ></app-post-images-grid>
    </div>

    <div
      *ngIf="
        dataType !== DataTypesList.photoReport &&
        postData &&
        postData.files?.[0] &&
        !postData.files![0].mimeType.includes('image')
      "
      class="file-preview"
    >
      <!-- Используем конструкцию postData.files!, так как выше проверили, что postData.files != null -->
      <app-file-upload
        [itemId]="postData.files![0].id"
        [fileTitle]="postData.files![0].name"
        [filePath]="postData.files![0].path"
        [inFavouritesList]="postData.files![0].favorite"
        [isDisabled]="(isLoadingFavorite$ | async) === true"
        (changeFavouritesStatus)="
          handleChangeFavouritesStatus(postData.files![0].id, postData.files![0].favorite)
        "
        [postFileView]="true"
        [currentPlatform]="platform"
        type="file"
      ></app-file-upload>
    </div>

    <div
      *ngIf="dataType !== DataTypesList.photoReport && postData && postData.poll"
      class="post-poll"
    >
      <app-polls
        [postData]="postData"
        [isStoreList]="isStoreList"
        [viewType]="viewType"
      ></app-polls>
    </div>

    <div
      [class.block-mobile]="platform === CurrentPlatform.web"
      [class.block-web]="platform !== CurrentPlatform.web"
    >
      <app-post-information
        [isStoreList]="isStoreList"
        [postId]="currentPostData?.id"
        [postCommentsCount]="currentPostData?.commentCount"
        [postIsLiked]="currentPostData?.liked"
        [postLikesCount]="currentPostData?.likes"
        [viewType]="viewType"
        [dataType]="dataType"
        [onCommentIconClick]="onCommentIconClick"
      ></app-post-information>
    </div>
    <ng-content></ng-content>
    <ng-content select="[photoReport]"></ng-content>
  </ng-container>

  <ng-template #postDropdown>
    <ng-container *ngIf="currentUser$ | async as currentUser">
      <ng-container *ngIf="platform !== CurrentPlatform.web">
        <div class="button-mob" *ngIf="getIsShowEditPhotoReportBtn(currentUser)">
          <img
            src="assets/images/icons-svg/edit.svg"
            alt="edit"
            (click)="editPhotoReport()"
            width="24"
            height="24"
          />
        </div>
        <div class="button-mob" *ngIf="getIsShowDeletePhotoReportBtn(currentUser)">
          <img
            src="assets/images/icons-svg/delete-basket.svg"
            alt="delete"
            (click)="getAlertDeleteModal(deletePhotoReport, dataType === DataTypesList.post)"
            width="24"
            height="24"
          />
        </div>
        <button
          *ngIf="getIsShowDropdownButton(currentUser)"
          [id]="
            viewType === ViewTypesList.modal ? currentPostData?.id + '-modal' : currentPostData?.id
          "
          class="post-dropdown__button"
          (click)="handleOpenMenu()"
        >
          <img
            [style.width]="MenuIconWidth.web"
            src="assets/images/icons-svg/posts/post-menu.svg"
            alt="menu"
          />
        </button>
        <ion-popover
          cssClass="popover"
          [trigger]="
            viewType === ViewTypesList.modal ? currentPostData?.id + '-modal' : currentPostData?.id
          "
          [isOpen]="isOpen"
          (didDismiss)="isOpen = false"
          [dismissOnSelect]="true"
          side="bottom"
          alignment="start"
          [showBackdrop]="false"
        >
          <ng-template>
            <ion-content [forceOverscroll]="false">
              <app-dropdown-menu
                [isPinned]="postData?.pinned"
                [isOpen]="isOpen"
                [postUserId]="photoReportData ? photoReportData.author?.id : postData?.author?.id"
                [postId]="postData?.id"
                [shopId]="photoReportData?.shop?.id"
                [dataType]="dataType"
                [viewType]="viewType"
                (closeModalEvent)="handleOpenMenu()"
                [getAlertDeleteModal]="getAlertDeleteModal"
              ></app-dropdown-menu>
            </ion-content>
          </ng-template>
        </ion-popover>
      </ng-container>

      <ng-container *ngIf="platform === CurrentPlatform.web">
        <div class="post-dropdown__button" *ngIf="getIsShowEditPhotoReportBtn(currentUser)">
          <img
            [style.width]="MenuIconWidth.web"
            src="assets/images/icons-svg/edit.svg"
            alt="edit"
            (click)="editPhotoReport()"
          />
        </div>
        <div class="post-dropdown__button" *ngIf="getIsShowDeletePhotoReportBtn(currentUser)">
          <img
            [style.width]="MenuIconWidth.web"
            src="assets/images/icons-svg/delete-basket.svg"
            alt="delete"
            (click)="getAlertDeleteModal(deletePhotoReport, dataType === DataTypesList.post)"
          />
        </div>
        <app-dropdown-container
          *ngIf="getIsShowDropdownButton(currentUser)"
          (emitDropdownClose)="isOpen = false"
        >
          <button
            [id]="currentPostData?.id"
            class="post-dropdown__button"
            (click)="handleOpenMenu()"
          >
            <img
              [style.width]="MenuIconWidth.web"
              src="assets/images/icons-svg/posts/post-menu.svg"
              alt="menu"
            />
          </button>
          <ng-container *ngIf="isOpen">
            <app-dropdown-menu
              [isPinned]="postData?.pinned"
              [isOpen]="isOpen"
              [postUserId]="photoReportData ? photoReportData.author?.id : postData?.author?.id"
              [postId]="postData?.id"
              [shopId]="photoReportData?.shop?.id"
              [dataType]="dataType"
              [viewType]="viewType"
              (closeModalEvent)="handleOpenMenu()"
              [getAlertDeleteModal]="getAlertDeleteModal"
            ></app-dropdown-menu>
          </ng-container>
        </app-dropdown-container>
      </ng-container>
    </ng-container>
  </ng-template>
</app-block>
