import { Pipe, PipeTransform } from '@angular/core';
import { IImage } from '../../services/posts/posts.type';

@Pipe({
  name: 'getImagesLikesCount',
})
export class GetImagesLikesCountPipe implements PipeTransform {
  transform(likedList: number[] | undefined, currentImage: IImage): number {
    if (!likedList) return currentImage.likes;
    if (likedList.includes(currentImage.id) && currentImage.liked) {
      return currentImage.likes - 1;
    } else if (likedList.includes(currentImage.id) && !currentImage.liked) {
      return currentImage.likes + 1;
    }

    return currentImage.likes;
  }
}
