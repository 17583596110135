<a
  class="section-card no-drag"
  [id]="section?.id"
  [routerLink]="!utils.isWeb ? navigation.link : null"
>
  <a [routerLink]="utils.isWeb ? navigation.link : null">
    <img class="section-card__image" src="assets/images/icons-svg/photo-report-logo.svg" />
  </a>

  <div class="section-card__container">
    <a
      class="nav-link"
      [state]="navigation.state"
      [routerLink]="utils.isWeb ? navigation.link : null"
    >
      <app-text
        class="section-card__title"
        [text]="section?.name || stage?.name"
        [textType]="TextType.base"
        [textSize]="TextSizes.h4Name"
      ></app-text>
    </a>
  </div>
</a>
