import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, map, mergeMap, Observable, take } from 'rxjs';
import {
  addFileToFavoritesList,
  blockPost,
  deletePostByPostId,
  getCurrentPostData,
  pinPostByPostId,
  removeFileFromFavoritesList,
} from 'src/app/data/store/actions/posts.actions';
import { selectCurrentUser } from 'src/app/data/store/selectors/users.selectors';
import { IUser } from 'src/app/shared/services/user/user.type';
import {
  CurrentPlatform,
  DataType,
  TextSizes,
  TextType,
  UserRoles,
  ViewType,
} from 'src/app/shared/enums';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  changeEditMode,
  deletePhotoReport,
} from '../../../../data/store/actions/photo-report.action';
import { CreatePhotoReportComponent } from '../../../../modules/dashboard/components/shops/pages/current-shop-page/create-photo-report/create-photo-report.component';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { selectIsEditMode } from '../../../../data/store/selectors/photo-report.selectors';
import { CreatePostComponent } from '../../../../modules/dashboard/components/create-post/create-post.component';
import { clearUploadedFilesData } from '../../../../data/store/actions/uploading-files.actions';
import {
  selectFavoritesIsLoading,
  selectFavouritesListToBeUpdated,
} from '../../../../data/store/selectors/posts.selectors';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { UserTestModId } from 'src/config';

@UntilDestroy()
@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent implements OnInit {
  @Input() isPinned: boolean | undefined;

  @Input() isOpen: boolean | undefined;

  @Input() postUserId: number | undefined;

  @Input() postId: number | undefined;

  @Input() viewType: string | undefined;

  @Input() dataType: string | undefined;

  @Input() isFavourite: boolean | undefined;

  @Input() fileId: number | undefined;

  @Input() shopId: number | undefined;

  @Input() getAlertDeleteModal:
    | ((fn: () => void, isPostDataType?: boolean) => void | undefined)
    | undefined;

  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();

  public favouritesListToBeUpdated$: Observable<number[] | any[]> = this._store.select(
    selectFavouritesListToBeUpdated,
  );

  public isLoadingFavorite$ = this._store.select(selectFavoritesIsLoading);

  public currentUser$: Observable<IUser | null> = this._store.select(selectCurrentUser);

  public currentPlatform$: Observable<CurrentPlatform | null> =
    this._store.select(selectCurrentPlatform);

  currentPlatform: CurrentPlatform | null = null;

  public currentUser: IUser | null = null;

  public currentMode: string = typeof ViewType;

  public postPage: boolean = Boolean(this._activateRoute.snapshot.paramMap.get('postId'));

  public readonly photoReportId: number = parseInt(this._activateRoute.snapshot.params['reportId']);

  public readonly TextType = TextType;

  public readonly TextSizes = TextSizes;

  public readonly userRoles = UserRoles;

  public readonly ViewTypesList = ViewType;

  public readonly DataTypesList = DataType;

  public pinIcon: string = 'assets/images/icons-svg/dropdown/pin-icon.svg';

  public favouritesIcon: string = 'assets/images/icons-svg/dropdown/favorites-icon.svg';

  public deleteIcon: string = 'assets/images/icons-svg/dropdown/delete-icon.svg';

  constructor(
    private _activateRoute: ActivatedRoute,
    private _store: Store,
    private _modalCtrl: ModalController,
    private _actionSheetCtrl: ActionSheetController,
  ) {}

  ngOnInit(): void {
    this._store
      .select(selectIsEditMode)
      .pipe(untilDestroyed(this))
      .subscribe((currentMode) => (this.currentMode = currentMode));

    this.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => (this.currentUser = user));

    this.currentPlatform$
      .pipe(untilDestroyed(this))
      .subscribe((platform) => (this.currentPlatform = platform));
  }

  get canDeletePost() {
    if (!this.currentUser) {
      return false;
    }

    return (
      (this.currentUser.id === this.postUserId ||
        this.currentUser.userRole === this.userRoles.moderator ||
        (this.dataType === this.DataTypesList.photoReport &&
          this.currentUser.userRole === this.userRoles.moderator)) &&
      this.dataType !== this.DataTypesList.image
    );
  }

  get canReportPost() {
    if (!this.currentUser || this.currentPlatform !== CurrentPlatform.ios) {
      return false;
    }

    return this.currentUser.id === UserTestModId;
  }

  public pinPost() {
    this._store.dispatch(
      pinPostByPostId({
        postId: this.postId!,
        isPostView: this.dataType === this.DataTypesList.post,
        postIsPinned: this.isPinned ?? false,
        postCreationMode: false,
      }),
    );
    this.closeModalEvent.emit();
  }

  public async reportPost() {
    const actionSheet = await this._actionSheetCtrl.create({
      header: 'Пожаловаться',
      buttons: [
        {
          text: 'Спам',
          role: 'destructive',
          handler: () => this.handleReport('Спам'),
        },
        {
          text: 'Оскорбления и угрозы',
          role: 'destructive',
          handler: () => this.handleReport('Оскорбления и угрозы'),
        },
        {
          text: 'Разжигание ненависти',
          role: 'destructive',
          handler: () => this.handleReport('Разжигание ненависти'),
        },
        {
          text: 'Насилие и жестокость',
          role: 'destructive',
          handler: () => this.handleReport('Насилие и жестокость'),
        },
        {
          text: 'Призывы к незаконным действиям',
          role: 'destructive',
          handler: () => this.handleReport('Призывы к незаконным действиям'),
        },
        {
          text: 'Ложная информация',
          role: 'destructive',
          handler: () => this.handleReport('Ложная информация'),
        },
        {
          text: 'Прочее',
          role: 'destructive',
          handler: () => this.handleReport('Прочее'),
        },
        {
          text: 'Отмена',
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
    this.closeModalEvent.emit();
  }

  private handleReport(reason: string) {
    if (!this.postId) {
      return;
    }

    this._store.dispatch(blockPost({ postId: this.postId, reason }));

    setTimeout(async () => {
      const toast = await this._actionSheetCtrl.create({
        header: 'Спасибо! Ваша жалоба принята и будет рассмотрена модераторами.',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await toast.present();
    }, 1000);
  }

  public deletePost = () => {
    if (this.dataType === this.DataTypesList.photoReport) {
      this._store.dispatch(deletePhotoReport({ photoReportId: this.photoReportId }));
    } else {
      this._store.dispatch(
        deletePostByPostId({
          postId: this.postId!,
          isPostView: this.dataType === this.DataTypesList.post,
        }),
      );
    }
    this.closeModalEvent.emit();
  };

  public changeFavoritesStatus(favouritesListToBeUpdated: number[] | any[]) {
    if (!this.fileId) {
      return;
    }
    const isInFavouritesList = favouritesListToBeUpdated.includes(this.fileId);
    if (
      (isInFavouritesList && this.isFavourite === true) ||
      (!isInFavouritesList && this.isFavourite === false)
    ) {
      this._store.dispatch(addFileToFavoritesList({ postId: this.postId, fileId: this.fileId }));
    } else {
      this._store.dispatch(
        removeFileFromFavoritesList({
          postId: this.postId,
          fileId: this.fileId,
        }),
      );
    }
    this.closeModalEvent.emit();
  }

  public changeEditMode(dataType: string | undefined) {
    if (dataType === this.DataTypesList.photoReport) {
      this._store.dispatch(changeEditMode({ currentMode: ViewType.editPhotoReport }));
      if (this.currentMode === ViewType.editPhotoReport) {
        this.openCreatePhotoReportModal();
      }
    } else this.closeModalEvent.emit();
  }

  public editPostData() {
    if (!this.postId) return;

    this._store.dispatch(getCurrentPostData({ postId: this.postId, isEditMode: true }));
    const modal$ = from(
      this._modalCtrl.create({
        component: CreatePostComponent,
        componentProps: {
          isEditMode: true,
          postId: this.postId,
        },
        animated: true,
        cssClass: 'edit-post-modal',
        backdropDismiss: false,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();

    modal$
      .pipe(
        untilDestroyed(this),
        mergeMap((modal) =>
          from(modal.onWillDismiss()).pipe(
            map(() => {
              this._store.dispatch(clearUploadedFilesData());
            }),
          ),
        ),
      )
      .subscribe();
  }

  public openCreatePhotoReportModal() {
    const modal$ = from(
      this._modalCtrl.create({
        component: CreatePhotoReportComponent,
        animated: true,
        backdropDismiss: false,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();

    modal$
      .pipe(
        untilDestroyed(this),
        map((modal) => modal.onWillDismiss()),
      )
      .subscribe();
  }
}
