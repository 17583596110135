import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IImage } from 'src/app/shared/services/posts/posts.type';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-post-images-grid',
  templateUrl: './post-images-grid.component.html',
})
export class PostImagesGridComponent implements OnInit {
  @Input() images: IImage[] = [];

  @Output() clicked = new EventEmitter<number>();

  public readonly columnRange: number[] = [1, 3];

  public showGrid = false;

  constructor(private _utils: UtilsService, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.showGrid = true;
      this._cdr.detectChanges();
    });
  }

  get gap() {
    return this._utils.isWeb ? 4 : 2;
  }

  public onClick(index: number) {
    this.clicked.emit(index);
  }

  public trackByFn(index: number, obj: IImage) {
    return obj.id;
  }
}
