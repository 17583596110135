<app-block>
  <form *ngIf="postData" [formGroup]="pollsForm" class="polls">
    <app-information-label
      class="label"
      [class.label-public]="postData.poll.type === pollTypesData.public.type"
      [text]="
        postData.poll.type === pollTypesData.public.type
          ? pollTypesData.public.text
          : pollTypesData.anonymous.text
      "
    ></app-information-label>
    <div>
      <app-text
        [text]="postData.poll.text"
        [textType]="TextType.base"
        [textSize]="TextSize.h1Header"
        class="polls__title"
      ></app-text>
    </div>
    <div *ngFor="let item of postData.poll.pollOptions; index as i" class="polls__item">
      <label
        class="polls__item__label"
        [class.chosen-value-label]="chosenId.includes(item.id) && !showResults"
        [class.polls__label-submitted]="showResults"
        [for]="viewType === ViewTypesList.modal ? modalMod + item.id : item.id"
      >
        <div class="polls__item__label__block" [class.polls__item-submitted]="showResults">
          <div [class.polls-title-submitted]="showResults">
            <app-text
              [text]="item.text"
              [textType]="TextType.base"
              [textSize]="TextSize.h3Body"
              class="polls__title"
            ></app-text>
            <img
              *ngIf="showResults && item.isVoted"
              src="assets/images/icons-svg/chosen-option.svg"
              alt="chosen-option"
            />
          </div>
          <div *ngIf="showResults" class="polls-result">
            <app-text
              [text]="item.votersCount.toString()"
              [textSize]="TextSize.h4Name"
              [textType]="TextType.base"
            >
            </app-text>
          </div>
        </div>
        <div *ngIf="!showResults" class="polls__item__label__input">
          <input
            [disabled]="showResults"
            [id]="viewType === ViewTypesList.modal ? modalMod + item.id : item.id"
            [type]="postData.poll.isMultipleChoices ? 'checkbox' : 'radio'"
            class="custom-control-input"
            name="chosenValue"
            (click)="onCheckChange($event)"
          />
          <span class="custom-input" [style.opacity]="postData.poll.isMultipleChoices ? '' : '0'"
            ><img src="assets/images/icons-svg/posts/checked-input.svg"
          /></span>
        </div>
      </label>
      <div *ngIf="showResults" class="polls-result-bar" [style.width.%]="item.votersPercent"></div>
    </div>
    <div class="polls__btn" *ngIf="!showResults && chosenOptions.length">
      <app-button
        [btnType]="Buttons.base"
        [textSize]="ButtonTextSize.buttonText1"
        text="проголосовать"
        (click)="handleSubmitPollResults()"
      >
      </app-button>
    </div>
  </form>
</app-block>
