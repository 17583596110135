import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import SwiperCore, { SwiperOptions, Zoom } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Media, MediaAlbum, MediaAlbumResponse } from '@capacitor-community/media';
import { first, from, map, mergeMap, Observable, of, take, tap } from 'rxjs';

import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { successNotificationMessages } from '../../constants';
import {
  CurrentPlatform,
  DataType,
  ImagePreviewType,
  TextSizes,
  TextType,
  ViewType,
} from '../../enums';
import {
  addFileToFavoritesList,
  removeFileFromFavoritesList,
} from '../../../data/store/actions/posts.actions';
import {
  selectFavoritesIsLoading,
  selectFavouritesListToBeUpdated,
} from '../../../data/store/selectors/posts.selectors';
import { selectLikedLIst } from '../../../data/store/selectors/photo-report.selectors';
import { UtilsService } from '../../services/utils.service';
import { BaseHttpService } from '../../services/base-http/base-http.service';
import { ImgService } from '../../services/img/img.service';
import { SlidePhotoGalleryWithCommentsComponent } from '../slide-photo-gallery-with-comments/slide-photo-gallery-with-comments.component';
import { IGalleryState } from 'src/app/data/store/models/gallery.model';
import { selectGalleryData } from 'src/app/data/store/selectors/gallery.selectors';
import {
  changeGalleryDataImageIndex,
  resetGalleryData,
} from 'src/app/data/store/actions/gallery.action';

SwiperCore.use([Zoom]);

@UntilDestroy()
@Component({
  selector: 'app-slide-photo-gallery',
  templateUrl: './slide-photo-gallery.component.html',
  styleUrls: ['./slide-photo-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidePhotoGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('slides', { static: false }) slides?: SwiperComponent;

  public galleryData: IGalleryState = { images: [], chosenImgIndex: 0 };

  public favouritesListToBeUpdated$ = this._store.select(selectFavouritesListToBeUpdated);

  public config?: SwiperOptions;

  public isLoadingFavorite = false;

  public selectLikedList$: Observable<number[]> = this._store.select(selectLikedLIst);

  public likedListToBeUpdated?: number[];

  public currentPlatform: CurrentPlatform | null = null;

  private forceDetectChanges = false;

  readonly CurrentPlatform = CurrentPlatform;

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  readonly ViewTypesList = ViewType;

  readonly DataTypesList = DataType;

  readonly ImagePreviewType = ImagePreviewType;

  constructor(
    private _modalCtrl: ModalController,
    private _store: Store,
    private _actionSheetCtrl: ActionSheetController,
    private _cdr: ChangeDetectorRef,
    private _utils: UtilsService,
    private _baseHTTP: BaseHttpService,
    private _imgService: ImgService,
  ) {
    this.openGalleryWithComments = this.openGalleryWithComments.bind(this);
  }

  ngOnInit(): void {
    this._store
      .select(selectGalleryData)
      .pipe(untilDestroyed(this))
      .subscribe((galleryData) => {
        this._utils.changeSwiperSlide({
          swiper: this.slides?.swiperRef!,
          modalId: 'SlidePhotoGalleryComponent',
          prevIndex: this.galleryData.chosenImgIndex,
          currentIndex: galleryData.chosenImgIndex,
        });

        this.galleryData = galleryData;

        this.config = {
          loop: !this.galleryData.isLoopDisabled && this.galleryData.images.length > 1,
          initialSlide: this.galleryData.chosenImgIndex,
          slidesPerView: 1,
        };
        if (this.forceDetectChanges) {
          this._cdr.detectChanges();
        }
      });

    this._store
      .select(selectLikedLIst)
      .pipe(untilDestroyed(this))
      .subscribe((likedList) => {
        this.likedListToBeUpdated = likedList;
        if (this.forceDetectChanges) {
          this._cdr.detectChanges();
        }
      });

    this._store
      .select(selectCurrentPlatform)
      .pipe(first())
      .subscribe((platform) => {
        this.currentPlatform = platform;
        if (platform === CurrentPlatform.ios) {
          StatusBar.setStyle({ style: Style.Dark });
        }
      });

    this._store
      .select(selectFavoritesIsLoading)
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => {
        this.isLoadingFavorite = isLoading;
      });
  }

  ngOnDestroy(): void {
    this._store.dispatch(resetGalleryData());
    if (this.currentPlatform === CurrentPlatform.ios) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }

  ngAfterViewInit(): void {
    this.forceDetectChanges = true;
  }

  get zoomSettings(): SwiperOptions['zoom'] {
    return { toggle: false };
  }

  public goBack() {
    this._utils.dismissModal();
  }

  public slideChanged() {
    if (this.slides?.swiperRef.realIndex == null) {
      return;
    }

    this._store.dispatch(changeGalleryDataImageIndex({ index: this.slides.swiperRef.realIndex }));
    this._cdr.detectChanges();
    this._cdr.markForCheck();
  }

  public handleOpenModalMenu(favouritesList: number[] | any[]) {
    const { images, chosenImgIndex } = this.galleryData;

    const fileId = this.galleryData.images[chosenImgIndex].id;
    const isInFavoriteList =
      (!favouritesList.includes(images[chosenImgIndex].id) && images[chosenImgIndex].favorite) ||
      (favouritesList.includes(images[chosenImgIndex].id) && !images[chosenImgIndex].favorite);

    const actionSheet$ = from(
      this._actionSheetCtrl.create({
        cssClass: 'action-sheet-menu',
        backdropDismiss: false,
        buttons: [
          {
            text: isInFavoriteList ? 'Удалить из избранного' : 'Добавить в избранное',
            icon: isInFavoriteList
              ? 'assets/images/icons-svg/delete-from-fav.svg'
              : 'assets/images/icons-svg/add-to-fav.svg',
            data: { action: 'delete' },
            handler: () => {
              if (!this.isLoadingFavorite) {
                if (isInFavoriteList) {
                  this._store.dispatch(removeFileFromFavoritesList({ fileId: fileId }));
                } else {
                  this._store.dispatch(addFileToFavoritesList({ fileId: fileId }));
                }
              }
            },
          },
          {
            text: 'Скачать',
            icon: 'assets/images/icons-svg/save-icon.svg',
            data: { action: 'share' },
            handler: () =>
              this._getExternalImageFromURL(
                this.galleryData.images[this.galleryData.chosenImgIndex].path,
              ),
          },
          {
            text: 'Отмена',
            role: 'cancel',
            data: { action: 'cancel' },
          },
        ],
      }),
    );
    actionSheet$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();
    actionSheet$
      .pipe(
        untilDestroyed(this),
        map((modal) => modal.onWillDismiss()),
      )
      .subscribe();
  }

  public async openGalleryWithComments() {
    const slidePhotoGalleryModal = await this._modalCtrl.getTop();

    const modal = await this._modalCtrl.create({
      id: 'SlidePhotoGalleryWithCommentsComponent',
      component: SlidePhotoGalleryWithCommentsComponent,
      componentProps: { slidePhotoGalleryModal },
      mode: CurrentPlatform.ios,
      cssClass: ['modal-post-view', 'image-comments-view'],
      animated: false,
      canDismiss: true,
      breakpoints: [0, 1],
    });

    modal.present();
  }

  private _getExternalImageFromURL = (path: string) => {
    const albumName = 'Befree Social';
    this._baseHTTP
      .getImage(path)
      .pipe(
        mergeMap((res: Blob) => {
          return this._imgService.imageFileReader(res as File).pipe(
            mergeMap((base64Data) => {
              // Handle albums
              return from(Media.getAlbums()).pipe(
                mergeMap((albums: MediaAlbumResponse) => {
                  const albumIdentifierValue =
                    this.currentPlatform === CurrentPlatform.ios
                      ? albums.albums.find(
                          (albumsList: MediaAlbum) => albumsList.name === albumName,
                        )?.identifier
                      : albumName;

                  if (!albumIdentifierValue && this.currentPlatform === CurrentPlatform.ios) {
                    // Doesn't exist, create new album
                    return from(Media.createAlbum({ name: albumName })).pipe(
                      mergeMap(() => {
                        return from(Media.getAlbums()).pipe(
                          map((albumsList) => {
                            return [
                              albumsList.albums.find((a) => a.name === albumName)?.identifier ??
                                albumIdentifierValue,
                              base64Data,
                            ];
                          }),
                        );
                      }),
                    );
                  }
                  return of([albumIdentifierValue, base64Data]);
                }),
              );
            }),
            mergeMap((data: (string | null | ArrayBuffer | undefined)[]) => {
              return from(
                //Download photo to the particular album
                Media.savePhoto({
                  path: data[1] as string,
                  album: typeof data[0] === 'string' ? data[0] : albumName,
                }),
              ).pipe(
                tap(() =>
                  this._utils.openNotification(successNotificationMessages.savedPhotoToGallery),
                ),
              );
            }),
          );
        }),
      )
      .subscribe();
  };
}
