<ion-header class="ion-no-border" no-border-bottom>
  <ion-toolbar>
    <div class="title">
      <app-text
        [textSize]="TextSizes.h1Header"
        [textType]="TextType.base"
        [text]="'Фильтры'"
      ></app-text>
    </div>
    <ion-buttons slot="end">
      <app-button
        (click)="clearAllFilters()"
        class="custom-btn"
        [btnType]="Buttons.small"
        [textSize]="ButtonTextSizes.btnMainSmall"
        [color]="'white'"
        [text]="'сбросить всё'"
        [class.disable-reset-btn]="!isFiltersChosen"
        [isDisabled]="!isFiltersChosen"
      ></app-button> </ion-buttons
  ></ion-toolbar>
</ion-header>

<ion-content appIonScrollBar [forceOverscroll]="false">
  <ion-list class="ion-no-border">
    <ion-item *ngFor="let option of menuOptions" class="ion-no-border">
      <div class="option" *ngIf="!initialFilters?.[option]?.isFilterSingleButton">
        <div class="option__name" (click)="selectFilter(option)" [class.disabled]="onlyFavorite">
          <app-text
            [textSize]="TextSizes.h4Name"
            [textType]="TextType.base"
            [text]="FiltersOptionsNames[option]"
          ></app-text>
          <app-image [img]="arrowRight"></app-image>
        </div>
        <ng-container *ngIf="initialFilters?.[option]?.params?.length && !onlyFavorite">
          <app-selected-filters
            [selectedOptionData]="initialFilters?.[option]?.params"
            [selectedOptionName]="option"
          ></app-selected-filters>
        </ng-container>
      </div>
    </ion-item>
    <ion-item *ngIf="withFavoriteOption" class="favs">
      <ion-label
        ><app-text
          [textSize]="TextSizes.h4Name"
          [textType]="TextType.base"
          [text]="'избранные магазины'"
        ></app-text
      ></ion-label>
      <ion-toggle slot="end" [checked]="onlyFavorite" (ionChange)="favsChange($event)"></ion-toggle>
    </ion-item>
    <ng-content></ng-content>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <app-button
        class="button-footer"
        [btnType]="Buttons.small"
        [textSize]="ButtonTextSizes.buttonText1"
        [text]="'применить'"
        (click)="applyFilters()"
      ></app-button
    ></ion-buttons>
  </ion-toolbar>
</ion-footer>
