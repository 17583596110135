<div
  *ngIf="notify"
  class="notification-card"
  [class.notification-card_new]="isModalMode"
  [class.notification-card_modal]="isModalMode"
  [class.notification-card_border]="!isModalMode"
  [class.notification-card_disabled]="notify.entity.removed || isModalMode"
  (click)="goToEntity()"
>
  <div *ngIf="isModalMode" class="notification-card__indicator"></div>

  <div
    class="notification-card__avatar"
    [class.notification-card__avatar_border]="!notify.author.avatarPreview"
  >
    <app-image [img]="notify.author.avatarPreview | addBaseUrl"></app-image>

    <img
      *ngIf="notify.type === repliesResultTypes.like"
      src="./assets/images/icons-svg/notifications/like.svg"
      class="notification-card__type-icon"
    />

    <img
      *ngIf="notify.type === repliesResultTypes.comment"
      src="./assets/images/icons-svg/notifications/comment.svg"
      class="notification-card__type-icon"
    />
  </div>

  <div class="notification-card__content">
    <div class="notification-card__name">
      <app-text
        [text]="notify.author | getFullName"
        [textType]="TextType.base"
        [textSize]="TextSizes.h4Name"
        (click)="goToUser($event)"
      ></app-text>

      <ng-container *ngIf="currentUser$ | async as currentUser">
        <app-text
          *ngIf="notify.type === repliesResultTypes.comment"
          class="notification-card__type-text"
          [text]="notify | notificationReply"
          [textType]="TextType.base"
          [textSize]="TextSizes.mobInput"
        ></app-text>
        <app-text
          *ngIf="notify.type !== repliesResultTypes.comment"
          class="notification-card__type-text"
          [text]="notify | notificationText : currentUser.id"
          [textType]="TextType.base"
          [textSize]="TextSizes.mobInput"
        ></app-text>
      </ng-container>
    </div>

    <div class="notification-card__text" *ngIf="notificationText || notificationReplyText.length">
      <app-text
        *ngIf="notificationText"
        [text]="notificationText"
        [textType]="TextType.base"
        [textSize]="TextSizes.notification"
      ></app-text>

      <app-text
        *ngIf="notificationReplyText.length"
        [textType]="TextType.base"
        [textSize]="TextSizes.notification"
      >
        <span>{{ notificationReplyText[0] }}</span
        >{{ notificationReplyText[1] }}
      </app-text>
    </div>

    <div class="notification-card__info">
      <app-text [textType]="TextType.base" [textSize]="TextSizes.h3Body">
        <span>{{ notify.createDate | dateFormat }}</span>

        <ng-container
          *ngIf="
            !notify.entity.replyForCommentText &&
            notify.entity.type === notificationTypes.post &&
            notify.type === repliesResultTypes.comment
          "
        >
          {{ notify.entity.msg ? 'к записи:' : 'к записи' }} <br /><span>{{
            notify.entity.msg
          }}</span>
        </ng-container>
        <ng-container *ngIf="notify.entity.replyForCommentText">
          {{ 'на комментарий' }}: <br /><span>{{ notify.entity.replyForCommentText }}</span>
        </ng-container>
      </app-text>
    </div>
  </div>

  <div
    class="notification-card__preview"
    [class.notification-card__preview_centered]="showFileIcon || showPollIcon"
  >
    <div class="notification-card__image" *ngIf="showImageIcon">
      <app-image [img]="notify.entity.imagePreview | addBaseUrl"></app-image>

      <div *ngIf="showCounter" class="notification-card__overlay">
        <app-text
          [text]="'+' + (notify.entity.imageCount - 1 + '')"
          [textType]="TextType.base"
          [textSize]="TextSizes.h4Name"
        ></app-text>
      </div>
    </div>

    <div *ngIf="showFileIcon" class="notification-card__file">
      <app-image
        [img]="notify.entity.postFileName | getFilePicture : (platform$ | async) !== Platform.web"
      ></app-image>
    </div>

    <div *ngIf="showPollIcon" class="notification-card__poll">
      <app-image img="/assets/images/icons-svg/poll.svg"></app-image>
    </div>
  </div>
</div>
