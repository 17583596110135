import { createReducer, on } from '@ngrx/store';
import {
  clearDepartmentsList,
  currentDepartmentDataFailed,
  currentDepartmentDataSuccess,
  currentGroupDataFailed,
  currentGroupDataSuccess,
  currentRegionDataFailed,
  currentRegionDataSuccess,
  getAdditionalDepartmentsList,
  getAdditionalDepartmentsListFailed,
  getAdditionalDepartmentsListSuccess,
  getDepartmentsList,
  getDepartmentsListFailed,
  getDepartmentsListSuccess,
  getEmployeesList,
  getEmployeesListFailed,
  getEmployeesListSuccess,
  initCurrentDepartmentData,
  initCurrentGroupData,
  initCurrentRegionData,
} from '../actions/department.action';
import { IDepartmentState } from '../models/department.model';

const initialState: IDepartmentState = {
  currentList: [],
  isAllListFetched: false,
  department: null,
  group: null,
  region: null,
  isLoading: false,
  error: null,
};

export const departmentReducer = createReducer(
  initialState,
  on(
    initCurrentDepartmentData,
    (state: IDepartmentState): IDepartmentState => ({
      ...state,
      isAllListFetched: false,
      isLoading: true,
      department: null,
      error: null,
    }),
  ),
  on(
    initCurrentGroupData,
    initCurrentRegionData,
    (state: IDepartmentState): IDepartmentState => ({
      ...state,
      isAllListFetched: false,
      isLoading: true,
      group: null,
      region: null,
      error: null,
    }),
  ),
  on(
    currentDepartmentDataSuccess,
    (state: IDepartmentState, action): IDepartmentState => ({
      ...state,
      isLoading: false,
      department: action.department,
    }),
  ),
  on(
    getEmployeesList,
    getDepartmentsList,
    getAdditionalDepartmentsList,
    (state: IDepartmentState): IDepartmentState => ({
      ...state,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    getEmployeesListSuccess,
    (state: IDepartmentState, action): IDepartmentState => ({
      ...state,
      currentList:
        !state.currentList || action.resetData
          ? action.employeesList.elements
          : [...state.currentList, ...action.employeesList.elements],
      isAllListFetched: action.employeesList.elements.length < action.size,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    getDepartmentsListSuccess,
    getAdditionalDepartmentsListSuccess,
    (state: IDepartmentState, action): IDepartmentState => ({
      ...state,
      currentList:
        !state.currentList || action.resetData
          ? action.departmentsList
          : [...state.currentList, ...action.departmentsList],
      isAllListFetched: action.departmentsList.length < action.size,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    currentGroupDataSuccess,
    (state: IDepartmentState, action): IDepartmentState => ({
      ...state,
      isLoading: false,
      group: action.group,
    }),
  ),
  on(
    currentRegionDataSuccess,
    (state: IDepartmentState, action): IDepartmentState => ({
      ...state,
      isLoading: false,
      region: action.region,
    }),
  ),
  on(
    currentDepartmentDataFailed,
    currentGroupDataFailed,
    currentRegionDataFailed,
    getAdditionalDepartmentsListFailed,
    getEmployeesListFailed,
    getDepartmentsListFailed,
    (state: IDepartmentState, action): IDepartmentState => ({
      ...state,
      isLoading: false,
      error: action.error,
    }),
  ),
  on(
    clearDepartmentsList,
    (state: IDepartmentState): IDepartmentState => ({
      ...state,
      currentList: [],
      isAllListFetched: false,
      isLoading: false,
    }),
  ),
);
