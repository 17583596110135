import { Pipe, PipeTransform } from '@angular/core';
import { ICountryOrCity, IRegion } from '../../services/departments/departments.type';
import { IDepartment } from '../../services/posts/posts.type';
import { IShop, IUserPosition } from '../../services/user/user.type';
import { FiltersOptions } from '../../enums';
import { FiltersOptionsType } from 'src/app/data/store/models/filters.model';

@Pipe({
  name: 'disableFiltersCheckbox',
})
export class DisableFiltersCheckboxPipe implements PipeTransform {
  transform(
    currentItemId: number,
    selectedElements: (IShop | ICountryOrCity | IRegion | IDepartment | IUserPosition)[],
    activeFilterList: FiltersOptionsType | null,
  ): boolean {
    return (
      activeFilterList === FiltersOptions.potential &&
      selectedElements.length > 1 &&
      !selectedElements.find((val) => val.id === currentItemId)
    );
  }
}
