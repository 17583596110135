const ignoreErrors = [
  'isProxied',
  'ChunkLoadError',
  /Loading chunk [\d]+ failed/,
  'Load failed',
  'Failed to fetch',
  "Cannot read properties of undefined (reading 'speed')",
  /Http failure response for assets\/images\/icons-svg.+Unknown Error/,
  "undefined is not an object (evaluating 'this.params.speed')",
];

export default ignoreErrors;
