import { createReducer, on } from '@ngrx/store';
import { IPostsDataState } from '../models/posts.model';
import {
  addFileToFavoritesList,
  addFileToFavoritesListSuccess,
  addToFavoritesListFailed,
  changeEditCommentMode,
  clearFavoriteListToBeUpdated,
  clearPostData,
  closePostModal,
  createNewPost,
  createNewPostFailed,
  createNewPostSuccess,
  createPollData,
  createReplyToCommentData,
  deletePostByPostIdSuccess,
  dislikePost,
  dislikePostFailed,
  dislikePostSuccess,
  editComment,
  editCommentFailed,
  editCommentSuccess,
  editPost,
  editPostFailed,
  editPostSuccess,
  favoriteListToBeUpdated,
  getCurrentPostData,
  getCurrentPostDataFailed,
  getCurrentPostDataSuccess,
  getPostData,
  getPostDataFailed,
  getPostDataSuccess,
  initPostsData,
  likePost,
  likePostFailed,
  likePostSuccess,
  openPostModal,
  pinPostByPostId,
  pinPostByPostIdFailed,
  removeFileFromFavoritesList,
  removeFileFromFavoritesListSuccess,
  removeFromFavoritesListFailed,
  updatePostData,
  updatePostDataFailed,
  updatePostDataSuccess,
  voteInThePollAction,
  voteInThePollFailed,
  voteInThePollSuccess,
  voteParticipantsAction,
  voteParticipantsFailed,
  voteParticipantsSuccess,
} from '../actions/posts.actions';
import { IPost } from '../../../shared/services/posts/posts.type';

const initialState: IPostsDataState = {
  error: null,
  posts: [],
  allPostsFetched: false,
  currentPost: null,
  editedPost: null,
  replyTo: null,
  isLoading: false,
  isFavoritesLoading: false,
  isPostModalOpened: false,
  voteParticipants: null,
  postToBeUpdated: null,
  poll: null,
  deletedPostId: null,
  favoritesList: [],
  isEditMode: false,
  isEditCommentMode: false,
  editedCommentData: null,
  currentCommentData: null,
};

export const postsDataReducer = createReducer(
  initialState,
  on(
    initPostsData,
    clearPostData,
    (state): IPostsDataState => ({
      ...state,
      isLoading: true,
      currentPost: null,
      editedPost: null,
      replyTo: null,
      posts: [],
      voteParticipants: null,
      deletedPostId: null,
    }),
  ),
  on(
    getCurrentPostData,
    (state, action): IPostsDataState => ({
      ...state,
      isLoading: true,
      currentPost: action.isEditMode ? state.currentPost : null,
      replyTo: null,
      voteParticipants: null,
      deletedPostId: null,
      editedPost: null,
    }),
  ),

  on(
    getPostData,
    (state): IPostsDataState => ({
      ...state,
      isLoading: true,
      currentPost: null,
      replyTo: null,
      voteParticipants: null,
      deletedPostId: null,
    }),
  ),

  on(
    getPostDataSuccess,
    (state, action): IPostsDataState => ({
      ...state,
      isLoading: false,
      posts: action.resetData || !state.posts ? action.posts : [...state.posts, ...action.posts],
      allPostsFetched: action.posts.length < action.size,
    }),
  ),

  on(
    voteInThePollSuccess,
    (state, action): IPostsDataState => ({
      ...state,
      currentPost: state.currentPost
        ? { ...state.currentPost, poll: action.postTobeUpdated.poll }
        : null,
      posts: state.posts
        ? state.posts.map((post: IPost) =>
            post.id === action.postTobeUpdated.id
              ? { ...post, poll: action.postTobeUpdated.poll }
              : post,
          )
        : [],
      isLoading: true,
    }),
  ),
  on(
    voteInThePollAction,
    voteParticipantsAction,
    updatePostData,
    likePost,
    dislikePost,
    addFileToFavoritesList,
    removeFileFromFavoritesList,
    (state): IPostsDataState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    addFileToFavoritesList,
    removeFileFromFavoritesList,
    (state): IPostsDataState => ({
      ...state,
      isFavoritesLoading: true,
    }),
  ),
  on(
    removeFileFromFavoritesListSuccess,
    removeFromFavoritesListFailed,
    addFileToFavoritesListSuccess,
    addToFavoritesListFailed,
    favoriteListToBeUpdated,
    (state): IPostsDataState => ({
      ...state,
      isFavoritesLoading: false,
    }),
  ),
  on(
    addFileToFavoritesList,
    removeFileFromFavoritesList,
    (state, action): IPostsDataState => ({
      ...state,
      currentPost: state.currentPost
        ? {
            ...state.currentPost,
            file: { ...state.currentPost.file, favorite: !state.currentPost.file?.favorite },
            files: state.currentPost.files?.[0]
              ? [{ ...state.currentPost.files[0], favorite: !state.currentPost.files[0]?.favorite }]
              : [],
          }
        : null,
      posts: state.posts
        ? state.posts.map((post: IPost) =>
            post.id === action.postId
              ? {
                  ...post,
                  file: { ...post.file, favorite: !post.file?.favorite },
                  files: post.files?.[0]
                    ? [{ ...post.files[0], favorite: !post.files[0]?.favorite }]
                    : [],
                }
              : post,
          )
        : [],
      isLoading: false,
      error: null,
    }),
  ),

  on(
    pinPostByPostId,
    (state: IPostsDataState): IPostsDataState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    getCurrentPostDataSuccess,
    (state: any, action): IPostsDataState => ({
      ...state,
      currentPost: action.isEditMode ? state.currentPost : action.post,
      editedPost: action.isEditMode ? action.post : null,
      error: null,
    }),
  ),
  on(
    createReplyToCommentData,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      replyTo: action.comment,
      error: null,
    }),
  ),
  on(
    changeEditCommentMode,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      isEditCommentMode: action.isEditMode,
      currentCommentData: action.currentCommentData,
      editedCommentData: !action.isEditMode ? null : state.editedCommentData,
      error: null,
    }),
  ),
  on(
    editComment,
    (state: IPostsDataState): IPostsDataState => ({
      ...state,
      editedCommentData: null,
      isEditCommentMode: false,
      error: null,
    }),
  ),
  on(
    editCommentSuccess,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      editedCommentData: action.commentData,
      currentCommentData: null,
      error: null,
    }),
  ),
  on(
    createNewPostFailed,
    voteInThePollFailed,
    voteParticipantsFailed,
    updatePostDataFailed,
    getCurrentPostDataFailed,
    likePostFailed,
    dislikePostFailed,
    pinPostByPostIdFailed,
    getPostDataFailed,
    editPostFailed,
    editCommentFailed,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      isEditMode: false,
      isEditCommentMode: false,
      isLoading: false,
      error: action.error,
    }),
  ),
  on(
    openPostModal,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      isPostModalOpened: true,
      isEditMode: action.isEditMode ? action.isEditMode : false,
    }),
  ),
  on(
    closePostModal,
    createNewPost,
    (state: IPostsDataState): IPostsDataState => ({
      ...state,
      isPostModalOpened: false,
      isEditMode: false,
    }),
  ),
  on(
    voteParticipantsSuccess,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      voteParticipants: action.voteParticipants,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    createPollData,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      poll: action.poll,
    }),
  ),
  on(
    updatePostDataSuccess,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      currentPost: state.currentPost
        ? { ...state.currentPost, commentCount: action.postToBeUpdated.commentCount }
        : state.currentPost,
      postToBeUpdated: action.postToBeUpdated,
      posts: state.posts
        ? state.posts.map((post: IPost) =>
            post.id === action.postToBeUpdated.id
              ? { ...post, commentCount: action.postToBeUpdated.commentCount }
              : post,
          )
        : [],
      isLoading: false,
      error: null,
    }),
  ),
  on(
    likePostSuccess,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      currentPost: state.currentPost
        ? { ...state.currentPost, liked: true, likes: state.currentPost.likes + 1 }
        : null,
      posts: state.posts
        ? state.posts.map((post: IPost) =>
            post.id === action.postId ? { ...post, liked: true, likes: post.likes + 1 } : post,
          )
        : [],
      isLoading: false,
      error: null,
    }),
  ),
  on(
    dislikePostSuccess,
    (state: IPostsDataState, action): IPostsDataState => ({
      ...state,
      currentPost: state.currentPost
        ? { ...state.currentPost, liked: false, likes: state.currentPost.likes - 1 }
        : null,
      posts: state.posts
        ? state.posts.map((post: IPost) =>
            post.id === action.postId ? { ...post, liked: false, likes: post.likes - 1 } : post,
          )
        : [],
      isLoading: false,
      error: null,
    }),
  ),
  on(deletePostByPostIdSuccess, (state: IPostsDataState, action): any => ({
    ...state,
    deletedPostId: action.deletePostId,
    posts: state.posts?.filter((post) => post.id !== action.deletePostId),
  })),
  on(createNewPostSuccess, (state: IPostsDataState, action): any => ({
    ...state,
    posts: state.posts ? [action.post, ...state.posts] : null,
    poll: null,
  })),
  on(editPost, (state: IPostsDataState): any => ({
    ...state,
    posts: state.posts.map((post) =>
      post.id === state.editedPost?.id
        ? { ...post, files: [], file: {}, images: [], image: {} }
        : post,
    ),
    currentPost: null,
  })),
  on(editPostSuccess, (state: IPostsDataState, action): any => ({
    ...state,
    posts: state.posts
      ? state.posts.map((post: IPost) =>
          post.id === action.post.id
            ? {
                ...action.post,
                files: action.post.files,
                file: action.post.file,
                images: action.post.images,
                image: action.post.image,
              }
            : post,
        )
      : null,
    currentPost: action.post,
    poll: null,
    isEditMode: false,
  })),
  on(favoriteListToBeUpdated, (state: IPostsDataState, action): any => ({
    ...state,
    favoritesList:
      state.favoritesList.length && state.favoritesList.includes(action.fileId)
        ? state.favoritesList.filter((item) => item !== action.fileId)
        : state.favoritesList.length
        ? [...state.favoritesList, action.fileId]
        : [action.fileId],
  })),
  on(clearFavoriteListToBeUpdated, (state: IPostsDataState): any => ({
    ...state,
    favoritesList: [],
  })),
);
