<ng-container *ngIf="item">
  <div class="stage-card">
    <ng-container *ngIf="item && currentPlatform">
      <img
        class="stage-card__image"
        (click)="openLink()"
        [class.stage-card__image--centered]="isImageCentered"
        [src]="
          item.uploadedFile
            ? (item.name | getFilePicture : currentPlatform !== Platform.web)
            : 'assets/images/icons-svg/files/mp4.svg'
        "
      />

      <div class="stage-card__content">
        <app-text
          [text]="item.name"
          [textType]="TextType.base"
          [textSize]="TextSizes.h4Name"
          class="nav-link"
          (click)="openLink()"
        ></app-text>

        <app-text
          *ngIf="subtext"
          class="stage-card__note"
          [text]="subtext"
          [textType]="TextType.base"
          [textSize]="TextSizes.h5Post"
        ></app-text>
      </div>
    </ng-container>
  </div>

  <ion-modal [isOpen]="isVideoModalOpen" (didDismiss)="isVideoModalOpen = false">
    <ng-template>
      <ion-content scroll-y="false" [forceOverscroll]="false">
        <iframe [src]="videoUrl"></iframe>
      </ion-content>
    </ng-template>
  </ion-modal>
</ng-container>
