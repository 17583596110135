import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'photoViewCounter',
})
export class PhotoViewCounterPipe implements PipeTransform {
  transform(index: number, totalPages: number): string {
    return `${index + 1}/${totalPages}`;
  }
}
