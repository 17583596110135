import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUploadingFilesState, UPLOADING_FILES_KEY } from '../models/uploading-files.model';

export const selectFeature = createFeatureSelector<IUploadingFilesState>(UPLOADING_FILES_KEY);

export const selectUploadingFilesState = createSelector(
  selectFeature,
  (state: IUploadingFilesState) => state,
);

export const selectUploadingFilesData = createSelector(
  selectFeature,
  (state: IUploadingFilesState) => state.uploadingFiles,
);

export const selectEditModeUploadingFiles = createSelector(
  selectFeature,
  (state: IUploadingFilesState) => state.uploadingFilesOfEditedPost,
);

export const selectUploadingFilesByCategory = createSelector(
  selectFeature,
  (state: IUploadingFilesState) => state.uploadingFilesByCategory,
);

export const selectLocalId = createSelector(
  selectFeature,
  (state: IUploadingFilesState) => state.localId,
);

export const selectUploadingFilesIsLoading = createSelector(
  selectFeature,
  (state: IUploadingFilesState) => state.isLoading,
);

export const selectUploadingFiles = createSelector(
  selectUploadingFilesData,
  selectEditModeUploadingFiles,
  (uploadingFiles, editUploadingFiles) => [uploadingFiles, editUploadingFiles],
);
