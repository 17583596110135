import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { StorageType } from '../storage/storage.type';
import { IToken } from '../../interfaces';
import { from, map, mergeAll, Observable, switchMap, take } from 'rxjs';
import { CapacitorHttp, HttpOptions, HttpHeaders, Capacitor } from '@capacitor/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpResponse, QueryParams } from './base-http.type';
import { UtilsService } from '../utils.service';
import { errorNotificationMessages } from '../../constants';
import { CurrentPlatform, RequestNesting } from '../../enums';
import { environment } from 'src/environments/environment';
import { ConnectionStatus, Network } from '@capacitor/network';

const AUTH_HEADER = 'x-auth-token';

const DEFAULT_HEADERS = {
  Accept: 'application/json, text/plain, */*',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  'Sec-Fetch-Site': 'same-origin',
  'Content-Type': 'application/json',
};

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  private _baseHref = '/api/v1';

  private _isConnectedToNetwork: boolean | undefined;

  public currentPlatform: string | undefined;

  constructor(
    private _storageService: StorageService,
    private _utils: UtilsService,
    private _http: HttpClient,
  ) {
    this.currentPlatform = Capacitor.getPlatform();
    if (this.currentPlatform !== CurrentPlatform.web) {
      this._baseHref = environment.befreeUrl + '/api/v1';
    }
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      this._isConnectedToNetwork = status.connected;
      if (!status.connected && this.currentPlatform !== CurrentPlatform.web) {
        this._utils.openNotification(errorNotificationMessages.networkError, true);
      }
    });

    from(Network.getStatus()).subscribe((status) => {
      this._isConnectedToNetwork = status.connected;
    });
  }

  private _createDefaultHeaders(headersToSet?: HttpHeaders): Observable<HttpHeaders> {
    let headers = headersToSet ? { ...headersToSet } : { ...DEFAULT_HEADERS };

    return this._storageService.getItem<IToken>(StorageType.Token).pipe(
      map((token: IToken) => {
        if (token?.access_token) {
          Object.assign(headers, { [AUTH_HEADER]: `${token.access_token}` });
          return headers;
        }

        return headers;
      }),
    );
  }

  public createHeaders(headers: HttpHeaders) {
    return this._createDefaultHeaders(headers);
  }

  public getImage(url: string) {
    const HEADERS = { 'Cache-Control': 'no-cache' };
    return this.createHeaders(HEADERS).pipe(
      take(1),
      switchMap(async (headers) => {
        return { headers: headers };
      }),
      switchMap(async ({ headers: { [AUTH_HEADER]: auth, ...headers } }) => {
        return this._http.get(environment.befreeUrl + url, { responseType: 'blob', headers });
      }),
      mergeAll(),
    );
  }

  public get<R>(
    url: string,
    params?: QueryParams,
    type?: RequestNesting,
  ): Observable<HttpResponse<R>['data']> {
    return this._createDefaultHeaders().pipe(
      take(1),
      switchMap(async (headers) => {
        return {
          url: this._baseHref + url,
          params: this._removeNullParams(params) || undefined,
          headers: headers,
        };
      }),
      switchMap((options) => {
        return from(this._doCapacitorHttp<R>(options, 'get', type));
      }),
    );
  }

  public post<R>(
    url: string,
    data?: QueryParams,
    type?: RequestNesting,
  ): Observable<HttpResponse<R>['data']> {
    return this._createDefaultHeaders().pipe(
      take(1),
      switchMap(async (headers) => {
        return {
          url: this._baseHref + url,
          data: data,
          headers: headers,
        };
      }),
      switchMap((options) => {
        return from(this._doCapacitorHttp<R>(options, 'post', type));
      }),
    );
  }

  public put<R>(
    url: string,
    data?: QueryParams,
    type?: RequestNesting,
  ): Observable<HttpResponse<R>['data']> {
    return this._createDefaultHeaders().pipe(
      take(1),
      switchMap(async (headers) => {
        return {
          url: this._baseHref + url,
          data: data,
          headers: headers,
        };
      }),
      switchMap((options) => {
        return from(this._doCapacitorHttp<R>(options, 'put', type));
      }),
    );
  }

  public postFormData<R>(url: string, data: FormData): Observable<HttpResponse<R>['data']> {
    return this.createHeaders({}).pipe(
      take(1),
      switchMap(async (headers) =>
        this._http
          .post(`${environment.befreeUrl}${this._baseHref}${url}`, data, {
            headers,
          })
          .pipe(map((response) => this._handleResponse(response, RequestNesting.withoutData))),
      ),
      mergeAll(),
    );
  }

  public delete<R>(url: string, type?: RequestNesting): Observable<HttpResponse<R>['data']> {
    return this._createDefaultHeaders().pipe(
      take(1),
      switchMap(async (headers) => {
        return {
          url: this._baseHref + url,
          headers: headers,
        };
      }),
      switchMap((options) => {
        return from(this._doCapacitorHttp<R>(options, 'delete', type));
      }),
    );
  }

  private async _doCapacitorHttp<R>(
    options: HttpOptions,
    key: 'get' | 'put' | 'post' | 'delete',
    type?: RequestNesting,
  ): Promise<HttpResponse<R>['data']> {
    if (!this._isConnectedToNetwork && this.currentPlatform !== CurrentPlatform.web) {
      this._utils.openNotification(errorNotificationMessages.networkError, true);
    } else if (!this._isConnectedToNetwork && this.currentPlatform === CurrentPlatform.web) {
      this._utils.openNotification(errorNotificationMessages.default, true);
    }
    if (key === 'delete') {
      return CapacitorHttp.request({ ...options, method: 'delete' })
        .then((response) => {
          return this._handleResponse(response, type);
        })
        .catch((e) => this._handleError(e));
    }
    return CapacitorHttp[key](options)
      .then((response) => {
        return this._handleResponse(response, type);
      })
      .catch((e) => this._handleError(e));
  }

  private _handleResponse = (response: any, type?: RequestNesting) => {
    const authHeaderValue = Object.entries(response.headers || {}).find(
      ([key]) => key.toLowerCase() === AUTH_HEADER,
    )?.[1];

    if (authHeaderValue) {
      this._storageService.setItem(StorageType.Token, {
        access_token: authHeaderValue,
      });
    }
    if (response.data.error) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new HttpErrorResponse(response.data);
    } else {
      return type === RequestNesting.withoutData ? response.data : response.data.data;
    }
  };

  private _handleError(e: HttpErrorResponse): Observable<Error> {
    //need to pull up a list of errors
    //need to connect the notification service
    const code = e.status;

    if (code === 403) {
      this._storageService.clearStorage();
    } else if (!e?.error?.details?.includes('No posts pinned') && e?.error?.code !== 401) {
      this._utils.openNotification(errorNotificationMessages.default, true);
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw e;
  }

  private _removeNullParams(params: QueryParams | undefined): {} | null {
    if (!params) return null;

    return Object.entries(params).reduce(
      // eslint-disable-next-line no-param-reassign
      (a: QueryParams, [k, v]) => (v == null || v === '' ? a : ((a[k] = v.toString()), a)),
      {},
    );
  }
}
