import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonTextSizes, TextSizes, TextType } from '../../../../../../../shared/enums';
import { NotificationTypes, befreeUrlValues } from '../../../../../../../shared/constants';
import { StorageService } from '../../../../../../../shared/services/storage/storage.service';
import { selectNotifications } from 'src/app/data/store/selectors/notifications.selectors';
import { setNotifications } from 'src/app/data/store/actions/notifications.action';
import { INotifications } from 'src/app/shared/services/notifications/notifications.type';

@UntilDestroy()
@Component({
  selector: 'app-training-notification',
  templateUrl: './training-notification.component.html',
  styleUrls: ['./training-notification.component.scss'],
})
export class TrainingNotificationComponent implements OnInit {
  private allNotifications: INotifications[] = [];

  public trainingNotifications: INotifications[] = [];

  public readonly TextType = TextType;

  public readonly TextSize = TextSizes;

  public readonly ButtonTextSizes = ButtonTextSizes;

  constructor(private store: Store, private router: Router, private localStorage: StorageService) {}

  ngOnInit(): void {
    this.store
      .select(selectNotifications)
      .pipe(untilDestroyed(this))
      .subscribe((newNotifications) => {
        this.trainingNotifications = newNotifications.filter(
          (n) => n.notificationType === NotificationTypes.trainingNotification,
        );
      });
  }

  close(message?: string) {
    this.store.dispatch(
      setNotifications({
        reset: true,
        notifications: this.allNotifications.filter((n) =>
          message
            ? n.message !== message
            : n.notificationType !== NotificationTypes.trainingNotification,
        ),
      }),
    );
  }

  goToEmployeeDevelopmentPage() {
    this.router.navigate(
      [befreeUrlValues.dashboard, befreeUrlValues.training, befreeUrlValues.employeeDevelopment],
      { state: { notification: true } },
    );
    this.close();
  }
}
