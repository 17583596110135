<div class="notifications-modal">
  <div class="notifications-modal__header">
    <app-text
      [textSize]="TextSizes.h1Header"
      [textType]="TextType.base"
      [text]="'УВЕДОМЛЕНИЯ'"
    ></app-text>
    <div class="notifications-modal__header-more" (click)="goToNotificationPage()">
      <app-text
        [textSize]="TextSizes.h6BtnSmall"
        [textType]="TextType.base"
        [text]="'ещё'"
      ></app-text>
      <app-image [img]="'./assets/images/icons-svg/notifications/arrow-more.svg'"></app-image>
    </div>
  </div>

  <ion-content appIonScrollBar [forceOverscroll]="false">
    <app-common-notifications-list [isModalMode]="true"></app-common-notifications-list>
  </ion-content>
</div>
