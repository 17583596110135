import { Injectable } from '@angular/core';
import { FiltersOptionsType } from 'src/app/data/store/models/filters.model';
import { IFilter } from '../../interfaces';
import { BaseHttpService } from '../base-http/base-http.service';
import { IDepartment, IDepartmentArray } from '../posts/posts.type';
import { IShop, IShopArray } from '../user/user.type';
import { ICountryOrCityArray, IRegionArray } from './departments.type';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  private readonly _adminHref = '/admin';

  private readonly _departmentsUrl = `/departments`;

  private readonly _locationUrl = `/location`;

  private readonly _users = `/users`;

  private readonly _positions = `/positions`;

  private readonly _trainers = `/trainers`;

  private readonly _shopUrl = `/shop`;

  private readonly _regionUrl = `/region`;

  private readonly _citiesUrl = `/cities`;

  private readonly _pagedUrl = `/paged`;

  private readonly _favsUrl = `/favs`;

  private readonly _filtersOptionsMethod: { [key in FiltersOptionsType]?: string } = {
    REGIONS: `${this._departmentsUrl}${this._regionUrl}${this._pagedUrl}`,
    SHOPS: `${this._departmentsUrl}${this._shopUrl}${this._pagedUrl}`,
    CITIES: `${this._locationUrl}${this._citiesUrl}`,
    DEPTS: `${this._departmentsUrl}${this._pagedUrl}`,
    GROUPS: `${this._departmentsUrl}${this._pagedUrl}`,
  };

  private readonly _filtersCurrentEntityMethod: { [key in FiltersOptionsType]?: string } = {
    REGIONS: `${this._departmentsUrl}`,
    SHOPS: `${this._departmentsUrl}`,
    CITIES: `${this._locationUrl}${this._citiesUrl}`,
    DEPTS: `${this._departmentsUrl}`,
    GROUPS: `${this._departmentsUrl}`,
    TRAINER: `${this._users}`,
    POSITIONS: `${this._positions}`,
  };

  constructor(private _http: BaseHttpService) {}

  public getDepartmentById(id: number) {
    return this._http.get<IDepartment>(`${this._departmentsUrl}/${id}`);
  }

  public getDepartments(filters?: IFilter) {
    return this._http.get<IDepartmentArray>(`${this._departmentsUrl}${this._pagedUrl}`, filters);
  }

  public getDepartmentsAsAdmin(filters?: IFilter) {
    return this._http.get<IDepartmentArray>(
      `${this._adminHref}${this._departmentsUrl}${this._pagedUrl}`,
      filters,
    );
  }

  public getShops(filters?: IFilter, onlyFavorites?: boolean) {
    return this._http.get<IShopArray>(
      `${this._departmentsUrl}${this._shopUrl}${onlyFavorites ? this._favsUrl : this._pagedUrl}`,
      filters,
    );
  }

  public getShopsAsAdmin(filters?: IFilter) {
    return this._http.get<IShopArray>(
      `${this._adminHref}${this._departmentsUrl}${this._shopUrl}${this._pagedUrl}`,
      filters,
    );
  }

  public getCurrentShop(id: number) {
    return this._http.get<IShop>(`${this._departmentsUrl}/${id}`);
  }

  public getCurrentEntity(id: number, filter: FiltersOptionsType) {
    return this._http.get(`${this._filtersCurrentEntityMethod[filter]}/${id}`);
  }

  public getParamsForFilters(optionsType: FiltersOptionsType, filters?: IFilter) {
    return this._http.get<IRegionArray | ICountryOrCityArray | IShopArray>(
      `${this._filtersOptionsMethod[optionsType]}`,
      filters,
    );
  }

  public getAnyDepartment(id: number) {
    return this._http.get<IDepartment>(`${this._departmentsUrl}/${id}`);
  }

  public getAdditionalDepartmentLists(id: number, filters?: IFilter) {
    return this._http.get<any>(`${this._departmentsUrl}/${id}${this._pagedUrl}`, filters);
  }
}
