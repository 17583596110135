<div class="update-page">
  <img src="assets/images/logo.png" alt="логотип Befree" class="update-page__logo" />

  <div class="update-page__content">
    <h1 class="update-page__title">обновите приложение</h1>

    <div class="update-page__image">
      <img src="assets/images/update.png" alt="облако со стрелочками" />
    </div>

    <p *ngIf="(appUpdateInfo$ | async)?.message as message" class="update-page__text">
      {{ message }}
    </p>

    <div class="update-page__button">
      <app-button text="обновить" btnType="button-base" (click)="onUpdateClick()"></app-button>
    </div>
  </div>
</div>
