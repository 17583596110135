import { App } from '@capacitor/app';
import { Component } from '@angular/core';
import { mobileAppUrl } from 'src/app/shared/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CurrentPlatform } from 'src/app/shared/enums';
import { Store } from '@ngrx/store';
import { selectAppUpdateData } from 'src/app/data/store/selectors/platform.selectors';

@Component({
  selector: 'app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['./app-update.component.scss'],
})
export class AppUpdateComponent {
  public appUpdateInfo$ = this._store.select(selectAppUpdateData);

  constructor(private _store: Store, private _utils: UtilsService) {}

  public onUpdateClick() {
    const platform = this._utils.getCurrentPlatform();
    if (platform) {
      window.open(mobileAppUrl[platform], '_blank');
    }
    if (platform === CurrentPlatform.android) {
      App.exitApp();
    }
  }
}
