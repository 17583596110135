<ion-list class="pagination-container">
  <ng-content></ng-content>
</ion-list>
<ion-infinite-scroll (ionInfinite)="onScroll($event)">
  <ion-infinite-scroll-content [class.loading]="showLoading">
    <div *ngIf="isAllLoaded && !paginationData?.length && !showLoading && noDataText" class="empty">
      <app-text
        [textSize]="TextSizes.h5Post"
        [textType]="TextType.base"
        [text]="noDataText"
      ></app-text>
    </div>
    <div *ngIf="showLoading" class="spinner-block">
      <ion-spinner></ion-spinner>
    </div>
  </ion-infinite-scroll-content>
</ion-infinite-scroll>
