import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'addBaseUrl',
})
export class AddBaseUrlPipe implements PipeTransform {
  transform(src: string | undefined | null): string {
    if (src) {
      return environment.befreeUrl + src;
    }
    return '';
  }
}
