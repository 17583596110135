import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IComment, ICommentReply, IImage } from '../../services/posts/posts.type';
import { ModalController } from '@ionic/angular';
import { selectCurrentPlatform } from '../../../data/store/selectors/platform.selectors';
import { Store } from '@ngrx/store';
import { NavigationStart, Router } from '@angular/router';
import { filter, first, map, of, Subscription, switchMap, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CurrentPlatform,
  DataType,
  ImagePreviewType,
  ScrollToType,
  TextSizes,
  TextType,
  UserDataImgSize,
  ViewType,
} from '../../enums';
import SwiperCore, { Keyboard as SwiperKeyboard, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { UtilsService } from '../../services/utils.service';
import { PhotoReportsService } from '../../services/photo-reports/photo-reports.service';
import {
  selectCommentEditMode,
  selectFavoritesIsLoading,
  selectFavouritesListToBeUpdated,
  selectReplyTo,
} from '../../../data/store/selectors/posts.selectors';
import { ScrollToService } from '../../services/scroll-to/scroll-to.service';
import { selectLikedLIst } from '../../../data/store/selectors/photo-report.selectors';
import deepClone from '../../utils/deepClone';
import { Actions, ofType } from '@ngrx/effects';
import {
  addFileToFavoritesList,
  changeEditCommentMode,
  createReplyToCommentData,
  editComment,
  editCommentSuccess,
  removeFileFromFavoritesList,
} from 'src/app/data/store/actions/posts.actions';
import { addPhotoReportComment } from 'src/app/data/store/actions/photo-report.action';
import { Keyboard } from '@capacitor/keyboard';
import { TPushNavigationPayload } from '../../services/push/push.type';
import { IGalleryState } from 'src/app/data/store/models/gallery.model';
import { selectGalleryData } from 'src/app/data/store/selectors/gallery.selectors';
import {
  changeGalleryDataImageIndex,
  resetGalleryData,
  setGalleryImages,
} from 'src/app/data/store/actions/gallery.action';
import { SlidePhotoGalleryFullscreenComponent } from '../slide-photo-gallery-fullscreen/slide-photo-gallery-fullscreen.component';

SwiperCore.use([SwiperKeyboard]);

@UntilDestroy()
@Component({
  selector: 'app-slide-photo-gallery-with-comments',
  templateUrl: './slide-photo-gallery-with-comments.component.html',
  styleUrls: ['./slide-photo-gallery-with-comments.component.scss'],
})
export class SlidePhotoGalleryWithCommentsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public commentToScrollData?: TPushNavigationPayload;

  @Input() public slidePhotoGalleryModal?: HTMLIonModalElement;

  @ViewChild('commentsView') webContent?: ElementRef<HTMLDivElement>;

  @ViewChild('columnsView') mobileContent?: ElementRef<HTMLDivElement>;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @ViewChild('commentContainer') commentContainer?: ElementRef<HTMLDivElement>;

  readonly CurrentPlatform = CurrentPlatform;

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  readonly UserDataImgSize = UserDataImgSize;

  readonly ViewTypesList = ViewType;

  readonly DataTypesList = DataType;

  readonly ImagePreviewType = ImagePreviewType;

  public galleryData: IGalleryState = { images: [], chosenImgIndex: 0 };

  public favouritesListToBeUpdated$ = this._store.select(selectFavouritesListToBeUpdated);

  public isLoadingFavorite$ = this._store.select(selectFavoritesIsLoading);

  public openImageId?: number;

  public currentPlatform: CurrentPlatform | null = null;

  public currentPlatform$ = this._store.select(selectCurrentPlatform);

  public selectLikedList$ = this._store.select(selectLikedLIst);

  public replyToComment$ = this._store.select(selectReplyTo);

  public imagesList: (IImage & { photoReportId?: number })[] = [];

  public commentToScroll: TPushNavigationPayload | null = null;

  public page = 0;

  public size = 20;

  public isAllCommentsFetched = false;

  public isLoading: boolean = true;

  public isFirstLoading: boolean = true;

  public comments: IComment[] = [];

  public commentsCount = 0;

  public newComment: IComment | null = null;

  public keyboardOpen: boolean = false;

  public editCommentId: number | null = null;

  private _totalElements: number = 0;

  public config?: SwiperOptions;

  private _routerSubscription?: Subscription;

  constructor(
    private _modalCtrl: ModalController,
    private _store: Store,
    private _cdr: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _photoReportsService: PhotoReportsService,
    private _scrollToService: ScrollToService,
    private _actions$: Actions,
    private _router: Router,
  ) {
    this._resetEdit();
    this._resetReply();

    this._routerSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        if (this.slidePhotoGalleryModal) {
          this.slidePhotoGalleryModal.animated = false;
          this.slidePhotoGalleryModal.dismiss();
        }
      });
  }

  ngOnInit(): void {
    this._store
      .select(selectGalleryData)
      .pipe(untilDestroyed(this))
      .subscribe((galleryData) => {
        this._utilsService.changeSwiperSlide({
          swiper: this.swiper?.swiperRef!,
          modalId: 'SlidePhotoGalleryWithCommentsComponent',
          prevIndex: this.galleryData.chosenImgIndex,
          currentIndex: galleryData.chosenImgIndex,
        });

        this.galleryData = galleryData;

        this.config = {
          loop: !this.galleryData.isLoopDisabled && this.galleryData.images.length > 1,
          initialSlide: this.galleryData.chosenImgIndex,
          spaceBetween: 20,
          slidesPerView: 1,
          centeredSlides: true,
        };

        this.imagesList = deepClone(this.galleryData.images);
        this.commentsCount = this.imagesList[this.galleryData.chosenImgIndex]?.commentCount || 0;
        this.commentToScroll = this.commentToScrollData || null;
      });

    this._actions$.pipe(ofType(editCommentSuccess)).subscribe((action) => {
      this._updateMainList(action.commentData, true);
      this._store.dispatch(
        changeEditCommentMode({
          isEditMode: false,
          currentCommentData: {},
        }),
      );
    });

    this.currentPlatform$?.subscribe((platform) => {
      this.currentPlatform = platform;
      this._subscribeToScroll(platform);
      if (platform !== CurrentPlatform.web) {
        Keyboard?.addListener('keyboardWillShow', this.onKeyboardWillShow.bind(this));
        Keyboard?.addListener('keyboardWillHide', this.onKeyboardWillHide.bind(this));
      }
    });

    this._store
      .select(selectCommentEditMode)
      .pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        if (!isEditMode) {
          this.editCommentId = null;
        }
      });

    this._modalCtrl
      .getTop()
      .then((modal) => {
        modal
          ?.onWillDismiss()
          .then(() => {
            this._resetEdit();
            this._resetReply();
          })
          .catch(() => {});
      })
      .catch(() => {});
  }

  ngOnDestroy(): void {
    this._routerSubscription?.unsubscribe();
    if (this.currentPlatform === CurrentPlatform.web || this.commentToScrollData) {
      this._store.dispatch(resetGalleryData());
    }
  }

  ngAfterViewInit(): void {
    this.getComments();
  }

  public getComments(showLastPage?: boolean, slideChanged?: boolean) {
    if (this.galleryData.hideComments || !this.imagesList[this.galleryData.chosenImgIndex]?.id) {
      return;
    }

    this.isLoading = true;
    this._photoReportsService
      .getImagesComments(this.imagesList[this.galleryData.chosenImgIndex].id, {
        page: this.page,
        size: this.size,
        sort: 'id,desc',
      })
      .pipe(first())
      .subscribe((commentsArray) => {
        if (!commentsArray) {
          return;
        }

        this._totalElements = commentsArray.totalElements;

        this.isLoading = false;
        this.isFirstLoading = false;

        if (showLastPage || slideChanged) {
          this.comments = commentsArray.elements;
        } else {
          this.comments = [...this.comments, ...commentsArray.elements];
        }

        this.isAllCommentsFetched = this.comments.length === commentsArray.totalElements;

        this._scrollToComment();
      });
  }

  public createImageData(
    text: string,
    type: string,
    reply: Partial<ICommentReply> | null,
  ): Partial<IComment> {
    if (reply) {
      return { text: text, type: type, replayFor: { type: reply.type, id: reply.id } };
    }
    return { text: text, type: type };
  }

  public createComment(commentData: { text: string; commentId?: number; isEditMode: boolean }) {
    if (!commentData.text) return;

    this.replyToComment$
      .pipe(
        take(1),
        switchMap((reply) => {
          let data = this.createImageData(commentData.text, 'Image', reply);
          if (commentData.isEditMode && commentData.commentId) {
            this._store.dispatch(
              editComment({
                commentId: commentData.commentId,
                commentData: data,
              }),
            );
            return of('');
          } else {
            if (this.imagesList[this.galleryData.chosenImgIndex]?.id) {
              return this._photoReportsService
                .addCommentsToImage(this.imagesList[this.galleryData.chosenImgIndex].id, data)
                .pipe(
                  take(1),
                  map((imagesComment) => {
                    this._updateImageCommentsCount();
                    if (!imagesComment.replayFor) {
                      this._updateMainList(imagesComment);
                    } else {
                      this.newComment = imagesComment;
                    }
                    this._cdr.detectChanges();
                  }),
                );
            }
            return of('');
          }
        }),
      )
      .subscribe();

    this.editCommentId = null;
    this._store.dispatch(
      changeEditCommentMode({
        isEditMode: false,
        currentCommentData: {},
      }),
    );
  }

  public onScroll(event: Event): void {
    const element = event.target as HTMLDivElement;
    if (
      element.scrollHeight - (element.scrollTop + element.clientHeight) <= 100 &&
      !this.isAllCommentsFetched &&
      !this.isLoading
    ) {
      this.page += 1;
      this.getComments();
    }
  }

  public slideChanged() {
    if (
      this.swiper?.swiperRef.realIndex == null ||
      this.swiper?.swiperRef.realIndex === this.galleryData.chosenImgIndex
    ) {
      return;
    }

    this.page = 0;
    this.comments = [];
    this._store.dispatch(changeGalleryDataImageIndex({ index: this.swiper.swiperRef.realIndex }));
    this.openImageId = undefined;
    this.isFirstLoading = true;
    this.getComments(false, true);
    this.commentsCount = this.imagesList[this.galleryData.chosenImgIndex]?.commentCount || 0;
    this._cdr.detectChanges();
  }

  public isFileNotFav(favouritesListToBeUpdated: number[]) {
    const { id, favorite } = this.galleryData.images[this.galleryData.chosenImgIndex || 0] || {};
    const isInFavouritesList = favouritesListToBeUpdated.includes(id);
    return (isInFavouritesList && favorite) || (!isInFavouritesList && !favorite);
  }

  public onFavoriteButtonClick(favouritesListToBeUpdated: number[]) {
    const fileId = this.galleryData.images[this.galleryData.chosenImgIndex || 0].id;
    if (this.isFileNotFav(favouritesListToBeUpdated)) {
      this._store.dispatch(addFileToFavoritesList({ fileId }));
    } else {
      this._store.dispatch(removeFileFromFavoritesList({ fileId }));
    }
  }

  public goBack() {
    this._utilsService.dismissModal();
  }

  public changeCommentEditMode = (comment: IComment, editMode?: boolean) => {
    if (!comment) {
      return;
    }

    if (this.editCommentId === comment.id || editMode === false) {
      this.editCommentId = null;
    } else {
      this.editCommentId = comment.id;
    }

    this._store.dispatch(
      changeEditCommentMode({
        isEditMode: editMode === false ? false : this.editCommentId === comment.id,
        currentCommentData: editMode === false ? {} : comment,
      }),
    );
  };

  public onKeyboardWillShow() {
    this.keyboardOpen = true;
    setTimeout(() => this._scrollToBottom(), 500);
    this.commentContainer?.nativeElement.classList.remove('gallery__comment_padding');
  }

  public onKeyboardWillHide() {
    this.keyboardOpen = false;
    this.commentContainer?.nativeElement.classList.add('gallery__comment_padding');
  }

  public closeModal() {
    this._utilsService.dismissModal();
  }

  public trackByFn(_index: number, comment: IComment) {
    return comment.id;
  }

  public async openFullscreenModal() {
    const modal = await this._modalCtrl.create({
      id: 'SlidePhotoGalleryFullscreenComponent',
      component: SlidePhotoGalleryFullscreenComponent,
      mode: CurrentPlatform.ios,
      cssClass: 'fullscreen-gallery-modal',
      animated: false,
      canDismiss: true,
      breakpoints: [0, 1],
    });

    modal.present();
  }

  public async handleBackgroundClick(event: MouseEvent) {
    if (this.keyboardOpen) {
      await Keyboard.hide();
      event.stopPropagation();
    }
  }

  private _scrollToComment() {
    if (!this.comments.length || !this.commentToScroll) {
      return;
    }

    this._utilsService.scrollToComment(this.commentToScroll).then(() => {
      this.commentToScroll = null;
    });
  }

  private _updateImageCommentsCount() {
    if (this.imagesList[this.galleryData.chosenImgIndex]) {
      this._store.dispatch(
        addPhotoReportComment({
          index: this.galleryData.chosenImgIndex,
          category: this.imagesList[this.galleryData.chosenImgIndex].imageCategory,
        }),
      );
      const newImages = [...this.imagesList];
      newImages[this.galleryData.chosenImgIndex].commentCount += 1;
      this.commentsCount = newImages[this.galleryData.chosenImgIndex].commentCount;
      this.imagesList = newImages;
      this._store.dispatch(setGalleryImages({ images: newImages }));
    }
  }

  private _updateMainList(comment: IComment, isEditMode?: boolean) {
    if (isEditMode) {
      const comments = [...this.comments];
      const index = this.comments.findIndex((c) => c.id === comment.id);
      if (index > -1) {
        comments[index] = comment;
        this.comments = comments;
      }
    } else {
      this.comments = [...this.comments, comment];
    }
    this.page = Math.trunc(this._totalElements! / this.size);
    this.getComments(true);
    this._scrollToBottom();
  }

  private _scrollToBottom() {
    setTimeout(() => {
      this._scrollToService.scrollToEvent({
        scrollTo: ScrollToType.bottom,
        isModalView: true,
      });
    });
  }

  private _subscribeToScroll(platform: CurrentPlatform | null) {
    this._scrollToService
      .scrollToEventListener()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const contentView = platform === CurrentPlatform.web ? this.webContent : this.mobileContent;

        contentView?.nativeElement.scrollTo({
          top: contentView.nativeElement.scrollHeight,
          behavior: 'smooth',
        });
      });
  }

  private _resetEdit() {
    this._store.dispatch(changeEditCommentMode({ isEditMode: false, currentCommentData: {} }));
  }

  private _resetReply() {
    this._store.dispatch(createReplyToCommentData({ comment: null }));
  }
}
