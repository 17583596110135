<div class="fs-gallery">
  <ng-container *ngIf="imagesList.length > 1">
    <div class="fs-gallery__arrow fs-gallery__arrow--left">
      <button
        type="button"
        [disabled]="galleryData.isLoopDisabled && swiper?.swiperRef?.realIndex === 0"
      >
        <app-image
          img="assets/images/icons-svg/arrow-right-big.svg"
          (click)="swiper?.swiperRef?.slidePrev()"
          [rotateAngle]="180"
        ></app-image>
      </button>
    </div>

    <div class="fs-gallery__arrow fs-gallery__arrow--right">
      <button
        type="button"
        [disabled]="
          galleryData.isLoopDisabled && swiper?.swiperRef?.realIndex === imagesList.length - 1
        "
      >
        <app-image
          img="assets/images/icons-svg/arrow-right-big.svg"
          (click)="swiper?.swiperRef?.slideNext()"
        ></app-image>
      </button>
    </div>
  </ng-container>

  <swiper
    #swiper
    *ngIf="!!swiperConfig"
    class="fs-gallery__swiper"
    [zoom]="{ toggle: false }"
    [config]="swiperConfig"
    [keyboard]="true"
    [shortSwipes]="false"
    [slidesPerView]="1"
    [longSwipesRatio]="0.05"
    (slideChange)="onSlideChange()"
  >
    <ng-template swiperSlide *ngFor="let img of imagesList">
      <div class="swiper-zoom-container">
        <img [src]="img | getImagePreview : ImagePreviewType.fullScreen" (click)="toggleZoom()" />
      </div>
    </ng-template>
  </swiper>

  <button type="button" class="fs-gallery__close" (click)="closeModal()">
    <app-svg icon="close"></app-svg>
  </button>
</div>
