<ng-container
  *ngIf="likedListToBeUpdated && (favouritesListToBeUpdated$ | async) as favouritesListToBeUpdated"
>
  <ion-header class="ion-no-border" no-border-bottom>
    <ion-toolbar>
      <div class="header">
        <img
          src="/assets/images/icons-svg/arrow-right.svg"
          class="header__back"
          (click)="goBack()"
        />

        <app-text
          [text]="galleryData.chosenImgIndex | photoViewCounter : galleryData.images.length"
          [textSize]="TextSizes.h1Header"
          [textType]="TextType.base"
        ></app-text>

        <img
          src="assets/images/icons-svg/posts/post-menu.svg"
          (click)="handleOpenModalMenu(favouritesListToBeUpdated)"
        />
      </div>
    </ion-toolbar>
  </ion-header>

  <ion-content [forceOverscroll]="false">
    <div class="gallery">
      <swiper
        *ngIf="config"
        #slides
        [zoom]="zoomSettings"
        [config]="config"
        (slideChange)="slideChanged()"
      >
        <ng-template swiperSlide *ngFor="let img of galleryData.images">
          <div class="swiper-zoom-container">
            <img [src]="img | getImagePreview : ImagePreviewType.fullScreen" />
          </div>
        </ng-template>
      </swiper>

      <div class="gallery__title" *ngIf="!!galleryData.photoReportData">
        <b>{{ galleryData.photoReportData.name }} ({{ galleryData.photoReportData.city }})</b>
        <b>{{ galleryData.photoReportData.category }}</b>
      </div>
    </div>
  </ion-content>

  <ion-footer>
    <app-post-information
      *ngIf="!galleryData.hideInputs"
      [postId]="galleryData.images[galleryData.chosenImgIndex]?.id"
      [viewType]="ViewTypesList.gallery"
      [postIsLiked]="
        likedListToBeUpdated | isImageLiked : galleryData.images[galleryData.chosenImgIndex]
      "
      [postLikesCount]="
        likedListToBeUpdated | getImagesLikesCount : galleryData.images[galleryData.chosenImgIndex]
      "
      [postCommentsCount]="galleryData.images[galleryData.chosenImgIndex].commentCount"
      [class.liked-icon]="
        likedListToBeUpdated | isImageLiked : galleryData.images[galleryData.chosenImgIndex]
      "
      [onCommentIconClick]="openGalleryWithComments"
    ></app-post-information>
  </ion-footer>
</ng-container>
