import { Component, Input } from '@angular/core';
import { SpinnerColor } from '../../enums';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() color: SpinnerColor = SpinnerColor.default;

  @Input() isLoading: boolean | undefined = false;
}
