import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentPlatform, TextSizes, TextType } from 'src/app/shared/enums';
import { UtilsService } from '../../services/utils.service';
import { GetFilePicturePipe } from '../../pipe/get-file-picture/get-file-picture.pipe';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() mode: string = '';

  @Input() fileTitle: string | undefined;

  @Input() fileSubtitle: number | string | undefined = '';

  @Input() isRemoved: boolean = false;

  @Input() isLoading: boolean = false;

  @Input() type: string | undefined;

  @Input() itemId: number | undefined;

  @Input() postFileView: boolean | undefined;

  @Input() inFavouritesList: boolean | undefined;

  @Input() filePath: string | undefined;

  @Input() currentPlatform: CurrentPlatform | null = null;

  @Input() isDisabled: boolean = false;

  @Output() deleteItem: EventEmitter<string | undefined> = new EventEmitter();

  @Output() changeFavouritesStatus: EventEmitter<string | undefined> = new EventEmitter();

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  constructor(private _utilsService: UtilsService, private _getFilePicture: GetFilePicturePipe) {}

  handleDeleteItem(itemId: number | undefined) {
    this.deleteItem.emit(itemId?.toString());
  }

  handleChangeFavouritesStatus(itemId: number | undefined) {
    this.changeFavouritesStatus.emit(itemId?.toString());
  }

  public downloadFile() {
    if (this.type !== 'poll' && this.mode !== 'create') {
      this._utilsService.downloadFile(this.fileTitle, this.filePath);
    }
  }

  public async openLinkMob() {
    if (this.filePath) {
      if (this.filePath.includes('pdf') && this.currentPlatform === CurrentPlatform.android) {
        this._utilsService.openPdfModal(this.filePath, this.fileTitle);
      } else {
        await this._utilsService.openUrlInBrowser(this.filePath);
      }
    }
  }

  public getFilePreview(isMobile: boolean) {
    if (this.type === 'poll') {
      return 'assets/images/icons-svg/post-creation/poll-to-upload.svg';
    }

    return this.isRemoved
      ? 'assets/images/icons-svg/deleted-file-template.svg'
      : this._getFilePicture.transform(this.fileTitle, isMobile);
  }
}
