import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrainingRoutingModule } from './training-routing.module';
import { TrainingSectionsComponent } from './pages/training-sections/training-sections.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CurrentSectionPageComponent } from './pages/current-section-page/current-section-page.component';
import { CurrentSectionPageModule } from './pages/current-section-page/current-section-page.module';
import { CurrentStagePageComponent } from './pages/current-stage-page/current-stage-page.component';
import { CurrentEmployeeFormComponent } from './pages/current-employee-form/current-employee-form.component';
import { CategoryQuestionsBlockComponent } from './pages/current-employee-form/form-of-development/category-questions-block/category-questions-block.component';
import { FormOfDevelopmentComponent } from './pages/current-employee-form/form-of-development/form-of-development.component';
import { EmployeeDevelopmentComponent } from './pages/employee-development/employee-development.component';
import { EmployeeDevelopmentFiltersComponent } from './pages/components/employee-development-filters/employee-development-filters.component';
import { TrainingDevelopmentTableComponent } from './pages/components/training-development-table/training-development-table.component';
import { TrainingAnalyticsComponent } from './pages/training-analytics/training-analytics.component';
import { TrainingNotificationComponent } from './pages/components/training-notification/training-notification.component';

@NgModule({
  declarations: [
    TrainingSectionsComponent,
    CurrentSectionPageComponent,
    CurrentStagePageComponent,
    CurrentEmployeeFormComponent,
    CategoryQuestionsBlockComponent,
    FormOfDevelopmentComponent,
    EmployeeDevelopmentComponent,
    EmployeeDevelopmentFiltersComponent,
    TrainingDevelopmentTableComponent,
    TrainingAnalyticsComponent,
    TrainingNotificationComponent,
  ],
  imports: [CommonModule, TrainingRoutingModule, SharedModule, CurrentSectionPageModule],
  exports: [
    CategoryQuestionsBlockComponent,
    FormOfDevelopmentComponent,
    TrainingNotificationComponent,
  ],
})
export class TrainingModule {}
