import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http/base-http.service';
import { Observable } from 'rxjs';
import { INotifications, INotificationsResponse } from './notifications.type';
import { RequestNesting } from '../../enums';
import { IFilter } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly _notificationHref = '/notification';

  private readonly _new = '/new';

  private readonly _unread = '/unread';

  private readonly _replies = '/replies';

  constructor(private _http: BaseHttpService) {}

  public pollingNotifications(): Observable<INotifications[]> {
    return this._http.post<INotifications[]>(
      `${this._notificationHref}`,
      {},
      RequestNesting.withoutData,
    );
  }

  public getNotifications(filters?: IFilter) {
    return this._http.get<INotificationsResponse>(`${this._replies}${this._new}`, filters);
  }

  public getUnreadNotifications(filters?: IFilter) {
    return this._http.get<INotificationsResponse>(`${this._replies}${this._unread}`, filters);
  }
}
