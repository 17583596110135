<ng-container *ngIf="notificationsList$ | async as list">
  <app-pagination-container
    *ngIf="isLoading$"
    [class]="isModalMode ? '' : 'notifications'"
    [isLoading$]="isLoading$"
    [paginationData]="list"
    [isFiltersPagination]="isModalMode"
    [noDataText]="'У вас нет новых уведомлений'"
    [isAllLoaded]="isAllLoaded$ | async"
    (emitScrolling)="getNotificationsList()"
  >
    <div *ngFor="let notify of list">
      <app-notification-card
        [notify]="notify"
        [isModalMode]="isModalMode"
        [notificationCurrentPageType]="pageType"
      ></app-notification-card>
    </div>
  </app-pagination-container>
</ng-container>
