import { Pipe, PipeTransform } from '@angular/core';
import { ICountryOrCity, IRegion } from '../../services/departments/departments.type';
import { IDepartment } from '../../services/posts/posts.type';
import { IShop, IUserPosition } from '../../services/user/user.type';

@Pipe({
  name: 'checkIsSelected',
})
export class CheckIsSelectedPipe implements PipeTransform {
  transform(
    id: number,
    selectedElements: (IShop | ICountryOrCity | IRegion | IDepartment | IUserPosition)[],
  ): boolean {
    return Boolean(selectedElements.filter((el) => el.id === id).length);
  }
}
