import { Pipe, PipeTransform } from '@angular/core';
import { INotification } from '../../services/notifications/notifications.type';
import { NotificationsTypes } from '../../enums';

@Pipe({
  name: 'notificationReply',
})
export class NotificationReplyPipe implements PipeTransform {
  transform(notify: INotification): string {
    if (notify.entity.replyForCommentText) {
      return notify.author.isMale ? 'ответил:' : 'ответила:';
    }
    let text = `${notify.author.isMale ? 'оставил' : 'оставила'} комментарий`;
    if (notify.entity.type === NotificationsTypes.photoReport) {
      text += ' к фотоотчёту:';
    } else if (notify.entity.type === NotificationsTypes.photoReportImg) {
      text += ' к фотографии:';
    } else {
      text += ':';
    }
    return text;
  }
}
