import { Store } from '@ngrx/store';
import { SwiperComponent } from 'swiper/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import SwiperCore, { Keyboard as SwiperKeyboard, SwiperOptions, Zoom } from 'swiper';

import deepClone from 'src/app/shared/utils/deepClone';
import { IImage } from 'src/app/shared/services/posts/posts.type';
import { IGalleryState } from 'src/app/data/store/models/gallery.model';
import { ImagePreviewType } from 'src/app/shared/enums';
import { selectGalleryData } from 'src/app/data/store/selectors/gallery.selectors';
import { changeGalleryDataImageIndex } from 'src/app/data/store/actions/gallery.action';
import { UtilsService } from '../../services/utils.service';

SwiperCore.use([SwiperKeyboard, Zoom]);

@UntilDestroy()
@Component({
  selector: 'app-slide-photo-gallery-fullscreen',
  templateUrl: './slide-photo-gallery-fullscreen.component.html',
  styleUrls: ['./slide-photo-gallery-fullscreen.component.scss'],
})
export class SlidePhotoGalleryFullscreenComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  public readonly ImagePreviewType = ImagePreviewType;

  public imagesList: IImage[] = [];

  public galleryData: IGalleryState = { images: [], chosenImgIndex: 0 };

  public swiperConfig?: SwiperOptions;

  private _zoomPosition = '';

  private _isZoomEnabled = false;

  constructor(
    private _store: Store,
    private _cdr: ChangeDetectorRef,
    private _utils: UtilsService,
  ) {}

  ngOnInit(): void {
    this._store
      .select(selectGalleryData)
      .pipe(untilDestroyed(this))
      .subscribe((galleryData) => {
        this.galleryData = galleryData;

        this.swiperConfig = {
          loop: !this.galleryData.isLoopDisabled && this.galleryData.images.length > 1,
          initialSlide: this.galleryData.chosenImgIndex,
          slidesPerView: 1,
          centeredSlides: true,
        };

        this.imagesList = deepClone(this.galleryData.images);
      });
  }

  public toggleZoom() {
    let newZoomPosition = (
      document.querySelector('.swiper-slide-active .swiper-zoom-container') as HTMLElement
    ).style.transform;

    if (this._zoomPosition !== newZoomPosition && this._isZoomEnabled) {
      this._zoomPosition = newZoomPosition;
      return;
    }

    this.swiper?.swiperRef.zoom.toggle();
    this._isZoomEnabled = !this._isZoomEnabled;
    if (!this._isZoomEnabled) {
      newZoomPosition = '';
    }

    this._zoomPosition = newZoomPosition;
  }

  public closeModal() {
    this._utils.dismissModal();
  }

  public onSlideChange() {
    this._zoomPosition = '';
    this._isZoomEnabled = false;

    if (this.swiper?.swiperRef.realIndex == null) {
      return;
    }

    this._store.dispatch(changeGalleryDataImageIndex({ index: this.swiper.swiperRef.realIndex }));
    this._cdr.detectChanges();
    this._cdr.markForCheck();
  }
}
