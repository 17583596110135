import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { NotificationActions } from '../models/notifications.model';
import {
  INotification,
  INotifications,
} from 'src/app/shared/services/notifications/notifications.type';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationPageType } from 'src/app/shared/enums';

export const startPollingNotifications = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.pollingNotification}`,
);

export const setNotifications = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.setNotifications}`,
  props<{ notifications: INotifications[]; reset?: boolean }>(),
);

export const noopNotification = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.noopNotification}`,
);

export const deleteNotification = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.deleteNotification}`,
);

export const getNotificationsList = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.notificationsList} ${ReducerStatuses.INIT}`,
  props<{ currentPageType: NotificationPageType }>(),
);

export const getUnreadNotificationsList = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.unreadNotificationsList} ${ReducerStatuses.INIT}`,
  props<{ currentPageType: NotificationPageType }>(),
);

export const getNotificationsListSuccess = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.notificationsList} ${ReducerStatuses.SUCCESS}`,
  props<{ list: INotification[]; resetData: boolean; totalElements: number }>(),
);

export const getUnreadNotificationsListSuccess = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.unreadNotificationsList} ${ReducerStatuses.SUCCESS}`,
  props<{
    list: INotification[];
    resetData: boolean;
    totalElements: number;
  }>(),
);

export const getNotificationsListFailed = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.notificationsList} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const clearNotificationsLists = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.clearNotificationsLists}`,
);

export const clearUnreadNotificationsList = createAction(
  `${ReducerSections.NOTIFICATIONS} ${NotificationActions.clearUnreadNotificationsList}`,
);
