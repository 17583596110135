import Swiper from 'swiper';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActionSheetController, ModalController, ToastController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  catchError,
  first,
  from,
  fromEvent,
  map,
  mergeAll,
  mergeMap,
  of,
  switchMap,
  take,
  timer,
} from 'rxjs';
import {
  deleteUploadedFilesData,
  initUploadFile,
} from 'src/app/data/store/actions/uploading-files.actions';
import {
  befreeUrlValues,
  errorNotification,
  errorNotificationMessages,
  initialCurrentDepartmentsLinks,
  successNotification,
  trainingLevelsTypes,
} from '../constants';
import { CurrentPlatform, FileType, FiltersOptions, RefresherTypes } from '../enums';
import { IShop } from './user/user.type';
import { IPhotoReport, IPhotoReportCategoryArray } from './photo-reports/photo-reports.type';
import { IDepartment, IImage, IPost, IUploadingFile } from './posts/posts.type';
import { FirstArgument, IFilter, ImagePickerOptions, ITabsLinks } from '../interfaces';
import moment from 'moment';
import { SlidePhotoGalleryWithCommentsComponent } from '../components/slide-photo-gallery-with-comments/slide-photo-gallery-with-comments.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, ReadFileOptions } from '@capacitor/filesystem';
import { FiltersOptionsType, IFiltersOptionsParam } from 'src/app/data/store/models/filters.model';
import { dislikeImageAction, likeImageAction } from '../../data/store/actions/photo-report.action';
import { getCurrentUser } from 'src/app/data/store/actions/users.actions';
import { initPostsData } from 'src/app/data/store/actions/posts.actions';
import {
  applySingleFilterAction,
  changeFavoriteState,
  clearFiltersStore,
  refreshPaginationData,
  updateFiltersAction,
} from 'src/app/data/store/actions/filters.actions';
import { AddBaseUrlPipe } from '../pipe/add-baseUrl/add-base-url.pipe';
import {
  ITrainingFormData,
  ITrainingLevels,
  ITrainingLevelsSection,
  ITrainingLevelsSectionParameters,
} from './training/training.type';
import * as deep from 'fast-deep-equal';
import { Browser } from '@capacitor/browser';
import { PdfViewerComponent } from '../components/pdf-viewer/pdf-viewer.component';
import { TPushNavigationPayload } from './push/push.type';
import { SlidePhotoGalleryComponent } from '../components/slide-photo-gallery/slide-photo-gallery.component';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { setGalleryData } from 'src/app/data/store/actions/gallery.action';
import { IGalleryState } from 'src/app/data/store/models/gallery.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private _modalCtrl: ModalController,
    private _actionSheetCtrl: ActionSheetController,
    private _location: Location,
    private _store: Store,
    private _toastController: ToastController,
    private _router: Router,
    private _route: ActivatedRoute,
    private _addBaseUrl: AddBaseUrlPipe,
  ) {}

  deepEqual = (a: any, b: any): boolean => {
    return deep(a, b);
  };

  public backBtnEventSubscription() {
    fromEvent(document, 'backbutton')
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          return from(this._modalCtrl.getTop()).pipe(take(1));
        }),
      )
      .subscribe((modal) => {
        if (modal) {
          modal.dismiss();
        } else {
          this._location.back();
        }
      });
  }

  public isCurrentShopFavorite(favShops: IShop[] | null, currentShopId?: number): boolean {
    if (currentShopId && favShops && favShops.find((favShop) => favShop.id === currentShopId)) {
      return Boolean(favShops.length);
    }

    return Boolean(null);
  }

  public getCategoriesList(
    photoReport: IPhotoReport,
    categories: IPhotoReportCategoryArray,
  ): string[] {
    let photoReportCategories: string[] = [];
    categories.photoReportCategory.forEach((category) => {
      if (photoReport.imagesData[category.categoryName]) {
        photoReportCategories.push(category.categoryName);
      }
    });
    if (photoReportCategories.includes('NO_CATEGORY')) {
      photoReportCategories = photoReportCategories.filter(
        (category: string) => category !== 'NO_CATEGORY',
      );
      photoReportCategories.push('NO_CATEGORY');
    }
    return photoReportCategories;
  }

  public getPhotoReportCategoriesNames(
    photoReportCategories: string[],
    categories: IPhotoReportCategoryArray,
  ): { [key: string]: string } {
    return photoReportCategories?.reduce((acc: any, cur: string) => {
      const categoryValue = categories?.photoReportCategory.find(
        (item) => item.categoryName === cur,
      )?.categoryValue;
      acc = { ...acc, [cur]: categoryValue === '' ? 'Прочее' : categoryValue };
      return acc;
    }, {});
  }

  public getPhotoReportImagesId(photoReport: IPhotoReport): { id: number }[] {
    return Object.keys(photoReport.imagesData)
      .map((category) => photoReport.imagesData[category])
      .flat()
      .map((item) => {
        return { id: item.id };
      });
  }

  public base64ToBlob(base64: string | undefined, format: string) {
    if (!base64) {
      return;
    }
    const byteCharacters = atob(base64);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: `image/${format}` });
  }

  public uploadFile(fileType: FileType, file: File, localId: number, imageCategory?: string) {
    this._store.dispatch(
      initUploadFile({
        localId,
        fileType: fileType,
        file: file,
        imageCategory,
      }),
    );
  }

  public downloadFile(
    fileName: string = '',
    filePath: string = '',
    isNeedTargetBlank: boolean = true,
  ) {
    const a = document.createElement('a');
    a.href = this._addBaseUrl.transform(filePath);
    if (isNeedTargetBlank) a.target = '_blank';

    if (
      !(
        fileName.includes('.pdf') ||
        fileName.includes('.jpg') ||
        fileName.includes('.jpeg') ||
        fileName.includes('.png')
      )
    ) {
      a.download = fileName;
    }

    a.dispatchEvent(new MouseEvent('click'));
  }

  public async openUrlInBrowser(filepath = '', transform = true) {
    const url = transform ? this._addBaseUrl.transform(filepath) : filepath;
    await Browser.open({ url });
  }

  public handleDeleteChosenImage(itemId: number, imageCategory?: string, imageId?: number) {
    this._store.dispatch(
      deleteUploadedFilesData({
        localIdToBeDeleted: itemId,
        imageCategory: imageCategory,
        imageId: imageId,
      }),
    );
  }

  public getUploadingFilesByCategory(
    fileData: IUploadingFile,
    file: File,
    imageCategory: string,
    uploadingFilesByCategory: { [key: string]: IUploadingFile[] } | null,
  ) {
    if (uploadingFilesByCategory && !uploadingFilesByCategory[imageCategory]) {
      return {
        ...uploadingFilesByCategory,
        [imageCategory]: [
          {
            ...fileData,
            file: file,
          },
        ],
      };
    } else if (uploadingFilesByCategory && uploadingFilesByCategory[imageCategory]) {
      return {
        ...uploadingFilesByCategory,
        [imageCategory]: [
          ...uploadingFilesByCategory[imageCategory],
          {
            ...fileData,
            file: file,
          },
        ],
      };
    } else {
      return {
        [imageCategory]: [
          {
            ...fileData,
            file: file,
          },
        ],
      };
    }
  }

  public setDepartmentTabLinks(department: IDepartment): ITabsLinks[] {
    const updatedLinks: ITabsLinks[] = [];

    if (!updatedLinks.length) updatedLinks.push(initialCurrentDepartmentsLinks);

    if (department.hasRegions) {
      updatedLinks.push({
        name: 'РЕГИОНЫ',
        url: befreeUrlValues.regions,
      });
    }

    if (department.hasSubdepts) {
      updatedLinks.push({
        name: 'ГРУППЫ',
        url: befreeUrlValues.groups,
      });
    }

    return updatedLinks;
  }

  public openNotification(message: string, isError?: boolean) {
    const toast$ = from(
      this._toastController.create({
        message: message,
        duration: 3000,
        position: 'bottom',
        icon: isError ? errorNotification : successNotification,
        color: 'dark',
        mode: CurrentPlatform.ios,
        cssClass: 'custom-ion-toast',
      }),
    );

    toast$
      .pipe(
        take(1),
        map((toast) => toast.present()),
      )
      .subscribe();
  }

  public goToEmployeePage(id?: number) {
    const selection = window.getSelection();
    if (!selection?.toString()) {
      this._router.navigate([befreeUrlValues.dashboard, befreeUrlValues.employees, id]);
    }
  }

  getFavoriteImagesCategory(dateValue: string) {
    const date = moment.utc(dateValue);
    const localeDate = moment(date.toLocaleString()).locale('ru');
    return `${localeDate.format('MMMM YYYY')}`;
  }

  openGalleryModalWithComments(
    view: 'fullscreen' | 'with-comments',
    galleryData: IGalleryState,
    commentToScrollData?: TPushNavigationPayload,
  ) {
    this._store.dispatch(setGalleryData({ gallery: galleryData }));
    this._store
      .select(selectCurrentPlatform)
      .pipe(first())
      .subscribe((platform) => {
        const showFullscreen = platform !== CurrentPlatform.web && view === 'fullscreen';

        const modal$ = from(
          this._modalCtrl.create({
            component: showFullscreen
              ? SlidePhotoGalleryComponent
              : SlidePhotoGalleryWithCommentsComponent,
            componentProps: { commentToScrollData },
            mode: CurrentPlatform.ios,
            cssClass: showFullscreen ? undefined : ['modal-post-view', 'image-comments-view'],
            animated: true,
            canDismiss: true,
            breakpoints: [0, 1],
            id: showFullscreen
              ? 'SlidePhotoGalleryComponent'
              : 'SlidePhotoGalleryWithCommentsComponent',
          }),
        );

        modal$
          .pipe(
            take(1),
            map((modal) => modal.present()),
          )
          .subscribe();
        modal$
          .pipe(
            untilDestroyed(this),
            mergeMap((modal) => from(modal.onWillDismiss())),
          )
          .subscribe();
      });
  }

  openPdfModal(url: string, pdfName?: string) {
    const pdfUrl = this._addBaseUrl.transform(url);
    const modal$ = from(
      this._modalCtrl.create({
        component: PdfViewerComponent,
        componentProps: {
          pdfName,
          pdfUrl,
          goBack: () => this.dismissModal(),
        },
        mode: CurrentPlatform.ios,
        breakpoints: [0, 0, 1, 1],
        cssClass: 'modal-pdf-view',
        canDismiss: true,
        animated: true,
      }),
    );

    modal$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();
    modal$
      .pipe(
        untilDestroyed(this),
        mergeMap((modal) => from(modal.onWillDismiss())),
      )
      .subscribe();
  }

  public openImagePickerModal(
    localId: number,
    selectedCategory?: string,
    limitOptions?: ImagePickerOptions,
  ) {
    const actionSheet$ = from(
      this._actionSheetCtrl.create({
        backdropDismiss: false,
        buttons: [
          {
            text: 'Сделать фото',
            data: {
              action: 'choose',
            },
            handler: () => this.getPhoto(localId, selectedCategory, limitOptions),
          },
          {
            text: 'Выбрать из галереи',
            data: {
              action: 'choose',
            },
            handler: () => this.pickImages(localId, selectedCategory, limitOptions),
          },
          {
            text: 'Отмена',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
      }),
    );
    actionSheet$
      .pipe(
        take(1),
        map((modal) => modal.present()),
      )
      .subscribe();
    actionSheet$
      .pipe(
        untilDestroyed(this),
        map((modal) => modal.onWillDismiss()),
      )
      .subscribe();
  }

  public pickImages(localId: number, selectedCategory?: string, limitOptions?: ImagePickerOptions) {
    let id = localId;

    from(
      Camera.pickImages({
        correctOrientation: true,
        limit: limitOptions?.limitLength,
      }),
    )
      .pipe(
        mergeMap((images) => {
          if (limitOptions?.limitLength) {
            const currentFileLimit =
              limitOptions?.limitLength - (limitOptions?.chosenFilesLength || 0);
            const newFilesCount = images.photos.length;

            if (newFilesCount > currentFileLimit) {
              this.openNotification(
                errorNotificationMessages.postPhotoLimit(currentFileLimit),
                true,
              );
              images.photos = images.photos.slice(0, currentFileLimit);
            }
          }
          return images.photos.map((image) => {
            return from(Filesystem.readFile(<ReadFileOptions>{ path: image.path })).pipe(
              take(1),
              map(async (imageFile) => {
                let base64ToBlob = this.base64ToBlob(imageFile.data, image.format);
                const loadedFile = new File([base64ToBlob!], `loaded.${image.format}`, {
                  type: base64ToBlob!.type,
                });

                if (limitOptions?.onSelect) {
                  limitOptions.onSelect(loadedFile);
                } else {
                  this.uploadFile(FileType.image, loadedFile, id, selectedCategory);
                }

                id++;
              }),
            );
          });
        }),
        mergeAll(),
        catchError(() => {
          this.openNotification('Ошибка, попробуйте загрузить другое изображение', true);
          return of([]);
        }),
      )
      .subscribe();
  }

  public getPhoto(localId: number, selectedCategory?: string, limitOptions?: ImagePickerOptions) {
    from(
      Camera.getPhoto({
        correctOrientation: true,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
      }),
    )
      .pipe(
        map((image: Photo) => {
          return {
            blob: this.base64ToBlob(image.base64String, image.format),
            image,
          };
        }),
        untilDestroyed(this),
      )
      .subscribe(({ blob, image }: { blob: Blob | undefined; image: Photo }) => {
        const loadedFile = new File([blob!], `loaded.${image.format}`, {
          type: blob!.type,
        });
        if (limitOptions?.onSelect) {
          limitOptions.onSelect(loadedFile);
        } else {
          this.uploadFile(FileType.image, loadedFile, localId, selectedCategory);
        }
      });
  }

  public setRequestFilterParams(data: {
    filtersOptionsParams?: IFiltersOptionsParam | null;
    filterOption?: FiltersOptions;
  }): string | null {
    if (!data.filterOption) {
      const filterParams = data.filtersOptionsParams
        ? Object.values(data.filtersOptionsParams).find(
            (option) => option?.params && option.params.length,
          )
        : null;

      return filterParams?.params?.map((el: any) => el.id).join(',') || null;
    } else if (data.filtersOptionsParams && data.filterOption) {
      return (
        data.filtersOptionsParams[data.filterOption]?.params?.map((el: any) => el.id).join(',') ||
        null
      );
    }
    return null;
  }

  public likeImage(currentImage: IImage, isInLikedList: boolean) {
    if ((currentImage.liked && !isInLikedList) || (!currentImage.liked && isInLikedList)) {
      this._store.dispatch(dislikeImageAction({ fileId: currentImage.id }));
    } else {
      this._store.dispatch(likeImageAction({ fileId: currentImage.id }));
    }
  }

  public handleRefresh(event: any, type?: string, callback?: VoidFunction) {
    this._store.dispatch(clearFiltersStore());
    timer(2000)
      .pipe(take(1))
      .subscribe(() => {
        if (callback) {
          callback();
        } else {
          switch (type) {
            case RefresherTypes.favoriteShops:
              this._store.dispatch(getCurrentUser());
              break;
            case RefresherTypes.postFeed:
              this._store.dispatch(initPostsData());
              break;
            default:
              this._store.dispatch(refreshPaginationData());
              break;
          }
        }

        event.target.complete();
      });
  }

  public setRequestEmployeeSearch(searchParam: string | null) {
    const [name, surname] = searchParam ? searchParam.split(' ') : [];

    return {
      name,
      surname,
    };
  }

  public createTrainingFormData(
    currentLevelData: ITrainingLevels,
    isChangeMode?: boolean,
  ): Partial<ITrainingFormData> {
    return trainingLevelsTypes.reduce((acc, type) => {
      const isDraft = currentLevelData.userTraining?.isDraft;
      const isAnyMode: boolean = Boolean(isChangeMode || isDraft);
      acc = {
        ...acc,
        userTraining: {
          ...currentLevelData.userTraining,
          startTraining: isAnyMode ? currentLevelData.userTraining?.startTraining : null,
          endTraining: isAnyMode ? currentLevelData.userTraining?.endTraining : null,
          userPositions: isAnyMode ? currentLevelData.userTraining?.userPositions : [],
          developmentTalk: isAnyMode ? currentLevelData.userTraining?.developmentTalk : null,
        },
        [type]: currentLevelData[type].map((level: ITrainingLevelsSection) => {
          return {
            id: level.id,
            parameters: level.parameters.map((parameter: ITrainingLevelsSectionParameters) => {
              return {
                id: parameter.id,
                grade: isAnyMode ? parameter.grade : null,
                comment: isAnyMode ? parameter.comment : null,
              };
            }),
          };
        }),
      };
      return acc;
    }, {});
  }

  public updateFiltersDataByOption(filters: IFiltersOptionsParam, option: FiltersOptionsType) {
    return Object.assign(
      { ...filters },
      {
        [option]: {
          ...filters[option],
          params: null,
        },
      },
    );
  }

  public updateFiltersDataByIdAfterCleaning(
    filters: IFiltersOptionsParam,
    option: FiltersOptionsType,
    id?: number,
  ) {
    return Object.assign(
      { ...filters },
      {
        [option]: {
          ...filters[option],
          params: filters[option]?.params?.filter((el) => el.id !== id),
        },
      },
    );
  }

  public cleanMonoTypeFilters(
    filters: IFiltersOptionsParam,
    options: FiltersOptionsType[],
    activeOption: FiltersOptionsType,
    id?: number,
  ) {
    options.forEach((option) => {
      if (option === activeOption) {
        filters = this.updateFiltersDataByIdAfterCleaning(filters, option, id);
      }
    });

    return filters;
  }

  public cleanMultiplyTypeFilters(
    filters: IFiltersOptionsParam,
    options: FiltersOptionsType[],
    excludeFiltersOptions: FiltersOptions[],
    activeOption: FiltersOptionsType,
  ) {
    if (
      !excludeFiltersOptions.includes(activeOption) &&
      !filters[activeOption]?.isFilterSingleButton
    ) {
      options.forEach((option) => {
        if (
          !filters[option]?.isFilterSingleButton ||
          (filters[option]?.isFilterSingleButton && option === FiltersOptions.trainer)
        ) {
          filters = this.updateFiltersDataByOption(filters, option);
        }
        excludeFiltersOptions.forEach((excludeOption) => {
          filters = this.updateFiltersDataByOption(filters, excludeOption);
        });
      });
    }

    if (
      excludeFiltersOptions.includes(activeOption) &&
      !filters[activeOption]?.isFilterSingleButton
    ) {
      if (activeOption === FiltersOptions.trainer) {
        filters = this.updateFiltersDataByOption(filters, activeOption);
      } else {
        excludeFiltersOptions.forEach((excludeOption) => {
          filters = this.updateFiltersDataByOption(filters, excludeOption);
        });
      }
    }

    if (filters[activeOption]?.isFilterSingleButton) {
      filters = this.updateFiltersDataByOption(filters, activeOption);

      if (activeOption === FiltersOptions.trainerRole) {
        filters = this.updateFiltersDataByOption(filters, FiltersOptions.trainer);
      }
    }

    return filters;
  }

  public getOnlyExcludeFiltersOptions(filteredObject: IFiltersOptionsParam) {
    return Object.entries(filteredObject)
      .map(([key, value]) => {
        if (value?.isFilterExcludedInDependentFilters) {
          return key as FiltersOptions;
        }
        return '' as FiltersOptions;
      })
      .filter((val) => val);
  }

  public updateFiltersWhenSelecting(
    filters: IFiltersOptionsParam,
    options: FiltersOptionsType[],
    activeOption: FiltersOptionsType,
  ) {
    options.forEach((option) => {
      if (
        option !== activeOption &&
        filters[activeOption!]?.params?.length &&
        !filters[option]?.isFilterSingleButton &&
        activeOption !== FiltersOptions.shops &&
        activeOption !== FiltersOptions.trainer
      ) {
        filters = this.updateFiltersDataByOption(filters, option);
      } else if (
        options.includes(FiltersOptions.trainer) &&
        option !== activeOption &&
        !filters[option]?.isFilterSingleButton &&
        activeOption === FiltersOptions.shops
      ) {
        filters = this.updateFiltersDataByOption(filters, FiltersOptions.trainer);
      } else if (activeOption === FiltersOptions.trainerRole) {
        filters = this.updateFiltersDataByOption(filters, FiltersOptions.trainer);
      }
    });

    return filters;
  }

  public changeIsPinnedValue = (postsList: IPost[], postId: number | null) => {
    return postsList.map((post: IPost) =>
      post.pinned || !postId
        ? {
            ...post,
            pinned: false,
          }
        : post.id === postId
        ? { ...post, pinned: true }
        : post,
    );
  };

  public syncFiltersRouteQuery(filters: any, favs?: boolean | null) {
    const filterJSON = JSON.stringify(
      { ...filters, page: null, size: null, isLastWeekOfEducation: null, favs },
      (key, value) => {
        if (value === null || value === false) {
          return undefined;
        }
        return value;
      },
    );

    const path = this._router.url.split('?')[0];
    const currentQueryParams = this._route.snapshot.queryParams;
    const newQueryParams = {
      ...currentQueryParams,
      filter: filterJSON === '{}' ? undefined : filterJSON,
    };
    this._router.navigate([path], { queryParams: newQueryParams });
  }

  public getFiltersFromUrl(excludeKeys: string[] = []) {
    const json = this._route.snapshot.queryParams['filter'];
    if (json) {
      const filters = JSON.parse(json);
      excludeKeys.forEach((x) => {
        delete filters[x];
      });
      return filters;
    }
    return {};
  }

  public updateStateFilterOnInit(
    isSingleApply: boolean,
    initFilters?: IFiltersOptionsParam | null,
  ) {
    const filtersFromUrl = this.getFiltersFromUrl();
    let filters = initFilters ? initFilters : {};
    for (const filtersFromUrlKey in {
      ...filtersFromUrl,
      onlyUntrained: undefined,
      endTraining: undefined,
    }) {
      const item = filtersFromUrl[filtersFromUrlKey];
      if (filtersFromUrlKey === 'favs') {
        this._store.dispatch(
          changeFavoriteState({
            favs: !!item,
          }),
        );
        return;
      }
      if (item) {
        const names = this._getSplittedValue(item.name);
        const values = this._getSplittedValue(item.value);

        const params = names?.map((name: string, i: number) => ({
          id: values[i],
          name,
        }));
        filters = {
          ...filters,
          [item.type]: {
            params,
            isFilterSingleButton: [
              'positionGroupId',
              'stage',
              'trainingRoleType',
              'trainerId',
            ].includes(filtersFromUrlKey)
              ? true
              : undefined,
          },
        };
        if (isSingleApply) {
          this._store.dispatch(
            applySingleFilterAction({
              data: filters,
              activeFilterOption: item.type,
            }),
          );
        } else {
          this._store.dispatch(
            updateFiltersAction({
              filtersOptionsParams: filters,
              activeFilterOption: item.type,
            }),
          );
        }
      }
    }
  }

  public getUrlEntity(
    filters: IFilter,
    itemName: keyof IFilter,
    type: FiltersOptions,
    filtersMenuParams?: IFiltersOptionsParam | null,
  ) {
    const filtersFromUrl = this.getFiltersFromUrl();
    const getNamesFromEntity = (key: FiltersOptions, filterKey: string) => {
      if (filtersMenuParams?.[key]?.params) {
        return filtersMenuParams?.[key]?.params
          ?.map((i) => i.name || (i.firstName ? `${i.firstName} ${i.lastName || ''}` : undefined))
          .join();
      }
      return filtersFromUrl[filterKey]?.name;
    };

    if (filters[itemName]) {
      return { value: filters[itemName], name: getNamesFromEntity(type, itemName), type };
    }
    return undefined;
  }

  public scrollToComment(commentToScroll: TPushNavigationPayload) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const commentElement = document.getElementById(commentToScroll.commentId);
        if (commentElement) {
          if (commentToScroll.replyCommentId) {
            const repliedCommentElement = document.getElementById(commentToScroll.replyCommentId!);
            repliedCommentElement?.scrollIntoView();
            resolve(true);
          } else {
            commentElement.scrollIntoView();
            resolve(true);
          }
        } else {
          resolve(true);
        }
      }, 300);
    });
  }

  get isWeb() {
    return this.getCurrentPlatform() === CurrentPlatform.web;
  }

  public getCurrentPlatform(): CurrentPlatform | null {
    let currentPlatform: CurrentPlatform | null = null;

    this._store
      .select(selectCurrentPlatform)
      .pipe(take(1))
      .subscribe((i) => (currentPlatform = i));

    return currentPlatform;
  }

  async changeSwiperSlide({
    swiper,
    modalId,
    prevIndex,
    currentIndex,
  }: {
    swiper: Swiper;
    modalId: string;
    prevIndex: number;
    currentIndex: number;
  }) {
    const currentModal = await this._modalCtrl.getTop();
    if (currentModal && currentModal.id !== modalId && currentIndex !== prevIndex) {
      swiper.slideToLoop(currentIndex);
    }
  }

  async getMimeTypeViaFileReader(file: File) {
    return new Promise<string>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onloadend = (event) => {
        if (event.target?.result) {
          const arr = new Uint8Array(event.target.result as ArrayBuffer).subarray(0, 4);
          let header = '';
          for (var i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }

          switch (header) {
            case '89504e47':
              resolve('image/png');
              break;
            case '47494638':
              resolve('image/gif');
              break;
            case 'ffd8ffe0':
            case 'ffd8ffe1':
            case 'ffd8ffe2':
            case 'ffd8ffe3':
            case 'ffd8ffe8':
              resolve('image/jpeg');
              break;
            default:
              resolve('');
              break;
          }
        } else {
          resolve('');
        }
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  async dismissModal(data?: FirstArgument<typeof this._modalCtrl.dismiss>) {
    const openedModal = await this._modalCtrl.getTop();
    if (openedModal) {
      this._modalCtrl.dismiss(data);
    }
  }

  public _getSplittedValue = (value?: unknown) => {
    try {
      return typeof value === 'string' ? value?.split(',') : [];
    } catch {
      return [];
    }
  };
}
