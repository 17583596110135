import { HttpErrorResponse } from '@angular/common/http';
import { IComment, ICommentReply, IPoll, IPost } from '../../../shared/services/posts/posts.type';
import { IUser } from '../../../shared/services/user/user.type';

export const POSTS_DATA_KEY = 'postsData';

export interface IPostsDataState {
  posts: IPost[] | [];
  editedPost: IPost | null;
  allPostsFetched: boolean;
  error: string | HttpErrorResponse | null;
  currentPost: IPost | null;
  replyTo: ICommentReply | null;
  isLoading: boolean;
  isFavoritesLoading: boolean;
  isPostModalOpened: boolean;
  voteParticipants: IUser[] | null;
  postToBeUpdated: IPost | null;
  poll: IPoll | null;
  deletedPostId: number | null;
  favoritesList: number[] | any[];
  isEditMode: boolean;
  isEditCommentMode: boolean;
  editedCommentData: IComment | null;
  currentCommentData: Partial<IComment> | null;
}

export enum PostActions {
  PostData = 'PostData',
  CreateNewPost = 'CreateNewPost',
  EditPost = 'EditPost',
  OpenPostModal = 'OpenPostModal',
  ClosePostModal = 'ClosePostModal',
  VoteInThePoll = 'VoteInThePoll',
  UpdateDataAfterTheVoting = 'UpdateDataAfterTheVoting',
  VoteParticipants = 'VoteParticipants',
  CreatePoll = 'CreatePoll',
  GetCurrentPost = 'GetCurrentPost',
  CreateReply = 'CreateReply',
  InitUploadFiles = 'InitUploadFiles',
  UploadFile = 'UploadFile',
  CleanUploadedFilesData = 'CleanUploadedFilesData',
  DeleteUploadedFilesData = 'DeleteUploadedFilesData',
  LikePost = 'LikePost',
  DislikePost = 'DislikePost',
  PinPostByPostId = 'PinPostByPostId',
  DeletePostByPostId = 'DeletePostByPostId',
  AddToFavouritesList = 'AddToFavoritesLists',
  RemoveFromFavouritesList = 'RemoveFromFavoritesLists',
  UpdateFavouritesList = 'UpdateFavouritesList',
  ClearFavoriteListToBeUpdated = 'clearFavoriteListToBeUpdated',
  ClearPostsData = 'ClearPostsData',
  PostEditMode = 'PostEditMode',
  EditComment = 'EditComment',
  EditCommentMode = 'EditCommentMode',
  BlockPost = 'BlockPost',
}
