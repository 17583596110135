import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  changeEditCommentMode,
  createReplyToCommentData,
} from 'src/app/data/store/actions/posts.actions';
import {
  selectCommentEditMode,
  selectCurrentCommentData,
  selectReplyTo,
} from 'src/app/data/store/selectors/posts.selectors';
import { IComment } from '../../services/posts/posts.type';
import { CurrentPlatform } from '../../enums';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { Keyboard } from '@capacitor/keyboard';

@UntilDestroy()
@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() isNeedFocus?: boolean = false;

  @Input() changeCommentEditMode: ((comment: IComment, editMode?: boolean) => void) | undefined;

  @Output() emitComment: EventEmitter<{ text: string; commentId?: number; isEditMode: boolean }> =
    new EventEmitter<{
      text: string;
      commentId?: number;
      isEditMode: boolean;
    }>();

  @ViewChild('container') container?: ElementRef<HTMLInputElement>;

  @ViewChild('commentInput', { static: false }) ionTextArea:
    | { el: HTMLInputElement; value: string; setFocus: () => void }
    | undefined;

  public platform: CurrentPlatform | null = null;

  public replyToComment$ = this._store.select(selectReplyTo);

  public isEditMode: boolean = false;

  public commentToEditText = '';

  public keyboardOpen: boolean = false;

  public currentCommentData: Partial<IComment> | null | undefined;

  public createCommentForm: FormGroup = this._formBuilder.group({
    comment: [null, [Validators.required]],
  });

  public isFocused: boolean = false;

  private _answerTo: string | null = null;

  constructor(private _formBuilder: FormBuilder, private _store: Store) {}

  ngOnInit(): void {
    this.replyToComment$.pipe(untilDestroyed(this)).subscribe((replyTo) => {
      if (replyTo) {
        this._answerTo = `${replyTo.author.firstName}, `;
        this.createCommentForm.patchValue({
          comment: this._answerTo,
        });
        this.ionTextArea?.setFocus();
      } else {
        this._answerTo = '';
        this.isFocused = false;
        this.createCommentForm.patchValue({ comment: null });
      }
    });

    this._store
      .select(selectCommentEditMode)
      .pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        this.isEditMode = isEditMode;
        if (isEditMode) {
          this.createCommentForm.patchValue({ comment: this.currentCommentData?.text });
          this.ionTextArea?.setFocus();
        } else {
          this.isFocused = false;
          this.createCommentForm.patchValue({ comment: null });
        }
      });

    this._store
      .select(selectCurrentCommentData)
      .pipe(untilDestroyed(this))
      .subscribe((currentCommentData: Partial<IComment> | null) => {
        if (this.isEditMode) {
          this.currentCommentData = currentCommentData;
          this.commentToEditText = this.currentCommentData?.text || '';
          this.createCommentForm.patchValue({ comment: currentCommentData?.text });
        }
      });

    this._store
      .select(selectCurrentPlatform)
      .pipe(untilDestroyed(this))
      .subscribe((platform) => {
        this.platform = platform;

        if (platform !== 'web') {
          Keyboard?.addListener('keyboardWillShow', () => {
            this.keyboardOpen = true;
            this.container?.nativeElement.classList.remove('padding');
          });

          Keyboard?.addListener('keyboardWillHide', () => {
            this.keyboardOpen = false;
            this.container?.nativeElement.classList.add('padding');
          });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['isNeedFocus'].currentValue === true) {
      this.ionTextArea?.setFocus();
    }
  }

  ngOnDestroy(): void {
    this.resetEdit();
    this.resetReply();
    this.ionTextArea?.el.removeEventListener('paste', this.onPaste);
    this.ionTextArea?.el.removeEventListener('keypress', this.onKeyPress);
  }

  ngAfterViewInit(): void {
    this.ionTextArea?.el.addEventListener('paste', this.onPaste);
    this.ionTextArea?.el.addEventListener('keypress', this.onKeyPress);
  }

  get isDisabled() {
    const value = this.ionTextArea?.value || '';
    return this._answerTo ? this._answerTo.trim() === value.trim() : !value.trim();
  }

  public resetEdit() {
    this._store.dispatch(changeEditCommentMode({ isEditMode: false, currentCommentData: {} }));
  }

  public resetReply() {
    this._store.dispatch(createReplyToCommentData({ comment: null }));
  }

  public createComment() {
    if (this.isDisabled) {
      return;
    }

    this.emitComment.emit({
      text: this.createCommentForm.controls['comment'].value,
      commentId: this.currentCommentData?.id,
      isEditMode: this.isEditMode,
    });
    this.createCommentForm.controls['comment'].markAsUntouched();
    this.createCommentForm.patchValue({ comment: null });

    if (this._answerTo) {
      this.resetReply();
    }
  }

  public clearReplyData(event: string | null) {
    if (this._answerTo && !event?.includes(this._answerTo)) {
      this.createCommentForm.patchValue({ comment: this._answerTo });
    }
  }

  public onEnterKeydown(event: Event) {
    event.preventDefault();
  }

  private onPaste(event: ClipboardEvent) {
    const ionTextArea = (event.target as HTMLInputElement)?.parentElement?.parentElement;
    if (ionTextArea) {
      setTimeout(() => {
        ionTextArea.scrollTo(0, ionTextArea.scrollHeight);
      }, 50);
    }
  }

  private onKeyPress(event: KeyboardEvent) {
    const ionTextArea = (event.target as HTMLInputElement)?.parentElement?.parentElement;
    if (ionTextArea && event.key === 'Enter' && ionTextArea.clientHeight > 190) {
      setTimeout(() => {
        ionTextArea.scrollTo(0, ionTextArea.scrollHeight);
      }, 50);
    }
  }
}
