import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { PhotoReportsActions } from '../models/photo-report.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ICreatePhotoReportData,
  IPhotoReport,
  IPhotoReportCategory,
  IPhotoReportToUpdate,
} from '../../../shared/services/photo-reports/photo-reports.type';
import { IImage } from '../../../shared/services/posts/posts.type';

export const getCurrentPhotoReportData = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.PhotoReportData}`,
  props<{ photoReportId?: number }>(),
);

export const getCurrentPhotoReportDataSuccess = createAction(
  `${ReducerSections.PHOTO_REPORT} ${ReducerStatuses.SUCCESS}`,
  props<{
    photoReport: IPhotoReport;
    categories: IPhotoReportCategory[];
    photoReportImages: { [key: string]: IImage[] };
    photoReportCategories: string[];
    photoReportCategoriesNames: { [key: string]: string };
    photoReportImagesId: { id: number }[];
  }>(),
);

export const getCurrentPhotoReportDataFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getPhotoReportsList = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.PhotoReportsList}`,
);

export const getPhotoReportsListSuccess = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.PhotoReportsList} ${ReducerStatuses.SUCCESS}`,
  props<{ photoReportsList: IPhotoReport[]; resetData?: boolean; size: number }>(),
);

export const getPhotoReportsListFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.PhotoReportsList} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const likePhotoReport = createAction(
  `${ReducerSections.LIKE} ${PhotoReportsActions.LikePhotoReport}`,
  props<{ photoReportId: number }>(),
);

export const likePhotoReportSuccess = createAction(
  `${ReducerSections.LIKE} ${PhotoReportsActions.LikePhotoReport} ${ReducerStatuses.SUCCESS}`,
);

export const likePhotoReportFailed = createAction(
  `${ReducerSections.LIKE} ${PhotoReportsActions.LikePhotoReport} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const dislikePhotoReport = createAction(
  `${ReducerSections.LIKE} ${PhotoReportsActions.DislikePhotoReport}`,
  props<{ photoReportId: number }>(),
);

export const dislikePhotoReportSuccess = createAction(
  `${ReducerSections.LIKE} ${PhotoReportsActions.DislikePhotoReport} ${ReducerStatuses.SUCCESS}`,
);

export const dislikePhotoReportFailed = createAction(
  `${ReducerSections.LIKE} ${PhotoReportsActions.DislikePhotoReport} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const deletePhotoReport = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.DeletePhotoReport}`,
  props<{ photoReportId: number }>(),
);

export const deletePhotoReportSuccess = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.DeletePhotoReport} ${ReducerStatuses.SUCCESS}`,
);

export const deletePhotoReportFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.DeletePhotoReport} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const deletePhotoReportImageFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.ChangePhotoReportImage} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const updatePhotoReportData = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.UpdatePhotoReportData}`,
  props<{ photoReportData: IPhotoReportToUpdate }>(),
);

export const updatePhotoReportDataFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.UpdatePhotoReportData} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const getPhotoReportCategoriesInit = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.GetPhotoReportCategories}`,
);

export const getPhotoReportCategoriesSuccess = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.GetPhotoReportCategories} ${ReducerStatuses.SUCCESS}`,
  props<{ categories: IPhotoReportCategory[] }>(),
);

export const getPhotoReportCategoriesFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.GetPhotoReportCategories} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const createPhotoReportInit = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.CreatePhotoReport}`,
  props<{ createPhotoReportData: ICreatePhotoReportData; currentMode: string }>(),
);

export const createPhotoReportSuccess = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.CreatePhotoReport} ${ReducerStatuses.SUCCESS}`,
  props<{ photoReportId?: number; currentMode: string }>(),
);

export const createPhotoReportFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.CreatePhotoReport} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const updateCurrentPhotoReportData = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.LikePhotoReport} ${PhotoReportsActions.UpdatePhotoReportData}`,
  props<{ photoReportId: number }>(),
);

export const updateCurrentPhotoReportDataSuccess = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.LikePhotoReport} ${PhotoReportsActions.UpdatePhotoReportData}  ${ReducerStatuses.SUCCESS}`,
  props<{ photoReportToBeUpdated: IPhotoReport }>(),
);

export const updateCurrentPhotoReportDataFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.LikePhotoReport} ${PhotoReportsActions.UpdatePhotoReportData} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const clearCurrentPhotoReportData = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.PhotoReportData} ${PhotoReportsActions.ClearCurrentPhotoReportData}`,
);

export const changeEditMode = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.ChangeEditMode}`,
  props<{ currentMode: string }>(),
);

export const changeEditModeFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.ChangeEditMode} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const updateLikedList = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.LikedListUpdate}`,
  props<{ fileId: number }>(),
);

export const clearLikedList = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.ClearLikedList}`,
);

export const likeImageAction = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.LikeImage}`,
  props<{ fileId: number }>(),
);

export const likeImageActionFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.LikeImage} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const dislikeImageAction = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.DislikeImage}`,
  props<{ fileId: number }>(),
);

export const dislikeImageActionFailed = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.DislikeImage} ${ReducerStatuses.FAILED}`,
  props<{ error: string }>(),
);

export const clearPhotoReportsList = createAction(
  `${ReducerSections.PHOTO_REPORT} ${PhotoReportsActions.ClearPhotoReportsList}`,
);

export const addPhotoReportComment = createAction(
  `${ReducerSections.ADD_COMMENT} ${PhotoReportsActions.AddComment}`,
  props<{ index: number; category: string }>(),
);
