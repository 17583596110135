import { createReducer, on } from '@ngrx/store';
import { IUsersState } from '../models/users.model';
import {
  addUserAvatar,
  addUserAvatarFailed,
  addUserAvatarSuccess,
  getCountersFailed,
  getCurrentUser,
  getCurrentUserFailed,
  getCurrentUserSuccess,
  getFavoriteFiles,
  getFavoriteFilesSuccess,
  getUserPageData,
  getUserPageDataFailed,
  getUserPageDataSuccess,
  loginAction,
  loginFailed,
  loginSuccess,
  logoutAction,
  setCounters,
} from '../actions/users.actions';
import { addToFavoriteSuccess, removeFromFavoriteSuccess } from '../actions/shop.action';

const initialState: IUsersState = {
  currentUser: null,
  favoriteFilesList: [],
  userPageData: null,
  isLoading: false,
  isAvatarLoading: false,
  error: null,
  counters: null,
};

export const usersReducer = createReducer(
  initialState,
  on(
    getCurrentUser,
    loginAction,
    (state): IUsersState => ({ ...state, isLoading: true, error: null }),
  ),
  on(
    getCurrentUserSuccess,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      isLoading: false,
      currentUser: {
        ...action.user,
        favoriteShops: [
          ...action.user.favoriteShops.map((shop) => {
            return { ...shop, isFavorite: true };
          }),
        ],
      },
      error: null,
    }),
  ),
  on(
    getUserPageData,
    (state: IUsersState): IUsersState => ({
      ...state,
      isLoading: false,
      userPageData: null,
      error: null,
    }),
  ),
  on(
    getUserPageDataSuccess,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      isLoading: false,
      userPageData: action.user,
      error: null,
    }),
  ),
  on(
    setCounters,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      isLoading: false,
      counters: action.counters,
      error: null,
    }),
  ),
  on(
    loginSuccess,
    (state: IUsersState): IUsersState => ({
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    logoutAction,
    (): IUsersState => ({
      ...initialState,
    }),
  ),
  on(
    getCurrentUserFailed,
    getCountersFailed,
    loginFailed,
    getUserPageDataFailed,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      isLoading: false,
      error: action.error,
    }),
  ),
  on(
    removeFromFavoriteSuccess,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      currentUser: state?.currentUser
        ? {
            ...state.currentUser,
            favoriteShops: [
              ...state.currentUser.favoriteShops.map((shop) => {
                if (shop.id === action.shopId) {
                  return { ...shop, isFavorite: false };
                }
                return shop;
              }),
            ],
          }
        : null,
    }),
  ),
  on(
    addToFavoriteSuccess,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      currentUser: state?.currentUser
        ? {
            ...state.currentUser,
            favoriteShops: [
              ...state.currentUser.favoriteShops.map((shop) => {
                if (shop.id === action.shopId) {
                  return { ...shop, isFavorite: true };
                }
                return shop;
              }),
            ],
          }
        : null,
    }),
  ),
  on(
    getFavoriteFiles,
    (state: IUsersState): IUsersState => ({
      ...state,
      favoriteFilesList: [],
      isLoading: true,
      error: null,
    }),
  ),
  on(
    getFavoriteFilesSuccess,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      favoriteFilesList: action.favoriteFilesList,
      isLoading: false,
      error: null,
    }),
  ),
  on(
    addUserAvatar,
    (state: IUsersState): IUsersState => ({
      ...state,
      isAvatarLoading: true,
    }),
  ),
  on(
    addUserAvatarFailed,
    (state: IUsersState): IUsersState => ({
      ...state,
      isAvatarLoading: false,
    }),
  ),
  on(
    addUserAvatarSuccess,
    (state: IUsersState, action): IUsersState => ({
      ...state,
      isAvatarLoading: false,
      userPageData: state.userPageData
        ? {
            ...state.userPageData,
            avatar:
              state.userPageData.id === action.userId ? action.avatar : state.userPageData.avatar,
          }
        : null,
      currentUser: state?.currentUser
        ? {
            ...state.currentUser,
            avatar: action.avatar,
          }
        : null,
    }),
  ),
);
