<div
  #container
  *ngIf="currentPlatform$ | async as currentPlatform"
  class="create-post create-post_padding"
  [class.create-post-web]="
    !isMobModalOpen || (isEditMode && currentPlatform === CurrentPlatform.web)
  "
  [class.edit-post]="isEditMode && currentPlatform === CurrentPlatform.web"
  appClickOutside
  (clickOutside)="closeForm(chosenFiles)"
>
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <ion-header
      *ngIf="isMobModalOpen || (isEditMode && currentPlatform !== CurrentPlatform.web)"
      [class.ion-no-border]="currentPlatform !== CurrentPlatform.web"
    >
      <ion-toolbar>
        <app-text
          [textSize]="TextSizes.h1Header"
          [textType]="TextType.base"
          [text]="isEditMode ? 'Редактирование' : 'Что у вас нового?'"
        ></app-text>

        <ion-buttons slot="end">
          <ion-icon (click)="cancelPostCreation()" slot="icon-only" name="close-outline"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <form
      class="create-post__form"
      [formGroup]="newPostFormGroup"
      [class.create-post__form-mob]="
        isMobModalOpen || (isEditMode && currentPlatform !== CurrentPlatform.web)
      "
    >
      <ion-grid>
        <div class="create-post__form-content">
          <ion-row class="ion-align-items-start">
            <ion-col
              size=".8"
              class="col-avatar"
              *ngIf="!isMobModalOpen || (!isEditMode && currentPlatform === CurrentPlatform.web)"
            >
              <ng-container *ngIf="currentUser$ | async as currentUser">
                <div class="avatar">
                  <app-image
                    [img]="(currentUser?.avatar?.previewNormal | addBaseUrl) ?? ''"
                  ></app-image>
                </div>
              </ng-container>
            </ion-col>
            <ion-col class="col-text">
              <div class="content">
                <ion-textarea
                  #ionTextArea
                  [rows]="1"
                  [autofocus]="currentPlatform !== CurrentPlatform.web"
                  formControlName="msg"
                  [class.textarea-shown]="
                    isCreatePostOpen ||
                    isCreatePollOpen ||
                    form['poll'].value ||
                    isMobModalOpen ||
                    isEditMode
                  "
                  (ionFocus)="isCreatePostOpen = true"
                  spellcheck="true"
                  autocomplete="off"
                  autocapitalize="true"
                  placeholder="Написать пост"
                  maxlength="5000"
                  auto-grow="true"
                  [ngStyle]="
                    currentPlatform === CurrentPlatform.web
                      ? undefined
                      : { 'max-height': textAreaMaxHeight }
                  "
                ></ion-textarea>
              </div>
            </ion-col>
            <ion-col
              size=".3"
              class="col-icons"
              *ngIf="
                !isMobModalOpen &&
                currentPlatform === CurrentPlatform.web &&
                currentUser.userRole === userRoles.moderator
              "
            >
              <div
                *ngIf="!isCreatePostOpen && !form['poll'].value && !isEditMode"
                class="icon-poll"
                (click)="isCreatePostOpen = true; openPollWebModal()"
              >
                <div>
                  <ion-icon src="assets/images/icons-svg/posts/poll.svg"></ion-icon>
                </div>
              </div>
            </ion-col>
            <div
              *ngIf="chosenFiles.length"
              class="new-post-content__files"
              [class.mobile-post-creation]="currentPlatform !== CurrentPlatform.web"
            >
              <div
                #imagesContainer
                [class.mb]="currentPlatform === CurrentPlatform.web && !!form['poll'].value"
                [class.uploaded-images]="
                  chosenFiles.length && chosenFiles[0].fileType === FileType.image
                "
              >
                <ng-container *ngFor="let file of chosenFiles">
                  <ng-container *ngIf="file.fileType === FileType.image">
                    <div *ngIf="!file.error" class="uploaded-images__item">
                      <img
                        [src]="file.localUrl"
                        [class.loading]="file.isLoading"
                        draggable="false"
                        class="uploaded-images__item__image"
                      />
                      <img
                        class="delete-image"
                        src="assets/images/icons-svg/delete-icon-black.svg"
                        (click)="utils.handleDeleteChosenImage(file.localId)"
                      />
                      <div class="spinner" *ngIf="file.isLoading">
                        <ion-spinner color="light" name="circular"></ion-spinner>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="file.fileType === FileType.file">
                    <app-file-upload
                      [id]="file.localId"
                      [fileTitle]="file.localName"
                      type="file"
                      mode="create"
                      (deleteItem)="utils.handleDeleteChosenImage(file.localId)"
                      [currentPlatform]="currentPlatform"
                      [isLoading]="file.isLoading"
                    ></app-file-upload>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngIf="chosenFiles.length > (isEditMode ? 3 : 4)">
                <div
                  *ngIf="showLeftArrow"
                  class="arrow arrow-left"
                  (click)="currentPlatform === CurrentPlatform.web ? scrollLeft() : null"
                >
                  <img src="assets/images/icons-svg/arrow-right-big.svg" />
                </div>
                <div
                  *ngIf="showRightArrow"
                  class="arrow arrow-right"
                  (click)="currentPlatform === CurrentPlatform.web ? scrollRight() : null"
                >
                  <img src="assets/images/icons-svg/arrow-right-big.svg" />
                </div>
              </ng-container>
            </div>

            <div
              *ngIf="form['poll'].value"
              class="create-post__form-poll"
              (click)="isMobModalOpen ? openPollBottomSheet() : openPollWebModal()"
            >
              <app-file-upload
                [fileTitle]="form['poll'].value.text"
                [fileSubtitle]="'Опрос'"
                [isDisabled]="isEditMode"
                [type]="'poll'"
                (deleteItem)="!isEditMode ? deletePoll() : ''"
                [currentPlatform]="currentPlatform"
              ></app-file-upload>
            </div>
          </ion-row>
        </div>
      </ion-grid>
    </form>

    <ion-footer
      [class.footer-height]="currentPlatform === CurrentPlatform.web"
      *ngIf="
        isCreatePostOpen || isCreatePollOpen || form['poll'].value || isMobModalOpen || isEditMode
      "
      [class.ion-no-border]="currentPlatform !== CurrentPlatform.web"
    >
      <div class="error-block" *ngIf="error">
        <div class="error-block__text" [textContent]="error.message"></div>
        <div class="tooltip">
          <img src="assets/images/icons-svg/info.svg" />
          <div class="tooltip-content">
            <div class="tooltip-content__text">
              <span class="tooltip-content__text_bold">Форматы: </span>
              <span [textContent]="error.formats"></span>
            </div>
            <div class="tooltip-content__text">
              <span class="tooltip-content__text_bold">Размер: </span>
              до 10 MB
            </div>
          </div>
        </div>
      </div>
      <ion-toolbar>
        <ion-buttons
          slot="start"
          class="options"
          [class.no-poll-view]="currentUser.userRole !== userRoles.moderator"
        >
          <input
            #imageInput
            type="file"
            [accept]="availableImageFormats"
            (change)="handleUploadFile($event, chosenFiles)"
            multiple
            [style.display]="'none'"
          />
          <input
            #fileInput
            type="file"
            [accept]="allowedFormatsForFile.join(', ')"
            (change)="handleUploadFile($event, chosenFiles, allowedFormatsForFile)"
            [style.display]="'none'"
          />
          <app-image
            [img]="postModalMenuOptions[0].img"
            [class.disable]="
              (chosenFiles.length && chosenFiles[0].fileType !== FileType.image) ||
              chosenFiles.length >= fileLimit
            "
            (click)="
              currentPlatform === CurrentPlatform.web
                ? imageInput.click()
                : chooseImage(chosenFiles)
            "
          ></app-image>
          <app-image
            [img]="postModalMenuOptions[1].img"
            [class.disable]="chosenFiles.length"
            (click)="!chosenFiles.length && fileInput.click()"
          ></app-image>
          <ng-container *ngIf="currentUser.userRole === userRoles.moderator">
            <app-image
              [img]="postModalMenuOptions[2].img"
              (click)="isMobModalOpen ? openPollBottomSheet() : openPollWebModal()"
              [class.disable]="isEditMode || !!form['poll'].value"
            ></app-image>
            <app-image
              [img]="isPinned ? postModalMenuOptions[4].img : postModalMenuOptions[3].img"
              (click)="changeIsPinnedValue()"
            ></app-image>
          </ng-container>
        </ion-buttons>
        <ion-buttons slot="end">
          <div
            [ngClass]="
              isMobModalOpen || (isEditMode && currentPlatform !== CurrentPlatform.web)
                ? 'submit-btn-mob'
                : 'submit-btn-web'
            "
          >
            <app-button
              [btnType]="
                isMobModalOpen || (isEditMode && currentPlatform !== CurrentPlatform.web)
                  ? Buttons.small
                  : Buttons.base
              "
              [textSize]="ButtonTextSizes.h6BtnSmall"
              [text]="isMobModalOpen ? 'Готово' : isEditMode ? 'Сохранить' : 'Опубликовать'"
              [isDisabled]="!checkCanToSavePost(chosenFiles)"
              (click)="confirmPostCreation(currentUser.userDepartment.id, chosenFiles)"
            ></app-button>
          </div>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-container>
</div>

<div
  *ngIf="(currentPlatform$ | async) === CurrentPlatform.web && isEditMode"
  class="close-button"
  (click)="closeModal()"
>
  <app-svg icon="close"></app-svg>
</div>
