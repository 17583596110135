import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IonContent, ScrollDetail } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import {
  befreeUrlValues,
  scrollToTopAnimationSpeed,
  userMenuOptionsWeb,
} from 'src/app/shared/constants';
import { TextSizes, TextType } from 'src/app/shared/enums';
import { ScrollToService } from 'src/app/shared/services/scroll-to/scroll-to.service';
import { ScrollPositionSaver } from 'src/app/shared/services/scroll-position-saver/scroll-position-saver';
import { selectCounters } from 'src/app/data/store/selectors/users.selectors';
import { initPostsData } from 'src/app/data/store/actions/posts.actions';

@UntilDestroy()
@Component({
  selector: 'app-ion-page-content',
  templateUrl: './ion-page-content.component.html',
  styleUrls: ['./ion-page-content.component.scss'],
})
export class IonPageContentComponent implements OnInit {
  @ViewChild(IonContent) content?: IonContent;

  @Input() isModalView = false;

  @Input() forceOverscroll = true;

  @Output() scrolled = new EventEmitter<CustomEvent<ScrollDetail>>();

  public counters$ = this._store.select(selectCounters);

  public readonly TextType = TextType;

  public readonly TextSizes = TextSizes;

  public readonly menuOptions = userMenuOptionsWeb;

  public readonly dashboardUrl = befreeUrlValues.dashboard;

  public scrollEvent?: CustomEvent<ScrollDetail>;

  constructor(
    private _store: Store,
    private _router: Router,
    private _scrollToService: ScrollToService,
    private _scrollPositionSaver: ScrollPositionSaver,
  ) {}

  ngOnInit(): void {
    const savedPosition = this._scrollPositionSaver.getPosition(this._router.url);
    setTimeout(() => this.content?.scrollToPoint(0, savedPosition));

    this._scrollToService
      .scrollToEventListener()
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event.isModalView === this.isModalView || !event.isModalView) {
          this.content?.[event.scrollTo](scrollToTopAnimationSpeed);
        }
      });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        untilDestroyed(this),
      )
      .subscribe(() => {
        // не сохранять при переходе из модалки
        if (this.scrollEvent && !this.isModalView) {
          this._scrollPositionSaver.savePosition(
            this._router.url,
            this.scrollEvent?.detail?.scrollTop,
          );
        }
      });
  }

  get isBirthdaysColShow() {
    const whereToShow = [new RegExp('^/dashboard/posts$'), new RegExp('^/dashboard/post.*$')];
    return whereToShow.some((path) => path.exec(this._router.url));
  }

  public onScroll(event: CustomEvent<ScrollDetail>) {
    this.scrollEvent = event;
    this.scrolled.emit(event);
  }

  public navigate(url: string) {
    this._scrollPositionSaver.deletePositions();
    sessionStorage.clear();

    if (url === befreeUrlValues.posts) {
      this._store.dispatch(initPostsData());
    }

    this._scrollContent();
  }

  public isUrlExternal(url: string) {
    return url.includes('https://');
  }

  private _scrollContent() {
    this.content?.scrollToTop(0);
  }
}
