import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { befreeUrlValues } from '../../../../shared/constants';
import { TrainingSectionsComponent } from './pages/training-sections/training-sections.component';
import { EmployeeDevelopmentComponent } from './pages/employee-development/employee-development.component';
import { TrainingAnalyticsComponent } from './pages/training-analytics/training-analytics.component';

const routes: Routes = [
  {
    path: befreeUrlValues.sections,
    component: TrainingSectionsComponent,
  },
  {
    path: befreeUrlValues.employeeDevelopment,
    component: EmployeeDevelopmentComponent,
  },
  {
    path: befreeUrlValues.analytics,
    component: TrainingAnalyticsComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: befreeUrlValues.sections },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrainingRoutingModule {}
