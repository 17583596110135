import { HttpErrorResponse } from '@angular/common/http';
import {
  IPhotoReport,
  IPhotoReportCategory,
} from '../../../shared/services/photo-reports/photo-reports.type';
import { IImage } from '../../../shared/services/posts/posts.type';

export const PHOTO_REPORT_DATA_KEY = 'PHOTO_REPORT_DATA_KEY';

export enum PhotoReportsActions {
  PhotoReportData = 'PhotoReportData',
  PhotoReportsList = 'PhotoReportsList',
  UploadFile = 'UploadFile',
  UploadedFilesData = 'UploadedFilesData',
  CleanUploadedFilesData = 'CleanUploadedFilesData',
  DeleteUploadedFilesData = 'DeleteUploadedFilesData',
  LikePhotoReport = 'LikePhotoReport',
  DislikePhotoReport = 'DislikePhotoReport',
  DeletePhotoReport = 'DeletePhotoReport',
  ChangePhotoReportImage = 'ChangePhotoReportImage',
  UpdatePhotoReportData = 'UpdatePhotoReportData',
  GetPhotoReportCategories = 'GetPhotoReportCategories',
  CreatePhotoReport = 'CreatePhotoReport',
  CleanCreatePhotoReportUploadingImages = 'CleanCreatePhotoReportUploadingImages',
  ClearCurrentPhotoReportData = 'ClearCurrentPhotoReportData',
  ChangeEditMode = 'ChangeEditMode',
  LikedListUpdate = 'LikedListUpdate',
  ClearLikedList = 'ClearLikedList',
  LikeImage = 'LikeImage',
  DislikeImage = 'DislikeImage',
  ClearPhotoReportsList = 'ClearPhotoReportsList',
  AddComment = 'AddComment',
}

export interface IPhotoReportDataState {
  photoReport: IPhotoReport | null;
  photoReportsList: IPhotoReport[];
  isAllListFetched: boolean;
  categories: IPhotoReportCategory[] | null;
  photoReportImages: { [key: string]: IImage[] } | null;
  photoReportCategories: string[] | null;
  photoReportCategoriesNames: { [key: string]: string } | null;
  photoReportImagesId: { id: number }[] | null | undefined;
  uploadingFiles: { [key: string]: IImage[] } | null;
  likedList: number[] | any[];
  currentMode: string;
  error: string | HttpErrorResponse | null;
  isLoading: boolean;
}
