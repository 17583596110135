import { createReducer, on } from '@ngrx/store';
import {
  addToFavorite,
  addToFavoriteFailed,
  addToFavoriteSuccess,
  clearShopsList,
  clearShopsPhotoReportsList,
  currentShopDataFailed,
  currentShopDataSuccess,
  getCurrentShopsPhotoReports,
  getCurrentShopsPhotoReportsFailed,
  getCurrentShopsPhotoReportsSuccess,
  getShopsList,
  getShopsListFailed,
  getShopsListSuccess,
  initCurrentShopData,
  removeFromFavorite,
  removeFromFavoriteFailed,
  removeFromFavoriteSuccess,
} from '../actions/shop.action';
import { IShopState } from '../models/shop.model';
import { getAdditionalDepartmentsList } from '../actions/department.action';

const initialState: IShopState = {
  shop: null,
  isLoading: false,
  shopList: [],
  allShopDataFetched: false,
  isShopDataListLoading: false,
  error: null,
  shopPhotoReportList: [],
};

export const shopReducer = createReducer(
  initialState,
  on(
    initCurrentShopData,
    (state: IShopState): IShopState => ({
      ...state,
      isLoading: true,
      shop: null,
      error: null,
    }),
  ),
  on(
    getShopsList,
    getCurrentShopsPhotoReports,
    (state: IShopState): IShopState => ({
      ...state,
      allShopDataFetched: false,
      isShopDataListLoading: true,
      error: null,
    }),
  ),
  on(
    getShopsListSuccess,
    (state: IShopState, action): IShopState => ({
      ...state,
      shopList:
        !state.shopList || action.resetData
          ? action.shopList.elements
          : [...state.shopList, ...action.shopList.elements],
      allShopDataFetched: action.shopList.elements.length < action.size,
      isShopDataListLoading: false,
      error: null,
    }),
  ),
  on(getCurrentShopsPhotoReportsSuccess, (state: IShopState, action): IShopState => {
    return {
      ...state,
      shopPhotoReportList: action.resetData
        ? action.currentShopsPhotoReports
        : [...state.shopPhotoReportList, ...action.currentShopsPhotoReports],
      allShopDataFetched: action.currentShopsPhotoReports.length < action.size,
      isShopDataListLoading: false,
      error: null,
    };
  }),
  on(
    addToFavorite,
    removeFromFavorite,
    (state: IShopState): IShopState => ({
      ...state,
      isLoading: true,
      error: null,
    }),
  ),
  on(
    currentShopDataSuccess,
    (state: IShopState, action): IShopState => ({
      ...state,
      isLoading: false,
      shop: action.shop,
    }),
  ),
  on(
    addToFavoriteSuccess,
    (state: IShopState, action): IShopState => ({
      ...state,
      isLoading: false,
      shop: state.shop ? { ...state.shop, isFavorite: true } : initialState.shop,
      shopList: state.shopList.length
        ? state.shopList.map((shop) =>
            shop.id === action.shopId ? { ...shop, isFavorite: true } : shop,
          )
        : state.shopList,
    }),
  ),
  on(
    removeFromFavoriteSuccess,
    (state: IShopState, action): IShopState => ({
      ...state,
      isLoading: false,
      shop: state.shop ? { ...state.shop, isFavorite: false } : initialState.shop,
      shopList: state.shopList
        ? state.shopList.map((shop) =>
            shop.id === action.shopId ? { ...shop, isFavorite: false } : shop,
          )
        : state.shopList,
    }),
  ),
  on(
    currentShopDataFailed,
    addToFavoriteFailed,
    removeFromFavoriteFailed,
    getShopsListFailed,
    getCurrentShopsPhotoReportsFailed,
    (state: IShopState, action): IShopState => ({
      ...state,
      isLoading: false,
      isShopDataListLoading: false,
      error: action.error,
    }),
  ),
  on(
    clearShopsList,
    (state: IShopState): IShopState => ({
      ...initialState,
      shop: state.shop,
    }),
  ),
  on(
    clearShopsPhotoReportsList,
    (state): IShopState => ({
      ...state,
      shopPhotoReportList: [],
    }),
  ),
  on(
    getAdditionalDepartmentsList,
    (state: IShopState): IShopState => ({
      ...state,
      isShopDataListLoading: true,
    }),
  ),
);
