import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { INotifications } from '../../services/notifications/notifications.type';
import { selectNotificationsWithoutLikes } from 'src/app/data/store/selectors/notifications.selectors';
import { Observable } from 'rxjs';
import { EntityForNotification, TextSizes, TextType } from '../../enums';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  animations: [
    trigger('appearance', [
      transition(':enter', [
        style({ transform: 'translateY(20%)', opacity: 0 }),
        animate('350ms ease-in', style({ transform: 'translateY(0%)', opacity: 1 })),
      ]),
      transition(':leave', [animate('350ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NotificationContainerComponent {
  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly notificationTypes = EntityForNotification;

  public notifications$: Observable<INotifications[] | null> = this._store.select(
    selectNotificationsWithoutLikes,
  );

  constructor(private _store: Store) {}
}
