import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import {
  CurrentPlatform,
  DataType,
  MenuIconWidth,
  PostUserViewType,
  TextSizes,
  TextType,
  UserDataImgSize,
  UserRoles,
  ViewType,
} from '../../enums';
import { zoomValue } from '../../constants';
import { IImage, IPost } from '../../services/posts/posts.type';
import { selectCurrentPlatform } from '../../../data/store/selectors/platform.selectors';
import { Store } from '@ngrx/store';
import { from, map, Observable, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IPhotoReport } from '../../services/photo-reports/photo-reports.type';
import {
  addFileToFavoritesList,
  removeFileFromFavoritesList,
} from '../../../data/store/actions/posts.actions';
import { UtilsService } from '../../services/utils.service';
import { selectCurrentUser } from '../../../data/store/selectors/users.selectors';
import { IUser } from '../../services/user/user.type';
import { changeEditMode, deletePhotoReport } from '../../../data/store/actions/photo-report.action';
import { CreatePhotoReportComponent } from '../../../modules/dashboard/components/shops/pages/current-shop-page/create-photo-report/create-photo-report.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AlertController, ModalController } from '@ionic/angular';
import { selectFavoritesIsLoading } from 'src/app/data/store/selectors/posts.selectors';

@Component({
  selector: 'app-post-template',
  templateUrl: './post-template.component.html',
  styleUrls: ['./post-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostTemplateComponent implements OnChanges {
  @Input() postData: IPost | null | undefined;

  @Input() photoReportData: IPhotoReport | null | undefined;

  @Input() hashtag: string | undefined;

  @Input() isStoreList: boolean = false;

  @Input() viewType: string | undefined;

  @Input() userViewType: PostUserViewType = PostUserViewType.full;

  @Input() dataType: string | undefined;

  @Input() onCommentIconClick: (() => void) | undefined | null;

  public maxLength: number = 120;

  public isOpen: boolean = false;

  public isLoadingFavorite$ = this._store.select(selectFavoritesIsLoading);

  public platform$: Observable<CurrentPlatform | null> = this._store.select(selectCurrentPlatform);

  public currentUser$: Observable<IUser | null> = this._store.select(selectCurrentUser);

  public befreeUrl: string = environment.befreeUrl;

  public currentPostData: IPost | IPhotoReport | undefined | null;

  public readonly zoomValue = zoomValue;

  public readonly CurrentPlatform = CurrentPlatform;

  public readonly MenuIconWidth = MenuIconWidth;

  public readonly UserDataImgSize = UserDataImgSize;

  public readonly TextType = TextType;

  public readonly TextSizes = TextSizes;

  public readonly ViewTypesList = ViewType;

  public readonly DataTypesList = DataType;

  public readonly PostUserViewType = PostUserViewType;

  public readonly columnRange: number[] = [1, 3];

  public readonly userRoles = UserRoles;

  constructor(
    private alertController: AlertController,
    private _store: Store,
    public utilsService: UtilsService,
    private _modalCtrl: ModalController,
    private _cdRef: ChangeDetectorRef,
  ) {}

  public getIsShowDropdownButton(currentUser: IUser): boolean {
    return (
      !(
        this.getIsShowEditPhotoReportBtn(currentUser) ||
        this.getIsShowDeletePhotoReportBtn(currentUser)
      ) &&
      !!(
        (currentUser.id === this.currentPostData?.author?.id && this.postData) ||
        currentUser.userRole === this.userRoles.moderator ||
        this.photoReportData?.shop?.id === currentUser.userDepartment.id
      )
    );
  }

  public getIsShowDeletePhotoReportBtn(currentUser: IUser): boolean {
    return !!(
      this.photoReportData?.shop &&
      currentUser.userRole === this.userRoles.moderator &&
      currentUser.userDepartment.id !== this.photoReportData.shop.id
    );
  }

  public getIsShowEditPhotoReportBtn(currentUser: IUser): boolean {
    return !!(
      this.photoReportData?.shop &&
      currentUser.userRole === this.userRoles.standard &&
      currentUser.userDepartment.id === this.photoReportData.shop.id &&
      currentUser.id !== this.photoReportData.author?.id
    );
  }

  public getAlertDeleteModal = (deletePost: () => void, isPostDataType?: boolean) => {
    this.alertController
      .create({
        header: isPostDataType
          ? 'Вы уверены, что хотите удалить запись?'
          : 'Вы уверены, что хотите удалить фотоотчёт?',
        subHeader: 'После удаления вы не сможете отменить это действие.',
        cssClass: 'custom-alert',
        mode: 'ios',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Отменить',
            role: 'cancel',
            cssClass: 'alert-button',
          },
          {
            text: 'Удалить',
            role: 'confirm',
            cssClass: 'alert-button',
            handler: () => {
              deletePost();
            },
          },
        ],
      })
      .then((alert) => alert.present());
  };

  public deletePhotoReport = () => {
    if (this.photoReportData?.id) {
      this._store.dispatch(deletePhotoReport({ photoReportId: this.photoReportData.id }));
    }
  };

  public editPhotoReport() {
    if (this.photoReportData?.id) {
      this._store.dispatch(changeEditMode({ currentMode: ViewType.editPhotoReport }));
      const modal$ = from(
        this._modalCtrl.create({
          component: CreatePhotoReportComponent,
          animated: true,
          backdropDismiss: false,
        }),
      );

      modal$
        .pipe(
          take(1),
          map((modal) => modal.present()),
        )
        .subscribe();

      modal$
        .pipe(
          untilDestroyed(this),
          map((modal) => modal.onWillDismiss()),
        )
        .subscribe();
    }
  }

  ngOnChanges(): void {
    this.currentPostData =
      this.dataType === this.DataTypesList.photoReport ? this.photoReportData : this.postData;

    this._cdRef.detectChanges();
  }

  handleOpenMenu() {
    this.isOpen = !this.isOpen;
  }

  public async openGalleryModal(index: number) {
    if (!this.postData) return;
    const data = { ...this.postData?.author, date: this.postData?.created };
    this.utilsService.openGalleryModalWithComments('fullscreen', {
      images: this.postData?.images,
      userData: data,
      hideInputs: true,
      chosenImgIndex: index,
    });
  }

  handleChangeFavouritesStatus(id: number, isInFavouritesList: boolean) {
    if (!this.postData) {
      return;
    }
    if (!isInFavouritesList) {
      this._store.dispatch(
        addFileToFavoritesList({
          fileId: id,
          postId: this.postData?.id,
        }),
      );
    } else {
      this._store.dispatch(
        removeFileFromFavoritesList({
          fileId: id,
          postId: this.postData?.id,
        }),
      );
    }
  }

  public trackByFn(index: number, obj: IImage) {
    return obj.id;
  }
}
