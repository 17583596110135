import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IShop } from 'src/app/shared/services/user/user.type';
import { IUsersState, USERS_KEY } from '../models/users.model';
import { UnreadCountersStates } from 'src/app/shared/enums';

export const selectFeature = createFeatureSelector<IUsersState>(USERS_KEY);

export const selectUsersState = createSelector(selectFeature, (state: IUsersState) => state);

export const selectCurrentUser = createSelector(
  selectFeature,
  (state: IUsersState) => state.currentUser,
);

export const selectCounters = createSelector(selectFeature, (state: IUsersState) => state.counters);

export const selectUnreadCommentsCounter = createSelector(
  selectFeature,
  (state: IUsersState) => state.counters?.[UnreadCountersStates.comments],
);

export const selectUnreadRepliesCounter = createSelector(
  selectFeature,
  (state: IUsersState) => state.counters?.[UnreadCountersStates.replies],
);

export const selectLoginErrorCode = createSelector(selectFeature, (state) =>
  state.error ? state.error.error?.message : null,
);

export const selectCurrentUserFavsShop = createSelector(
  selectFeature,
  (state: IUsersState) => state.currentUser?.favoriteShops,
);

export const selectCurrentUserFavsShopIds = createSelector(selectFeature, (state: IUsersState) =>
  state.currentUser
    ? state.currentUser.favoriteShops
        .filter((shop: IShop) => shop.isFavorite)
        .map((shop: IShop) => shop.id)
    : [],
);

export const selectUserPageData = createSelector(
  selectFeature,
  (state: IUsersState) => state.userPageData,
);

export const selectUsersDataIsLoading = createSelector(
  selectFeature,
  (state: IUsersState) => state.isLoading,
);

export const selectFavoriteFilesList = createSelector(
  selectFeature,
  (state: IUsersState) => state.favoriteFilesList,
);

export const selectUserAvatarIsLoading = createSelector(
  selectFeature,
  (state: IUsersState) => state.isAvatarLoading,
);
