import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { clearFiltersStore, incrementFilterPage } from 'src/app/data/store/actions/filters.actions';
import { selectFiltersMenuState } from 'src/app/data/store/selectors/filters.selectors';
import { noDataText } from '../../constants';
import { TextSizes, TextType } from '../../enums';
import { distinctUntilChanged, filter, Observable, take } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { TypedAction } from '@ngrx/store/src/models';
import { NavigationStart, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-pagination-container',
  templateUrl: './pagination-container.component.html',
  styleUrls: ['./pagination-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationContainerComponent implements OnInit {
  @Input() isLoading$: Observable<boolean | null> = new Observable();

  @Input() isAllLoaded: boolean | null = true;

  @Input() noDataText?: string = noDataText;

  @Input() paginationData: Array<any> | null | undefined;

  @Input() isFiltersPagination: boolean = false;

  @Input() isOnlyFiltersList: boolean = false;

  @Input() hideLoading?: boolean = false;

  @Input() listName?: string;

  @Input() clearListAction?: TypedAction<any>;

  @Output() emitScrolling = new EventEmitter<string | null | undefined>();

  readonly TextSizes = TextSizes;

  readonly TextType = TextType;

  public filtersMenu$ = this._store.select(selectFiltersMenuState);

  public isLoading: boolean | null = false;

  public elementId: string | undefined;

  constructor(
    private _store: Store,
    private _utils: UtilsService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
  ) {}

  public ngOnInit(): void {
    this.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading: boolean | null) => {
      this.isLoading = isLoading;
      this._cdr.detectChanges();
    });

    this.filtersMenu$
      .pipe(
        untilDestroyed(this),
        filter(
          (data) =>
            (data.filters?.filtersMenuParams != null || data.query != null) && !this.isLoading,
        ),
        distinctUntilChanged((prev, curr) => {
          return this._utils.deepEqual(prev, curr);
        }),
      )
      .subscribe((res) => {
        this.isLoading = true;
        setTimeout(() => {
          this.emitScrolling.emit(res.query);
        }, 0);
      });

    this._cdr.detectChanges();
    if (!this.paginationData?.length) {
      this.isLoading = true;
      this._store.dispatch(incrementFilterPage({ initialPage: 0 }));
      this.emitScrolling.emit();
    }
    if (this.listName) {
      this.resetListWithOptions(this.listName, this.clearListAction, !this.isFiltersPagination);
    }

    if (sessionStorage[String(this.listName)]) {
      this.elementId = sessionStorage[String(this.listName)];
    }
  }

  public resetListWithOptions(
    listName?: string,
    action?: TypedAction<any>,
    clearFilters?: boolean,
  ) {
    this._router.events
      .pipe(filter((event): event is NavigationStart => event instanceof NavigationStart))
      .pipe(take(1))
      .subscribe((event: NavigationStart) => {
        if (!event.url.includes(String(listName)) && action) {
          if (event.url === '/dashboard/shops' && listName === 'shop-list') {
            return;
          }
          this._store.dispatch(action);
          if (clearFilters) this._store.dispatch(clearFiltersStore());
        }
      });
  }

  public onScroll(event?: Event, initialPage?: number): void {
    (event as InfiniteScrollCustomEvent)?.target.complete();
    if (!this.isAllLoaded && !this.isLoading) {
      this.isLoading = true;
      this._store.dispatch(incrementFilterPage({ initialPage }));
      this.emitScrolling.emit();
    }
  }

  get showLoading() {
    return this.isLoading && !this.hideLoading;
  }
}
