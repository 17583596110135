import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() btnType: string | undefined;

  @Input() textSize: string | undefined;

  @Input() color: string | undefined;

  @Input() src: string | undefined;

  @Input() text: string | undefined;

  @Input() isLoading: boolean = false;

  @Input() isDisabled: boolean = false;

  @Input() isCursorPointer: boolean = true;

  constructor(private _elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this._elementRef &&
      changes['isDisabled']?.currentValue !== changes['isDisabled']?.previousValue
    ) {
      this._elementRef.nativeElement.style['pointer-events'] = changes['isDisabled'].currentValue
        ? 'none'
        : 'initial';
    }
  }
}
