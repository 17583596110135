import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Storage, StorageType } from './storage.type';
import { from, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public storageChange$ = new Subject<Storage>();

  public async setItem(type: StorageType, value: any) {
    await Preferences.set({
      key: type,
      value: JSON.stringify(value),
    });
  }

  public getItem<T>(type: StorageType): Observable<T> {
    return from(this._get(type));
  }

  private async _get(type: StorageType) {
    const { value } = await Preferences.get({ key: type });
    return value ? JSON.parse(value) : null;
  }

  public async removeItem(type: StorageType) {
    await Preferences.remove({ key: type });
  }

  public clearStorage(): void {
    localStorage.clear();
    Object.keys(StorageType).forEach((k: string) => {
      const key = k as keyof typeof StorageType;

      this.removeItem(StorageType[key]);
    });
  }
}
