import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { IFilter, IPostsFilter } from '../../interfaces';
import { BaseHttpService } from '../base-http/base-http.service';
import { IUser, IUsersArray } from '../user/user.type';
import {
  IComment,
  ICommentsArray,
  IImage,
  IPoll,
  IPollOptions,
  IPost,
  IPostData,
  IPostsArray,
} from './posts.type';
import { FileUploadHttpService } from '../file-upload-http/file-upload-http.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  private readonly _postHref = '/posts';

  private readonly _commentsUrl = `/comments`;

  private readonly _rootCommentsUrl = `/root-comments`;

  private readonly _departmentsUrl = `/departments`;

  private readonly _likesUrl = `/likes`;

  private readonly _likeUrl = `/like`;

  private readonly _unlikeUrl = `/unlike`;

  private readonly _pinUrl = `/pin`;

  private readonly _pollsHref = '/polls';

  private readonly _pollVoteUrl = '/vote';

  private readonly _pollsParticipantsUrl = '/participants';

  private readonly _filesHref = '/files';

  private readonly _uploadUrl = '/upload';

  private readonly _imagesUrl = '/images';

  private readonly _rotateSyncUrl = '/rotate_sync';

  private readonly _blockPost = '/block';

  constructor(
    private _http: BaseHttpService,
    private _httpFile: FileUploadHttpService,
    private _store: Store,
  ) {}

  public getPosts(filters?: IPostsFilter): Observable<IPostsArray> {
    return this._http.get<IPostsArray>(`${this._postHref}`, filters);
  }

  public getPostById(postId: number): Observable<IPost> {
    return this._http.get<IPost>(`${this._postHref}/${postId}`);
  }

  public getPostComments(postId: number): Observable<IPost> {
    return this._http.get<IPost>(`${this._postHref}/${postId}${this._commentsUrl}`);
  }

  public getPostRootComments(postId: number, filters?: IFilter): Observable<ICommentsArray> {
    return this._http.get<ICommentsArray>(
      `${this._postHref}/${postId}${this._rootCommentsUrl}`,
      filters,
    );
  }

  public getPostsByDepartment(departmentId: number): Observable<IPostsArray> {
    return this._http.get<IPostsArray>(`${this._postHref}/${this._departmentsUrl}/${departmentId}`);
  }

  public getPostLikes(postId: number): Observable<IUsersArray> {
    return this._http.get<IUsersArray>(`${this._postHref}/${this._likesUrl}/${postId}`);
  }

  public createPost(post: Partial<IPostData>): Observable<IPost> {
    return this._http.post<IPost>(`${this._postHref}`, post);
  }

  public editPost(postId: number, post: Partial<IPostData>): Observable<IPost> {
    return this._http.post<IPost>(`${this._postHref}/${postId}`, post);
  }

  public addCommentToPost(postId: number, post: Partial<IComment>): Observable<IComment> {
    return this._http.post<IComment>(`${this._postHref}/${postId}${this._commentsUrl}`, post);
  }

  public editComment(commentId: number, commentData: Partial<IComment>): Observable<IComment> {
    return this._http.put<IComment>(`${this._commentsUrl}/${commentId}`, commentData);
  }

  public pinPost(postId: number): Observable<IPost> {
    return this._http.post<IPost>(`${this._postHref}/${postId}${this._pinUrl}`);
  }

  public deletePost(postId: number): Observable<IPost> {
    return this._http.delete<IPost>(`${this._postHref}/${postId}`);
  }

  public likePost(postId: number): Observable<IPost> {
    return this._http.post<IPost>(`${this._postHref}${this._likeUrl}/${postId}`);
  }

  public dislikePost(postId: number): Observable<IPost> {
    return this._http.post<IPost>(`${this._postHref}${this._unlikeUrl}/${postId}`);
  }

  public voteInThePoll(pollId: number, pollsOptions: IPollOptions[]): Observable<IPoll> {
    return this._http.post(`${this._pollsHref}/${pollId}${this._pollVoteUrl}`, {
      options: pollsOptions,
    });
  }

  public getVotedParticipants(pollId: number, pollOptionId: number): Observable<IUser[]> {
    return this._http.get<IUser[]>(`${this._pollsHref}/${pollId}${this._pollsParticipantsUrl}`, {
      pollOptionId,
    });
  }

  public getFile(id: number) {
    return this._http.get<IImage>(`${this._filesHref}/${id}`);
  }

  public uploadFile(file: { file: File; rotateAngle?: number; imageCategory?: string }) {
    return this._httpFile.postBlob(`${this._filesHref}${this._uploadUrl}`, file).pipe(retry(1));
  }

  public changeImageRotateAngle(id: number, rotate: number) {
    return this._http.post(`${this._imagesUrl}${this._rotateSyncUrl}`, { id: id, angle: rotate });
  }

  public blockPost(id: number, reason: string) {
    return this._http.post<IPostsArray>(`${this._postHref}/${id}/${this._blockPost}`, { reason });
  }
}
