import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http/base-http.service';
import { IPaginationFilter } from '../../../data/store/models/filters.model';
import {
  ICurrentSection,
  IPositionsList,
  ITrainingLevels,
  ITrainingEmployeesArray,
  ITrainingStage,
  ITrainingFormData,
  IPositionsListItem,
  ITrainingXlsReport,
} from './training.type';
import { ITrainingSearchResult } from './training.type';
import { Observable, map } from 'rxjs';
import { IFilter } from '../../interfaces';
import { ITrainingUserPositionGroup, IUser } from '../user/user.type';

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  private readonly _trainingUrl = '/training';

  private readonly _trainerUrl = '/trainer';

  private readonly _sectionsUrl = '/sections';

  private readonly _sectionUrl = '/section';

  private readonly _contentUrl = '/content';

  private readonly _pagedUrl = '/paged';

  private readonly _stageUrl = '/stage';

  private readonly _levelsUrl = '/levels';

  private readonly _positionsUrl = '/positions';

  private readonly _publishUrl = '/publish';

  private readonly _trainings = '/trainings';

  private readonly _trainingEmployees = '/users';

  private readonly _untrained = '/untrained';

  private readonly _count = '/count';

  private readonly _trainingReport = '/report';

  constructor(private _http: BaseHttpService) {}

  public getSections(filters?: IPaginationFilter) {
    return this._http.get<ITrainingUserPositionGroup[]>(
      `${this._trainingUrl}${this._sectionsUrl}`,
      filters,
    );
  }

  public getCurrentSectionInfo(id: number) {
    return this._http.get<ICurrentSection>(`${this._trainingUrl}${this._sectionUrl}/${id}`);
  }

  public getCurrentStage(id: number) {
    return this._http.get<ITrainingStage>(`${this._trainingUrl}${this._stageUrl}/${id}`);
  }

  public getTrainingFilesList(filters?: IPaginationFilter) {
    return this._http.get<ITrainingSearchResult>(
      `${this._trainingUrl}${this._contentUrl}${this._pagedUrl}`,
      filters,
    );
  }

  public getTrainerLevels(userId: number): Observable<ITrainingLevels[]> {
    return this._http.get<ITrainingLevels[]>(`${this._trainerUrl}${this._levelsUrl}/${userId}`);
  }

  public getPotentialList(filters?: IFilter): Observable<IPositionsList> {
    return this._http.get<IPositionsList>(`${this._positionsUrl}${this._pagedUrl}`, filters);
  }

  public getPositionsList(): Observable<IPositionsListItem[]> {
    return this._http.get<IPositionsListItem[]>(`${this._positionsUrl}${this._levelsUrl}`);
  }

  public sendForm(form: Partial<ITrainingFormData>, userId?: number) {
    return this._http.put(
      `${this._trainerUrl}${this._levelsUrl}/${userId}${this._publishUrl}`,
      form,
    );
  }

  public saveDraftForm(form: Partial<ITrainingFormData>) {
    return this._http.put(`${this._trainerUrl}${this._levelsUrl}`, form);
  }

  public getTrainingEmployeesList(filters?: IFilter): Observable<IUser[]> {
    return this._http
      .get<ITrainingEmployeesArray>(`${this._trainings + this._trainingEmployees}`, filters)
      .pipe(map((data) => data.elements));
  }

  public getTrainingXlsReport(filters: FormData): Observable<ITrainingXlsReport> {
    return this._http.postFormData<ITrainingXlsReport>(
      `${this._trainings}${this._trainingReport}`,
      filters,
    );
  }
}
