import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PluginListenerHandle } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, Observable, Subscription, take } from 'rxjs';
import { clearFiltersStore } from 'src/app/data/store/actions/filters.actions';
import { startPollingNotifications } from 'src/app/data/store/actions/notifications.action';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import { selectUnreadCommentsCounter } from 'src/app/data/store/selectors/users.selectors';
import { befreeUrlValues, footerMenuOptions } from 'src/app/shared/constants';
import { CurrentPlatform, ScrollToType, TextSizes, TextType } from 'src/app/shared/enums';
import { PushService } from 'src/app/shared/services/push/push.service';
import { ScrollToService } from 'src/app/shared/services/scroll-to/scroll-to.service';
import { ScrollPositionSaver } from '../../shared/services/scroll-position-saver/scroll-position-saver';
import { initPostsData } from '../../data/store/actions/posts.actions';
import { getCounters } from 'src/app/data/store/actions/users.actions';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('footer') footer?: ElementRef<HTMLDivElement>;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly befreeUrlValues = befreeUrlValues;

  public readonly footerMenuOptions = footerMenuOptions;

  public readonly CurrentPlatform = CurrentPlatform;

  public unreadComments$: Observable<number | undefined> = this._store.select(
    selectUnreadCommentsCounter,
  );

  public currentUrl = this.router.url.replace('/dashboard/', '');

  public platform$: Observable<CurrentPlatform | null> = this._store.select(selectCurrentPlatform);

  private _routerSubscription?: Subscription;

  keyboardWillShowListener: PluginListenerHandle | undefined;

  keyboardWillHideListener: PluginListenerHandle | undefined;

  constructor(
    public router: Router,
    private _store: Store,
    private _pushService: PushService,
    private _scrollToService: ScrollToService,
    private _scrollPositionSaver: ScrollPositionSaver,
  ) {
    this.keyboardShowHandler = this.keyboardShowHandler.bind(this);
    this.keyboardHideHandler = this.keyboardHideHandler.bind(this);

    this._routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this._store.dispatch(clearFiltersStore());
      });
  }

  ngOnInit(): void {
    this.platform$.pipe(untilDestroyed(this), take(1)).subscribe((platform) => {
      if (platform === CurrentPlatform.web) {
        this._store.dispatch(getCounters());
        this._store.dispatch(startPollingNotifications());
      } else if (platform) {
        this._pushService.init(platform);
        this.keyboardWillShowListener = Keyboard.addListener(
          'keyboardWillShow',
          this.keyboardShowHandler,
        );
        this.keyboardWillHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this.keyboardHideHandler,
        );
      }
    });
  }

  ngOnDestroy(): void {
    this._routerSubscription?.unsubscribe();
    this.keyboardWillShowListener?.remove();
    this.keyboardWillHideListener?.remove();
  }

  public navigate(url: string) {
    this._scrollPositionSaver.deletePositions();
    sessionStorage.clear();

    if (url === befreeUrlValues.posts) {
      this._store.dispatch(initPostsData());
    }

    if (this.currentUrl === url) {
      this._scrollToService.scrollToEvent({ scrollTo: ScrollToType.top });
    }

    this.router.navigate([`${befreeUrlValues.dashboard}/${url}`]);
    this.currentUrl = url;
  }

  public trackByFn(index: number) {
    return index;
  }

  get isFooterShown() {
    const notToShowUrl = [
      new RegExp('dashboard/posts/'),
      new RegExp(`/${befreeUrlValues.dashboard}/${befreeUrlValues.avatar}`),
    ];
    return !notToShowUrl.some((path) => path.exec(this.router.url));
  }

  get isHeaderShown() {
    const notToShowUrl = [new RegExp(`/${befreeUrlValues.dashboard}/${befreeUrlValues.avatar}`)];
    return !notToShowUrl.some((path) => path.exec(this.router.url));
  }

  public keyboardShowHandler() {
    if (this.footer) {
      this.footer.nativeElement.classList.add('footer_hidden');
    }
  }

  public keyboardHideHandler() {
    if (this.footer) {
      this.footer.nativeElement.classList.remove('footer_hidden');
    }
  }
}
