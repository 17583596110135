import { createAction, props } from '@ngrx/store';
import { ReducerSections, ReducerStatuses } from '../models/reducer';
import { UsersActions } from '../models/users.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IFilter, ILoginAccount } from '../../../shared/interfaces';
import { IImage } from 'src/app/shared/services/posts/posts.type';
import {
  IUser,
  IAvatar,
  ICropParams,
  IFavouritesFile,
} from 'src/app/shared/services/user/user.type';

export const loginAction = createAction(
  `${ReducerSections.USERS} ${UsersActions.Login}`,
  props<{ data: ILoginAccount }>(),
);

export const loginSuccess = createAction(
  `${ReducerSections.USERS}  ${UsersActions.Login} ${ReducerStatuses.SUCCESS}`,
);

export const loginFailed = createAction(
  `${ReducerSections.USERS} ${UsersActions.Login} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getCurrentUser = createAction(`${ReducerSections.USERS} ${UsersActions.GetCurrent}`);

export const getCurrentUserSuccess = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetCurrent} ${ReducerStatuses.SUCCESS}`,
  props<{ user: IUser }>(),
);

export const getCurrentUserFailed = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetCurrent} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getCounters = createAction(`${ReducerSections.USERS} ${UsersActions.GetCounters}`);

export const getCountersSuccess = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetCounters} ${ReducerStatuses.SUCCESS}`,
  props<{ userPostUnread: number; userCommentUnread: number; userRepliesUnread: number }>(),
);

export const setCounters = createAction(
  `${ReducerSections.USERS} ${UsersActions.SetCounters}`,
  props<{ counters: { [x: string]: number } }>(),
);

export const getCountersFailed = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetCounters} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const logoutAction = createAction(`${ReducerSections.USERS} ${UsersActions.Logout}`);

export const getUserPageData = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetUser}`,
  props<{ userId: number }>(),
);

export const getUserPageDataSuccess = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetUser} ${ReducerStatuses.SUCCESS}`,
  props<{ user: IUser }>(),
);

export const getUserPageDataFailed = createAction(
  `${ReducerSections.USERS} ${UsersActions.GetUser} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const getFavoriteFiles = createAction(
  `${ReducerSections.FILE} ${UsersActions.GetFavoritesFiles}`,
  props<{ favoriteFilesFilters: Partial<IFilter> }>(),
);

export const getFavoriteFilesSuccess = createAction(
  `${ReducerSections.FILE} ${UsersActions.GetFavoritesFiles} ${ReducerStatuses.SUCCESS}`,
  props<{
    favoriteFilesList: IFavouritesFile[];
    size?: number;
  }>(),
);

export const getFavoriteFilesFailed = createAction(
  `${ReducerSections.FILE} ${UsersActions.GetFavoritesFiles} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);

export const addUserAvatar = createAction(
  `${ReducerSections.USERS} ${UsersActions.AddAvatar} ${ReducerStatuses.INIT}`,
  props<{ avatar: { cropParams: ICropParams; originalImage: Pick<IImage, 'id'> } }>(),
);

export const addUserAvatarSuccess = createAction(
  `${ReducerSections.USERS} ${UsersActions.AddAvatar} ${ReducerStatuses.SUCCESS}`,
  props<{ avatar: IAvatar; userId: number }>(),
);

export const addUserAvatarFailed = createAction(
  `${ReducerSections.USERS} ${UsersActions.AddAvatar} ${ReducerStatuses.FAILED}`,
  props<{ error: HttpErrorResponse }>(),
);
