import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, first, map, Observable, of, take, timer } from 'rxjs';
import {
  changeEditCommentMode,
  editComment,
  getCurrentPostData,
  updatePostData,
} from 'src/app/data/store/actions/posts.actions';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';
import {
  selectCommentEditMode,
  selectCurrentCommentData,
  selectCurrentPost,
  selectReplyTo,
} from 'src/app/data/store/selectors/posts.selectors';
import { arrowRight, arrowRightSmall, befreeUrlValues } from 'src/app/shared/constants';
import {
  CurrentPlatform,
  DataType,
  MenuIconWidth,
  PostUserViewType,
  ScrollToType,
  TextSizes,
  TextType,
  UserDataImgSize,
  ViewType,
} from 'src/app/shared/enums';
import { PostsService } from 'src/app/shared/services/posts/posts.service';
import { IComment, ICommentReply, IImage, IPost } from 'src/app/shared/services/posts/posts.type';
import { InfiniteScrollCustomEvent, RefresherCustomEvent } from '@ionic/angular';
import {
  clearLikedList,
  getCurrentPhotoReportData,
} from '../../../../data/store/actions/photo-report.action';
import { PhotoReportsService } from '../../../../shared/services/photo-reports/photo-reports.service';
import { selectPhotoReportData } from '../../../../data/store/selectors/photo-report.selectors';
import { IPhotoReport } from '../../../../shared/services/photo-reports/photo-reports.type';
import { ScrollToService } from 'src/app/shared/services/scroll-to/scroll-to.service';
import { PostCommentComponent } from '../../../../shared/components/post-comment/post-comment.component';
import { Keyboard } from '@capacitor/keyboard';
import { TPushNavigationPayload } from 'src/app/shared/services/push/push.type';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { PhotoreportViewTypeIconsComponent } from '../photoreports/photoreports-page/photoreports-view-types/photoreport-view-type-icons/photoreport-view-type-icons.component';

@UntilDestroy()
@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss'],
})
export class PostViewComponent implements OnInit, OnDestroy {
  @Input() backNavigateUrl: string | undefined;

  @Input() public isCommentMode: boolean | undefined;

  @Input() setCommentMode: (() => void) | undefined | null;

  @Input() userViewType: PostUserViewType = PostUserViewType.full;

  @ViewChild('commentComponent') commentComponent: PostCommentComponent | undefined;

  @ContentChild('photoReportList') photoReportList?: PhotoreportViewTypeIconsComponent;

  public commentToScroll: TPushNavigationPayload | null = null;

  public data: IPhotoReport | IPost | null = null;

  public postData$: Observable<IPost | null> = this._store.select(selectCurrentPost);

  public photoReportData$: Observable<IPhotoReport | null> =
    this._store.select(selectPhotoReportData);

  public isCommentEditMode$: Observable<boolean> = this._store.select(selectCommentEditMode);

  public readonly TextType = TextType;

  public readonly TextSizes = TextSizes;

  public readonly arrowRightSmall = arrowRightSmall;

  public readonly arrowRight = arrowRight;

  public readonly ViewTypesList = ViewType;

  public postId: number = parseInt(this._activateRoute.snapshot.params['postId']);

  public readonly photoReportId: number = parseInt(this._activateRoute.snapshot.params['reportId']);

  public selectCurrentPost$ = this._store.select(selectCurrentPost);

  public selectCurrentPhotoReport$ = this._store.select(selectPhotoReportData);

  public platform$: Observable<CurrentPlatform | null> = this._store.select(selectCurrentPlatform);

  public replyToComment$: Observable<ICommentReply | null> = this._store.select(selectReplyTo);

  public readonly CurrentPlatform = CurrentPlatform;

  public readonly UserDataImgSize = UserDataImgSize;

  public readonly MenuIconWidth = MenuIconWidth;

  public readonly DataTypesList = DataType;

  public isOpen: boolean = false;

  public isLoading: boolean = false;

  public page: number = 0;

  public size: number = 20;

  private _totalElements: number = 0;

  public isAllCommentsFetched = false;

  public comments: IComment[] = [];

  public newComment: IComment | null = null;

  public isModalView: boolean = false;

  public isCommentEditMode: boolean = false;

  public currentComment: Partial<IComment> | undefined | null;

  public keyboardOpen: boolean = false;

  public currentPlatform$ = this._store.select(selectCurrentPlatform);

  public isNeedFocus = false;

  constructor(
    private readonly _activateRoute: ActivatedRoute,
    private _store: Store,
    private _postsService: PostsService,
    private _photoReportsService: PhotoReportsService,
    private _router: Router,
    private _scrollToService: ScrollToService,
    private _utils: UtilsService,
    private _cdRef: ChangeDetectorRef,
  ) {
    this.rerenderPhotoReportList = this.rerenderPhotoReportList.bind(this);
    this.commentToScroll = this._router.getCurrentNavigation()?.extras
      .state as typeof this.commentToScroll;
  }

  async handleBackgroundClick(event: MouseEvent) {
    if (this.keyboardOpen) {
      this.isNeedFocus = false;
      this.keyboardOpen = false;
      await Keyboard.hide();
      event.stopPropagation();
    }
  }

  public onCommentIconClick = () => {
    this.setCommentMode?.();
    this.isNeedFocus = true;
    this._cdRef.detectChanges();
  };

  public ngOnInit(): void {
    if (this.postId) {
      this._store.dispatch(getCurrentPostData({ postId: this.postId }));
      this._getComments();
    } else if (this.photoReportId) {
      this._store.dispatch(getCurrentPhotoReportData({ photoReportId: this.photoReportId }));
      this._getComments();
    } else {
      this._router.navigateByUrl(befreeUrlValues.dashboard);
    }
    combineLatest([this.photoReportData$, this.postData$])
      .pipe(
        map(([photoReport, postData]) => {
          return photoReport ? photoReport : postData;
        }),
      )
      .subscribe((data) => {
        this.data = data;
        this.openGalleryModal();
      });

    this._store
      .select(selectCurrentCommentData)
      .pipe(untilDestroyed(this))
      .subscribe((commentData) => (this.currentComment = commentData));

    this.currentPlatform$?.subscribe((platform) => {
      if (platform !== 'web') {
        Keyboard?.addListener('keyboardWillShow', () => {
          this._applyWriteMode();
          this.keyboardOpen = true;
        });

        Keyboard?.addListener('keyboardWillHide', () => {
          this.keyboardOpen = false;
        });
      }
    });

    this.isCommentEditMode$.pipe(untilDestroyed(this)).subscribe((isEditMode: boolean) => {
      if (!isEditMode) {
        this.editCommentId = null;
      }
    });
  }

  public editComment(commentText: string) {
    if (!this.currentComment?.id || !this.currentComment) return;
    this._store.dispatch(
      editComment({
        commentId: this.currentComment.id,
        commentData: {
          type: this.currentComment.type,
          replayFor: this.currentComment.replayFor,
          text: commentText,
        },
      }),
    );
    this.comments = this.comments.map((comment) =>
      comment.id === this.currentComment?.id && !this.currentComment.replayFor
        ? { ...comment, text: commentText }
        : comment,
    );
    this._store.dispatch(
      changeEditCommentMode({
        isEditMode: false,
        currentCommentData: {},
      }),
    );
    this.editCommentId = null;
  }

  public trackByFn(index: number, obj: any) {
    return obj.id;
  }

  private _getComments(showLastPage?: boolean, srollToLastComment?: boolean) {
    this.isLoading = true;
    let commentsData$;
    if (!this.photoReportId) {
      commentsData$ = this._postsService.getPostRootComments(this.postId, {
        page: this.page,
        size: this.size,
      });
    } else {
      commentsData$ = this._photoReportsService.getPhotoReportRootComments(this.photoReportId, {
        page: this.page,
        size: this.size,
      });
    }
    commentsData$
      .pipe(
        first(),
        catchError(() => of()),
      )
      .subscribe((commentsArray) => {
        if (!commentsArray) {
          return;
        }

        this._totalElements = commentsArray.totalElements;

        this.isLoading = false;

        if (showLastPage) {
          this.comments = commentsArray.elements;
          if (srollToLastComment) {
            this._cdRef.detectChanges();
            const lastComment = this.comments[this.comments.length - 1];
            setTimeout(() => {
              if (lastComment) {
                this.commentComponent?.scrollToComment(lastComment.id);
              }
            }, 500);
          }
        } else {
          this.comments = [...this.comments, ...commentsArray.elements];
        }
        this.isAllCommentsFetched = this.comments.length === commentsArray.totalElements;
        this.scrollToComment();
      });
  }

  public onScroll(event?: Event): void {
    (event as InfiniteScrollCustomEvent)?.target.complete();
    if (!this.isAllCommentsFetched && !this.isLoading) {
      this.page += 1;
      this._getComments();
    }
  }

  public getPrevious() {
    this.page = 0;
    this._getComments(true);
  }

  public refreshList(event: Event) {
    timer(2000)
      .pipe(take(1))
      .subscribe(() => {
        if (this.isLoading) {
          return;
        }
        this.isAllCommentsFetched = false;
        this.comments = [];
        this.getPrevious();
        (event as RefresherCustomEvent)?.target.complete();
      });
  }

  public createComment(commentData: { text: string; isEditMode: boolean }) {
    if (!commentData.text) return;
    if (commentData.isEditMode) {
      this.editComment(commentData.text);
      return;
    }
    this.replyToComment$.pipe(take(1)).subscribe((reply) => {
      let data: Partial<IComment> = {
        text: commentData.text,
        type: this.photoReportId ? 'PhotoReport' : 'Post',
      };

      if (reply) {
        Object.assign(data, { replayFor: { type: reply.type, id: reply.id } });
      }
      if (this.data) {
        this.data = {
          ...this.data,
          commentCount: this.data.commentCount + 1,
        };
      }
      if (!this.photoReportId) {
        this._addCommentToPost(data);
      } else {
        this._addCommentToPhotoreport(data);
      }
    });
  }

  private _addCommentToPhotoreport(data: Partial<IComment>) {
    if (this.isCommentMode === false) {
      this.setCommentMode?.();
    }
    this._photoReportsService
      .addCommentToPhotoReport(this.photoReportId, data)
      .subscribe((photoReportComment) => {
        this._applyComment(photoReportComment);
      });
  }

  private _addCommentToPost(data: Partial<IComment>) {
    this._postsService
      .addCommentToPost(this.postId, data)
      .pipe(untilDestroyed(this))
      .subscribe((postComment) => {
        this._applyComment(postComment);
      });
  }

  private _applyComment(postComment: IComment) {
    if (this.data) {
      this._store.dispatch(
        this.postId
          ? updatePostData({ postId: this.data.id })
          : getCurrentPhotoReportData({ photoReportId: this.data.id }),
      );
    }
    if (!postComment.replayFor) {
      this._updateMainList(postComment);
    } else {
      this.newComment = postComment;
    }
  }

  private _applyWriteMode() {
    if (this.data) {
      this._store.dispatch(
        this._router.url.includes(befreeUrlValues.posts)
          ? updatePostData({ postId: this.data.id })
          : getCurrentPhotoReportData({ photoReportId: this.data.id }),
      );
    }

    this._updateMainList(undefined, true);
  }

  private _updateMainList(comment?: IComment, scrollToLastComment?: boolean) {
    if (comment) {
      this.comments.push(comment);
    }
    this.page = Math.trunc(this._totalElements! / this.size);
    this._getComments(true, scrollToLastComment);

    if (comment) {
      setTimeout(() => this.commentComponent?.scrollToComment(comment.id), 500);
    }
  }

  private _scrollToBottom() {
    this._scrollToService.scrollToEvent({
      scrollTo: ScrollToType.bottom,
      isModalView: this.isModalView,
    });
  }

  private scrollToComment() {
    if (!this.comments.length || !this.commentToScroll || this.commentToScroll?.imageId) {
      return;
    }

    if (this.photoReportId && this.setCommentMode) {
      this.setCommentMode();
    }

    this._utils.scrollToComment(this.commentToScroll).then(() => {
      this.commentToScroll = null;
    });
  }

  private openGalleryModal() {
    if (this.data && this.photoReportId && this.commentToScroll?.imageId) {
      try {
        this._utils.dismissModal();
      } catch {}

      let index = -1;
      let images: IImage[] = [];

      Object.values((this.data as IPhotoReport).imagesData).every((categoryImages) => {
        const imageIndex = categoryImages.findIndex(
          (i) => i.id === +this.commentToScroll!.imageId!,
        );
        if (imageIndex > -1) {
          index = imageIndex;
          images = [...categoryImages];
          return false;
        }
        return true;
      });

      this._utils.openGalleryModalWithComments(
        'with-comments',
        {
          images,
          userData: this.data?.author,
          chosenImgIndex: index,
        },
        this.commentToScroll,
      );

      this.commentToScroll = null;
    }
  }

  ngOnDestroy(): void {
    this._store.dispatch(clearLikedList());
  }

  get postData() {
    return this.postId ? (this.data as IPost) : undefined;
  }

  get photoReportData() {
    return this.photoReportId ? (this.data as IPhotoReport) : undefined;
  }

  get showKeyboardOverlay() {
    const isInputFocused = document.activeElement?.tagName.toLowerCase() === 'textarea';
    return this.keyboardOpen || isInputFocused;
  }

  public editCommentId: number | null = null;

  changeCommentEditMode = (comment: IComment, editMode?: boolean) => {
    if (!comment) {
      return;
    }

    if (this.editCommentId === comment.id || editMode === false) {
      this.editCommentId = null;
    } else {
      this.editCommentId = comment.id;
    }
    this._store.dispatch(
      changeEditCommentMode({
        isEditMode: editMode === false ? false : this.editCommentId === comment.id,
        currentCommentData: editMode === false ? {} : comment,
      }),
    );
  };

  public rerenderPhotoReportList() {
    this.photoReportList?.rerenderList();
  }
}
