import { Injectable } from '@angular/core';
import { PostsService } from '../../../shared/services/posts/posts.service';
import {
  addFileToFavoritesList,
  addFileToFavoritesListSuccess,
  addToFavoritesListFailed,
  blockPost,
  blockPostSuccess,
  createNewPost,
  createNewPostFailed,
  createNewPostSuccess,
  deletePostByPostId,
  deletePostByPostIdFailed,
  deletePostByPostIdSuccess,
  dislikePost,
  dislikePostFailed,
  dislikePostSuccess,
  editComment,
  editCommentFailed,
  editCommentSuccess,
  editPost,
  editPostFailed,
  editPostSuccess,
  favoriteListToBeUpdated,
  getCurrentPostData,
  getCurrentPostDataFailed,
  getCurrentPostDataSuccess,
  getPostData,
  getPostDataFailed,
  getPostDataSuccess,
  initPostsData,
  likePost,
  likePostFailed,
  likePostSuccess,
  pinPostByPostId,
  pinPostByPostIdFailed,
  removeFileFromFavoritesList,
  removeFileFromFavoritesListSuccess,
  removeFromFavoritesListFailed,
  updatePostData,
  updatePostDataFailed,
  updatePostDataSuccess,
  voteInThePollAction,
  voteInThePollFailed,
  voteInThePollSuccess,
} from '../actions/posts.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { ScrollToService } from 'src/app/shared/services/scroll-to/scroll-to.service';
import { IComment, IPost } from '../../../shared/services/posts/posts.type';
import {
  befreeUrlValues,
  errorNotificationMessages,
  successNotificationMessages,
} from 'src/app/shared/constants';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DataType, ScrollToType } from 'src/app/shared/enums';
import { UserService } from '../../../shared/services/user/user.service';
import { selectFiltersState } from '../selectors/filters.selectors';
import { Store } from '@ngrx/store';
import { changeUploadingFilesList } from '../actions/uploading-files.actions';

@Injectable()
export class PostsEffects {
  constructor(
    private _actions$: Actions,
    private _postsService: PostsService,
    private _scrollToService: ScrollToService,
    private _router: Router,
    private _utils: UtilsService,
    private _userService: UserService,
    private _store: Store,
  ) {}

  public getPostsData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getPostData, initPostsData, createNewPostSuccess),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([action, filtersState]) => {
        let filters = {};
        if (action.type === getPostData.type) {
          filters = {
            page: filtersState.page,
            // startsFrom: filtersState.page === 0 ? null : action.startsFrom,
            size: filtersState.size,
          };
        }
        return this._postsService.getPosts(filters).pipe(
          map((posts) => {
            return getPostDataSuccess({
              posts: posts.elements,
              resetData: action.type === getPostData.type ? filtersState?.page === 0 : true,
              size: filtersState.size,
            });
          }),
          catchError((error) => of(getPostDataFailed({ error }))),
        );
      }),
    );
  });

  public createNewPost$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(createNewPost),
      switchMap((action) => {
        return this._postsService.createPost(action.post).pipe(
          map((post) => {
            this._utils.openNotification(successNotificationMessages.createPost);
            this._scrollToService.scrollToEvent({ scrollTo: ScrollToType.top });
            return createNewPostSuccess({ post: post, isPinned: action.isPinned });
          }),
          catchError((error) => {
            return of(createNewPostFailed({ error }));
          }),
        );
      }),
    );
  });

  public createNewPostSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(createNewPostSuccess),
      switchMap((action) => {
        if (action.isPinned && action.type === createNewPostSuccess.type)
          return of(
            pinPostByPostId({
              postId: action.post.id,
              postIsPinned: action.isPinned,
              isPostView: false,
              removeNotification: true,
              postCreationMode: action.type === createNewPostSuccess.type,
              noScrolling: action.isPinned,
            }),
          );
        return of();
      }),
    );
  });

  public editPost$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(editPost),
      switchMap((action) => {
        if (!action.post.id) return of();
        return this._postsService.editPost(action.post.id, action.post).pipe(
          map((post) => {
            this._utils.openNotification(successNotificationMessages.editPost);
            return editPostSuccess({ post: post, isPinned: action.isPinned });
          }),
          catchError((error) => {
            return of(editPostFailed({ error }));
          }),
        );
      }),
    );
  });

  public editPostSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(editPostSuccess),
      switchMap((action) => {
        if (action.isPinned !== undefined) {
          return of(
            pinPostByPostId({
              postId: action.post.id,
              postIsPinned: action.isPinned,
              isPostView: false,
              removeNotification: true,
              postCreationMode: false,
              noScrolling: action.isPinned,
              postEditMode: true,
            }),
          );
        }
        return of();
      }),
    );
  });

  public pinPostById$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(pinPostByPostId),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([action, filtersState]) => {
        return this._postsService.pinPost(action.postId).pipe(
          switchMap(() => {
            if (action.postIsPinned && !action.removeNotification) {
              this._utils.openNotification(successNotificationMessages.unpinPost);
            } else if (!action.removeNotification) {
              this._utils.openNotification(successNotificationMessages.pinPost);
            }
            if (!action.noScrolling) {
              this._scrollToService.scrollToEvent({ scrollTo: ScrollToType.top });
            }

            return this._postsService
              .getPosts({
                startsFrom: null,
                size: filtersState.size,
              })
              .pipe(
                map((posts) => {
                  return getPostDataSuccess({
                    posts: posts.elements,
                    resetData: true,
                    size: filtersState.size,
                  });
                }),
                catchError((error) => of(getPostDataFailed({ error }))),
              );
          }),
          catchError((error) => {
            return of(pinPostByPostIdFailed({ error }));
          }),
        );
      }),
    );
  });

  public deletePostById$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(deletePostByPostId),
      switchMap((action) => {
        return this._postsService.deletePost(action.postId).pipe(
          map(() => {
            this._utils.openNotification(successNotificationMessages.deletePost);
            if (action.isPostView) {
              this._router.navigateByUrl(befreeUrlValues.dashboard);
            }

            return deletePostByPostIdSuccess({
              deletePostId: action.postId,
            });
          }),
          catchError((error) => {
            this._utils.openNotification(errorNotificationMessages.default, true);
            return of(deletePostByPostIdFailed({ error }));
          }),
        );
      }),
    );
  });

  public voteInThePoll$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(voteInThePollAction),
      switchMap((action) => {
        return this._postsService.voteInThePoll(action.pollsId, action.pollsOptions).pipe(
          map(() => {
            return updatePostData({
              postId: action.postId,
              dataTypeToBeUpdated: DataType.poll,
            });
          }),
          catchError((error) => of(voteInThePollFailed({ error }))),
        );
      }),
    );
  });

  public getPostDataById$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(updatePostData),
      switchMap((action) => {
        return this._postsService.getPostById(action.postId).pipe(
          map((postData: IPost) => {
            if (action.dataTypeToBeUpdated === DataType.poll) {
              return voteInThePollSuccess({ postTobeUpdated: postData });
            }
            return updatePostDataSuccess({
              postToBeUpdated: postData,
              dataTypeToBeUpdated: action.dataTypeToBeUpdated,
            });
          }),
          catchError((error) => of(updatePostDataFailed({ error }))),
        );
      }),
    );
  });

  public getCurrentPostData$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentPostData),
      switchMap((action) => {
        return this._postsService.getPostById(action.postId).pipe(
          map((post) => {
            return getCurrentPostDataSuccess({
              post: post,
              isEditMode: action.isEditMode,
            });
          }),
          catchError((error) => {
            return of(getCurrentPostDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentPostDataSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentPostDataSuccess),
      mergeMap((action) => {
        if (action.isEditMode) {
          return of(
            changeUploadingFilesList({
              uploadingFiles: action.post,
              poll: action.post.poll,
              isEditMode: true,
            }),
          );
        }
        return of();
      }),
    );
  });

  public likePost$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(likePost),
      switchMap((action) => {
        return this._postsService.likePost(action.postId).pipe(
          map(() => {
            return likePostSuccess({
              postId: action.postId,
            });
          }),
          catchError((error) => {
            return of(likePostFailed({ error }));
          }),
        );
      }),
    );
  });

  public dislikePost$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(dislikePost),
      switchMap((action) => {
        return this._postsService.dislikePost(action.postId).pipe(
          map(() => {
            return dislikePostSuccess({
              postId: action.postId,
            });
          }),
          catchError((error) => {
            return of(dislikePostFailed({ error }));
          }),
        );
      }),
    );
  });

  public addToFavouritesList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(addFileToFavoritesList),
      switchMap((action) => {
        return this._userService.addToFavorites(action.fileId).pipe(
          map(() => {
            this._utils.openNotification(successNotificationMessages.addToFavorites);
            if (action.postId) {
              return addFileToFavoritesListSuccess({
                postId: action.postId,
              });
            } else {
              return favoriteListToBeUpdated({ fileId: action.fileId });
            }
          }),
          catchError((error) => {
            return of(addToFavoritesListFailed({ error }));
          }),
        );
      }),
    );
  });

  public removeFromFavouritesList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(removeFileFromFavoritesList),
      switchMap((action) => {
        return this._userService.removeFromFavorites(action.fileId).pipe(
          map(() => {
            this._utils.openNotification(successNotificationMessages.removeFromFavorites);
            if (action.postId) {
              return removeFileFromFavoritesListSuccess({
                postId: action.postId,
              });
            } else {
              return favoriteListToBeUpdated({ fileId: action.fileId });
            }
          }),
          catchError((error) => {
            return of(removeFromFavoritesListFailed({ error }));
          }),
        );
      }),
    );
  });

  public editComment$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(editComment),
      switchMap((action) => {
        return this._postsService.editComment(action.commentId, action.commentData).pipe(
          map((comment: IComment) => {
            return editCommentSuccess({
              commentId: action.commentId,
              commentData: comment,
            });
          }),
          catchError((error) => {
            return of(editCommentFailed({ error }));
          }),
        );
      }),
    );
  });

  public blockPost$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(blockPost),
      switchMap((action) => {
        return this._postsService.blockPost(action.postId, action.reason).pipe(
          map(() => blockPostSuccess()),
          catchError(() => of()),
        );
      }),
    );
  });
}
