<div class="filters">
  <form (ngSubmit)="hideKeyboard()">
    <ion-searchbar
      #searchbar
      mode="ios"
      inputmode="search"
      enterkeyhint="search"
      [placeholder]="searchBarText"
      [debounce]="600"
      (ionChange)="searchValueChange($event)"
    ></ion-searchbar>
  </form>
  <app-filters-button
    *ngIf="isNeedFiltersButton"
    [dataForFilters]="dataForFilters"
    [excludeSingleFiltersOptions]="excludeSingleFiltersOptions"
  ></app-filters-button>
</div>
