<ng-container *ngIf="notifications$ | async as notifies">
  <ng-container *ngIf="notifies.length">
    <ng-container *ngFor="let notify of notifies | slice : 0 : 3" [@appearance]>
      <div *ngIf="notify?.notification?.type" class="notification-container">
        <div class="notification-container__user">
          <app-image
            class="notification-container__user-avatar"
            [img]="notify.notificationAuthor.avatar?.previewSmall | addBaseUrl"
          ></app-image>
          <app-image
            class="notification-container__user-icon"
            *ngIf="notify.notification.type === notificationTypes.post"
            [img]="'./assets/images/icons-svg/notifications/_comment.svg'"
          ></app-image>
          <app-image
            class="notification-container__user-icon"
            *ngIf="notify.notification.type === notificationTypes.photoReport"
            [img]="'./assets/images/icons-svg/notifications/photo-report.svg'"
          ></app-image>
        </div>
        <div class="notification-container__content">
          <div class="notification-container__content__user-name">
            <app-text
              [textSize]="TextSizes.h4Name"
              [textType]="TextType.base"
              [text]="notify.notificationAuthor | getFullName"
            ></app-text>
          </div>
          <div class="notification-container__content__text">
            <app-text
              [textSize]="TextSizes.mobInput"
              [textType]="TextType.base"
              text="оставил(а) комментарий"
            ></app-text>
          </div>
          <div class="notification-container__content__message">
            <app-text
              [textSize]="TextSizes.notification"
              [textType]="TextType.base"
              [text]="notify.message"
            ></app-text>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
