<div class="post-information">
  <button
    type="button"
    class="post-information__item"
    [class.post-information__item_sm]="!postLikesCount || postLikesCount < 10"
    [class.post-information__item_md]="
      postLikesCount && postLikesCount >= 10 && postLikesCount < 100
    "
    [class.post-information__item_lg]="postLikesCount && postLikesCount >= 100"
    (click)="onLikePost()"
  >
    <svg class="like-icon" [ngClass]="{ 'like-icon_liked': postIsLiked }" icon="like"></svg>
    <p class="base-like">{{ postLikesCount }}</p>
  </button>

  <button
    type="button"
    *ngIf="!hideComments"
    class="post-information__item"
    [ngClass]="{ cursor_initial: onCommentIconClick === null }"
    [class.post-information__item_sm]="!postCommentsCount || postCommentsCount < 10"
    [class.post-information__item_md]="
      postCommentsCount && postCommentsCount >= 10 && postCommentsCount < 100
    "
    [class.post-information__item_lg]="postCommentsCount && postCommentsCount >= 100"
    (click)="onClickComments?.()"
  >
    <app-svg class="comment-icon" icon="posts/comment" alt="comment"></app-svg>
    <p class="base-like">{{ postCommentsCount }}</p>
  </button>
</div>
