import { IImage } from 'src/app/shared/services/posts/posts.type';
import { IUser } from 'src/app/shared/services/user/user.type';

export const GALLERY_KEY = 'galleryData';

export interface IGalleryState {
  images: IImage[];
  userData?: IUser;
  hideInputs?: boolean;
  hideComments?: boolean;
  chosenImgIndex: number;
  isLoopDisabled?: boolean;
  photoReportData?: {
    name: string;
    city: string;
    category: string;
  };
}

export enum GalleryActions {
  getGallery = 'getGallery',
  setGallery = 'setGallery',
  resetGallery = 'resetGallery',
  setGalleryImages = 'setGalleryImages',
  changeGalleryDataImageIndex = 'changeGalleryDataImageIndex',
}
