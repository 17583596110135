import { Component, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, from, map, Observable, take } from 'rxjs';
import {
  changeEditMode,
  createPhotoReportInit,
  getPhotoReportCategoriesInit,
} from 'src/app/data/store/actions/photo-report.action';
import {
  selectCategoriesData,
  selectIsEditMode,
  selectNotFilledCategoriesOfUploadedPhotos,
  selectPhotoReportData,
  selectPhotoReportsListIsLoading,
} from 'src/app/data/store/selectors/photo-report.selectors';
import {
  selectLocalId,
  selectUploadingFilesByCategory,
} from 'src/app/data/store/selectors/uploading-files.selectors';
import {
  Buttons,
  ButtonTextSizes,
  CurrentPlatform,
  FileType,
  TextSizes,
  TextType,
  ViewType,
} from 'src/app/shared/enums';
import {
  ICreatePhotoReportData,
  IPhotoReport,
  IPhotoReportCategory,
} from 'src/app/shared/services/photo-reports/photo-reports.type';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { selectCurrentPlatform } from '../../../../../../../data/store/selectors/platform.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  changeUploadedImageRotate,
  changeUploadedImageRotateSuccess,
  cleanCreatePhotoReportUploadingImages,
} from 'src/app/data/store/actions/uploading-files.actions';
import { IUploadingFile } from 'src/app/shared/services/posts/posts.type';
import { UtilsService } from 'src/app/shared/services/utils.service';
import {
  availableImageFormats,
  cssStyles,
  errorNotificationMessages,
} from 'src/app/shared/constants';
import { PostsService } from 'src/app/shared/services/posts/posts.service';

@UntilDestroy()
@Component({
  selector: 'app-create-photo-report',
  templateUrl: './create-photo-report.component.html',
  styleUrls: ['./create-photo-report.component.scss'],
})
export class CreatePhotoReportComponent implements OnInit {
  public currentMode: string = typeof ViewType;

  public currentPhotoReportData: IPhotoReport | null | undefined;

  public currentPhotoReportCategories: string[] | null | undefined;

  public readonly CurrentPlatform = CurrentPlatform;

  public readonly TextSizes = TextSizes;

  public readonly TextType = TextType;

  public readonly Buttons = Buttons;

  public readonly ButtonTextSizes = ButtonTextSizes;

  public readonly availableImageFormats = availableImageFormats;

  public isLoading = false;

  public isImageLoading = false;

  public selectedCategory: string = '';

  public imageIds: { id: number }[] = [];

  public categories: (IPhotoReportCategory & {
    showLeftArrow: boolean;
    showRightArrow: boolean;
  })[] = [];

  public categoriesList$ = this._store.select(selectCategoriesData);

  public uploadingImages$: Observable<{ [key: string]: IUploadingFile[] } | null> | undefined =
    this._store.select(selectUploadingFilesByCategory);

  public currentPlatform$: Observable<CurrentPlatform | null> | undefined =
    this._store.select(selectCurrentPlatform);

  private _shopId!: number;

  private _uploadedImages: {
    [key: string]: IUploadingFile[];
  } | null = null;

  private readonly _imagesLimit: number = 100;

  public createPhotoReportForm: FormGroup = this._fb.group({
    description: [''],
  });

  private _localId: number = 0;

  constructor(
    private _store: Store,
    private _fb: FormBuilder,
    private alertController: AlertController,
    private _utils: UtilsService,
    private _elRef: ElementRef,
    private _postService: PostsService,
  ) {}

  ngOnInit(): void {
    this._store.dispatch(getPhotoReportCategoriesInit());

    this._store
      .select(selectLocalId)
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => {
        this._localId = id;
      });

    // TODO: must simplify
    this.uploadingImages$?.pipe(untilDestroyed(this)).subscribe((fileData) => {
      this.currentPhotoReportCategories = fileData ? Object.keys(fileData) : undefined;
      this._uploadedImages = fileData;
      this.isImageLoading = fileData
        ? Object.values(fileData)
            .flat()
            .some((f) => f.isLoading)
        : false;

      if (fileData) {
        this.currentPhotoReportCategories?.forEach((category: any) => {
          fileData[category].forEach((file: IUploadingFile) => {
            if (
              file.id &&
              !file.error &&
              (!this.imageIds.length || !this.imageIds.find((img) => file.id === img.id))
            ) {
              this.imageIds = [...this.imageIds, { id: file.id }];
            }
          });
        });
      }
    });

    this._store
      .select(selectPhotoReportData)
      .pipe(untilDestroyed(this))
      .subscribe((reportData) => (this.currentPhotoReportData = reportData));

    this._store
      .select(selectPhotoReportsListIsLoading)
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => (this.isLoading = isLoading));

    this._store
      .select(selectIsEditMode)
      .pipe(untilDestroyed(this))
      .subscribe((currentMode) => {
        if (currentMode && this.currentPhotoReportData) {
          this.createPhotoReportForm.patchValue({
            description: this.currentPhotoReportData.description,
          });
        }
        this.currentMode = currentMode;
      });

    this.categoriesList$?.subscribe((categories) => {
      this.categories =
        categories?.map((category) => ({
          ...category,
          showLeftArrow: false,
          showRightArrow: true,
        })) || [];
    });
  }

  get form() {
    return this.createPhotoReportForm.controls;
  }

  public chooseImageForCategory(categoryName: string) {
    this.selectedCategory = categoryName;

    this._utils.openImagePickerModal(this._localId, categoryName, {
      chosenFilesLength: this._uploadedImages?.[categoryName]?.length || 0,
      limitLength: 0,
    });
  }

  public handleUploadImages(event: any, categoryName: string) {
    this.selectedCategory = categoryName;

    let loadingImagesLength = event.target.files.length;
    let currentFileLimit = this._imagesLimit;
    if (this._uploadedImages && this._uploadedImages[categoryName]) {
      currentFileLimit = this._imagesLimit - this._uploadedImages[categoryName].length;
    }

    if (loadingImagesLength > currentFileLimit) {
      this._utils.openNotification(errorNotificationMessages.photoReportPhotoLimit, true);
    }

    for (
      let i = 0;
      i < (loadingImagesLength <= currentFileLimit ? loadingImagesLength : currentFileLimit);
      i++
    ) {
      this._utils.uploadFile(FileType.image, event.target.files[i], this._localId, categoryName);
      this._localId++;
    }

    (event.target as HTMLInputElement).value = '';
  }

  public async confirmPhotoReportCreation() {
    await this._updateImages();

    let photoReportData: ICreatePhotoReportData;

    if (this.currentMode === ViewType.editPhotoReport) {
      photoReportData = {
        ...this.currentPhotoReportData,
        images: this.imageIds,
      };
      delete photoReportData.imagesData;
    } else {
      photoReportData = {
        shop: {
          id: this._shopId,
        },
        images: this.imageIds,
      };
    }

    if (
      (this.form['description'].value && this.currentMode !== ViewType.editPhotoReport) ||
      this.currentMode === ViewType.editPhotoReport
    ) {
      photoReportData = Object.assign(photoReportData, {
        description: this.form['description'].value,
      });
    }

    this._store.dispatch(
      createPhotoReportInit({
        createPhotoReportData: photoReportData,
        currentMode: this.currentMode,
      }),
    );
    this._store.dispatch(changeEditMode({ currentMode: ViewType.createPhotoReport }));
  }

  public handleDeleteChosenItem(localId: number, uploadId?: number, imageCategory?: string) {
    this._utils.handleDeleteChosenImage(localId, imageCategory, uploadId);

    this.imageIds = this.imageIds.filter((img) => img.id !== uploadId);
  }

  public createAlert() {
    if (this.imageIds.length) {
      this._store
        .select(selectNotFilledCategoriesOfUploadedPhotos)
        ?.pipe(take(1))
        .subscribe((notFilledCategories) => {
          let notFilledCategoriesArray: string[] | undefined = notFilledCategories?.map((cat) => {
            return cat.categoryValue !== '' ? cat.categoryValue : 'Без категории';
          });

          from(
            this.alertController.create({
              header: `Вы уверены, что хотите ${
                this.currentMode === ViewType.editPhotoReport
                  ? 'сохранить изменения'
                  : 'создать фотоотчёт'
              }?`,
              subHeader: notFilledCategoriesArray?.length
                ? 'У вас остались не заполнены  следующие альбомы: ' +
                  notFilledCategoriesArray.join('; ')
                : undefined,
              mode: 'ios',
              cssClass: 'custom-alert',
              backdropDismiss: false,
              buttons: [
                {
                  text: 'Отменить',
                  role: 'cancel',
                  cssClass: cssStyles.AlertBtn,
                },
                {
                  text: this.currentMode === ViewType.editPhotoReport ? 'Сохранить' : 'Создать',
                  role: 'confirm',
                  cssClass: cssStyles.AlertBtn,
                  handler: () => {
                    this.confirmPhotoReportCreation();
                  },
                },
              ],
            }),
          )
            .pipe(
              take(1),
              map((alert) => alert.present()),
            )
            .subscribe();
        });
    }
  }

  public closeAlert() {
    from(
      this.alertController.create({
        header:
          this.currentMode === ViewType.editPhotoReport
            ? 'Вы уверены, что хотите отменить редактирование фотоотчёта?'
            : 'Вы уверены, что хотите отменить создание фотоотчёта?',
        subHeader:
          this.currentMode === ViewType.editPhotoReport
            ? 'После отмены изменения будут утеряны'
            : 'После отмены фотоотчёт будет утерян',
        mode: 'ios',
        cssClass: 'custom-alert',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Назад',
            role: 'cancel',
            cssClass: 'alert-button',
          },
          {
            text: 'ОК',
            role: 'confirm',
            cssClass: 'alert-button',
            handler: () => {
              this._utils.dismissModal();
              this._store.dispatch(cleanCreatePhotoReportUploadingImages());
              this._store.dispatch(changeEditMode({ currentMode: ViewType.createPhotoReport }));
            },
          },
        ],
      }),
    )
      .pipe(
        take(1),
        map((alert) => alert.present()),
      )
      .subscribe();
  }

  public changeImageRotate(image: IUploadingFile, imageCategory: string) {
    const rotateAngle =
      image.localRotateAngle == null && image.rotateAngle
        ? image.rotateAngle
        : image.localRotateAngle == null
        ? 0
        : image.localRotateAngle;

    const payload = {
      id: image.id!,
      localId: image.localId,
      rotateAngle: rotateAngle === 270 ? 0 : rotateAngle + 90,
      imageCategory: imageCategory,
    };

    this._store.dispatch(
      this.currentMode === ViewType.editPhotoReport
        ? changeUploadedImageRotateSuccess(payload)
        : changeUploadedImageRotate(payload),
    );
  }

  public trackByFn(index: number) {
    return index;
  }

  private scrollTo(id: string, step: number) {
    const container = this._elRef.nativeElement.querySelector('#' + id);
    if (container) {
      const scrollLeft = container.scrollLeft + step;
      const widthDiff = container.scrollWidth - container.clientWidth;

      container.scrollLeft = scrollLeft;

      const categories = [...this.categories];
      const index = categories.findIndex((category) => category.categoryName === id);
      if (index > -1) {
        categories[index].showRightArrow = widthDiff > scrollLeft;
        categories[index].showLeftArrow = scrollLeft > 0 && widthDiff > 0;
        this.categories = categories;
      }
    }
  }

  public scrollLeft(id: string) {
    this.scrollTo(id, -150);
  }

  public scrollRight(id: string) {
    this.scrollTo(id, 150);
  }

  private async _updateImages() {
    if (this.uploadingImages$ && this.currentMode === ViewType.editPhotoReport) {
      try {
        this.isLoading = true;

        const promises: Promise<unknown>[] = [];
        const categories = await firstValueFrom(this.uploadingImages$);

        Object.values(categories || {}).forEach((images) => {
          images.forEach((image) => {
            if (image.localRotateAngle != null && image.rotateAngle !== image.localRotateAngle) {
              promises.push(
                firstValueFrom(
                  this._postService.changeImageRotateAngle(image.id!, image.localRotateAngle),
                ),
              );
            }
          });
        });

        await Promise.all(promises);
      } catch {}
    }
  }
}
