import { Injectable } from '@angular/core';
import { PostsService } from '../../../shared/services/posts/posts.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { ImgService } from '../../../shared/services/img/img.service';
import {
  changeUploadedImageRotate,
  changeUploadedImageRotateFailed,
  changeUploadedImageRotateSuccess,
  initUploadFile,
  initUploadFileFailed,
  initUploadFileSuccess,
  updateFileLocalUrl,
  uploadFileFailed,
  uploadFileSuccess,
} from '../actions/uploading-files.actions';
import { Store } from '@ngrx/store';
import { selectUploadingFilesState } from '../selectors/uploading-files.selectors';
import { UtilsService } from '../../../shared/services/utils.service';

@Injectable()
export class UploadingFilesEffects {
  constructor(
    private _actions$: Actions,
    private _postsService: PostsService,
    private _imageService: ImgService,
    private _postService: PostsService,
    private _store: Store,
    private _utils: UtilsService,
  ) {}

  public initUploadFile$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(initUploadFile),
      mergeMap((action) =>
        this._imageService.imageFileReader(action.file).pipe(
          concatLatestFrom(() => this._store.select(selectUploadingFilesState)),
          map(
            ([
              localUrl,
              {
                postEditMode,
                uploadingFiles,
                uploadingFilesOfEditedPost,
                uploadingFilesByCategory,
              },
            ]) => {
              // Проверяем добавлен ли уже файл с таким localId.
              // Нужно для конвертации heic и jpeg для отображения превью фото.
              let uploadedFile;
              if (action.imageCategory) {
                uploadedFile = uploadingFilesByCategory?.[action.imageCategory]?.find(
                  (f) => f.localId === action.localId,
                );
              } else if (postEditMode) {
                uploadedFile = uploadingFilesOfEditedPost.find((f) => f.localId === action.localId);
              } else {
                uploadedFile = uploadingFiles.find((f) => f.localId === action.localId);
              }

              if (uploadedFile) {
                return updateFileLocalUrl({
                  localId: action.localId,
                  localUrl: localUrl as string,
                  imageCategory: action.imageCategory,
                });
              }

              let filesByCategory;

              if (action.imageCategory) {
                filesByCategory = this._utils.getUploadingFilesByCategory(
                  {
                    localId: action.localId,
                    localUrl: localUrl as string,
                    localName: action.file.name,
                    localSize: action.file.size,
                    fileType: action.fileType,
                    localRotateAngle: 0,
                    isLoading: true,
                  },
                  action.file,
                  action.imageCategory,
                  uploadingFilesByCategory,
                );
              }

              return initUploadFileSuccess({
                localId: action.localId,
                localUrl: localUrl as string,
                localName: action.file.name,
                localSize: action.file.size,
                fileType: action.fileType,
                file: action.file,
                rotateAngle: action.rotateAngle,
                imageCategory: action.imageCategory,
                uploadingFilesByCategory: filesByCategory,
              });
            },
          ),
          catchError((error) => of(initUploadFileFailed({ error }))),
        ),
      ),
    );
  });

  public uploadFile$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(initUploadFileSuccess),
      mergeMap((action) =>
        this._postsService
          .uploadFile({
            file: action.file,
            rotateAngle: action.rotateAngle,
            imageCategory: action.imageCategory,
          })
          .pipe(
            switchMap((file) =>
              of(
                uploadFileSuccess({
                  localId: action.localId,
                  id: file.data.id,
                  imageCategory: action.imageCategory,
                  uploadedFileData: file.data,
                }),
              ),
            ),
            catchError((error) => {
              return of(uploadFileFailed({ error, localId: action.localId }));
            }),
          ),
      ),
    );
  });

  public changeUploadedImageRotate$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(changeUploadedImageRotate),
      mergeMap((action) => {
        return this._postService.changeImageRotateAngle(action.id, action.rotateAngle).pipe(
          switchMap(() =>
            of(
              changeUploadedImageRotateSuccess({
                localId: action.localId,
                id: action.id,
                imageCategory: action.imageCategory,
                rotateAngle: action.rotateAngle,
              }),
            ),
          ),
          catchError((error) =>
            of(changeUploadedImageRotateFailed({ localId: action.localId, error: error })),
          ),
        );
      }),
    );
  });
}
