import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ICONS_PATH } from './icons-path';

// этот компонент для того что бы изменять svg помощью css свойств, в ангуляре не предусмотрен такое управление svg

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg[icon]',
  template: '<svg:use [attr.href]="href"></svg:use>',
  styles: [':host { fill: transparent; stroke: transparent; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  icon = '';

  constructor(@Inject(ICONS_PATH) private readonly path: string) {}

  get href(): string {
    return `${this.path}/${this.icon}.svg#${this.icon}`;
  }
}
