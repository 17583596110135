import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import {
  selectIsAllListFetched,
  selectIsAllUnreadListFetched,
  selectNotificationsList,
  selectNotificationsListsIsLoading,
  selectUnreadNotificationsList,
  selectUnreadNotificationsListsIsLoading,
} from 'src/app/data/store/selectors/notifications.selectors';
import { NotificationPageType } from '../../enums';
import {
  getNotificationsList,
  getUnreadNotificationsList,
} from 'src/app/data/store/actions/notifications.action';
import { getCounters } from 'src/app/data/store/actions/users.actions';
import { INotification } from '../../services/notifications/notifications.type';

@Component({
  selector: 'app-common-notifications-list',
  templateUrl: './common-notifications-list.component.html',
  styleUrls: ['./common-notifications-list.component.scss'],
})
export class CommonNotificationsListComponent implements OnInit {
  @Input() isModalMode: boolean = false;

  @Input() pageType: NotificationPageType = NotificationPageType.all;

  public isLoading$: Observable<boolean> | null = null;

  public isAllLoaded$: Observable<boolean> | null = null;

  public notificationsList$: Observable<INotification[]> | null = null;

  public readonly notificationPageTypes = NotificationPageType;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    if (!this.isModalMode) {
      this._store.dispatch(getCounters());
    }

    this.isLoading$ = this.isModalMode
      ? this._store.select(selectUnreadNotificationsListsIsLoading)
      : this._store.select(selectNotificationsListsIsLoading);

    this.isAllLoaded$ = this.isModalMode
      ? this._store.select(selectIsAllUnreadListFetched)
      : this._store.select(selectIsAllListFetched);

    this.notificationsList$ = this.isModalMode
      ? this._store.select(selectUnreadNotificationsList)
      : this._store.select(selectNotificationsList);
  }

  public getNotificationsList() {
    this._store.dispatch(
      this.isModalMode
        ? getUnreadNotificationsList({ currentPageType: this.pageType })
        : getNotificationsList({ currentPageType: this.pageType }),
    );
  }
}
