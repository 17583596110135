import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPostsDataState, POSTS_DATA_KEY } from '../models/posts.model';

export const selectFeature = createFeatureSelector<IPostsDataState>(POSTS_DATA_KEY);

export const selectPostsData = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.posts,
);

export const selectPostsDataIsLoading = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.isLoading,
);

export const selectFavoritesIsLoading = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.isFavoritesLoading,
);

export const selectIsAllPstDataFetched = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.allPostsFetched,
);

export const selectIsPostModalOpened = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.isPostModalOpened,
);

export const selectVoteParticipants = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.voteParticipants,
);

export const selectCreatePoll = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.poll,
);

export const selectCurrentPost = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.currentPost,
);

export const selectEditedPost = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.editedPost,
);

export const selectReplyTo = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.replyTo,
);

export const selectDeletedPostId = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.deletedPostId,
);

export const selectPostToBeUpdated = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.postToBeUpdated,
);

export const selectFavouritesListToBeUpdated = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.favoritesList,
);

export const selectPostEditMode = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.isEditMode,
);

export const selectCommentEditMode = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.isEditCommentMode,
);

export const selectEditedCommentData = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.editedCommentData,
);

export const selectCurrentCommentData = createSelector(
  selectFeature,
  (state: IPostsDataState) => state.currentCommentData,
);
