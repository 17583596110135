import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  changeEditCommentMode,
  createReplyToCommentData,
} from 'src/app/data/store/actions/posts.actions';
import { selectCurrentUser } from 'src/app/data/store/selectors/users.selectors';
import { befreeUrlValues } from '../../constants';
import { CurrentPlatform, TextSizes, TextType } from '../../enums';
import { PostsService } from '../../services/posts/posts.service';
import { IComment } from '../../services/posts/posts.type';
import {
  selectReplyTo,
  selectCommentEditMode,
  selectEditedCommentData,
} from '../../../data/store/selectors/posts.selectors';
import { selectCurrentPlatform } from 'src/app/data/store/selectors/platform.selectors';

@UntilDestroy()
@Component({
  selector: 'app-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss'],
})
export class PostCommentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() comment: IComment | null = null;

  @Input() newComment: IComment | null = null;

  @Input() replyLevel = 0;

  @Input() editCommentId: number | null = null;

  @Input() preventScrollToReplies: boolean = false;

  @Input() changeCommentEditMode: ((comment: IComment, editMode?: boolean) => void) | undefined;

  @Input() rerenderPhotoReportList?: VoidFunction;

  readonly Platform = CurrentPlatform;

  readonly TextType = TextType;

  readonly TextSizes = TextSizes;

  public replyCommentId = 0;

  public isActive = false;

  public repliesCount = 0;

  public replyComments: IComment[] = [];

  public platform$ = this._store.select(selectCurrentPlatform);

  public currentUser$ = this._store.select(selectCurrentUser);

  constructor(private _store: Store, private _router: Router, private _postService: PostsService) {}

  ngOnInit(): void {
    this.repliesCount = this.comment?.repliesCount ?? 0;
    this.replyComments = this.comment?.replies || [];

    this._store
      .select(selectReplyTo)
      .pipe(untilDestroyed(this))
      .subscribe((reply) => {
        this.replyCommentId = reply?.id || 0;
      });

    this._store
      .select(selectEditedCommentData)
      .pipe(untilDestroyed(this))
      .subscribe((editedComment) => {
        if (editedComment) {
          this.replyComments = this.replyComments.map((comment) => {
            return comment.id === editedComment.id ? editedComment : comment;
          });
        }
      });

    this._store
      .select(selectCommentEditMode)
      .pipe(untilDestroyed(this))
      .subscribe((isEditMode: boolean) => {
        if (!isEditMode) {
          this.editCommentId = null;
        }
      });
  }

  ngOnChanges(): void {
    if (this.newComment?.replayFor?.id === this.comment?.id && !this.editCommentId) {
      this.updateList(this.newComment!);
    }
  }

  ngOnDestroy(): void {
    this._store.dispatch(
      changeEditCommentMode({
        isEditMode: false,
        currentCommentData: {},
      }),
    );
  }

  get authorUrl() {
    return ['/', befreeUrlValues.dashboard, befreeUrlValues.employees, this.comment!.author.id];
  }

  get commentText() {
    if (!this.comment) {
      return '';
    }

    if (!this.repliesCount) {
      return this.comment.text;
    }

    const [name, ...text] = this.comment.text.split(', ');
    return `<span>${name}</span>, ${text.join(', ')}`;
  }

  public editComment() {
    if (!this.editCommentId) {
      this._store.dispatch(createReplyToCommentData({ comment: null }));
    }
    this.changeCommentEditMode?.(this.comment!);
  }

  public replyToComment() {
    if (this.replyCommentId === this.comment!.id) {
      this.changeCommentEditMode?.(this.comment!, false);
      this._store.dispatch(createReplyToCommentData({ comment: null }));
    } else {
      this.changeCommentEditMode?.(this.comment!, false);
      this._store.dispatch(createReplyToCommentData({ comment: this.comment! }));
    }
  }

  public updateList(comment: IComment) {
    const replyComments = [...this.replyComments];
    const index = replyComments.findIndex((reply) => reply.id === comment.id);

    if (index > -1) {
      replyComments[index] = comment;
      this.replyComments = replyComments;
    } else {
      this.repliesCount += 1;
      replyComments.push(comment);
      this.replyComments = replyComments;

      setTimeout(() => {
        this.scrollToComment(comment.id);
        this._rerenderPhotoReportList();
      });
    }
  }

  public onAuthorClick() {
    this.isActive = true;
  }

  public scrollToComment(id: number) {
    const el = document.querySelector(`[data-comment-id="${id}"]`);
    el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  public trackRepliesBy(_index: number, comment: IComment) {
    return comment.id;
  }

  private _rerenderPhotoReportList() {
    this.platform$.pipe(first()).subscribe((platform) => {
      if (platform === CurrentPlatform.ios) {
        this.rerenderPhotoReportList?.();
      }
    });
  }
}
