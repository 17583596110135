<div class="option__chips">
  <ng-container *ngFor="let filterOption of selectedOptionData">
    <ion-chip [class.disabled]="isChipDisable"
      ><ion-label>
        <app-text
          *ngIf="selectedOptionName !== optionTypes?.endTraining"
          [textSize]="TextSizes.mobBody"
          [textType]="TextType.base"
          [text]="filterOption?.name ?? (filterOption | getFullName)"
        ></app-text>
        <app-text
          *ngIf="selectedOptionName === optionTypes?.endTraining"
          [textSize]="TextSizes.mobBody"
          [textType]="TextType.base"
          [text]="filterOption | dateFormat : false : true"
        ></app-text>
      </ion-label>
      <app-image
        class="option__chips-delete"
        *ngIf="!isChipDisable"
        [img]="removeChip"
        (click)="removeElem(selectedOptionName, filterOption.id)"
      ></app-image>
    </ion-chip>
  </ng-container>
</div>
