<ng-container *ngIf="postText">
  <div class="post-block">
    <p
      class="base-h3-body user-select-text"
      tabindex="-1"
      #postTextId
      [class.text-full]="showMore"
      [class.text-block]="!showMore"
      [innerHTML]="postText | getInnerHtml"
    ></p>
    <div
      *ngIf="isOverflown(postTextId)"
      (click)="showMore = true"
      class="button-h6-btn-small show-more"
      [textContent]="'Показать ещё'"
    ></div>
  </div>
</ng-container>
