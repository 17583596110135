import { Component, Input } from '@angular/core';
import { TextSizes, TextType } from '../../../enums';

@Component({
  selector: 'app-information-label',
  templateUrl: './information-label.component.html',
  styleUrls: ['./information-label.component.scss'],
})
export class InformationLabelComponent {
  @Input() text: string | undefined;

  readonly TextType = TextType;

  readonly TextSize = TextSizes;
}
