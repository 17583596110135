import { HttpErrorResponse } from '@angular/common/http';
import { ActiveFiltersSelectionsTypes, FiltersOptions } from 'src/app/shared/enums';
import { ICountryOrCity, IRegion } from 'src/app/shared/services/departments/departments.type';
import { IPositionsListItem } from 'src/app/shared/services/training/training.type';
import { IShop, IUser } from 'src/app/shared/services/user/user.type';

export const FILTERS_KEY = 'filtersData';

export interface IFiltersState {
  numOfActiveFilters?: ActiveFiltersSelectionsTypes;
  filtersState: IPaginationFilter;
  filtersMenuState: IAppliedFiltersMenu | null;
  filtersElementsList: any[];
  filtersElementsListQuery: string | null;
  isAllFiltersElementsFetched: boolean;
  isFiltersElementsListLoading: boolean;
  error: HttpErrorResponse | null;
}

export interface IAppliedFiltersMenu {
  filtersMenuParams?: IFiltersOptionsParam | null;
  favs?: boolean | null;
}

export interface IPaginationFilter {
  page: number;
  size: number;
  query: string | null;
  favs?: boolean | null;
  filtersList?: IFiltersOptionsParam | null;
}

export type FiltersOptionsType =
  | FiltersOptions.regions
  | FiltersOptions.cities
  | FiltersOptions.shops
  | FiltersOptions.depts
  | FiltersOptions.groups
  | FiltersOptions.potential
  | FiltersOptions.positions
  | FiltersOptions.trainer
  | FiltersOptions.stage
  | FiltersOptions.trainerRole
  | FiltersOptions.endTraining
  | FiltersOptions.segment;

export type IFiltersOptionsParam = {
  [key in FiltersOptionsType]?: IFiltersParams;
};

export interface IFiltersParams {
  order?: number;
  params: any[] | null;
  isFilterSingleButton?: boolean;
  isFilterExcludedInDependentFilters?: boolean;
}

export type FiltersRequestTypes =
  | IRegion[]
  | ICountryOrCity[]
  | IShop[]
  | IPositionsListItem[]
  | IUser[]
  | { name: number | string; id: number | string }[];

export enum FiltersActions {
  SetFiltersOptions = 'SetFiltersOptions',
  InitFilters = 'InitFilters',
  IncrementPage = 'IncrementPage',
  UpdateFilters = 'UpdateFilters',
  RefreshData = 'RefreshData',
  ApplyFilters = 'ApplyFilters',
  ClearFilters = 'ClearFilters',
  GetFilterElementsList = 'GetFilterElementsList',
  ClearFilterElementsList = 'ClearFilterElementsList',
  SearchValue = 'SearchValue',
  ChangeFavoriteState = 'ChangeFavoriteState',
  ClearFiltersStore = 'ClearFiltersStore',
  ApplySingleFilterAction = 'ApplySingleFilterAction',
  ExternalFiltersMenuCleaning = 'ExternalFiltersMenuCleaning',
  ApplySpecificFilters = 'ApplySpecificFilters',
}
