import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { ModalController } from '@ionic/angular';

@Injectable()
export class RouterEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _modalCtrl: ModalController,
  ) {}

  public routerEvent$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(() => {
          from(this._modalCtrl.getTop())
            .pipe(take(1))
            .subscribe((modal) => {
              if (modal) {
                modal.dismiss();
              }
            });
        }),
      );
    },
    { dispatch: false },
  );
}
