import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { successNotificationMessages } from 'src/app/shared/constants';
import { DepartmentsService } from 'src/app/shared/services/departments/departments.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import {
  addToFavorite,
  addToFavoriteFailed,
  addToFavoriteSuccess,
  currentShopDataFailed,
  currentShopDataSuccess,
  getCurrentShopsPhotoReports,
  getCurrentShopsPhotoReportsFailed,
  getCurrentShopsPhotoReportsSuccess,
  getShopsList,
  getShopsListFailed,
  getShopsListSuccess,
  initCurrentShopData,
  initShopData,
  removeFromFavorite,
  removeFromFavoriteFailed,
  removeFromFavoriteSuccess,
} from '../actions/shop.action';
import { FiltersOptions } from '../../../shared/enums';
import { PhotoReportsService } from '../../../shared/services/photo-reports/photo-reports.service';
import { selectFiltersMenuState, selectFiltersState } from '../selectors/filters.selectors';
import { Store } from '@ngrx/store';
import { IFilter } from '../../../shared/interfaces';

@Injectable()
export class ShopEffects {
  constructor(
    private _actions$: Actions,
    private _departmentsService: DepartmentsService,
    private _photoReportService: PhotoReportsService,
    private _userService: UserService,
    private _utils: UtilsService,
    private _store: Store,
  ) {}

  public getCurrentShop$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(initCurrentShopData),
      switchMap((action) => {
        return this._departmentsService.getCurrentShop(action.shopId).pipe(
          map((shop) => {
            return currentShopDataSuccess({ shop });
          }),
          catchError((error) => {
            return of(currentShopDataFailed({ error }));
          }),
        );
      }),
    );
  });

  public getShopsList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getShopsList, initShopData),
      concatLatestFrom(() => [
        this._store.select(selectFiltersState),
        this._store.select(selectFiltersMenuState),
      ]),
      switchMap(([action, filtersState, filtersMenu]) => {
        const { region, city, shop, favs } = this._utils.getFiltersFromUrl();

        let filters: IFilter = { page: 0, query: null };
        if (action.type === getShopsList.type) {
          filters = {
            page: filtersState.page,
            size: filtersState.size,
            query: filtersState.query,
            region: region?.value || null,
            city: city?.value || null,
            shop: shop?.value || null,
          };

          if (filtersMenu.filters?.filtersMenuParams && (!filtersMenu.filters.favs ?? !favs)) {
            filters = {
              ...filters,
              region: this._utils.setRequestFilterParams({
                filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
                filterOption: FiltersOptions.regions,
              }),
              city: this._utils.setRequestFilterParams({
                filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
                filterOption: FiltersOptions.cities,
              }),
              shop: this._utils.setRequestFilterParams({
                filtersOptionsParams: filtersMenu.filters.filtersMenuParams,
                filterOption: FiltersOptions.shops,
              }),
            };
          }
        }

        const getUrlEntity = (itemName: keyof IFilter, type: FiltersOptions) =>
          this._utils.getUrlEntity(filters, itemName, type, filtersMenu.filters?.filtersMenuParams);

        const filterToUrl = {
          region: getUrlEntity('region', FiltersOptions.regions),
          city: getUrlEntity('city', FiltersOptions.cities),
          shop: getUrlEntity('shop', FiltersOptions.shops),
        };

        this._utils.syncFiltersRouteQuery(filterToUrl, filtersMenu.filters?.favs ?? favs);

        return this._departmentsService.getShops(filters, false).pipe(
          map((shopList) => {
            return getShopsListSuccess({
              shopList: shopList,
              size: filtersState.size,
              resetData: filters.page === 0,
            });
          }),
          catchError((error) => {
            return of(getShopsListFailed({ error }));
          }),
        );
      }),
    );
  });

  public getCurrentShopsPhotoReports$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(getCurrentShopsPhotoReports),
      concatLatestFrom(() => [this._store.select(selectFiltersState)]),
      switchMap(([action, filtersState]) => {
        let filters: IFilter = {
          page: filtersState.page,
          size: filtersState.size,
          search: filtersState.query,
          shopId: action.shopId.toString(),
        };

        return this._photoReportService.getCurrentShopsPhotoReport(filters).pipe(
          map((photoReportsList) => {
            return getCurrentShopsPhotoReportsSuccess({
              currentShopsPhotoReports: photoReportsList.elements,
              size: filtersState.size,
              resetData: filters.page === 0,
            });
          }),
          catchError((error) => {
            return of(getCurrentShopsPhotoReportsFailed({ error }));
          }),
        );
      }),
    );
  });

  public addShopToFav$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(addToFavorite),
      switchMap((action) => {
        return this._userService.addShopToFavorite(action.shopId).pipe(
          map(() => {
            this._utils.openNotification(successNotificationMessages.addToFavorites);
            return addToFavoriteSuccess({ shopId: action.shopId });
          }),
          catchError((error) => {
            return of(addToFavoriteFailed({ error }));
          }),
        );
      }),
    );
  });

  public addToFavoriteSuccess$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(addToFavoriteSuccess),
        map(() => {
          this._utils.openNotification(successNotificationMessages.addToFavorites);
        }),
      );
    },
    { dispatch: false },
  );

  public removeFavShop$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(removeFromFavorite),
      switchMap((action) => {
        return this._userService.removeShopFromFavorite(action.shopId).pipe(
          map(() => {
            this._utils.openNotification(successNotificationMessages.removeFromFavorites);
            return removeFromFavoriteSuccess({ shopId: action.shopId });
          }),
          catchError((error) => {
            return of(removeFromFavoriteFailed({ error }));
          }),
        );
      }),
    );
  });

  public removeFromFavoriteSuccess$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(removeFromFavoriteSuccess),
        map(() => {
          this._utils.openNotification(successNotificationMessages.removeFromFavorites);
        }),
      );
    },
    { dispatch: false },
  );
}
