// Функционал, позволяющий копировать текст в рамках одного контейнера,
// имеющего класс user-select-text.

import { Capacitor } from '@capacitor/core';

const userSelectNone = 'user-select-none';
const userSelectText = 'user-select-text';

const currentPlatform = Capacitor.getPlatform();

function onFocusout() {
  const elements = document.getElementsByClassName(userSelectText);
  for (let index = 0; index < elements.length; index++) {
    elements[index].classList.remove(userSelectNone);
  }
}

function onSelectStart(selectionElement?: HTMLElement) {
  if (!selectionElement?.classList.contains(userSelectText)) {
    return;
  }
  const elements = document.getElementsByClassName(userSelectText);
  for (let index = 0; index < elements.length; index++) {
    if (selectionElement !== elements[index]) {
      elements[index].classList.add(userSelectNone);
    }
  }
}

function onSelectionChange() {
  const selectedText = document.getSelection()?.toString();
  const selectionElement = document.getSelection()?.anchorNode?.parentNode
    ?.nextSibling as HTMLElement;
  if (selectedText && selectionElement?.classList?.contains('show-more')) {
    selectionElement.click();
  }
}

if (currentPlatform === 'ios') {
  // IPhone не детектит событие selectstart, поэтому вызываем его в selectionchange
  document.addEventListener('selectionchange', function () {
    const selectionElement = document.getSelection()?.anchorNode?.parentNode as HTMLElement;
    onSelectionChange();
    onSelectStart(selectionElement);
  });

  let prevSelection = '';

  // IPhone не детектит событие focusout, поэтому делаем обработку в событии touchend
  document.addEventListener('touchend', function () {
    const selectedText = document.getSelection()?.toString();

    if ((prevSelection && !selectedText) || prevSelection === selectedText) {
      onFocusout();
    }

    prevSelection = selectedText || '';
  });
} else {
  // При начале выбора текста, всем элементам с классом user-select-text,
  // которые не являются элементом, в котором выбирается текст,
  // добавляем класс user-select-none.
  document.addEventListener('selectstart', function (event) {
    onSelectStart((event.target as HTMLElement)?.parentNode as HTMLElement);
  });

  // При расфокусе элемента, у всех элементов с классом user-select-text
  // удаляем класс user-select-none, делая их снова доступными для выбора.
  document.addEventListener('focusout', function () {
    onFocusout();

    // Снимаем текущее выделение при расфокусе элемента.
    if (window.getSelection) {
      window.getSelection()?.removeAllRanges();
    }
  });

  // Если выбрана хоть часть текста, то отображаем его полностью
  document.addEventListener('selectionchange', onSelectionChange);
}
