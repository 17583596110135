import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFilePicture',
})
export class GetFilePicturePipe implements PipeTransform {
  transform(name: string | undefined, isMobile: boolean): string {
    const extention = name?.split('.').pop()?.toLowerCase() || '';
    const validExtentions = [
      'doc',
      'docx',
      'gif',
      'heic',
      'heif',
      'jpeg',
      'jpg',
      'mov',
      'mp3',
      'mp4',
      'pdf',
      'png',
      'txt',
      'xls',
      'xlsx',
    ];

    if (validExtentions.includes(extention)) {
      if (isMobile) {
        switch (extention) {
          case 'doc':
          case 'docx':
          case 'txt':
            return 'assets/images/icons-svg/files/mobile/text.svg';
          case 'xls':
          case 'xlsx':
            return 'assets/images/icons-svg/files/mobile/table.svg';
          case 'pdf':
            return 'assets/images/icons-svg/files/mobile/pdf.svg';
          case 'gif':
          case 'heic':
          case 'heif':
          case 'jpeg':
          case 'jpg':
          case 'png':
            return 'assets/images/icons-svg/files/mobile/image.svg';
          case 'mov':
          case 'mp3':
          case 'mp4':
            return 'assets/images/icons-svg/files/mobile/video.svg';
          default:
            return 'assets/images/icons-svg/files/default.svg';
        }
      } else {
        return `assets/images/icons-svg/files/${extention}.svg`;
      }
    } else {
      return 'assets/images/icons-svg/files/default.svg';
    }
  }
}
