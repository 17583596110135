import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollPositionSaver {
  private scrollPositions: Record<string, number> = {};

  savePosition(route: string, position: number) {
    if (position) {
      this.scrollPositions[route] = position;
    }
  }

  getPosition(route: string): number {
    return this.scrollPositions[route] || 0;
  }

  deletePositions() {
    this.scrollPositions = {};
  }
}
